import { UIListHeaderTemplateDirective } from './list-header-template.directive';
import { UIListCellTemplateDirective } from './list-cell-template.directive';
import { Directive, Input, TemplateRef, ContentChild } from '@angular/core';
import { UIListFolderTemplateDirective } from './list-folder-template.directive';
import { UIListCellType } from '../../../../types/list-cell';

@Directive({
    // Current lint rules forces directives to be used as attributes
    // TODO: Either change the ruleset or update all the references of this directive
    // tslint:disable-next-line:directive-selector
    selector: 'ui-list-column'
})
export class UIListColumnDirective {
    /**
     * Name to show in header
     */
    @Input() name = '';

    /**
     * Property to display values from
     */
    @Input() property = '';

    /**
     * Type of data that should be displayed.
     */
    @Input() type: UIListCellType = 'auto';

    /**
     * Alignment of texts
     */
    @Input() align: 'left' | 'center' | 'right' = 'left';

    /**
     * If user should be able to sort by this column
     */
    @Input() sortable = true;

    /**
     * Werther values
     */
    @Input() filterable = true;

    /**
     * If cells should allow multiple row or not
     */
    @Input() multiline: boolean;

    /**
     * Hide column when list is bigger than this value.
     * Accepts a string in pixels or a number
     */
    @Input() hiddenAbove: number | string;

    /**
     * Hide column when list is smaller than this value
     * Accepts a string in pixels or a number
     */
    @Input() hiddenBelow: number | string;

    /**
     * Icon should be displayed in front of values. TO BE IMPLEMENTED.
     */
    @Input() icon: string;

    /**
     * Setting a description will add a info icon to the column header.
     * Hovering will show a tooltip containing the description string
     */
    @Input() description?: string;

    // @Input() tooltip: boolean;
    // @Input() tooltipText: string;

    /**
     * What to show for empty cells.
     */
    @Input() defaultValue: any;

    /**
     * Format to control display value of the cell.
     * Depending on value type use cases may differ.
     * Dates: 'MMM d, y' => 'Jun 15, 2015'
     * Percentages: '1.0-2' => '1.01%'
     * Numbers: '1.0-3' => '20.003'
     */
    @Input() format?: string;

    /**
     * Either set a fixed width in pixels or
     * By setting a number you can control how much a column can scale.
     * By setting first column width to 3 and the rest to 1
     * the first column will scale 3 times as much as the other ones.
     */
    @Input() set width(val: string | number) {
        if (!val) {
            this._width = '';
            this.fixedWidth = false;
        }
        // String whith a static unit (px or rem) is passed
        else if (typeof val === 'string' && val.toString().match(/(px|rem)$/)) {
            this._width = val;
            this.fixedWidth = true;
        }
        // Dynamic width (used to set grid column width)
        else {
            if (val.toString().match(/(%)$/)) {
                val = parseFloat(val as string) / 100.0;
            }
            this._width = `${val}fr`;
            this.fixedWidth = false;
        }
    }
    get width(): string | number {
        return this._width;
    }

    @ContentChild(UIListCellTemplateDirective, { read: TemplateRef })
    cellTemplate?: TemplateRef<any>;

    @ContentChild(UIListFolderTemplateDirective, { read: TemplateRef })
    folderTemplate?: TemplateRef<any>;

    @ContentChild(UIListHeaderTemplateDirective, { read: TemplateRef })
    headerTemplate?: TemplateRef<any>;

    /**
     * Set to true if column should be inline editable
     */
    @Input() editable = false;

    /**
     * Set to false if column header should not have a right-border
     */
    @Input() border = true;

    renderedWidth: string;
    fixedWidth = false;
    hidden: boolean;

    /**
     * In which order this is sorted
     */
    order: 'asc' | 'desc' | undefined;

    private _width: string;
}
