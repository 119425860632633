import { Component, OnInit, ViewChild } from '@angular/core';
import { IUIDialogConfig } from '../dialog-config.interface';
import { config } from 'rxjs';
import { UIDialogDirective } from '..';

@Component({
    selector: 'ui-dialog-docs-preview',
    templateUrl: './dialog.docs.preview.html',
    styleUrls: [
        './dialog.docs.preview.scss'
    ]
})
export class UIDialogDocsPreview implements OnInit {
    public config: IUIDialogConfig;

    ngOnInit() {
        this.config = {
            headerText: 'Dialog with dynamic config',
            panelClass: 'no-padding'
        };
    }

    changeConfig() {
        this.config = {
            headerText: 'Config Updated!',
            panelClass: 'world hello my class'
        };
    }
}
