<div
    class="container {{ type }}"
    (click)="onClick($event)">
    @if (loading) {
        <ui-loader></ui-loader>
    }
    <div class="content">
        @if (icon) {
            <ui-icon
                [icon]="icon"
                class="icon icon__leading">
            </ui-icon>
        }
        @if (svgIcon) {
            <ui-svg-icon
                [icon]="svgIcon"
                class="icon icon__leading">
            </ui-svg-icon>
        }
        @if (text) {
            <span class="text">
                {{ text }}
            </span>
        }

        @if (trailingIcon) {
            <ui-svg-icon
                [icon]="trailingIcon"
                class="icon icon__trailing">
            </ui-svg-icon>
        }
    </div>
</div>
