import { Component, ViewChild, ElementRef } from '@angular/core';
import { UITooltipService } from '../tooltip.service';

@Component({
    selector: 'ui-tooltip-docs-preview',
    templateUrl: './tooltip.docs.preview.html',
    styleUrls: ['./tooltip.docs.preview.scss']
})
export class UITooltipDocsPreview {

    @ViewChild('tooltip') tooltipEl: ElementRef;
    constructor(
        private tooltipService: UITooltipService
    ) {
        document.addEventListener('keydown', this.closeTooltips);
    }

    closeTooltips = (): void => {
        this.tooltipService.closeAll();
    };
}
