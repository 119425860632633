import { UIListNestedNodeType } from '../../../../types/nested-node-type';

export class UIListFlatNode {
    expanded = false;
    fullyLoaded = false;
    first = false;
    last = false;
    guid: string;
    edit = false;

    constructor(
        public data?: UIListNestedNodeType,
        public level: number = 1,
        public expandable: boolean = false
    ) {
        this.guid = `${Date.now()}_${Math.floor(Math.random() * 10000000)}`;
    }
}
