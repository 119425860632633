import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'ui-panel',
    templateUrl: 'panel.component.html',
    styleUrls: ['panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.ui-panel]': 'true'
    }
})
export class UIPanelComponent {
    @Input() name: string;
    @Input() buttonIcon: string;
    @Output() buttonClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
}
