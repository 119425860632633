import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'ui-header',
    templateUrl: 'header.component.html',
    styleUrls: ['header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.ui-header]': 'true',
        '[class.full]': 'full'
    }
})
export class UIHeaderComponent {
    @Output()
    logoClick: EventEmitter<MouseEvent> = new EventEmitter();

    @Input()
    full: boolean;

    @Input()
    showLogo = true;

    public onLogoClick(event: MouseEvent): void {
        this.logoClick.emit(event);
    }

    constructor() {}
}
