import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnChanges
} from '@angular/core';

@Component({
    selector: 'ui-tab',
    templateUrl: './tab.component.html',
    styleUrls: ['./tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UITabComponent implements OnChanges {
    @Input() name: string;
    @Input() dot: boolean;
    @Input() headerId: string;
    @Output() selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() tabChange: EventEmitter<void> = new EventEmitter<void>();

    private _selected = false;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    /**
     * Show or hide tab
     */
    @Input()
    set selected(selected: boolean) {
        if (this._selected !== selected) {
            this._selected = selected;
            this.selectedChange.emit(selected);
            this.changeDetectorRef.detectChanges(); // Need cause get/set doesn't trigger onChanges
        }
    }

    /**
     * Return boolean werther this tab is selected or not.
     */
    get selected(): boolean {
        return this._selected;
    }

    ngOnChanges(): void {
        setTimeout(() => {
            this.tabChange.emit();
        });
    }
}
