import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUIListDataNode } from './list-data-node';
import { propertyByPath } from '../../../../utils/property-by-path';
import { EventEmitter } from '@angular/core';

export class UIListDataSource<T> {
    /**
     * Database containing all data raw and unfiltered
     */
    dataStore: BehaviorSubject<IUIListDataNode[]> = new BehaviorSubject<IUIListDataNode[]>([]);

    /**
     * Observable listening on changes to dataStore.
     * Sorting and filtering before emitting the changes
     */
    dataChange: Observable<IUIListDataNode[]>;

    /**
     * Changes to single nodes
     */
    nodeChange = new EventEmitter<IUIListDataNode>();

    /**
     * Time to throttle sorting and filtering.
     * Big datasets should have a larger value.
     */
    throttle = 30;

    /**
     * When this is true the first item in the root of data
     * will act as a rootNode, only displaying it's children.
     */
    useRootNode = false;

    /**
     * Length of data in this datasource.
     * Note that front-end filtering does
     * not affect length of dataset.
     */
    length = 0;

    /**
     * Length of data when filtering is done.
     */
    filteredLength: number | undefined;

    /**
     * Custom filter function to override
     * native filter behaviour
     */
    customFilterFunction: (node: IUIListDataNode) => boolean;

    /**
     * Current "root-node" of data.
     * When set it will act as root node and only it's children will be visible
     */
    set currentNode(node: IUIListDataNode | undefined) {
        if (this._currentNode !== node) {
            this._currentNode = node;
            this.emit();
        }
    }

    /**
     * Get node that currently is displaying it's children
     */
    get currentNode(): IUIListDataNode | undefined {
        return this._currentNode;
    }

    /**
     * Get root node of data set
     */
    get rootNode(): IUIListDataNode | undefined {
        if (this.useRootNode) {
            return (this.data || [])[0];
        }
        return undefined;
    }

    /**
     * Werther the data show is root data
     */
    get inRootNode(): boolean {
        return (this.rootNode && this.rootNode === this.currentNode) === true;
    }

    /**
     * Get the raw data
     */
    get data(): IUIListDataNode[] {
        return this.dataStore.value;
    }

    /**
     * Set data and emit changes
     */
    set data(data: IUIListDataNode[]) {
        this.resetCurrentNode(data);
        this.dataStore.next(data);
    }

    /**
     * Set filter and trigger filter func
     */
    set filter(val: string | undefined) {
        this._filter = val;
        this.emit();
    }

    /**
     * Set filter and filter
     */
    get filter(): string | undefined {
        return this._filter;
    }

    private sortBy = ''; // Start with minus to sort descending
    private sortExpandbleFirst = true;

    private _filter?: string;
    public filterBy: string[] = [];
    private _currentNode?: IUIListDataNode;

    constructor(data: any[] = [], currentNode?: IUIListDataNode, useRootNode: boolean = false) {
        // If this should use a root node or not.
        this.useRootNode = useRootNode;

        // Used to set current node (a node which only the children is visible for)
        this._currentNode = currentNode;

        // Pipe modifiers to dataSource
        this.dataChange = this.dataStore
            .asObservable()
            // .pipe(throttle(() => timer(this.throttle)))
            .pipe(this.currentNodeFn) // Folder filter
            .pipe(map(this.filterFn)) // Filter
            .pipe(this.sortFn); // Sort

        // Set initial data
        this.data = data;
    }

    /**
     * Insert a single or multiple nodes
     * @param node Node to insert
     * @param parent Insert into parent. Ignore to add to root.
     */
    insert(
        nodes: any[] | any,
        parent?: IUIListDataNode,
        emit: boolean = true,
        addToTop?: boolean
    ): void {
        // Make sure nodes is an array
        if (!Array.isArray(nodes)) {
            nodes = [nodes];
        }

        // Insert in a parent node
        if (parent) {
            parent.children = parent.children || [];
            parent.children.push(...nodes);
        }
        // Insert in root
        else {
            if (addToTop) {
                this.data.unshift(...nodes);
            } else {
                this.data.push(...nodes);
            }
        }

        if (emit) {
            this.emit();
        }
    }

    /**
     * Remove a single node or multiple.
     * @param node Node to insert
     * @param parent Insert into parent. Ignore to add to root.
     */
    remove(nodes: any[] | any, emit: boolean = true): void {
        // Make sure nodes is an array
        if (!Array.isArray(nodes)) {
            nodes = [nodes];
        }

        nodes.forEach((node: IUIListDataNode) => {
            const parent = this.getParent(node);

            const children = (parent ? parent.children : this.data) || [];
            const indexOf = children.indexOf(node);

            if (!!~indexOf) {
                children.splice(indexOf, 1);
            } else {
                throw new Error('Could not remove node since no parent could be found');
            }
        });

        if (emit) {
            this.emit();
        }
    }

    /**
     * Move a single or multiple nodes.
     * @param node Node to insert
     * @param parent Insert into parent. Ignore to add to root.
     */
    move(nodes: any[] | any, parent?: IUIListDataNode): void {
        this.remove(nodes, false);
        this.insert(nodes, parent);
    }

    /**
     * Update values in a node.
     * @param node
     * @param updates
     */
    update(node: any, updates: any): void {
        if (typeof node === 'object' && typeof updates === 'object') {
            Object.assign(node, updates);
            this.emit(node);
        }
    }

    /**
     * Completely replace a single node.
     * @param node Node to insert
     * @param parent Insert into parent. Ignore to add to root.
     */
    replace(node: any, changes: any, parent?: IUIListDataNode): void {
        // Insert in a parent node
        if (parent) {
            parent.children = parent.children || [];
            const pos = parent.children.indexOf(node);
            parent.children.splice(pos, 1, changes);
        }
        // Insert in root
        else {
            const pos = this.data.indexOf(node);
            this.data.splice(pos, 1, changes);
        }

        this.emit();
    }

    /**
     * Remove all content in a node
     * @param node
     */
    clear(node?: any): void {
        if (node && this.isExpandable(node)) {
            node.children = [];
            this.emit();
        }
    }

    getParent(node: any): IUIListDataNode | undefined {
        return this.find((listNode: IUIListDataNode) => {
            if (this.isExpandable(listNode)) {
                return (listNode.children || []).some((child: IUIListDataNode) => child === node);
            }

            return false;
        });
    }

    /**
     * Sort by a specific property. Prefix with minus to sort descending
     * @param prop
     * @param order
     */
    sort(path: string = this.sortBy): void {
        this.sortBy = path || '';
        this.emit();
    }

    /**
     * Iterate all folders
     * @param callback Function to execute
     * @param rootFolder Optionally start search at a deeper node
     */
    forEach(callback: (node: IUIListDataNode) => void): void {
        const iterator = (node: IUIListDataNode) => {
            callback(node);

            if (node.children) {
                node.children.forEach(iterator);
            }
        };

        this.data.forEach(iterator);
    }

    /**
     * Get a folder from the subfolders of a folder
     * @param callback
     */
    public find(
        callback: (folder: IUIListDataNode) => boolean,
        searchIn: IUIListDataNode[] = this.data
    ): IUIListDataNode | undefined {
        let result: IUIListDataNode;
        const find = (
            nodes: IUIListDataNode[],
            callback: (folder: IUIListDataNode) => boolean
        ): IUIListDataNode => {
            for (const i in nodes) {
                const node: IUIListDataNode = nodes[i];
                if (callback(node)) {
                    return node;
                } else if (!result && node.children) {
                    result = find(node.children, callback);
                }
            }

            return result;
        };

        return find(searchIn, callback);
    }

    /**
     * Iterate all folders
     * @param callback Function to execute
     * @param rootFolder Optionally start search at a deeper node
     */
    forEachExpandable(callback: (node: IUIListDataNode) => void): void {
        const iterator = (node: IUIListDataNode) => {
            if (this.isExpandable(node)) {
                callback(node);
                node.children!.forEach(iterator);
            }
        };

        this.data.forEach(iterator);
    }

    /**
     * Function to tell if node is expandable / folder or not
     * @param node
     */
    isExpandable(node: IUIListDataNode): boolean {
        return node.children !== undefined;
    }

    /**
     * Notify all subscribers
     */
    emit(modifiedNode?: IUIListDataNode): void {
        this.length = this.getLength();
        this.dataStore.next(this.data);

        if (modifiedNode) {
            this.nodeChange.emit(modifiedNode);
        }
    }

    /**
     * Check if current node still exists in data.
     * If not, clear it to make refresh of data easier
     * @param data
     */
    private resetCurrentNode(data: IUIListDataNode[] = this.data): void {
        if (this.currentNode) {
            const isCurrentNode = (node: IUIListDataNode) => node === this.currentNode;
            this.currentNode = this.find(isCurrentNode, data);
        }
    }

    private getLength(): number {
        let length = 0;

        if (this.data) {
            // Root nodes doesn't affect length
            if (!this.useRootNode) {
                length += this.data.length;
            }

            this.forEachExpandable(
                expandable => (length += expandable.children ? expandable.children.length : 0)
            );
        }

        return length;
    }

    /**
     * Sort nodes function. Used only in observable.pipe
     * @param prop
     * @param order
     */
    private sortFn = map((data: IUIListDataNode[]) => {
        // Only sort if settings are provided
        if (this.sortBy !== undefined) {
            // If sort is prefixed by minus, sort descending
            const direction: number = this.sortBy.indexOf('-') === 0 ? 1 : -1;
            const prop = this.sortBy.replace(/^-/, '');

            // Sort rows
            const arraySortFn = (a: IUIListDataNode, b: IUIListDataNode): number => {
                let valueA: any = propertyByPath(a, prop, '');
                let valueB: any = propertyByPath(b, prop, '');

                if (typeof valueA === 'boolean') {
                    valueA = valueA.toString();
                }

                if (typeof valueB === 'boolean') {
                    valueB = valueB.toString();
                }

                // Prioritize expandable nodes first
                if (this.sortExpandbleFirst) {
                    const expA = this.isExpandable(a);
                    const expB = this.isExpandable(b);

                    // If one is expandable and the other not, sort by that fact
                    if (expA !== expB) {
                        return expB ? 1 : -1;
                    }
                }

                // To ignore case in sorting
                if (typeof valueA === 'string') {
                    valueA = valueA ? valueA.toLowerCase() : valueA;
                    valueB = valueB ? valueB.toLowerCase() : valueB;

                    return valueA.localeCompare(valueB) * -direction;
                }
                // Support date values
                if (valueA instanceof Date) {
                    valueA = valueA.getTime();
                    valueB = valueB.getTime();
                }

                // To number sort (eg. 10 should be after 1)
                if (!isNaN(valueA)) {
                    valueA = parseFloat(valueA);
                    valueB = parseFloat(valueB);
                }

                return valueA > valueB ? -direction : valueA < valueB ? direction : 0;
            };

            // Sort "root"
            data.sort(arraySortFn);

            // For all children and subchildren
            this.forEachExpandable((node: IUIListDataNode) => {
                if (node.children) {
                    node.children.sort(arraySortFn);
                }
            });
        }

        return data;
    });

    /**
     * Filter nodes. Used only in observable.pipe
     * @param prop
     * @param order
     */
    private filterFn = (data: IUIListDataNode[] = []) => {
        if ((this.filter || this.customFilterFunction) && data.length) {
            const arrayFilterFn = (_data: IUIListDataNode[]): IUIListDataNode[] => {
                const result = _data.filter(node => {
                    let filteredChildren = false;

                    // Nest this functionality
                    if (node.children && node.children.length) {
                        // Check if we get any matches so we don't remove the parent of children that matches filter
                        filteredChildren = arrayFilterFn(node.children).length > 0;
                    }

                    // Both conditions can have child nodes so we first set the filtered child node
                    let passFilter = filteredChildren;

                    if (!passFilter) {
                        // Filter function, use custom filter function if it exists
                        // else use native filter function
                        const searchFn = (property: string, node: IUIListDataNode) => {
                            const queries: string[] = this.filter!.toLowerCase().split(' ');
                            return queries.every((query: string) =>
                                `${node[property]}`.toLowerCase().trim().includes(query)
                            );
                        };

                        if (this.customFilterFunction) {
                            if (!passFilter) {
                                passFilter = this.customFilterFunction(node);
                            }
                        } else {
                            // If user wants to filter out some properties
                            if (this.filterBy.length > 0) {
                                this.filterBy.forEach((property: string) => {
                                    if (node.hasOwnProperty(property)) {
                                        passFilter = searchFn(property, node);
                                    }
                                });
                            } else {
                                Object.keys(node).forEach((property: string) => {
                                    // skip searching inside these properties
                                    const skip = [
                                        'totalCount',
                                        'children',
                                        'removedByFilter',
                                        'disabled',
                                        'isLoading',
                                        'editing'
                                    ].includes(property);
                                    if (!passFilter && !skip) {
                                        passFilter = searchFn(property, node);
                                    }
                                });
                            }
                        }
                    }

                    node.removedByFilter = !passFilter;

                    return passFilter;
                });

                this.filteredLength = result.length;
                return result;
            };

            // Filter "root"
            arrayFilterFn(data);
        }
        // Reset filtering
        else {
            this.filteredLength = undefined;
            this.forEach(node => (node.removedByFilter = false));
        }

        return data;
    };

    /**
     * Filter to only show the descendants of currentNode.
     */
    private currentNodeFn = map((data: IUIListDataNode[]) => {
        if (this.currentNode) {
            return this.currentNode.children ? this.currentNode.children.slice(0) : [];
        } else if (this.rootNode) {
            return this.rootNode.children ? this.rootNode.children.slice(0) : [];
        }
        return data;
    });
}
