import { Injectable } from '@angular/core';
import {
    UIConfirmDialogConfig,
    UICONFIRMDIALOG_DEFAULT_CONFIG
} from './confirm-dialog-config.interface';
import { UIDialogService } from '../dialog/dialog.service';
import { UIConfirmDialogComponent } from './confirm-dialog.component';
import { UIConfirmDialogResult } from '../../../types/dialog';

@Injectable({ providedIn: 'root' })
export class UIConfirmDialogService {
    constructor(public dialogService: UIDialogService) {}

    async confirm(config: UIConfirmDialogConfig): Promise<UIConfirmDialogResult> {
        if (config.discardText) {
            config.__discardable = true;
        }
        if (!config.maxWidth && config.__discardable && config.showCancelButton) {
            config.maxWidth = (UICONFIRMDIALOG_DEFAULT_CONFIG.maxWidth as number) + 20;
        }
        const dialogConfig = { ...UICONFIRMDIALOG_DEFAULT_CONFIG, ...config };
        // only open unique dialogs if Id present (prevents stacking on top)
        if (this.dialogService.hasDialog(dialogConfig.id)) {
            return 'prevented';
        }
        const dialogRef = this.dialogService.openComponent(UIConfirmDialogComponent, dialogConfig);
        await dialogRef.afterViewInit;
        const confirm = await dialogRef.subComponentRef.instance.initiate(config);
        dialogRef.close();
        return confirm;
    }
}
