import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewRef
} from '@angular/core';
import { Icon } from '../../icon/svg-icon/icons';

@Directive({
    // Should not be used
    selector: '[ui-selectable-base]'
})
export class UISelectableBaseDirective implements IUISelectable, OnDestroy, OnChanges {
    @Input() value: any;
    @Input() icon: string;
    @Input() svgIcon: Icon | '';
    @Input() flag: string;
    @Input() image: string;
    @Input() withBorder: boolean;

    @HostBinding('class.disabled')
    @Input()
    disabled!: boolean;

    @HostBinding('class.selected')
    @Input()
    selected: boolean;

    @HostBinding('class.previewed')
    @Input()
    previewed: boolean;

    /**
     * Prevent emitting when the input value changes by opting out (false)
     */
    @Input() emitOnInputValueChange = true;

    /**
     * Change event, trigger
     */
    @Output('selectedChange')
    public selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Select event, trigger. The "on" is to avoid name conflict with select method
     */
    @Output('select')
    public selectEmitter: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Preview via hotkeys event, trigger. The "on" is to avoid name conflict with select method
     */
    @Output('preview')
    public previewEmitter: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Hover preview event, trigger
     */
    @Output('hoverPreview')
    public hoverPreviewEmitter: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Deselect event, trigger. The "on" is to avoid name conflict with deselect method
     */
    @Output('deselect')
    public deselectEmitter: EventEmitter<any> = new EventEmitter<any>();

    // Should this be protected maybe? Change if needed.
    protected _selected = false;

    /**
     * Fires as soon selection changes for this component (regardless of silentEmits)
     */
    protected _selectedChangeEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

    public shiftPressed: boolean;

    constructor(
        public host: ElementRef,
        public changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnChanges(change: SimpleChanges): void {
        if (change['selected']) {
            this.setSelected(change['selected'].currentValue, !this.emitOnInputValueChange);
        } else if (change['previewed']) {
            this.setPreview(change['previewed'].currentValue);
        }
    }

    select(): void {
        this.setSelected(true);
    }

    preview(): void {
        this.setPreview(true);
    }

    deselect(): void {
        this.setSelected(false);
    }

    toggleSelect(): void {
        this.setSelected(!this.selected);
    }

    setPreview(previewed: boolean): void {
        if (this.previewed !== previewed) {
            this.previewed = previewed;

            if (previewed) {
                this.previewEmitter.emit(this.getValue());
            }

            // Trigger change detection on children components (needed when ChangeDetectionStrategy.OnPush is set on sub components)
            if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
                this.changeDetectorRef.detectChanges();
            }
        }
    }

    emitHoverPreview(): void {
        this.hoverPreviewEmitter.emit(this.getValue());
    }

    /**
     * Set selected to true or false. Can be done by just change selected instead. Use this to suppress events.
     * @param val true/false if this should be selected or not
     * @param silent Set to true if the component should not emit selection event
     */
    setSelected(selected: boolean = false, silentEmit?: boolean): void {
        if (this.selected !== selected) {
            this.selected = selected;

            if (!silentEmit) {
                this.selectedChange.emit(selected);
                if (selected) {
                    this.selectEmitter.emit(this.getValue());
                } else {
                    this.deselectEmitter.emit(this.getValue());
                }
            }

            this._selectedChangeEmitter.emit(selected);

            // Trigger change detection on children components (needed when ChangeDetectionStrategy.OnPush is set on sub components)
            if (this.changeDetectorRef && !(this.changeDetectorRef as any).destroyed) {
                this.changeDetectorRef.detectChanges();
            }
        }
    }

    /**
     * Get value parameter if defined. If not set the component will be returned. Intended to be used to get an accurate select/deselect
     */
    getValue(): any {
        return this.value !== undefined ? this.value : this;
    }

    ngOnDestroy(): void {
        this.changeDetectorRef.detach();
    }
}

export interface IUISelectable {
    value?: any;
    selected: boolean;
    disabled?: boolean;
    selectedChange: EventEmitter<boolean>;
}
