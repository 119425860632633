import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    Output
} from '@angular/core';
import { Icon } from '../../icon/svg-icon/icons';
import { Subject } from 'rxjs';

@Component({
    selector: 'ui-dropdown-item',
    templateUrl: 'dropdown-item.component.html',
    styleUrls: ['./dropdown-item.component.scss'],
    host: {
        class: 'ui-dropdown-item',
        '[class.disabled]': `disabled`,
        '[class.has-toggle]': 'isToggable',
        '[class.has-arrow]': 'uiDropdownTarget'
    }
})
export class UIDropdownItemComponent implements OnDestroy {
    @Output() toggleChange = new EventEmitter<boolean>();
    @Input() disabled?: boolean;
    @Input() toggle?: boolean;
    @Input() loading?: boolean;
    @Input() label: string;
    @Input() icon: string;
    @Input() svgIcon: Icon | '';
    @Input() uiDropdownTarget: any;
    @Input() preventCloseOnClick: boolean;
    @Input() preventToggleChange?: boolean;

    get isToggable(): boolean {
        return typeof this.toggle !== 'undefined';
    }

    triggersSubdropdown = false;
    hovered = new Subject<UIDropdownItemComponent>();
    @Output() itemClicked = new EventEmitter<UIDropdownItemComponent>();

    constructor(private _elementRef: ElementRef<HTMLElement>) {}

    ngOnDestroy(): void {
        this.hovered.complete();
    }

    @HostListener('mouseenter') mouseEnterEvent(): void {
        if (!this.disabled || !this.loading) {
            this.hovered.next(this);
        }
    }
    @HostListener('click', ['$event']) clickEvent(): void {
        if (this.preventToggleChange) {
            return;
        }

        if (!this.disabled || !this.loading) {
            if (this.isToggable) {
                this.toggle = !this.toggle;
                this.toggleChange.emit(this.toggle);
            }
            this.itemClicked.emit(this);
        }
    }

    _getHostElement(): HTMLElement {
        return this._elementRef.nativeElement;
    }
}

@Component({
    selector: 'ui-dropdown-divider',
    template: ``,
    styles: [
        `
            :host {
                margin: 3px 0;
                height: 1px;
                background: var(--divider-color);
                display: block;
            }
        `
    ]
})
export class UIDropdownDividerComponent {}
