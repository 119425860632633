import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { UITheme } from '../types/theme';
import { UIGlobalEvent } from './global-event';

@Injectable({ providedIn: 'root' })
export class UIThemeService {
    private theme: UITheme = 'default';
    private layout: UITheme = 'default';

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private globalEvent: UIGlobalEvent
    ) {
        this.getFromUrl();
        this.setStyle(this.theme, this.layout);
    }

    /**
     * Get current theme
     */
    public getActiveTheme(): UITheme {
        return this.theme ? this.theme : 'default';
    }

    /**
     * Get current layout
     */
    public getActiveLayout(): UITheme {
        return this.layout ? this.layout : 'default';
    }

    /**
     * Change style of the app.
     * If no theme and/or layout is provided it will go back to default.
     *
     * @param theme Theme to change to
     * @param layout Layout to change to
     * @param reflectUrl Set to true if you want your url to reflect active theme
     */
    public setStyle(theme?: UITheme, layout?: UITheme, includeInUrl: boolean = false): void {
        if (theme && theme !== this.theme) {
            this.theme = theme;
        }

        if (layout && layout !== this.layout) {
            this.layout = layout;
        }

        document.body.setAttribute('ui-theme', `${this.theme} ${this.layout}`);

        // Not all components can react to CSS changes. So we need to emit a global event.
        this.globalEvent.emit('theme-change');

        if (includeInUrl) {
            this.setInUrl();
        }
    }

    /**
     * Get theme and layout from current location (URL) and
     * set them to the theme service variables
     */
    private getFromUrl(): void {
        const search = 'URLSearchParams' in window ? new URLSearchParams(location.search) : undefined;

        if (search) {
            const theme: string | null = search.get('theme');
            const layout: string | null = search.get('layout');

            if (theme) {
                this.theme = theme as UITheme;
            }

            if (layout) {
                this.layout = layout as UITheme;
            }
        }
    }

    /**
     * Update current url to contain current theme as query paramets
     */
    private setInUrl(): void {
        const queryParams: Params = { ...this.activatedRoute.snapshot.queryParams };
        queryParams['theme'] = this.theme || 'default';
        queryParams['layout'] = this.layout || 'default';

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams
        });
    }
}
