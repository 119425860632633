import { Subscription, Observable, of, merge } from 'rxjs';
import {
    OnInit,
    OnDestroy,
    AfterContentInit,
    EventEmitter,
    ElementRef,
    Optional,
    Self,
    Input,
    Directive,
    Output,
    HostListener
} from '@angular/core';
import { UIDropdownComponent } from './dropdown.component';
import { UIDropdownItemComponent } from './dropdown-item.component';
import { filter } from 'rxjs/operators';

@Directive({
    // Current lint rules forces directives to be kebab-case
    // TODO: Either change the ruleset or update all the references of this directive
    // tslint:disable-next-line:directive-selector
    selector: '[uiDropdownTarget]',
    exportAs: 'uiDropdownTarget',
    host: {
        '[class.is-highlighted]': 'dropdownOpen'
    }
})
export class UIDropdownTargetDirective implements OnInit, OnDestroy, AfterContentInit {
    private hoverSubscription$ = Subscription.EMPTY;
    private closeSubscription$ = Subscription.EMPTY;
    private dropdownSubscription = Subscription.EMPTY;

    private _dropdownOpen = false;

    @Output() dropdownOpened = new EventEmitter<void>();
    @Output() dropdownClosed = new EventEmitter<void>();
    @Input() dropdownData: any = {};

    get dropdownOpen(): boolean {
        return this._dropdownOpen;
    }

    /**
     * @summary:
     *   Set to false to enable underlying components interaction
     */
    @Input() hasBackdrop?: boolean;

    /**
     * @summary:
     * (Directive) Should target a reference variable set on the dropdown component, e.g #dropdown
     */
    @Input('uiDropdownTarget') dropdownComponent: UIDropdownComponent;

    constructor(
        public hostElementRef: ElementRef<UIDropdownComponent & HTMLElement>,
        @Optional() public parentDropdown: UIDropdownComponent,
        @Optional() @Self() private dropdownItem: UIDropdownItemComponent
    ) {
        if (dropdownItem) {
            dropdownItem.triggersSubdropdown = this.triggersSubDropdown();
        }
    }

    @HostListener('click') onClick(): void {
        if (!this.triggersSubDropdown()) {
            this.toggleDropdown();
        }
    }

    @HostListener('mouseenter') onMouseEnter(): void {
        if (this.triggersSubDropdown()) {
            this.subscribeHover();
            this.openDropdown();
        }
    }

    @HostListener('mouseleave') onMouseLeave(): void {
        if (this.triggersSubDropdown()) {
            this.hoverSubscription$.unsubscribe();
        }
    }

    ngOnInit(): void {
        if (!(this.dropdownComponent instanceof UIDropdownComponent)) {
            throw new Error(
                'uiDropdownTarget must be referencing to a reference variable on the dropdown component, e.g #dropdown. Make sure you are not passing just a string value.'
            );
        }
    }

    toggleDropdown(): void {
        return this._dropdownOpen ? this.closeDropdown() : this.openDropdown();
    }

    ngOnDestroy(): void {
        if (this.dropdownComponent) {
            this.dropdownComponent.closePopover();
        }
        this.unsubscribeToActions();
        this.dropdownClosed.unsubscribe();
    }

    ngAfterContentInit(): void {
        this.subscribeDropdownComponent();
    }

    openDropdown(): void {
        if (!this.dropdownOpen && !this.dropdownComponent.disabled) {
            this.closeSubscription$ = this.closingActions().subscribe(() => this.closeDropdown());
            this.initDropdown();
        }
    }

    initDropdown(): void {
        const triggersSubDropdown = this.triggersSubDropdown();

        if (triggersSubDropdown) {
            this.positionSubDropdown();
        }
        this.dropdownComponent.parentDropdown = triggersSubDropdown ? this.parentDropdown : undefined;
        if (this.hasBackdrop !== undefined) {
            this.dropdownComponent.popover.config.hasBackdrop = this.hasBackdrop;
        } else {
            this.dropdownComponent.popover.config.hasBackdrop = !this.triggersSubDropdown();
        }

        this.dropdownComponent.closePopover();
        this.dropdownComponent.open(this.hostElementRef, this);

        this.setIsDropdownOpen(true);
    }

    closeDropdown(): void {
        this.dropdownComponent.close.emit();
        this._dropdownOpen = false;
    }

    triggersSubDropdown(): boolean {
        return !!(this.dropdownItem && this.parentDropdown && !this.parentDropdown?.closed);
    }

    private positionSubDropdown(): void {
        const { x, width, height } =
            this.hostElementRef.nativeElement.getBoundingClientRect() as DOMRect;
        const subDropdownWidth = +this.dropdownComponent.width ? +this.dropdownComponent.width : width;
        const offsetX = window.innerWidth - x - width > subDropdownWidth ? width : -subDropdownWidth;
        this.dropdownComponent.setOffset({ x: offsetX, y: -height - 5 });
    }

    private closingActions(): Observable<unknown> {
        const backdrop = this.dropdownComponent.popover.onClose;
        const parentClose = this.parentDropdown ? this.parentDropdown.close : of(undefined);
        const hover = this.parentDropdown
            ? this.parentDropdown.hovered().pipe(
                  filter(active => active !== this.dropdownItem),
                  filter(() => this._dropdownOpen)
              )
            : of(undefined);

        return merge(backdrop, parentClose, hover);
    }

    private destroyDropdown(): void {
        if (this.dropdownOpen) {
            this.setIsDropdownOpen(false);
            this.dropdownComponent.closePopover();
            if (!this.parentDropdown) {
                // If root dropdown is closed, make sure everything else is closed as well
                this.dropdownComponent.items.forEach(item => {
                    if (item.uiDropdownTarget && item.uiDropdownTarget instanceof UIDropdownComponent) {
                        item.uiDropdownTarget.closePopover();
                    }
                });
            }
            this.closeSubscription$.unsubscribe();
        }
    }

    private unsubscribeToActions(): void {
        this.closeSubscription$.unsubscribe();
        this.hoverSubscription$.unsubscribe();
        this.dropdownSubscription.unsubscribe();
    }

    private setIsDropdownOpen(isOpen: boolean): void {
        this._dropdownOpen = isOpen;
        this._dropdownOpen ? this.dropdownOpened.emit() : this.dropdownClosed.emit();
    }

    private subscribeHover(): void {
        this.hoverSubscription$ = this.parentDropdown
            .hovered()
            .pipe(filter(activeDropdownitem => activeDropdownitem === this.dropdownItem))
            .subscribe(() => this.openDropdown());
    }

    private subscribeDropdownComponent(): void {
        this.dropdownSubscription = this.dropdownComponent.close.subscribe(
            (reason: 'click' | undefined) => {
                this.destroyDropdown();
                // If clicked we should close the chain of nested dropdowns
                if (reason === 'click' && this.parentDropdown) {
                    this.parentDropdown.close.emit(reason);
                }
            }
        );
    }
}
