import { Component } from '@angular/core';
import { UISubmitResponse } from 'types/button';
import { UIDiscardCloseDialogCallback, UIConfirmCloseDialogCallback } from 'types/dialog';
import { UIConfirmDialogService } from '../confirm-dialog.service';

@Component({
    selector: 'ui-confirm-dialog-docs-preview',
    templateUrl: './confirm-dialog.docs.preview.html',
    styleUrls: ['./confirm-dialog.docs.preview.scss'],
})
export class UIConfirmDialogDocsPreview {

    constructor(private confirmDialogService: UIConfirmDialogService) {}

    async confirm(): Promise<void> {
        const result = await this.confirmDialogService.confirm({
            headerText: 'Confirm Dialog',
        });
        console.log(result);
    }

    async confirmDiscard(): Promise<void> {
        const result = await this.confirmDialogService.confirm({
            headerText: 'Confirm Discard Dialog',
            discardText: 'Discard',
            onConfirm: this.onConfirm,
            onConfirmed: this.onConfirmed,
        });
        console.log(result);
    }

    async confirmDiscardWithCancel(): Promise<void> {
        const result = await this.confirmDialogService.confirm({
            headerText: 'Confirm Discard With Cancel Dialog',
            discardText: 'Discard',
            showCancelButton: true,
            onDiscard: this.onDiscard,
            onDiscarded: this.onDiscarded,
            onConfirm: this.onConfirm,
            onConfirmed: this.onConfirmed,
        });
        console.log(result);
    }

    private onDiscard = (): Promise<UISubmitResponse> => new Promise((resolve) => setTimeout(resolve, 1000));

    private onDiscarded = (closeDialog: UIDiscardCloseDialogCallback, error?: any): void => {
        closeDialog('discard');
        console.log('Discard Finished');
    };

    private onConfirm = (): Promise<UISubmitResponse> => new Promise((resolve) => setTimeout(resolve, 1000));

    private onConfirmed = (closeDialog: UIConfirmCloseDialogCallback, error?: any): void => {
        closeDialog('confirm');
        console.log('Save Finished');
    };
}
