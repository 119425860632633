<label
    class="label"
    for="{{ id }}"
    *ngIf="label">
    {{ label }}
</label>
<input
    class="input"
    id="{{ id }}"
    spellcheck="false"
    [tabindex]="disabled ? -1 : tabindex ? tabindex : 0"
    [attr.maxlength]="maxlength"
    [attr.minlength]="minlength"
    [autocomplete]="autocomplete"
    [readonly]="disabled"
    [ngClass]="{
        invalid: validation?.invalid && (validation?.touched || !validation?.pristine),
        'icon-left': icon && iconPosition === 'left',
        'icon-right': icon && iconPosition === 'right'
    }"
    [placeholder]="placeholder"
    [type]="type"
    [value]="value"
    [(ngModel)]="value"
    (blur)="blur.emit()"
    (focus)="onFocus()"
    (input)="pushChanges($event)"
    (keydown)="onKeyDown($event)"
    (keyup)="onKeyUp($event)"
    (keyup.escape)="cancel.emit()"
    #valueContainer />
<ui-icon
    class="input-icon"
    *ngIf="icon"
    [ngClass]="{
        'expand-icon': expand,
        'input-icon-left': iconPosition === 'left',
        'input-icon-right': iconPosition === 'right'
    }"
    [icon]="icon">
</ui-icon>
<ui-svg-icon
    [icon]="svgIcon"
    *ngIf="svgIcon"
    [ngClass]="{
        'expand-icon': expand,
        'input-icon-left': iconPosition === 'left',
        'input-icon-right': iconPosition === 'right'
    }"
    class="input-icon"
    (click)="onClickIcon()">
</ui-svg-icon>
