import {
    Component,
    ViewChild,
    Input,
    ChangeDetectionStrategy,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
    ViewRef
} from '@angular/core';
import { UIPopoverDirective } from '../popover/popover.directive';
import { UIPopoverTargetDirective } from '../popover/popover-target.directive';
import { UITooltipDirective } from './tooltip.directive';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'ui-tooltip',
    templateUrl: 'tooltip.component.html',
    styleUrls: ['tooltip.component.scss'],
    providers: [UIPopoverTargetDirective],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UITooltipComponent implements OnChanges {
    @ViewChild('popover', { static: true }) popover: UIPopoverDirective;

    @Input()
    text: string;
    sanitizedText: SafeHtml;

    constructor(
        public domSanitizer: DomSanitizer,
        private changeDetector: ChangeDetectorRef
    ) {}

    ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges['text'].currentValue) {
            this.sanitizeText();
        }
    }

    sanitizeText(): void {
        this.sanitizedText = this.domSanitizer.bypassSecurityTrustHtml(this.text);
        if (!(<ViewRef>this.changeDetector).destroyed) {
            this.changeDetector.detectChanges();
        }
    }

    open(host: UIPopoverTargetDirective, tooltipDirective: UITooltipDirective): void {
        this.sanitizeText();
        const popoverRef = this.popover.open(host);
        popoverRef.componentInstance.host.nativeElement.classList.add('ui-tooltip');
        if (tooltipDirective.uiTooltipInteractive) {
            popoverRef.componentInstance.host.nativeElement.classList.add('interactive');
        }
    }

    close(): void {
        this.popover.close();
    }
}
