/**
 * This abstract class can be used in custom form controls
 */
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
    forwardRef,
    Input,
    ElementRef,
    Renderer2,
    ExistingProvider,
    HostBinding,
    Directive
} from '@angular/core';

export function customValueProvider(component: any): ExistingProvider {
    return {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => component),
        multi: true
    };
}

@Directive()
export abstract class CustomValueAccessorDirective<T> implements ControlValueAccessor {
    protected _value?: T;

    /**
     * This is an abstract function from ControlValueAccessor and should only
     * contain value type and overwrite to _value.
     *
     * Example implementation of `writeValue`:
     *
     * ```ts
     * writeValue(value: string): void {
     *   this.value = value;
     * }
     * ```
     *
     * @param value The value to be written to _value
     */
    public abstract writeValue(value: any): void;

    @Input()
    get value(): T | undefined {
        return this._value;
    }
    set value(value: T | undefined) {
        if (value !== this._value) {
            this._value = value;
            this.onChange(this._value);
        }
    }

    onChange = (_: any) => {};
    onTouched = () => {};
    private isDisabled: boolean;

    @Input()
    @HostBinding('class.disabled')
    @HostBinding('attr.disabled')
    @HostBinding('attr.aria-disabled')
    public get disabled(): boolean {
        return this.isDisabled;
    }
    public set disabled(value: boolean) {
        if (value !== this.isDisabled) {
            this.isDisabled = value || <any>null;
            this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', value);
        }
    }

    constructor(
        private _elementRef: ElementRef,
        private _renderer: Renderer2
    ) {}

    public registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    protected valueCaster(value: any): T {
        return value;
    }
}
