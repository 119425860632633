import { Component } from '@angular/core';

import moment from 'moment';
import { mockTimeZones } from './mock-timezones';

@Component({
    selector: 'ui-date-picker-docs-preview',
    templateUrl: './date-picker.docs.preview.html',
    styleUrls: ['./date-picker.docs.preview.scss']
})
export class UIDatePickerDocsPreview {
    preselectedDate = moment().startOf('day')
.toDate();
    preselectedEndDate = moment().add(4, 'day')
.toDate();
    showBorder = true;
    maxDate: Date = new Date();
    minDate: Date = moment().add(-10, 'days')
.toDate();
    isMaxDate: boolean;
    periodDateStart?: Date;
    firstWeekdaySunday: boolean;
    periodDateEnd: Date;
    hourDate: Date;
    selectedDate: Date;
    wait(): Promise<void> {
        return new Promise<void>(resolve => setTimeout(resolve, 2000));
    }
    timeZone: any;
    timeZones = mockTimeZones;

    constructor() {
        this.timeZone = this.timeZones.find(tz => tz.id === 'W. Europe Standard Time');
    }

    public onDateChange(event: Date) {
        this.selectedDate = event;
    }
}
