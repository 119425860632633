import { Component } from '@angular/core';

@Component({
    selector: 'ui-dropdown-docs-preview',
    templateUrl: './dropdown.docs.preview.html',
    styleUrls: ['./dropdown.docs.preview.scss']
})
export class UIDropdownDocsPreview {

    clickedItem: string;
    enabled = true;
    toggleVar = true;
    value: string | number;

    arr = [
        {
            val: 1,
            on: true
        },
        {
            val: 2,
            on: true
        },
        {
            val: 3,
            on: false
        }
    ];

    onClick(s: string) {
        this.clickedItem = s;
    }

    toggle() {
        this.toggleVar = !this.toggleVar;
    }

    input(value: number | string) {
        this.value = value;
    }
}
