<layout>
    <ng-container ngProjectAs="leftRegion"> </ng-container>

    <ng-container ngProjectAs="centerRegion">
        <ui-panel name="Colors and Variables">
            <h1>Bannerflow UI Colors and Variables</h1>
            <p>
                Bannerflow UI uses a predefined set of variables and colors, to make it easier for
                everyone to use them.
            </p>

            <p>There are two different types of variables.</p>
            <div style="margin-left: 4rem; margin-bottom: 4rem">
                <p>
                    <strong>* Global variables</strong> - These change depending on what theme you are
                    using. You should always try to use these if available. If a variable is missing,
                    it's better to create one and connect a color for each theme.
                </p>
                <p>
                    <strong>* Static colors</strong> - These never change, regardless of theme. Those
                    have a prefix of <strong>--ui-static-color-&lt;color name&gt;</strong> so they
                    should be easy to spot. If you need more colors, you need to talk to the UX-team
                    since they own these colors.
                </p>
            </div>

            <p style="margin-bottom: 4rem">
                <strong
                    >[Deprecation warning]: Do not use the variables starting with <i>--default</i>,
                    since they will be deprecated very soon.</strong
                >
            </p>

            <ui-input
                icon="search"
                [(ngModel)]="variableSearch"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Search variables">
            </ui-input>

            <ui-checkbox
                style="margin-top: 1rem"
                label="Hide --default variables"
                [selected]="!!hideDefaultVariables"
                (selectedChange)="changeHideDefault($event)">
            </ui-checkbox>

            <h2>Global variables</h2>
            <div
                class="variable-container"
                style="margin-bottom: 4rem">
                <div
                    *ngFor="
                        let variable of variables
                            | variableFilter: variableSearch : staticColors
                            | variableFilter: hideDefaultVariables : staticColors : true
                    "
                    class="variable-item"
                    style="min-width: 200px">
                    <div
                        class="variable-text"
                        style="cursor: pointer"
                        (click)="copyVariable(variable)">
                        <strong>{{ variable }}</strong>
                    </div>
                    <div class="variable-text-meta">{{ getVariableValue(variable) }}</div>
                </div>
            </div>

            <h2>Global color variables</h2>
            <div
                class="variable-container"
                style="margin-bottom: 4rem">
                <div
                    *ngFor="
                        let colorVariable of colorVariables
                            | variableFilter: variableSearch : staticColors
                            | variableFilter: hideDefaultVariables : staticColors : true
                    "
                    class="variable-item">
                    <div
                        class="variable-preview"
                        *ngIf="isColor(colorVariable)"
                        [style.background-color]="getVariableValue(colorVariable)"
                        (click)="copyVariable(colorVariable)">
                        <div class="variable-copy">
                            <div class="variable-copy-info">
                                <ui-svg-icon icon="copy"></ui-svg-icon><br />
                                Copy variable
                            </div>
                        </div>
                    </div>
                    <div class="variable-text">
                        <strong>{{ colorVariable }}</strong>
                    </div>
                    <div class="variable-text-meta">{{ getReferenceVariableName(colorVariable) }}</div>
                    <div class="variable-text-meta">{{ getVariableValue(colorVariable) }}</div>
                </div>
            </div>

            <h2>Static colors</h2>
            <div class="warning">
                <div class="warning-label">
                    <ui-svg-icon icon="warning-l-negative"></ui-svg-icon> Warning!
                </div>
                <div>
                    Using these colors in code is a very last resort.<br /><br />
                    You should always use the global variables above before using any of these colors,
                    or create a new variable and connect a color to that instead.<br />
                    If you need an aditional color, you need to speak to UX.
                </div>
            </div>
            <div class="variable-container">
                <div
                    *ngFor="
                        let color of staticColors
                            | variableFilter: variableSearch : staticColors
                            | variableFilter: hideDefaultVariables : staticColors : true
                    "
                    class="variable-item">
                    <div
                        class="variable-preview"
                        [style.background-color]="getVariableValue(color)"
                        (click)="copyVariable(color)">
                        <div class="variable-copy">
                            <div class="variable-copy-info">
                                <ui-svg-icon icon="copy"></ui-svg-icon><br />
                                Copy variable
                            </div>
                        </div>
                    </div>
                    <div class="variable-text">
                        <strong>{{ color }}</strong>
                    </div>
                    <div class="variable-text-meta">{{ getVariableValue(color) }}</div>
                </div>
            </div>

            <div class="buttons">
                <ui-button
                    type="default"
                    svgIcon="download"
                    text="download as .txt"
                    (click)="downloadContentAsTxt()">
                </ui-button>
            </div>
        </ui-panel>
    </ng-container>
</layout>
