import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'ui-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['../../../assets/icons/bannerflow-material.css', './icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIIconComponent {
    @Input() public icon: string | undefined;
}
