<div *ngIf="isDrillDown()">
    <ui-svg-icon
        *ngIf="first && expandable"
        class="toggle-icon"
        (click)="onToggleClick($event)"
        icon="arrow-right">
    </ui-svg-icon>
</div>

<ng-container *ngIf="column?.folderTemplate">
    <ng-container
        *ngTemplateOutlet="
            column.folderTemplate ? column.folderTemplate : null;
            context: {
                $implicit: value,
                value: value,
                displayValue: displayValue,
                data: data,
                column: column
            }
        "></ng-container>
</ng-container>
<ng-container *ngIf="!column?.folderTemplate">
    <ui-svg-icon
        *ngIf="first && expandable"
        class="toggle-icon"
        (click)="onToggleClick($event)"
        icon="arrow-right">
    </ui-svg-icon>
    <ui-svg-icon
        *ngIf="first"
        class="folder-icon"
        icon="folder">
    </ui-svg-icon>
    <span
        (click)="onNameClick($event)"
        class="name"
        >{{ displayValue || '' }}</span
    >
</ng-container>
