<ui-button
    (click)="dialog.open()"
    text="Open dialog">
</ui-button>

<ui-dialog
    #dialog="ui-dialog"
    [config]="{headerText: 'Dialog', panelClass: 'fullscreen' }">
    <ng-template ui-dialog-template>
        Fill this with any HTML
        <ui-dialog-buttons>
            <ui-button text="No" (click)="dialog.close()"></ui-button>
            <ui-button type="primary" text="I get it" (click)="dialog.close()"></ui-button>
        </ui-dialog-buttons>
    </ng-template>
</ui-dialog>

<ui-button
    class="button-margin"
    (click)="configDialog.open()"
    text="Dynamic Header Dialog">
</ui-button>

<ui-dialog
    #configDialog="ui-dialog"
    [config]="config">
    <ng-template ui-dialog-template>
        Click button to change this dialog
        <ui-dialog-buttons>
            <ui-button (click)="changeConfig()" text="Change Header Text"></ui-button>
        </ui-dialog-buttons>
    </ng-template>
</ui-dialog>
