<h2>Hour picker</h2>
Is max date applyed:<input type="checkbox" [checked]="isMaxDate" (change)="isMaxDate = !isMaxDate" />&nbsp;
<div class="date-picker-wrapper">
    <ui-date-picker [(date)]="hourDate" [hourPicker]="true" [maxDate]="isMaxDate ? maxDate : undefined"
        [minDate]="minDate">
    </ui-date-picker>
</div>
Date: {{hourDate | date:'full'}}


<h2>Period picker</h2>
Outer border:<input type="checkbox" [checked]="showBorder" (change)="showBorder = !showBorder" />&nbsp;
Start with Sunday:<input type="checkbox" [checked]="firstWeekdaySunday"
    (change)="firstWeekdaySunday = !firstWeekdaySunday" />&nbsp;
<div class="date-picker-wrapper">
    <ui-date-picker [dateSpanPicker]="true" [(startDate)]="preselectedDate" [(endDate)]="preselectedEndDate"
        [outerBorders]="showBorder" [firstWeekdaySunday]="firstWeekdaySunday" [maxDate]="maxDate">
    </ui-date-picker>
</div>
From  Start date: {{preselectedDate | date:'full'}} <br>
From  End date: {{preselectedEndDate | date:'full'}}

<h2>Pre selected date</h2>
<div class="date-picker-wrapper">
    <ui-date-picker [dateSpanPicker]="true" [(startDate)]="preselectedDate" [(endDate)]="preselectedEndDate">
    </ui-date-picker>
</div>
Start date: {{preselectedDate | date:'full'}}

<h2>Compact picker</h2>
<div class="date-picker-wrapper-small">
    <ui-date-picker [isCompact]="true" [yearPicker]="false" [hourPicker]="false" [(date)]="hourDate" [minDate]="minDate">
    </ui-date-picker>
</div>

<h2>Compact period picker</h2>
<div class="date-picker-wrapper-small">
    <ui-date-picker
        [isCompact]="true"
        [yearPicker]="false"
        [hourPicker]="false"
        [dateSpanPicker]="true"
        [(startDate)]="preselectedDate"
        [(endDate)]="preselectedEndDate"
        [firstWeekdaySunday]="firstWeekdaySunday"
        [maxDate]="maxDate">
    </ui-date-picker>
</div>
