import { Injectable } from '@angular/core';
import { UIDomAttachService, IUIAttachedDOMComponent } from '../../../services/dom-attach.service';
import { UINotificationComponent } from './notification.component';
import { Subscription } from 'rxjs';
import { UINotificationType, UINotificationPlacement } from '../../../types/notification';
import { Icon } from '../../icon/svg-icon/icons';

@Injectable({ providedIn: 'root' })
export class UINotificationService {
    notificationRef: IUIAttachedDOMComponent<UINotificationComponent>;
    notificationSubscription: Subscription;
    timeout: any;

    constructor(private uiDomAttachService: UIDomAttachService) {}

    open(
        text: string,
        {
            type = 'info',
            placement = 'bottom',
            icon = 'close',
            autoCloseDelay,
            hideCloseIcon = false,
            width = 28
        }: INotificationConfig = { type: 'info', placement: 'bottom', icon: 'close' },
        checkBoxText?: string,
        showCheckBox?: boolean
    ): void {
        if (this.notificationRef) {
            this.close();
        }

        this.notificationRef = this.uiDomAttachService.addComponent(
            UINotificationComponent,
            document.body
        );

        this.notificationRef.instance.text = text;
        this.notificationRef.instance.placement = placement;
        this.notificationRef.instance.type = type;
        this.notificationRef.instance.icon = hideCloseIcon ? undefined : icon;
        this.notificationRef.instance.width = width;
        if (checkBoxText && showCheckBox) {
            this.notificationRef.instance.checkBoxText = checkBoxText;
            this.notificationRef.instance.showCheckBox = showCheckBox;
        }

        this.notificationSubscription = this.notificationRef.instance.onClose.subscribe(() =>
            this.close()
        );
        if (autoCloseDelay) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => this.notificationRef.instance.close(), autoCloseDelay);
        }

        this.notificationRef.componentRef.hostView.detectChanges();
    }

    close(): void {
        if (this.notificationSubscription && !this.notificationSubscription.closed) {
            this.notificationSubscription.unsubscribe();
        }
        this.uiDomAttachService.remove(this.notificationRef);
    }
}

export interface INotificationConfig {
    type?: UINotificationType;
    icon?: Icon;
    autoCloseDelay?: number;
    placement?: UINotificationPlacement;
    hideCloseIcon?: boolean;
    width?: number;
}
