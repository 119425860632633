<ui-button text="Open dropdown" [uiDropdownTarget]="dropdown1"></ui-button>
<ui-dropdown #dropdown1 header="Header" type="menu" [disabled]="true">
    <ui-dropdown-item [(toggle)]="toggleVar">
        Item 1
    </ui-dropdown-item>
    <ui-dropdown-item [uiDropdownTarget]="dropdown2">
        Item Nested
    </ui-dropdown-item>
    <ui-dropdown-divider></ui-dropdown-divider>
    <ui-dropdown-item
        [label]="'⌘Z'"
        class="section menu-item"
        svgIcon="sync"
        (click)="onClick('Item 4')">
        Item 4
    </ui-dropdown-item>
    <ui-dropdown-item
        (click)="onClick('Item 4')"
        [disabled]="true">
        Item 5 (Disabled)
    </ui-dropdown-item>
    <ui-dropdown-input
        (valueChange)="input($event)"
        placeholder="Input.."
        placeholderFocus="Input something"
        type="text">
    </ui-dropdown-input>
</ui-dropdown>
<br>
<ng-container *ngIf="clickedItem">Clicked on: {{ clickedItem }}</ng-container>

<ui-dropdown #dropdown2 type="menu" [minWidth]="141" width="142">
    <ui-dropdown-item
        (click)="onClick('SUBITEM 1')">
        Sub 1
    </ui-dropdown-item>
    <ui-dropdown-item
        [uiDropdownTarget]="dropdown3">
        Sub
    </ui-dropdown-item>
    <ui-dropdown-input
        placeholder="Custom size"
        units="px"
        type="number"
        (valueChange)="input($event)"
        [toggle]="toggleVar"
        maxLength="4"
        icon="edit">
    </ui-dropdown-input>
</ui-dropdown>


<ui-dropdown #dropdown3 type="menu">
    <ui-dropdown-item (click)="onClick('Nested Sub 3')">
        Sub 3
    </ui-dropdown-item>
</ui-dropdown>

<ui-button text="Open dropdown 2" [uiDropdownTarget]="dropdownb"></ui-button>
<ui-dropdown #dropdownb type="menu">
    <ng-container *ngFor="let a of arr">
        <ui-dropdown-item [uiDropdownTarget]="dropdownc">
            {{ a.val }}
        </ui-dropdown-item>
    </ng-container>
</ui-dropdown>

<ui-dropdown #dropdownc type="menu">
    <ui-dropdown-item [toggle]="true" [preventCloseOnClick]="true">
        Hello!
    </ui-dropdown-item>
    <ui-dropdown-item [toggle]="true">
        Hello from the other side
    </ui-dropdown-item>
    <ui-dropdown-item [uiDropdownTarget]="dropdownd" [preventCloseOnClick]="true">
        Sub-Subdropdown
    </ui-dropdown-item>
    <ui-dropdown-item [uiDropdownTarget]="dropdownd">
        Sub-Subdropdown2
    </ui-dropdown-item>
</ui-dropdown>

<ui-dropdown #dropdownd type="menu">
    <ui-dropdown-item>
        Hello!
    </ui-dropdown-item>
</ui-dropdown>

<p>{{ toggleVar }}</p>
<p>input value: {{ value }} </p>
