import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    Renderer2
} from '@angular/core';
import { UIDragdropService } from './dragdrop.service';

export interface UIDropEvent<T> {
    dropTarget: T | string;
    droppedItems: T[];
}

// tslint:disable-next-line:directive-selector old settings
@Directive({ selector: '[uiDrop]', standalone: true })
export class UIDropDirective<T> {
    /** Directive for drag and drop, supports custom dragelement, set it with custom component
     * Consumer needs to set theese classes 'ui-dropzone-allowed' 'ui-dropzone-not-allowed', due to viewencapsulation
     */

    @Input() dropData: T | string;
    @Input() isAllowedToDrop: (dropTarget: string | T, draggedItems: T[]) => boolean;
    @Output() itemsDropped = new EventEmitter<UIDropEvent<T>>();

    constructor(
        private eleRef: ElementRef,
        private renderer: Renderer2,
        private service: UIDragdropService<T>
    ) {}

    private canDrop = false;

    @HostListener('mouseup', ['$event'])
    onMouseUp(): void {
        if (this.canDrop) {
            const droppedItems = {
                dropTarget: this.dropData,
                droppedItems: this.service.dragData
            };
            this.itemsDropped.emit(droppedItems);
            this.canDrop = false;
            this.renderer.removeClass(this.eleRef.nativeElement, 'ui-dropzone-allowed');
            this.renderer.removeClass(this.eleRef.nativeElement, 'ui-dropzone-not-allowed');
        }
    }

    @HostListener('mouseenter', ['$event'])
    onMouseEnter(): void {
        if (this.service.isDragging) {
            this.canDrop = this.isAllowedToDrop(this.dropData, this.service.dragData);
            this.renderer.addClass(
                this.eleRef.nativeElement,
                this.canDrop ? 'ui-dropzone-allowed' : 'ui-dropzone-not-allowed'
            );
        }
    }

    @HostListener('mouseleave', ['$event'])
    onMouseLeave(): void {
        this.canDrop = false;
        this.renderer.removeClass(this.eleRef.nativeElement, 'ui-dropzone-allowed');
        this.renderer.removeClass(this.eleRef.nativeElement, 'ui-dropzone-not-allowed');
    }
}
