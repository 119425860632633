import {
    Component,
    ElementRef,
    ContentChildren,
    QueryList,
    ViewChildren,
    AfterContentInit,
    ViewChild,
    ChangeDetectionStrategy,
    Renderer2,
    ChangeDetectorRef,
    AfterViewInit,
    OnDestroy
} from '@angular/core';
import { UITabComponent } from './tab.component';

const PADDING = 20;
const PREVIEW_CLASS = 'preview';

@Component({
    selector: 'ui-tabs',
    templateUrl: 'tabs.component.html',
    styleUrls: ['tabs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.ui-tabs]': 'true'
    }
})
export class UITabsComponent implements AfterContentInit, AfterViewInit, OnDestroy {
    // TODO: HANDLE REMOVAL AND ADDITION OF TABS AS WELL AS SELECTIONS BY BINDING ON EACH TAB
    @ContentChildren(UITabComponent)
    tabs: QueryList<UITabComponent>;

    @ViewChildren('tabHeader')
    tabHeaders: QueryList<ElementRef>;

    @ViewChild('indicator')
    indicatorRef: ElementRef;

    private tabSubscriber: any;
    private tabChangeSubscribers: any[] = [];

    constructor(
        private renderer: Renderer2,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.onTabChange = this.onTabChange.bind(this);
    }

    /**
     * When content is available
     */
    ngAfterContentInit(): void {
        // When a tab is added or removed
        this.tabSubscriber = this.tabs.changes.subscribe(() => {
            this.initalSelection();
            this.addTabSubscribers();
        });
        this.addTabSubscribers();

        // Content cant be change during loop since it will cause "ExpressionChangedError"

        this.initalSelection();
    }

    // When viewchildren is available
    ngAfterViewInit(): void {
        // Content cant be change during loop since it will cause "ExpressionChangedError"
        setTimeout(() => {
            this.resetSelectionIndicator();
        });
    }

    /**
     * Select a tab
     * @param selectedTab
     */
    selectTab(selectedTab: UITabComponent): void {
        this.tabs.forEach(tab => {
            tab.selected = tab === selectedTab;
        });

        this.changeDetectorRef.detectChanges();
        this.resetSelectionIndicator();
    }

    /**
     * When mouse hovers tab header.
     * @param event
     */
    hoverTab(event: MouseEvent): void {
        if (event && event.target) {
            const target: HTMLDivElement = event.target as HTMLDivElement;
            this.moveIndicatorToElement(target, true);
        }
    }

    /**
     * Reset position of indicator to currently active tab
     */
    resetSelectionIndicator(): void {
        const target: HTMLDivElement = this.getTabHeader()!;
        this.moveIndicatorToElement(target);
    }

    /**
     * Move indicator to an element.
     * @param target
     * @param preview
     */
    private moveIndicatorToElement(target?: HTMLDivElement, preview?: boolean): void {
        if (target && this.indicatorRef) {
            const indicator = this.indicatorRef.nativeElement;
            this.renderer.setStyle(indicator, 'left', `${target.offsetLeft + PADDING}px`);
            this.renderer.setStyle(indicator, 'width', `${target.offsetWidth - PADDING * 2}px`);

            if (preview) {
                this.renderer.addClass(indicator, PREVIEW_CLASS);
            } else {
                this.renderer.removeClass(indicator, PREVIEW_CLASS);
            }
        }
    }

    /**
     * When something in a child tab is changed from "outside"
     */
    private onTabChange(): void {
        this.changeDetectorRef.detectChanges();
    }

    /**
     * When having nothing selected -> Select first.
     */
    private initalSelection(): void {
        if (this.tabs && !this.getSelectedTab()) {
            this.selectTab(this.tabs.first);
        }
    }

    private getTabHeader(byIndex: number = this.getSelectedTabIndex()): HTMLDivElement | undefined {
        if (byIndex === undefined || !this.tabHeaders) {
            return undefined;
        }
        return this.tabHeaders.toArray()[byIndex].nativeElement as HTMLDivElement;
    }

    private getSelectedTab(): UITabComponent | undefined {
        return this.tabs.find(tab => tab.selected);
    }

    /**
     * Get index of selected tab
     */
    private getSelectedTabIndex(): number {
        return this.tabs.toArray().findIndex(tab => tab.selected);
    }

    /**
     * Add subscriptions to tabChange event (name/dot changes to children)
     */
    private addTabSubscribers(): void {
        this.removeTabSubscribers();

        this.tabChangeSubscribers = this.tabs.map((tab: UITabComponent) =>
            tab.tabChange.subscribe(this.onTabChange)
        );
    }

    /**
     * Remove all tabChange subscribers
     */
    private removeTabSubscribers(): void {
        if (this.tabChangeSubscribers) {
            while (this.tabChangeSubscribers.length) {
                this.tabChangeSubscribers.pop().unsubscribe();
            }
        }
    }

    /**
     * Cleanup component when destroyed
     */
    ngOnDestroy(): void {
        if (this.tabSubscriber) {
            this.tabSubscriber.unsubscribe();
        }
        this.changeDetectorRef.detach();
        this.removeTabSubscribers();
    }
}
