import { Component, OnInit } from '@angular/core';
import { UIErrorDialogService } from '../error-dialog.service';
import { IBFHttpError } from '../error.interface';
import { IUIErrorDialogConfig } from '../error-dialog-config.interface';

@Component({
    selector: 'ui-error-dialog-docs-preview',
    templateUrl: 'error-dialog.docs.preview.html',
    styleUrls: ['./error-dialog.docs.preview.scss']
})
export class UIErrorDialogDocsPreview {
    constructor(public errorDialogService: UIErrorDialogService) {}

    error: IBFHttpError = {
        code: 500,
        requestId: '1c36fab2-7139-4dac-9173-27a679a968a0'
    };

    config: IUIErrorDialogConfig = {};

    async showError() {
        await this.errorDialogService.show(this.config, this.error);
    }
}
