import {
    Component,
    HostListener,
    EventEmitter,
    HostBinding,
    ElementRef,
    ViewChild,
    AfterViewInit,
    ViewContainerRef,
    Type,
    ComponentRef,
    OnDestroy,
    ViewRef,
    Injector
} from '@angular/core';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';
import { ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import { UIDialogRef } from './dialog-ref';
import { IUIDialogConfig } from './dialog-config.interface';

@Component({
    selector: 'ui-dialog-master',
    templateUrl: 'dialog.component.html',
    styleUrls: ['dialog.component.scss'],
    providers: [ConfigurableFocusTrapFactory],
    animations: [
        trigger('slideContent', [
            state('void', style({ transform: 'translateY(15px) scale(0.98)', opacity: 0 })),
            state('enter', style({ transform: 'none', opacity: 1, filter: 'none' })),
            state('leave', style({ transform: 'translateY(15px) scale(0.95)', opacity: 0 })),
            transition('void => enter', animate('300ms cubic-bezier(0.390, 0.575, 0.565, 1.000)')),
            transition('enter => leave', animate('200ms cubic-bezier(0.390, 0.575, 0.565, 1.000)'))
        ])
    ]
})
export class UIDialogComponent implements AfterViewInit, OnDestroy {
    config: IUIDialogConfig;
    focusTrap: ConfigurableFocusTrapFactory;
    animationStateChanged: EventEmitter<AnimationEvent> = new EventEmitter<AnimationEvent>();

    @ViewChild('componentPlaceholder', { read: ViewContainerRef, static: true })
    viewContainerRef: ViewContainerRef;

    @ViewChild('bodyRef')
    private bodyRef: ElementRef<any>;

    @HostBinding('@slideContent')
    animationState: 'void' | 'enter' | 'leave' = 'void';

    private subComponentViewRef: ViewRef;

    constructor(
        private injector: Injector,
        public dialogRef: UIDialogRef,
        public host: ElementRef
    ) {
        this.config = this.dialogRef.config;

        // Get some dependencies
        this.focusTrap = this.injector.get(ConfigurableFocusTrapFactory);
    }

    ngAfterViewInit(): void {
        // Make sure user can't tab outside scope
        const focusTrap = this.focusTrap.create(this.bodyRef.nativeElement);
        if (!this.config.disableFocusInitialElement) {
            focusTrap.focusInitialElement();
        }

        // To avoid "ExpressionChangedError"
        setTimeout(() => {
            // Make ref to instance available
            this.dialogRef.subComponentRef = this.injectComponent(this.dialogRef.componentClass)!;

            // For subcomponentes to know when their instances is available
            this.dialogRef.resolveAfterViewInitPromise();

            // Fade in dialog
            this.animationState = 'enter';
        });
    }

    /**
     * Start fading out dialog
     */
    startExitAnimation(): void {
        this.animationState = 'leave';
    }

    /**
     * Tell the dialogRef to close the dialog
     */
    close(): void {
        if (this.config.closeFunc) {
            this.config.closeFunc();
        } else {
            this.dialogRef.close();
        }
    }

    /**
     * Inject a component to view
     * @param componentClass
     */
    private injectComponent(componentClass?: Type<any>): ComponentRef<any> | undefined {
        if (componentClass) {
            const component = this.viewContainerRef.createComponent(componentClass);
            this.subComponentViewRef = this.viewContainerRef.insert(component.hostView);
            return component;
        }
        return undefined;
    }

    /**
     *  Listen to when user presses escape
     * @param event
     */
    @HostListener('document:keydown', ['$event'])
    handleKeydown(event: KeyboardEvent): void {
        if (
            event.code === 'Escape' &&
            this.dialogRef.config.escKeyClose &&
            UIDialogRef.__openDialogs[0].componentInstance === this
        ) {
            this.close();
        }
    }

    /**
     * When in/out animation starts
     * @param event
     */
    @HostListener('@slideContent.start', ['$event'])
    onAnimationStart(event: AnimationEvent): void {
        this.animationStateChanged.emit(event);
    }

    /**
     * When in/out animation is done
     * @param event
     */
    @HostListener('@slideContent.done', ['$event'])
    onAnimationDone(event: AnimationEvent): void {
        this.animationStateChanged.emit(event);
    }

    ngOnDestroy(): void {
        if (this.subComponentViewRef) {
            this.subComponentViewRef.detach();
            this.subComponentViewRef.destroy();
        }
    }
}
