import {
    Component,
    Input,
    OnChanges,
    ElementRef,
    ChangeDetectionStrategy,
    Renderer2
} from '@angular/core';
import { UIButtonType } from '../../../types/button';

@Component({
    selector: 'ui-badge',
    templateUrl: 'badge.component.html',
    styleUrls: ['badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.button]': 'true',
        '[class.primary]': `type === 'primary'`,
        '[class.default]': `type === 'default'`
    }
})
export class UIBadgeComponent implements OnChanges {
    @Input() type: UIButtonType;

    private currentTypeClass: string;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {
        this.updateClass();
    }

    ngOnChanges(): void {
        this.updateClass();
    }

    /**
     * TODO: Replace with "@HostClass"
     */
    private updateClass(): void {
        const el = this.elementRef ? this.elementRef.nativeElement : undefined;

        if (el) {
            if (this.currentTypeClass) {
                this.renderer.removeClass(el, this.currentTypeClass);
            }
            this.renderer.addClass(el, this.type);
            this.currentTypeClass = this.type;
        }
    }
}
