<h3>Plain checkbox, event listeners</h3>
<ui-checkbox
    label="On"
    (select)="setOnState(true)"
    [disabled]="disabled"
    [selected]="on">
</ui-checkbox>
<ui-checkbox
    label="Off"
    (select)="setOnState(false)"
    [disabled]="disabled"
    [selected]="!on">
</ui-checkbox>
<p>Variable 'on' is: {{ on }}</p>
<ui-button text="{{ disabled ? 'Enable' : 'Disable'}}" (click)="toggleDisabledState()"></ui-button>

<h3>Plain checkbox (with two-way-binding)</h3>
<ui-checkbox
    label="Cow"
    [(selected)]="cow">
</ui-checkbox>
<ui-checkbox
    label="Chicken"
    [(selected)]="chicken">
</ui-checkbox>
<p>Variable 'cow' is: {{ cow }}. Variable 'chicken' is: {{ chicken }}</p>

<h3>Checkboxs in selectable list (Single select)</h3>
<ui-selectable-list
    #list
    [(selected)]="selectedOption"
    [multiSelect]="false">
    <ui-checkbox
        *ngFor="let option of checkboxOptions"
        [label]="option.label"
        [value]="option">
    </ui-checkbox>
</ui-selectable-list>
<p>
    <a (click)="list.deselectAll()" class="selection">Deselect</a><br/>
</p>
<p>
    Variable 'selectedOption.label' is: {{ selectedOption?.label }}
</p>



<h3>Checkboxs in selectable list (Multi select)</h3>
<ui-selectable-list
    #multiList
    [(selected)]="multiSelectedOptions"
    [multiSelect]="true">
    <ui-checkbox
        *ngFor="let option of checkboxOptions"
        [label]="option.label"
        [value]="option">
    </ui-checkbox>
</ui-selectable-list>
<p>
    <a (click)="multiList.selectAll()" class="selection" style="margin-right: 10px;">Select all</a>
    <a (click)="multiList.deselectAll()" class="selection">Deselect all</a><br/>
</p>
<p>
    Variable 'multiSelectedOptions' is: {{ multiSelectedOptions?.length }} long
</p>
