import { Component, OnInit } from '@angular/core';

@Component({
    // Demo Project components should not be forced to use ui- prefix
    // tslint:disable-next-line:component-selector
    selector: 'overview',
    templateUrl: 'overview.component.html'
})
export class OverviewComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
