import { Directive, TemplateRef } from '@angular/core';

/**
 * Only used to inject templates and know what it is intended to replace
 */
@Directive({
    selector: '[ui-select-token-field-template]'
})
export class UISelectTokenFieldTemplateDirective {
    constructor(public template: TemplateRef<any>) {}
}
