export const charToHTMLEntity: { [index: string]: string } = {
    '&': '&amp;',
    '"': '&quot;',
    '\\': '&#39;',
    '<': '&lt;',
    '>': '&gt;'
};

export function encodeXml(text: string): string {
    for (const i in charToHTMLEntity) {
        if (i === '\\') {
            text = text.replace(new RegExp('\\\\', 'g'), charToHTMLEntity[i]);
        } else {
            text = text.replace(new RegExp(i, 'g'), charToHTMLEntity[i]);
        }
    }

    return text;
}

export function randomString(length: number = 10): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}
