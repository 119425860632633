<div class="wrapper">
    <ui-button
        [svgIcon]="
            indeterminate ? 'indeterminate-checkmark' : selected ? 'checkbox-checkmark-default' : 'none'
        "
        [disabled]="disabled">
    </ui-button>
    <span
        *ngIf="label"
        class="checkbox-label"
        >{{ label }}</span
    >
</div>
