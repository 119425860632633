import { Component, Input, OnChanges } from '@angular/core';
import { UISubmitResponse, UIButtonType } from '../../../types/button';
import { Icon } from '../../icon/svg-icon/icons';

const MAX_WAIT_TIME = 500;

@Component({
    selector: 'ui-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    host: {
        '[class.ui-button]': 'true',
        '[class.icon-button]': 'hasIcon && !text',
        '[class.text-icon-button]': 'text && hasIcon',
        '[class.text-button]': 'text',
        '[class.primary]': `type === 'primary'`,
        '[class.discrete]': `type === 'discrete'`,
        '[class.default]': `type === 'default'`,
        '[class.disabled]': 'disabled',
        '[class.loading]': 'loading',
        '[class.borderless]': '!border',
        '[class.capitalize]': 'capitalize'
    }
})
export class UIButtonComponent implements OnChanges {
    /**
     * Text of the button.
     */
    @Input() text?: string;

    /**
     * @summary:
     * Icon of the button.
     */
    @Input() icon?: string;

    /**
     * @summary:
     * Svg icon of the button.
     */
    @Input() svgIcon?: Icon | 'none';

    /**
     * @summary:
     * Trailing icon of the button.
     */
    @Input() trailingIcon?: Icon;

    /**
     * @summary:
     * Icon of the button.
     * @description:
     * The 'done' handler is fired directly efter the submission, regardless of whether the
     * submission fails or succeeds. The passed error or state can be used to render any additional
     * message to the user.
     */
    @Input() submit?: <T>() => Promise<UISubmitResponse<T> | any>;

    /**
     * @summary:
     * Done handler.
     * @description:
     * The 'done' handler is fired directly efter the submission, regardless of whether the
     * submission fails or succeeds. The passed error or state can be used to render any additional
     * message to the user.
     */
    @Input() done?: (error?: any, state?: any) => any;

    /**
     * Type of the button.
     */
    @Input() type?: UIButtonType = 'default';

    /**
     * Disable button, set to true to prevent clicks.
     */
    @Input() disabled?: boolean;

    @Input() loading = false;

    /**
     * Show border, set false to hide border styles
     */
    @Input() border = true;

    /**
     * Capitalize the text of the button.
     */
    @Input() capitalize = false;

    get hasIcon(): boolean {
        return !!this.icon || !!this.svgIcon;
    }

    constructor() {}

    ngOnChanges(): void {
        if (!this.hasIcon && !this.text) {
            throw new Error(`You must set either an icon or text in your button.`);
        }
    }

    async onClick(event?: MouseEvent): Promise<void> {
        if (this.loading) {
            // Check if there's an event in case onClick is called programatically
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }
            return;
        }

        if (this.submit) {
            this.loading = true;
            const start = Date.now();
            let response: UISubmitResponse<any> = {};
            try {
                const submitResponse = (await this.submit()) || {};
                if (
                    typeof submitResponse.state !== 'undefined' ||
                    typeof submitResponse.error !== 'undefined'
                ) {
                    response = submitResponse;
                }
            } catch (e) {
                response.error = e;
            }
            const elapsed = Date.now() - start;
            const remaining = MAX_WAIT_TIME - elapsed;
            setTimeout(
                () => {
                    this.loading = false;
                    if (this.done) {
                        this.done(response.error, response.state);
                    }
                },
                remaining > 0 ? remaining : 0
            );
        }
    }
}
