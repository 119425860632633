<div
    class="cell-text-wrapper"
    (mouseenter)="mouseEnter()"
    (mouseleave)="mouseLeave()"
    *ngIf="!isEditing">
    <div
        class="ellipsis-wrapper"
        [uiTooltip]="value"
        [uiTooltipOnlyWhenTruncated]="true"
        uiTooltipMaxWidth="500px"
        [attr.data-testid]="'edit-name-' + value">
        <span class="clip-text">
            <a (click)="navigate($event)">
                <span>{{ value }}</span>
            </a>
        </span>
    </div>
    <ui-svg-icon
        icon="edit"
        [ngClass]="showEditIcon ? 'edit-icon-show' : 'edit-icon-hide'"
        [attr.data-testid]="'edit-icon-' + value"
        (click)="enableEditing($event)">
    </ui-svg-icon>
</div>

<div
    class="input-wrapper"
    *ngIf="isEditing"
    (clickOutside)="clickOutside && endEditing()">
    <ui-input
        (click)="$event.stopPropagation()"
        (mousedown)="$event.stopPropagation()"
        ui-theme="small"
        class="edit-input"
        #editInput
        [validation]="validationControl"
        [value]="value"
        [attr.data-testid]="'edit-input-' + value"
        [autofocus]="true"
        (keyup)="keyUpListener($event, editInput.value!)">
    </ui-input>
    <div class="icon-group">
        <ui-button
            [loading]="loading"
            svgIcon="checkmark-large"
            class="checkmark"
            [attr.data-testid]="'icon-group-checkmark'"
            ui-theme="small"
            (click)="saveEdit(editInput.value!, $event)">
        </ui-button>
        <ui-button
            [disabled]="cancelDisabled"
            svgIcon="close"
            class="close"
            [attr.data-testid]="'icon-group-close'"
            ui-theme="small"
            (click)="endEditing($event)"></ui-button>
    </div>
</div>
