import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { ComponentRef, ElementRef, Injectable, Injector, TemplateRef, Type } from '@angular/core';

import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { arrowPosition, IUIPopoverConfig, xPosition, yPosition } from '../../../types/popover';
import { inElement } from '../../../utils/in-element';
import { UIPopoverRef } from './popover-ref';
import { UIPopoverComponent } from './popover.component';
import { UIPOPOVER_DEFAULT_CONFIG } from './popover.interface';

@Injectable({ providedIn: 'root' })
export class UIPopoverService {
    constructor(
        private injector: Injector,
        private overlay: Overlay,
        private _scrollDispatcher: ScrollDispatcher
    ) {}

    /**
     * Open a popover containg the template passed to it
     * @param config
     * @param templateRef
     */
    openTemplate(
        target: ElementRef,
        templateRef?: TemplateRef<any>,
        config: IUIPopoverConfig = {}
    ): UIPopoverRef {
        return this.open(target, config, templateRef);
    }

    /**
     * Open a popover containg an instance of a the component passed to it.
     * @param config
     * @param componentClass
     */
    openComponent<T = any>(
        target: ElementRef,
        componentClass?: Type<T>,
        config: IUIPopoverConfig = {}
    ): UIPopoverRef<T> {
        return this.open(target, config, undefined, componentClass);
    }

    /**
     * Open popover with template / popover
     */
    private open(
        target: ElementRef,
        config: IUIPopoverConfig = {},
        templateRef?: TemplateRef<any>,
        componentClass?: Type<any>
    ): UIPopoverRef {
        // Override default configuration
        const popoverConfig = { ...UIPOPOVER_DEFAULT_CONFIG, ...config };

        // Returns an OverlayRef which is a PortalHost
        const overlayRef = this.createOverlay(target, popoverConfig);

        // Instantiate remote control
        const popoverRef = new UIPopoverRef(overlayRef, templateRef, componentClass, popoverConfig);

        const overlayComponent = this.attachPopoverContainer(overlayRef, popoverConfig, popoverRef);

        popoverRef.componentInstance = overlayComponent;

        const mouseover$: Observable<MouseEvent> = fromEvent<MouseEvent>(document, 'mouseover');
        const { uiTooltipInteractive }: { uiTooltipInteractive?: boolean } = popoverConfig;

        if (uiTooltipInteractive) {
            const { nativeElement }: { nativeElement: HTMLElement } = popoverRef.componentInstance.host;

            const documentMouseOverSubscription: Subscription = mouseover$
                .pipe(
                    switchMap((e: MouseEvent) =>
                        of(
                            inElement(e.target, nativeElement) ||
                                inElement(e.target, target.nativeElement)
                        ).pipe(delay(100))
                    )
                )
                .subscribe((isInteractive: boolean) => {
                    if (!isInteractive) {
                        popoverRef.close();
                        documentMouseOverSubscription.unsubscribe();
                    }
                });
        }

        if (
            !popoverConfig.hasBackdrop &&
            popoverConfig.popoverType !== 'menu' &&
            !uiTooltipInteractive
        ) {
            const documentMouseOverSubscription = mouseover$.subscribe((e: MouseEvent) => {
                if (
                    !inElement(
                        e.target,
                        overlayComponent.host.nativeElement.closest('.cdk-overlay-pane')
                    ) &&
                    !inElement(e.target, target.nativeElement)
                ) {
                    popoverRef.close();
                    documentMouseOverSubscription.unsubscribe();
                }
            });
        } else {
            if (popoverConfig.backdropClickClose) {
                overlayRef.backdropClick().subscribe(_ => popoverRef.close());
            }
        }

        return popoverRef;
    }

    private createOverlay(target: ElementRef, config: IUIPopoverConfig): OverlayRef {
        const overlayConfig = this.getOverlayConfig(target, config);
        const overlayRef = this.overlay.create(overlayConfig);
        if (overlayRef.backdropElement) {
            overlayRef.backdropElement.classList.add('prevent-text-blur');
        }
        return overlayRef;
    }

    private attachPopoverContainer(
        overlayRef: OverlayRef,
        config: IUIPopoverConfig,
        popoverRef: UIPopoverRef
    ): UIPopoverComponent {
        const injector = this.createInjector(popoverRef);

        const containerPortal = new ComponentPortal(UIPopoverComponent, null, injector);
        const containerRef: ComponentRef<UIPopoverComponent> = overlayRef.attach(containerPortal);

        return containerRef.instance;
    }

    /**
     * Inject properties to component contructor
     * @param popoverRef
     */
    private createInjector(popoverRef: UIPopoverRef): Injector {
        return Injector.create({
            providers: [{ provide: UIPopoverRef, useValue: popoverRef }],
            parent: this.injector
        });
    }

    private getOverlayConfig(target: ElementRef, config: IUIPopoverConfig): OverlayConfig {
        const positions = config.position ? this.getPositionStrategy(config) : config.positions;
        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(target)
            .withDefaultOffsetY(config.offset!.y)
            .withDefaultOffsetX(config.offset!.x)
            .withPositions(positions);

        positionStrategy.withScrollableContainers(
            this._scrollDispatcher.getAncestorScrollContainers(target)
        );

        config.arrowPosition = this.getArrowPosition(config);

        const targetBounds = target.nativeElement.getBoundingClientRect();

        // Convert to string of multiple classes that HTMLElement.classList.add supports.
        const panelClass = Array.isArray(config.panelClass)
            ? config.panelClass
            : config.panelClass && config.panelClass.split(' ');

        // Don't pass maxWidth as it causes missalignments with the positionstrategy
        const overlayConfig = new OverlayConfig({
            width: config.useTargetWidth ? targetBounds.width : config.width,
            height: config.height,
            minWidth: config.useTargetWidth
                ? targetBounds.width
                : config.minWidth || targetBounds.width,
            maxHeight: config.maxHeight,
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass: panelClass,
            positionStrategy
        });

        return overlayConfig;
    }

    private getArrowPosition(config: IUIPopoverConfig): arrowPosition {
        if (!config.arrowPosition) {
            return undefined;
        }
        if (config.position === 'top') {
            return 'bottom';
        }
        if (config.position === 'bottom') {
            return 'top';
        }
        if (config.position === 'left') {
            return 'right';
        }
        if (config.position === 'right') {
            return 'left';
        }
        return undefined;
    }

    private getPositionStrategy(config: IUIPopoverConfig): any {
        // Tooltip is by default underneath (bottom) the host element
        if (config.position === 'bottom') {
            return [
                {
                    originX: 'center' as xPosition,
                    originY: 'bottom' as yPosition,
                    overlayX: 'center' as xPosition,
                    overlayY: 'top' as yPosition
                }
            ];
        }
        if (config.position === 'top') {
            return [
                {
                    originX: 'center' as xPosition,
                    originY: 'top' as yPosition,
                    overlayX: 'center' as xPosition,
                    overlayY: 'bottom' as yPosition
                }
            ];
        }
        if (config.position === 'left') {
            return [
                {
                    originX: 'start' as xPosition,
                    originY: 'center' as yPosition,
                    overlayX: 'end' as xPosition,
                    overlayY: 'center' as yPosition
                }
            ];
        }
        if (config.position === 'right') {
            return [
                {
                    originX: 'end' as xPosition,
                    originY: 'center' as yPosition,
                    overlayX: 'start' as xPosition,
                    overlayY: 'center' as yPosition
                }
            ];
        }
        return undefined;
    }
}
