import { Component, Input } from '@angular/core';

@Component({
    selector: 'ui-menu-side-panel',
    templateUrl: './ui-menu-side-panel.component.html',
    styleUrls: ['./ui-menu-side-panel.component.scss'],
    host: {
        '[class.panel]': 'true',
        '[class.left-panel]': `type === 'left'`,
        '[class.right-panel]': `type === 'right'`
    }
})
export class UIMenuSidePanelComponent {
    @Input() type: 'left' | 'right';
}
