import { OverlayRef } from '@angular/cdk/overlay';
import { Subject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { UIPopoverComponent } from './popover.component';
import { TemplateRef, Type, ComponentRef, EventEmitter } from '@angular/core';
import { UIPOPOVER_DEFAULT_CONFIG } from './popover.interface';
import { IUIPopoverConfig } from '../../../types/popover';
export class UIPopoverRef<T = any> {
    componentInstance: UIPopoverComponent;
    subComponentRef: ComponentRef<T>;
    subComponentInstancePromise: Promise<ComponentRef<any>>;
    config: IUIPopoverConfig = {};
    afterViewInit: Promise<void>;
    onRendered = new EventEmitter();

    onClose = new EventEmitter();

    private _beforeClose: Subject<void> = new Subject<void>();
    private _afterClosed: Subject<void> = new Subject<void>();
    private _afterViewInitPromiseResolve: (value?: void | PromiseLike<void>) => void;

    constructor(
        public overlayRef: OverlayRef,
        public templateRef?: TemplateRef<any>,
        public componentClass?: Type<T>,
        config: IUIPopoverConfig = {}
    ) {
        this.config = { ...UIPOPOVER_DEFAULT_CONFIG, ...config };
        this.afterViewInit = new Promise<void>(resolve => {
            this._afterViewInitPromiseResolve = resolve;
        });
    }

    /**
     * Fade out and remove popover
     */
    close(): void {
        // If popover exists, fade it out
        if (this.componentInstance && this.componentInstance.animationState !== 'void') {
            this.componentInstance.animationStateChanged
                .pipe(
                    filter(event => event.phaseName === 'start'),
                    take(1)
                )
                .subscribe(() => {
                    this._beforeClose.next();
                    this._beforeClose.complete();
                    this.overlayRef.detachBackdrop();
                });

            this.componentInstance.animationStateChanged
                .pipe(
                    filter(event => event.phaseName === 'done' && event.toState === 'leave'),
                    take(1)
                )
                .subscribe(() => {
                    this.overlayRef.dispose();
                    this._afterClosed.next();
                    this._afterClosed.complete();

                    this.componentInstance = null!;
                });

            this.componentInstance.startExitAnimation();
        }
        // If this is already removed -> clean up
        else {
            this.destroy();
        }

        this.onClose.emit();
    }

    afterClosed(): Observable<void> {
        return this._afterClosed.asObservable();
    }

    beforeClose(): Observable<void> {
        return this._beforeClose.asObservable();
    }

    resolveAfterViewInitPromise(): void {
        this._afterViewInitPromiseResolve();
    }

    /**
     * Destroy and cleanup
     */
    destroy(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef.detachBackdrop();
        }
        this._beforeClose.complete();
        this._afterClosed.complete();
        this.componentInstance = null!;
    }
}
