import { Component, AfterViewInit } from '@angular/core';
import { INotificationConfig, UINotificationService } from 'components/notification';
import { DownloadUtilities } from 'utils/download-utilities';

// TODO: this variables extraction will not work with :root css variables

@Component({
    // Demo Project components should not be forced to use ui- prefix
    // tslint:disable-next-line:component-selector
    selector: 'variables',
    templateUrl: 'variables.component.html',
    styleUrls: ['./variables.component.scss']
})
export class VariablesComponent implements AfterViewInit {
    public variables: string[] = [];
    public colorVariables: string[] = [];
    public staticColors: string[] = [];

    public variableSearch: string;
    public hideDefaultVariables: string;

    constructor(private uiNotificationService: UINotificationService) {}

    public ngAfterViewInit(): void {
        const styleSheet: any = document.styleSheets[0];
        const staticColorSet: Set<string> = new Set();

        if (styleSheet) {
            const variablesList: any[] = [];

            for (const rules of styleSheet.cssRules) {
                if (rules.selectorText?.includes(':root')) {
                    variablesList.push(rules);
                }
            }

            variablesList.forEach((variableList: any) => {
                for (const variableRule of variableList.style) {
                    if (variableRule.includes('--ui-static') && this.isColor(variableRule)) {
                        staticColorSet.add(variableRule);
                    } else if (variableRule.includes('--')) {
                        // If it's a color, we want it on a specific array
                        if (this.isColor(variableRule)) {
                            this.colorVariables.push(variableRule);
                        } else {
                            this.variables.push(variableRule);
                        }
                    }
                }
            });

            this.staticColors = [...staticColorSet];
            setTimeout(() => {
                this.hideDefaultVariables = '--default';
            });
        }
    }

    public changeHideDefault(event: any): void {
        if (event) {
            this.hideDefaultVariables = '--default';
        } else {
            this.hideDefaultVariables = '';
        }
    }

    public getVariableValue(variableName: string): string {
        const value: string = window
            .getComputedStyle(document.body)
            .getPropertyValue(variableName)
            .trim();

        return value.includes('url(') ? 'Svg Image' : value;
    }

    public isColor(variableName: string): boolean {
        const property: string = window
            .getComputedStyle(document.body)
            .getPropertyValue(variableName)
            .trim();

        return !!(property[0] === '#' || (property[0] === 'r' && property.includes('rgb')));
    }

    public getReferenceVariableName(variable: string): string {
        const variableHex: string = this.getVariableValue(variable);
        let staticReference = '';

        if (this.isColor(variable)) {
            this.staticColors.forEach((staticColor: string) => {
                const staticHex = this.getVariableValue(staticColor);
                if (variableHex === staticHex) {
                    staticReference = staticColor;
                }
            });
        }

        return staticReference.length ? staticReference : variableHex;
    }

    public copyVariable(text: string): void {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                this.uiNotificationService.open(`Variable name copied!`, {
                    type: 'success',
                    autoCloseDelay: 1000
                });
            })
            .catch(() => {
                console.warn('failed to copy to clipboard');
            });
    }

    public downloadContentAsTxt(): void {
        const colorVariablesRecord = this.getColorRecord(this.colorVariables);
        const staticColorsRecord = this.getColorRecord(this.staticColors);
        const variablesRecord = this.getColorRecord(this.variables);

        const stringifiedRecords = JSON.stringify(
            { colorVariablesRecord, staticColorsRecord, variablesRecord },
            null,
            4
        );

        DownloadUtilities.downloadToText(stringifiedRecords, 'colors.txt');

        const config: INotificationConfig = {
            type: 'success',
            autoCloseDelay: 5000,
            placement: 'top'
        };

        const msg = 'Colors downloaded successfully to txt file.';

        this.uiNotificationService.open(msg, config);
    }

    private getColorRecord(colors: string[]): Record<string, string> {
        const record: Record<string, string> = {};
        colors.forEach(color => {
            record[color] = this.getVariableValue(color);
        });

        return record;
    }
}
