import { Component, Input } from '@angular/core';
import { UIListColumnDirective } from '../../../templates/list-column.directive';

@Component({
    selector: 'ui-list-header-cell',
    templateUrl: 'list-header-cell.component.html',
    styleUrls: ['list-header-cell.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush, // Didn't work with initial sorting, disable temporary
    host: {
        '[class.cell]': `true`,
        '[class.header-cell]': `true`,
        '[class.sortable]': `column?.sortable`,
        '[class.sort-asc]': `column?.order === 'asc'`,
        '[class.sort-desc]': `column?.order === 'desc'`,
        '[class.align-right]': `column?.align === 'right'`,
        '[class.align-center]': `column?.align === 'center'`,
        '[class.hidden]': `column.hidden`
        // '[style.max-width]' : `column.fixedWidth ? column.width : ''`,
        // '[style.flex-grow]': `column.fixedWidth ? '0' : column.width`
    }
})
export class UIListHeaderCellComponent {
    /**
     * Column to show in this cell
     */
    @Input() column: UIListColumnDirective;
}
