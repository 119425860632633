// libs
import { Component, ElementRef, ChangeDetectorRef, Input } from '@angular/core';
import { UISelectableBaseDirective } from '../selectable-list/selectable.component';

@Component({
    selector: 'ui-toggle-switch',
    templateUrl: 'toggle-switch.component.html',
    styleUrls: ['toggle-switch.component.scss'],
    providers: [
        {
            provide: UISelectableBaseDirective,
            useExisting: UIToggleSwitchComponent,
            multi: true
        }
    ],
    host: {
        '[class.ui-toggle-switch]': 'true',
        '[class.disabled]': 'disabled'
    }
})
export class UIToggleSwitchComponent extends UISelectableBaseDirective {
    /**
     * If the interim state should be shown
     */
    @Input() interim: boolean;

    /**
     * Label of the checkbox
     */
    @Input() label: string;

    constructor(
        public override host: ElementRef,
        public override changeDetectorRef: ChangeDetectorRef
    ) {
        super(host, changeDetectorRef);
    }

    public onClick(): void {
        if (this.disabled) {
            return;
        }
        if (this.interim) {
            // If user clicks the toggle in interim state, it always switches to TRUE
            this.interim = false;
            this.select();
        } else {
            this.toggleSelect();
        }
    }
}
