import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UITooltipService {
    onClose = new Subject<void>();

    constructor() {}

    /**
     * Closes all tooltips
     */
    closeAll(): void {
        this.onClose.next();
    }
}
