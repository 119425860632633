import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    ElementRef,
    Renderer2,
    OnDestroy,
    ViewChild,
    AfterViewInit,
    OnChanges,
    SimpleChanges,
    SimpleChange
} from '@angular/core';
import { UIGlobalEvent } from '../../../services/global-event';
import { CustomValueAccessorDirective, customValueProvider } from '../../../utils/customValueAccessor';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { UIInputAutocomplete, UIInputType, IconPositionType } from '../../../types/input';
import { Icon } from '../../icon/svg-icon/icons';

@Component({
    selector: 'ui-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    host: {
        '[class.input]': 'true',
        '[class.discrete]': 'discrete',
        '[class.super-discrete]': 'superDiscrete',
        '[class.expand]': 'expand',
        '[class.keep-expanded]': 'expand && value',
        '[class.dynamic-width]': 'dynamicWidth'
    },
    providers: [customValueProvider(UIInputComponent)]
})
export class UIInputComponent
    extends CustomValueAccessorDirective<string>
    implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
    /**
     * ID.
     */
    @Input() public id?: string = Math.random().toString(36).substring(2, 9);

    /**
     * Placeholder text.
     */
    @Input() public placeholder: string | undefined = '';

    /**
     * Label
     */
    @Input() public label?: string;

    /**
     * Tabindex
     */
    @Input() public tabindex = '';

    /**
     * Autocomplete when user is typing.
     * Default is 'on' but can be also 'off'
     */
    @Input() public autocomplete: UIInputAutocomplete = 'on';

    /**
     * Autofocus automatically sets focus to the input
     * when the input component is initialized
     */
    @Input() public autofocus = false;

    /**
     * Input type, allowed are 'text' and 'password'
     */
    @Input() public type: UIInputType = 'text';

    /**
     * Max value length of the input.
     */
    @Input() public maxlength?: number;

    /**
     * Min value length of the input.
     */
    @Input() public minlength?: number;

    /**
     * Validation
     */
    @Input() public validation?: UntypedFormControl | AbstractControl;

    /**
     * No borders when not focusing
     */
    @Input() public discrete: boolean;

    /**
     * No borders at all
     */
    @Input() public superDiscrete: boolean;

    /**
     * Disable undo.
     */
    @Input() public disableUndo: boolean;

    /**
     * Position of the icon, can be left or right
     */
    @Input() public iconPosition: IconPositionType = 'left';

    /**
     * Icon
     */
    @Input() public icon: string;

    /**
     * SvgIcon
     */
    @Input() public svgIcon: Icon;

    /**
     * Should the field be minimized from start?
     */
    @Input() public expand = false;

    /**
     * Should the field dynamically match input string width?
     */
    @Input() public dynamicWidth = false;

    /**
     * Characters that should be allowed, allows RegExp as a string.
     */
    @Input() public allowedChars: string;

    /**
     * Select all text when input gets focus
     */
    @Input() public selectTextOnFocus: boolean;

    /**
     * Blur text field on submit
     */
    @Input() public blurOnSubmit: boolean;

    /**
     * Submit event.
     */
    @Output() public submit = new EventEmitter<void>();

    /**
     * Cancel event.
     */
    @Output() public cancel = new EventEmitter<void>();

    /**
     * Blur event.
     */
    @Output() public blur = new EventEmitter<void>();

    /**
     * Undo event.
     */
    @Output() public undo = new EventEmitter<void>();

    /**
     * Redo event.
     */
    @Output() public redo = new EventEmitter<void>();

    /**
     * Change event.
     */
    @Output() public valueChange = new EventEmitter<string>();

    @Output() public dynamicWidthUpdated = new EventEmitter<void>();

    @Output() public clickIcon = new EventEmitter<void>();

    @Output() public keyUpEvent = new EventEmitter<KeyboardEvent>();

    @ViewChild('valueContainer') public valueContainer: ElementRef<HTMLInputElement>;

    private lastValidValue: string;
    private get inputElement(): HTMLInputElement {
        return this.valueContainer.nativeElement as HTMLInputElement;
    }

    constructor(
        private _element: ElementRef<HTMLInputElement>,
        private _renderer2: Renderer2,
        private globalEvent: UIGlobalEvent
    ) {
        super(_element, _renderer2);
        // In case CustomValueAccessor is used and it will fail
        // having Date or any other kind of Object use this.
        // this.value = '';
    }

    ngOnChanges(changes: SimpleChanges): void {
        const value = changes['value'] as SimpleChange | undefined;
        const isNewValue = value?.previousValue !== value?.currentValue;
        if (this.dynamicWidth && isNewValue) {
            setTimeout(() => {
                this.setDynamicWidth();
            });
        }
    }

    onFocus(): void {
        if (this.selectTextOnFocus) {
            setTimeout(() => {
                this.inputElement.select();
            });
        }
    }

    onKeyUp(event: KeyboardEvent): void {
        this.keyUpEvent.emit(event);
    }

    onKeyDown(event: KeyboardEvent): void {
        const key = event.code;
        const defmodKey = navigator.userAgent.includes('Mac OS X') ? event.metaKey : event.ctrlKey;

        if (this.allowedChars) {
            const regex = new RegExp(this.allowedChars, 'gi');
            if (!event.key.match(regex)) {
                event.preventDefault();
                return;
            }
        }

        if (key === 'Enter') {
            this.submit.emit();

            if (this.blurOnSubmit) {
                this.blurInput();
            }

            // Timeout here to grab the value if is being edited during submit. Submit is being used
            // in the color picker in Studio where a user can type in #fff and we reformat it to #FFFFFF.
            // We want the last valid value to be #FFFFFF in this case not #fff.
            setTimeout(() => {
                this.lastValidValue = this.valueContainer.nativeElement.value;
            });
            return;
        }

        const differFromEffectiveText = this.lastValidValue !== this.value;
        if (defmodKey && this.disableUndo) {
            let isUndoRedo = false;
            if (key === 'KeyZ') {
                if (event.shiftKey) {
                    this.redo.emit();
                } else {
                    if (differFromEffectiveText) {
                        this.value = this.lastValidValue;
                        event.preventDefault();
                        return;
                    }
                    this.undo.emit();
                }
                isUndoRedo = true;
            } else if (key === 'KeyY') {
                this.redo.emit();
                isUndoRedo = true;
            }
            if (isUndoRedo) {
                event.preventDefault();
                setTimeout(() => (this.lastValidValue = this.value!));
            }
        }
    }

    public ngOnInit(): void {
        this.globalEvent.on('theme-change', () => {});

        if (this.autofocus) {
            setTimeout(() => {
                this.focus();
            });
        }
        this.lastValidValue = this.value!;
    }

    public ngAfterViewInit(): void {
        if (this.dynamicWidth) {
            setTimeout(() => {
                this.setDynamicWidth();
            });
        }
    }

    public ngOnDestroy(): void {
        this.globalEvent.off('theme-change', () => {});
    }

    /**
     * Implementation from CustomValueAccessor class.
     * Should not contain any logic or emits.
     */
    public writeValue(value: string): void {
        this.value = value;
    }

    public pushChanges(event: Event): void {
        const value = (event.target as HTMLInputElement).value;
        this.valueContainer.nativeElement.value = value;
        // ? encodeXml(value)
        //       .replace(/\n/g, '<br>&#8203;')
        //       .replace(/\r\n/g, '<br>&#8203;')
        //       .replace(/&#8203;(.+?)/g, '$1')
        // : '&#8203;';

        if (this.dynamicWidth) {
            this.setDynamicWidth();
        }
        this.onChange(value);
        this.valueChange.emit(value);
    }

    private setDynamicWidth(): void {
        if (!this.valueContainer) {
            return;
        }

        const reservedCarretSpace = 3;
        let placeholderWidth = 0;

        if (this.valueContainer.nativeElement.value.length === 0 && this.placeholder) {
            this.valueContainer.nativeElement.style.width = '0px';
            this.valueContainer.nativeElement.value = this.placeholder;
            placeholderWidth = this.valueContainer.nativeElement.scrollWidth;
            this.valueContainer.nativeElement.value = '';
        }

        this.valueContainer.nativeElement.style.width = '0px';
        this._element.nativeElement.style.width = `${
            this.valueContainer.nativeElement.scrollWidth + placeholderWidth + reservedCarretSpace
        }px`;
        this.valueContainer.nativeElement.style.width = '100%';

        this.dynamicWidthUpdated.next();
    }

    /**
     * Set focus to the input field
     * @param select Select the text when focusing
     */
    public focus(select: boolean = false): void {
        this.inputElement.focus();
        if (select) {
            this.inputElement.select();
        }
    }

    /**
     * Blur the input field
     */
    public blurInput(): void {
        this.inputElement.blur();
    }

    public onClickIcon(): void {
        this.clickIcon.emit();
    }
}
