import { SelectionModel } from '@angular/cdk/collections';
import { CdkTreeNodeOutlet, FlatTreeControl } from '@angular/cdk/tree';
import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewRef
} from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Observable, of as ofObservable } from 'rxjs';
import { UIDropZoneEvent } from '../../../directives/drag-and-drop/drop-zone.directive';
import { SelectionType, UIListDropEvent } from '../../../types/list';
import { UIListNestedNodeType } from '../../../types/nested-node-type';
import { IUIEditedCellData } from './models';
import { UIListControlNode } from './models/list-control-node';
import { UIListFlatNode } from './models/list-data-flat-node';
import { IUIListDataNode } from './models/list-data-node';
import { UIListDataSource } from './models/list-data-source';
import { UIListColumnDirective } from './templates/list-column.directive';
import { UIListGridDirective } from './templates/list-grid.directive';

/*
none shows only a list, unable to select anything.
multi is the same as selectable=true and same behaviour as holding ctrl and clicking
and single will only select one (disable ctrl and shift usage, behave same as radio)
*/

/**
 * @title Tree with flat nodes: https://stackblitz.com/angular/poxkygvnbyd?file=app%2Ftree-checklist-example.ts
 */
@Component({
    selector: 'ui-list',
    templateUrl: 'list.component.html',
    styleUrls: ['list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.ui-list]': 'true',
        '[class.selectable]': 'selectionType!=="none"',
        '[class.checkboxes]': 'selectionType==="checkbox"',
        '[class.radio]': 'selectionType==="radio"',
        '[class.drag-and-drop]': 'selectionType!=="none"',
        '[class.expandable]': 'expandable',
        '[style.border-width]': `outerBorder ? '1px' : '0'`
    }
})
export class UIListComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
    /**
     * Werther this list should be displayed as a list or grid.
     */
    @Input() layout: 'list' | 'grid' = 'list';

    /**
     * The data table should show
     */
    @Input() dataSource: UIListDataSource<any>;

    /**
     * Define which selection type should be used
     */
    @Input() selectionType: SelectionType = 'none';

    /**
     * Single or multi selection
     */
    @Input() multiSelect = true;

    /**
     * Set and get sorting. Trigger event when changed.
     */
    @Input()
    set sortBy(val: string) {
        if (val !== this._sortBy) {
            this._sortBy = val;
            this.sortByProperty(val);
            this.sortByChange.emit(val);
        }
    }
    get sortBy(): string {
        return this._sortBy;
    }

    /**
     * Allow drag and drop to move items to expandable nodes
     */
    @Input() dragAndDrop = false;

    /**
     * Allow nodes to expand.
     */
    @Input() expandable = true;

    /**
     * Show or hide header
     */
    @Input() showHeader = true;

    /**
     * When browsing sub nodes, should a back button to the parent be shown or not?
     */
    @Input() showBackButton = true;

    /**
     * Option to drill down under folder with checkboxes.
     */
    @Input() enableDrillDown = false;

    /**
     * Show the default empty state or not
     */
    @Input() showEmptyState = true;

    /**
     * If more content should be loaded on scroll
     */
    @Input() loadMoreOnScroll = false;

    /**
     * Show an outer border.
     * Could be good to turn of if you want to control
     * that in the view instead
     */
    @Input() outerBorder = true;

    /**
     * Node of which children should be in the root of list.
     * Pass undefined to make first level in dataset show.
     */
    @Input() currentNode?: IUIListDataNode;

    @Input()
    set gridSize(val: string) {
        this.elementRef.nativeElement.style.setProperty('--grid-size', val || '100px');
    }

    @Input()
    set columnHeight(val: string) {
        this.elementRef.nativeElement.style.setProperty('--row-height', val || '5rem');
    }

    /**
     * Allow for a node to be dropped on the back navigation element in list
     */
    @Input() allowBackDrop: boolean;

    /**
     * Make the header sticky
     */
    @Input() stickyHeader: boolean;

    /**
     * When user browses to another node
     */
    @Output() currentNodeChange: EventEmitter<IUIListDataNode | undefined> = new EventEmitter();

    /**
     * When a sort i triggered by a user
     */
    @Output() sort: EventEmitter<string> = new EventEmitter();

    /**
     * Request more items in this expandable node
     */
    @Output() load: EventEmitter<IUIListDataNode | undefined> = new EventEmitter();

    /**
     * Request more items in this expandable node
     */
    @Output() drop: EventEmitter<UIListDropEvent> = new EventEmitter();

    /**
     * When item is dropped on back-navigation element
     */
    @Output() dropOnBackElement: EventEmitter<UIListDropEvent> = new EventEmitter();

    /**
     * When user selects or deselect an object
     */
    @Output() selectionChange: EventEmitter<IUIListDataNode[]> = new EventEmitter();

    /**
     * When sorting is changed
     */
    @Output() sortByChange: EventEmitter<string> = new EventEmitter();

    /**
     * When editing is done
     */
    @Output() editData: EventEmitter<IUIEditedCellData> = new EventEmitter();

    /**
     * Injected columns containg the templates for cells, folders, header etc
     */
    @ContentChildren(UIListColumnDirective) columns: QueryList<UIListColumnDirective>;

    /**
     * Injected columns containg the templates for cells, folders, header etc
     */
    @ContentChild(UIListGridDirective) grid?: UIListGridDirective;

    /**
     * Template for back navigation
     */
    @ViewChild('backNavigationTemplate') private backNavigationTemplate: TemplateRef<any>;

    /**
     * Template for loader
     */
    @ViewChild('loaderTemplate') private loaderTemplate: TemplateRef<any>;

    /**
     * Template for empty folder
     */
    @ViewChild('emptyTemplate') private emptyTemplate: TemplateRef<any>;

    /**
     * Template for no matches when filtering
     */
    @ViewChild('noMatchTemplate') private noMatchTemplate: TemplateRef<any>;

    @ContentChildren('children', { read: ElementRef }) children: QueryList<ElementRef>;
    nodeOutlet: QueryList<CdkTreeNodeOutlet>;

    /**
     * Controls navigation and expand collapse in the list
     */
    treeControl: FlatTreeControl<UIListFlatNode>;

    /**
     * List of nodes used in list view.
     */
    nodeSource: MatTreeFlatDataSource<UIListNestedNodeType, UIListFlatNode>;

    /**
     * Selection handler. Contains methods for deselect, selct etc
     */
    selection: SelectionModel<UIListFlatNode>;

    allSelected = false;

    /**
     * indeterminate selection handler
     */
    indeterminate = false;

    /**
     * True when the view / column grid have been rendered
     */
    layoutInitialized = false;

    /**
     * When node data is changed
     */
    nodeChange = new EventEmitter<IUIListDataNode>();
    nodeChange$: Observable<IUIListDataNode>;

    currentFlatNode?: UIListFlatNode;

    /**
     * Unique ID if radio buttons group
     */
    radioGroupId: string;

    /**
     * Map from flat node to nested node. This helps us finding the nested node to be modified
     */
    private flatNodeMap: Map<UIListFlatNode, UIListNestedNodeType> = new Map<
        UIListFlatNode,
        UIListNestedNodeType
    >();

    /**
     * Map from nested node to flattened node.
     * This helps us to keep the same object for selection
     */
    private nestedNodeMap: Map<UIListNestedNodeType, UIListFlatNode> = new Map<
        UIListNestedNodeType,
        UIListFlatNode
    >();

    /**
     * Converts a tree to a flat list.
     */
    private treeFlattener: MatTreeFlattener<UIListNestedNodeType, UIListFlatNode>;

    private _sortBy: string;
    private dataSubscription: any;
    private nodeSubscription: any;
    private columnSubscription: any;
    private lastSelectedNode: UIListFlatNode;

    constructor(
        private elementRef: ElementRef,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        // Initiate
        this.treeFlattener = new MatTreeFlattener<UIListNestedNodeType, UIListFlatNode>(
            this.transformer,
            this.getLevel,
            this.isExpandable,
            this.getChildren
        );
        this.treeControl = new FlatTreeControl<UIListFlatNode>(this.getLevel, this.isExpandable);
        this.nodeSource = new MatTreeFlatDataSource<UIListNestedNodeType, UIListFlatNode>(
            this.treeControl,
            this.treeFlattener
        );

        // Bind to keep correct scope without using arrow functions
        this.onDataChange = this.onDataChange.bind(this);
        this.onNodeChange = this.onNodeChange.bind(this);
        this.isExpandable = this.isExpandable.bind(this);
        this.transformer = this.transformer.bind(this);
        this.getLevel = this.getLevel.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.resizeTable = this.resizeTable.bind(this);

        this.radioGroupId = `${Date.now()}_${Math.ceil(Math.random() * 1000)}`;
    }

    /**
     * When some input is changed
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['dataSource']) {
            const dataSource = changes['dataSource'].currentValue;

            // Remove old data source listener if it exist
            this.unsubscribeDataSource();

            // Only if a dataSource is provided
            if (dataSource) {
                // sort before setting data
                this.sortByProperty();

                // Trigger initial data change
                this.onDataChange(dataSource.data);

                // Listen on changes
                this.dataSubscription = dataSource.dataChange.subscribe(this.onDataChange);

                // Listen on changes
                this.nodeSubscription = dataSource.nodeChange.subscribe(this.onNodeChange);
            }
        }
    }

    ngOnInit(): void {
        this.selection = new SelectionModel<UIListFlatNode>(this.multiSelect);
    }

    /**
     * When templates and columns changes
     */
    ngAfterContentInit(): void {
        // Listen to changes on columns
        this.columnSubscription = this.columns.changes.subscribe(() => {
            this.resizeTable();
            this.sortByProperty();
        });

        // Set initial size
        setTimeout(() => {
            this.resizeTable();
            this.sortByProperty();
        });
    }

    detectChanges(): void {
        if (!(this.changeDetectorRef as ViewRef).destroyed) {
            this.changeDetectorRef.detectChanges();
        }
    }

    /**
     * When dragging, right clicking etc. we want to make sure that node is selected.
     * If not included in the current multiselect, clear selection and select this one.
     */
    updateSelection(actionNode: UIListFlatNode): void {
        if (!this.selection.isSelected(actionNode)) {
            this.selection.clear();
            this.selection.select(actionNode);
        }
    }

    /**
     * Enable edit on provided node
     */
    edit(data: IUIListDataNode): void {
        const node = this.nestedNodeMap.get(data);
        if (node) {
            node.edit = true;
        }
    }

    /**
     * When nodes are dropped in an expandable node
     * @param event
     */
    onDrop(event: UIDropZoneEvent, dropOnBackElement: boolean = false): void {
        const flatNodes: UIListFlatNode[] = Array.isArray(event.dragData)
            ? event.dragData
            : [event.dragData];
        const to = event.dropZoneData;

        // Make sure a folder isn't dropped in itself or in it's chidren
        const nodes = flatNodes
            .filter(
                node => node.data && node !== to && !this.treeControl.getDescendants(node).includes(to)
            )
            .map((node: UIListFlatNode) => node.data) as IUIListDataNode[];

        if (nodes.length && to && to.data && !dropOnBackElement) {
            this.drop.emit({ nodes, to: to.data });
        }

        if (nodes.length && to && to.data && dropOnBackElement && this.allowBackDrop) {
            this.dropOnBackElement.emit({ nodes, to: to.data });
        }
    }

    /**
     * Navigate to folder (set it as current folder)
     * @param folder
     */
    browseNode(node?: UIListFlatNode): void {
        let data = node && node.data ? node.data : undefined;

        // Back button clicked
        if (data instanceof UIListControlNode) {
            data = data.parent;
        }

        // If node has been changed
        if ((!node && this.currentNode) || data !== this.currentNode) {
            this.currentNode = data;
            this.currentFlatNode = node!;

            this.dataSource.currentNode = this.currentNode;
            this.currentNodeChange.emit(this.currentNode);
        }
    }
    /**
     * Navigate to the parent level
     */
    goToParent(): void {
        if (!this.currentFlatNode) {
            return;
        }
        const parent = this.dataSource.getParent(this.currentNode);
        this.currentFlatNode!.data = new UIListControlNode('back', this.backNavigationTemplate, parent);
        this.browseNode(this.currentFlatNode);
    }

    /**
     * Open or close "folder"
     * @param node
     */
    toggleNodeCollapse(node: UIListFlatNode): void {
        if (this.treeControl.isExpanded(node)) {
            this.collapseNode(node);
        } else {
            this.expandNode(node);
        }
    }

    /**
     * Expand node and show it's children
     * @param node
     */
    expandNode(node: UIListFlatNode): void {
        this.treeControl.expand(node);
        this.loadMore(node);
    }

    /**
     * Check to see if folder should request more data.
     * @param node
     */
    loadMore(node: UIListFlatNode): void {
        if (!node || !node.data || node.data.isLoading) {
            return;
        }

        const data = this.isControlNode(node) ? (node.data as UIListControlNode).parent : node.data;

        // Expandable should load more data
        if (
            data &&
            data.children &&
            data.totalCount !== undefined &&
            (data.totalCount > data.children.length || data.totalCount === -1)
        ) {
            this.load.emit(data);
        }
    }

    /**
     * Hide node children
     * @param node
     * @param collapseChildren
     */
    collapseNode(node: UIListFlatNode, collapseChildren: boolean = true): void {
        this.treeControl.collapse(node);

        if (collapseChildren) {
            this.treeControl.collapseDescendants(node);
        }
    }

    /**
     * User is clicking on a sortable header.
     * Trigger a sort in dataSource
     * @param column
     */
    onSortClick(column: UIListColumnDirective): void {
        // If this is already sorted descending sort ascending else just sort descending
        this.sortBy = (column.order === 'desc' ? '-' : '') + (column.property || '');
    }

    /**
     * Get level of a node to know amount of indenting to apply.
     */
    getLevel(node: UIListFlatNode): number {
        return node.level;
    }

    /**
     * Get parent of a node
     */
    getParent(node: UIListFlatNode): UIListFlatNode | undefined {
        const currentLevel = this.treeControl.getLevel(node);

        if (currentLevel < 1) {
            return undefined;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.treeControl.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return undefined;
    }

    /**
     * Check if this node is expandable or not.
     * NOTE: this has wrong scope in here regardless of bind...
     * @param node Node to test. View will send this as a number..
     * @param viewNode By some reason when used in view, first parameter is a number.
     */
    isExpandable = (node: UIListFlatNode | number, viewNode?: UIListFlatNode) =>
        // So both view and list component can use the same function
        ((viewNode || node) as UIListFlatNode).expandable;

    /**
     * Check if this node is expandable or not.
     * NOTE: this has wrong scope in here regardless of bind...
     * @param node Node to test. View will send this as a number..
     * @param viewNode By some reason when used in view, first parameter is a number.
     */
    isControlNode = (node: UIListFlatNode | number, viewNode?: UIListFlatNode) => {
        node = (viewNode || node) as UIListFlatNode;

        // So both view and list component can use the same function
        return node.data instanceof UIListControlNode;
    };

    /**
     * Get all chidren of a node as an observable
     */
    getChildren = (
        node: IUIListDataNode,
        includeControlNodes: boolean = true
    ): Observable<UIListNestedNodeType[]> => {
        const prefixControlNodes: IUIListDataNode[] = [];
        const suffixControlNodes: IUIListDataNode[] = [];

        if (includeControlNodes) {
            if (this.dataSource.isExpandable(node) && node.children) {
                if (
                    node.totalCount &&
                    (node.children.length < node.totalCount || node.totalCount === -1)
                ) {
                    suffixControlNodes.push(new UIListControlNode('loader', this.loaderTemplate, node));
                } else if (node.children.length === 0 && !node.isLoading) {
                    prefixControlNodes.push(new UIListControlNode('empty', this.emptyTemplate, node));
                }
            }
        }

        return ofObservable([
            ...prefixControlNodes,
            ...this.getVisibleNodes(node.children),
            ...suffixControlNodes
        ]);
    };

    /**
     * Get children currently not filtered by dataSource
     * @param nodes
     */
    getVisibleNodes(nodes: IUIListDataNode[] = []): IUIListDataNode[] {
        return nodes.filter(node => !node.removedByFilter);
    }

    /**
     * Whether all the descendants of the node are selected
     */
    descendantsAllSelected(node: UIListFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        return descendants.every(child => this.selection.isSelected(child));
    }

    /**
     * Whether part of the descendants are selected
     */
    descendantsPartiallySelected(node: UIListFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child => this.selection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    /**
     * To improve performance use this to keep track of what's changed or not
     * @param _index Sent in from the CDK loop from the view.
     * @param node
     */
    trackBy(_index: number, node: UIListFlatNode): string {
        return `${node.guid}_${node.level}`;
    }

    /**
     * Check if node is currently visible (does not have a collpased parent).
     * @param node
     */
    isVisible(node: UIListFlatNode): boolean {
        let parent = this.getParent(node);

        while (parent) {
            if (!this.treeControl.isExpanded(parent)) {
                return false;
            }
            parent = this.getParent(parent);
        }

        return true;
    }

    /**
     * Select a node. If using shift or ctrl, enable multiselect
     */
    selectFlatNode(node: UIListFlatNode, event?: MouseEvent): void {
        if (this.selectionType !== 'multi' && this.selectionType !== 'single') {
            return;
        }

        let cmdKey = event && (event.metaKey || event.ctrlKey);
        let shiftKey = event && event.shiftKey;

        // Only allow cmd or ctrl key usage if selectionType is multi
        cmdKey = this.selectionType === 'multi' ? cmdKey : false;
        shiftKey = this.selectionType === 'multi' ? shiftKey : false;

        const isDisabled = this.isDisabled(node);

        // CMD or CTRL select
        if (cmdKey && this.multiSelect) {
            if (!isDisabled) {
                this.selection.toggle(node);

                if (this.selection.isSelected(node)) {
                    this.lastSelectedNode = node;
                }
            }
        }
        // Shift select
        else if (shiftKey && this.lastSelectedNode && this.multiSelect) {
            const index = this.treeControl.dataNodes.indexOf(node);
            const lastIndex = this.treeControl.dataNodes.indexOf(this.lastSelectedNode);

            const from = Math.min(index, lastIndex);
            const to = Math.max(index, lastIndex);

            const selected = this.treeControl.dataNodes.filter(
                (dataNode, nodeIndex) =>
                    nodeIndex >= from &&
                    nodeIndex <= to &&
                    this.isVisible(dataNode) &&
                    !this.isDisabled(dataNode)
            );
            this.selection.clear();
            this.selection.select(...selected);
        }
        // Regular select
        else {
            this.selection.clear();
            if (!isDisabled) {
                this.selection.select(node);
                this.lastSelectedNode = node; // Will be used for shift select
            }
        }

        this.selectionChange.emit(this.flatToNested(this.selection.selected));

        this.detectChanges();
    }

    private isDisabled(node: UIListFlatNode): boolean {
        return !!(node.data && (node.data as IUIListDataNode).disabled);
    }

    /**
     * Select an item in the list
     * @param data
     */
    select(data: UIListNestedNodeType, emitChanges: boolean = true): void {
        const node = this.nestedNodeMap.get(data);
        const selectableNodes: UIListFlatNode[] = this.treeControl.dataNodes.filter(
            (dataNode: any) => !dataNode.data?.disabled
        );

        if (this.selectionType === 'none') {
            return;
        }
        if (!node) {
            throw new Error('Could not select node though the flat node was not found.');
        }

        const emptyChildren = selectableNodes.filter(
            selection =>
                selection.data?.type === 'empty' && selection.data?.parent?.id === node.data?.id
        );
        emptyChildren.forEach(child => {
            this.selection.select(child);
        });

        const activeChildren = node.data?.children?.filter(child => !child.disabled);
        if (activeChildren?.length) {
            activeChildren.forEach(child => {
                this.select(child);
            });
        }
        this.selection.select(node);

        if (emitChanges) {
            this.selectionChange.emit(this.flatToNested(this.selection.selected));
        }
        this.detectChanges();
    }

    /**
     * Deelect an item in the list
     * @param data
     */
    deselect(data: UIListNestedNodeType): void {
        const node = this.nestedNodeMap.get(data);

        if (!node) {
            throw new Error('Could not select node though the flat node was not found.');
        }

        const emptyChildren = this.selection.selected.filter(
            selection =>
                selection.data?.type === 'empty' && selection.data?.parent?.id === node.data?.id
        );
        emptyChildren.forEach(child => {
            this.selection.deselect(child);
        });

        if (node.data?.children?.length) {
            node.data.children.forEach(child => {
                this.deselect(child);
            });
        }

        this.selection.deselect(node);

        this.selectionChange.emit(this.flatToNested(this.selection.selected));
        this.detectChanges();
    }

    private isAllSelected(): boolean {
        const selectableNodes: UIListFlatNode[] = this.treeControl.dataNodes.filter(
            (node: any) => !node.data?.disabled
        );
        return this.selection.selected.length === selectableNodes.length;
    }

    toggle(data: UIListNestedNodeType, select?: boolean): void {
        const node = this.nestedNodeMap.get(data);

        if (!node) {
            throw new Error('Could not select node though the flat node was not found.');
        }

        select = select !== undefined ? select : !this.selection.isSelected(node);

        if (select) {
            if (this.selectionType === 'radio') {
                this.selection.clear();
                this.selectionChange.emit([]);
            }
            this.select(node.data!, true);
        } else {
            if (this.selectionType === 'radio') {
                return;
            }
            this.deselect(node.data!);
        }

        this.allSelected = this.isAllSelected();
        this.indeterminate = !this.allSelected && this.selection.selected.length > 0;
    }

    /**
     * Select all items
     */
    selectAll(): void {
        if (this.selectionType !== 'checkbox' && this.selectionType !== 'multi') {
            return;
        }

        const selectableNodes: UIListFlatNode[] = this.treeControl.dataNodes.filter(
            (node: any) => !node.data?.disabled
        );
        this.selection.select(...selectableNodes);
        this.selectionChange.emit(this.flatToNested(this.selection.selected));

        this.allSelected = true;
        this.indeterminate = false;
        this.detectChanges();
    }

    /**
     * Deselect all items, except those who are disabled
     */
    deselectAll(): void {
        const nodesToNotDeselect: UIListFlatNode[] = this.treeControl.dataNodes
            ? this.treeControl.dataNodes.filter((node: any) =>
                  node.data ? node.data.disabled && this.selection.isSelected(node) : false
              )
            : [];

        this.selection.clear();
        this.selection.select(...nodesToNotDeselect);
        this.selectionChange.emit(nodesToNotDeselect);

        this.allSelected = false;
        this.indeterminate = false;
        this.detectChanges();
    }

    /**
     * When something has changed in the data
     * @param data
     */
    private onDataChange(data: IUIListDataNode[]): void {
        // Get visible (not filtered) nodes as data
        this.nodeSource.data = this.getRootNodes(data);
    }

    /**
     * When something has changed in the data
     * @param data
     */
    private onNodeChange(node: IUIListDataNode): void {
        this.nodeChange.emit(node);
        this.nodeChange$ = this.nodeChange.asObservable();
    }

    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    private transformer = (nestedNode: UIListNestedNodeType, level: number) => {
        let flatNode: UIListFlatNode | undefined = this.nestedNodeMap.get(nestedNode);

        if (!flatNode || flatNode.data !== nestedNode) {
            flatNode = new UIListFlatNode();
        }

        flatNode.data = nestedNode;
        flatNode.level = level;
        flatNode.expandable = this.dataSource.isExpandable(nestedNode);
        this.flatNodeMap.set(flatNode, nestedNode);
        this.nestedNodeMap.set(nestedNode, flatNode);

        return flatNode;
    };

    private getRootNodes(data: IUIListDataNode[]): IUIListDataNode[] {
        const currentNode = this.dataSource.currentNode;
        const prefixControlNodes: IUIListDataNode[] = [];
        const suffixControlNodes: IUIListDataNode[] = [];

        // Check if we should add a loader in the bottom
        if (currentNode) {
            if (
                !this.dataSource.useRootNode ||
                (this.dataSource.rootNode && !this.dataSource.inRootNode)
            ) {
                if (this.showBackButton) {
                    const parent = this.dataSource.getParent(currentNode);
                    prefixControlNodes.push(
                        new UIListControlNode('back', this.backNavigationTemplate, parent)
                    );
                }
            }
            if (this.dataSource.isExpandable(currentNode)) {
                if (
                    currentNode.totalCount &&
                    currentNode.children &&
                    currentNode.children.length < currentNode.totalCount
                ) {
                    suffixControlNodes.push(
                        new UIListControlNode('loader', this.loaderTemplate, currentNode)
                    );
                } else if (
                    currentNode.children &&
                    currentNode.children.length === 0 &&
                    !currentNode.isLoading
                ) {
                    prefixControlNodes.push(
                        new UIListControlNode('empty', this.emptyTemplate, currentNode)
                    );
                }
            }
        }

        // Check if we are in root, add empty placeholder if no data is present
        else if (!data.length && this.showEmptyState) {
            prefixControlNodes.push(new UIListControlNode('empty', this.emptyTemplate, currentNode));
        } else if (this.dataSource?.filteredLength === 0) {
            prefixControlNodes.push(
                new UIListControlNode('noMatch', this.noMatchTemplate, currentNode)
            );
        }

        return [...prefixControlNodes, ...this.getVisibleNodes(data), ...suffixControlNodes];
    }

    /**
     * Trigger a sort in dataSource
     * @param prop
     */
    private sortByProperty(prop: string = this.sortBy): void {
        const column = this.getColumnByProperty(prop);

        if (prop && column) {
            // If this is already sorted descending sort ascending else just sort descending
            column.order = prop.indexOf('-') === 0 ? 'asc' : 'desc';

            // Reset all columns except current
            this.columns.forEach((col: UIListColumnDirective) => {
                if (column !== col) {
                    col.order = undefined;
                }
            });

            // Trigger sort in data source
            if (this.dataSource) {
                this.dataSource.sort(prop);
            }
        }
    }

    /**
     * Get a column by its "property"
     */
    private getColumnByProperty(property: string = ''): UIListColumnDirective | undefined {
        property = property.replace(/^-/, '');

        return this.columns ? this.columns.find(col => col.property === property) : undefined;
    }

    /**
     * Get width of container for "element queries"
     */
    private getWidth(): number {
        if (this.elementRef && this.elementRef.nativeElement) {
            return this.elementRef.nativeElement.offsetWidth;
        }

        return 0;
    }

    /**
     * Refresh grid to hide/show column depending on screensize.
     * TODO: The parent may be resized without the window.resize fires
     * TODO: Check if the $event variable can be removed without the build to appveyor fails
     */
    @HostListener('window:resize', ['$event'])
    resizeTable(): void {
        const width: number = this.getWidth();

        if (width && this.columns.length) {
            // Set hidden state
            this.columns.forEach((col: UIListColumnDirective) => {
                const hiddenAbove: boolean = width > (+col.hiddenAbove || Number.MAX_VALUE);
                const hiddenBelow: boolean = width < (+col.hiddenBelow || 0);

                col.hidden = hiddenAbove || hiddenBelow;
            });

            // Get widths as an array (ignore hidden)
            const columnWidths = this.columns
                .filter(col => !col.hidden)
                .map(col => (col.width || '1fr').toString());

            // Add checkbox column as first column
            if (this.selectionType === 'checkbox' || this.selectionType === 'radio') {
                columnWidths.unshift('50px');
            }

            // To string in format "col1.width col2.width..."
            const grid: string = columnWidths.join(' ');

            this.layoutInitialized = true;

            // if (this.elementRef.nativeElement.style.getProperty('--grid') !== grid) {
            // Set grid variables
            this.elementRef.nativeElement.style.setProperty('--grid', grid);
            this.elementRef.nativeElement.style.setProperty(
                '--full-width-cell',
                `1 / ${columnWidths.length + 1}`
            );

            // Refresh view
            this.detectChanges();
            // }
        } else if (this.grid) {
            this.layoutInitialized = true;
        }
    }

    /**
     * When clicking outside this list
     */
    @HostListener('document:click', ['$event'])
    onBodyClick(event: MouseEvent): void {
        // If not clicking on a tree node
        if (
            !this.inTreeNode(event.target) &&
            this.selectionType !== 'radio' &&
            this.selectionType !== 'checkbox'
        ) {
            this.deselectAll();
        }
    }

    private inTreeNode(element: any): boolean {
        let target = element;
        while (target.parentNode) {
            if (target.nodeName === 'CDK-TREE-NODE') {
                return true;
            }
            target = target.parentNode;
        }
        return false;
    }

    /**
     * When component is destroyed, remove subscriptions etc
     */
    ngOnDestroy(): void {
        this.unsubscribeDataSource();
        if (this.columnSubscription) {
            this.columnSubscription.unsubscribe();
        }
    }

    /**
     * Get a list of the original asset from a list of flat nodes
     * @param nodes
     */
    private flatToNested(nodes: UIListFlatNode[] = []): IUIListDataNode[] {
        const nested = nodes
            .filter(node => node && node instanceof UIListControlNode === false)
            .map(node => this.flatNodeMap.get(node) as IUIListDataNode);

        return nested;
    }

    private unsubscribeDataSource(): void {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
        if (this.nodeSubscription) {
            this.nodeSubscription.unsubscribe();
        }
    }

    /**
     * Returns data when inline edit is done
     */
    editedData(data: IUIEditedCellData): void {
        this.editData.emit(data);
        this.deselect(data.oldData);
    }

    isExpanded(node: UIListFlatNode): boolean {
        return this.treeControl.isExpanded(node);
    }
}
