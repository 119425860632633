import {
    Component,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    ViewChild,
    AfterContentChecked,
    ContentChild,
    TemplateRef
} from '@angular/core';
import { UIAccordionHeaderTemplateDirective } from '../templates/ui-accordion-header-template.directive';
import { Icon } from '../../../icon/svg-icon/icons';

@Component({
    selector: 'ui-accordion-item',
    templateUrl: 'accordion-item.component.html',
    styleUrls: ['accordion-item.component.scss']
})
export class UIAccordionItemComponent implements AfterContentChecked {
    /**
     * Text for the accordion header.
     *
     */
    @Input() header: string;

    /**
     * When true the accordion header is highlighted, set to false by default.
     *
     */
    @Input() highlightHeader: boolean;

    /**
     * When true the header is displayed as an error, set to false by default.
     *
     */
    @Input() highlightErrorHeader: boolean;

    /**
     * Prepend the Caret by reversing Order of the header content: Caret & Custom header.
     *
     */
    @Input() leftSideCaret: boolean;

    /**
     * controls whether content is animated by css transition property. check the style file.
     *
     */
    @Input() isContentAnimated = true;

    /**
     * Sets an icon beside the accordion header.
     *
     */
    @Input() icon: string;

    @Input() svgIcon: Icon;

    @Input() actionIcon: string;

    @Input() expanded = false;

    @Input() padding: string;

    @ViewChild('accordionItemContent', { static: true }) accordionItemContent: ElementRef;

    @Output('toggleChange') public toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output('actionCallback') public actionCallback: EventEmitter<any> = new EventEmitter<any>();

    @ContentChild(UIAccordionHeaderTemplateDirective, { read: TemplateRef })
    headerTemplate?: TemplateRef<any>;

    contentHeight = '0px';

    isActionHovered: boolean;

    toggleAccordion(): void {
        if (!this.isActionHovered) {
            this.expanded = !this.expanded;
            this.toggleChange.emit(this.expanded);
        }
    }

    actionClicked(): void {
        this.actionCallback.emit();
    }

    // tslint:disable-next-line:use-life-cycle-interface
    ngAfterContentChecked(): void {
        this.checkContent();
    }

    checkContent(): void {
        if (this.expanded) {
            this.contentHeight = `${this.accordionItemContent.nativeElement.scrollHeight}px`;
        } else {
            this.contentHeight = '0px';
        }
    }
}
