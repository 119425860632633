<div class="wrapper-notification">
    <div class="notification">
        <div
            class="text"
            [innerHTML]="sanitizedText"></div>
        <ui-svg-icon
            *ngIf="icon"
            [icon]="icon"
            (click)="close()"></ui-svg-icon>
    </div>
    <ui-checkbox
        *ngIf="showCheckBox"
        [label]="checkBoxText"
        (selectedChange)="toggleCheckbox()"></ui-checkbox>
</div>
