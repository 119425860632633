import {
    Component,
    Input,
    ContentChildren,
    ChangeDetectorRef,
    EventEmitter,
    QueryList,
    Output,
    AfterContentInit,
    OnDestroy,
    HostBinding,
    ElementRef,
    Renderer2
} from '@angular/core';
import { UISelectableBaseDirective } from './selectable.component';
import { Subject } from 'rxjs';
import { CustomValueAccessorDirective, customValueProvider } from '../../../utils/customValueAccessor';

@Component({
    selector: 'ui-selectable-list',
    templateUrl: 'selectable-list.component.html',
    styleUrls: ['selectable-list.component.scss'],
    providers: [customValueProvider(UISelectableListComponent)]
})
export class UISelectableListComponent
    extends CustomValueAccessorDirective<string>
    implements AfterContentInit, OnDestroy
{
    @Input() public multiSelect = false;
    @Input() public tokenField = false;
    @Input() public searchable = false;
    @Input() public allowUnselected = false;
    @Input() public grid: IUISelectableListGrid;
    @ContentChildren(UISelectableBaseDirective, { descendants: true })
    public selectables?: QueryList<UISelectableBaseDirective>;
    @Output('selectedChange') public selectedChange: EventEmitter<any | any[]> = new EventEmitter<
        any | any[]
    >();

    @Output('previewChange') public previewChange: EventEmitter<any | any[]> = new EventEmitter<
        any | any[]
    >();
    @Output('previewStop') public previewStop: EventEmitter<any | any[]> = new EventEmitter<
        any | any[]
    >();
    @HostBinding('class.flex') public flex = false;
    public selectablesLength?: number;
    @Input('selected')
    public get selected(): any | any[] {
        return this._selected;
    }

    // For external use
    public set selected(val: any | any[]) {
        this._selected = val;

        if (!val) {
            this.selectedViewText = '';
        }

        this.updateChildren();
    }

    @Input('value')
    public override get value(): any | any[] {
        return this._selected;
    }

    // For external use
    public override set value(val: any | any[]) {
        this._selected = val;
        this.updateChildren();
    }

    public get isEmpty(): boolean {
        if (this._selected !== undefined) {
            const values: any[] = this.multiSelect ? this._selected : [this._selected];
            // Not empty if length > 0
            return !(values.length > 0);
        }
        return true;
    }

    selectedViewValue = '';
    selectedViewText = '';

    private selectableComponentsSubscription: any;
    private subscriptions: any[];
    private _selected: any | any[];
    private readonly _destroy = new Subject<void>();

    constructor(
        protected host: ElementRef,
        protected renderer: Renderer2,
        protected changeDetectorRef: ChangeDetectorRef
    ) {
        super(host, renderer);
    }
    /**
     * Run this when content is initialized
     */
    ngAfterContentInit(): void {
        // When a child is added or removed.
        this.selectableComponentsSubscription = this.selectables?.changes.subscribe(() => {
            this.initialize();
        });

        // This need to be run after view is rendered
        setTimeout(() => {
            this.initialize();
        });
    }

    /**
     * Deselect all chidren
     */
    deselectAll(ignore: any[] = [], silentEmit?: boolean): void {
        if (this.selectables) {
            // TODO: Prevent usage of this is !allowUnselected
            // if (this.selectables.length && !this.allowUnselected) {
            //     throw new Error('Deselect all is not allowed without enabling allowUnselected');
            // }
            this.selectables.forEach(item => {
                // Not in ignore list
                if (!item.disabled && ignore.indexOf(item.getValue()) === -1) {
                    item.setSelected(false, true);
                }
                if (this.tokenField) {
                    item.host.nativeElement.style.display = 'flex';
                }
            });
            if (!silentEmit) {
                this.updateSelected();
            }
        }
    }

    /**
     * Select all children
     */
    selectAll(): void {
        if (this.selectables) {
            if (this.selectables.length > 1 && !this.multiSelect) {
                throw new Error('Select all is not allowed without enabling multiSelect');
            }
            this.selectables.forEach(item => {
                // Only select items that are not disabled
                if (!item.disabled) {
                    item.setSelected(true, true);
                }
            });
            this.updateSelected();
        }
    }

    /**
     * Select a component with certain value
     */
    select(value: any): void {
        const component = this.getComponentByValue(value);
        if (component) {
            component.select();
        }
    }

    /**
     * Deselect a component with certain value
     */
    deselect(value: any): void {
        const component = this.getComponentByValue(value);
        if (component) {
            if (this.tokenField) {
                component.host.nativeElement.style.display = 'flex';
            }
            component.deselect();
        }
    }

    /**
     * Get a UISelectableBaseDirective based on the value property of that component.
     * @param value the value property of the component. Could also be the component itself if no values are specified
     */
    getComponentByValue(value: any): UISelectableBaseDirective | undefined {
        if (this.selectables) {
            if (value?.id) {
                return this.selectables.find(item => item.getValue().id === value.id);
            } else {
                return this.selectables.find(item => item.getValue() === value);
            }
        }
        return undefined;
    }

    /**
     * Initialization of the component should be done after content is initialized
     * and when new children is added or removed
     */
    protected initialize(): void {
        if (this.selectables && this.selectables.length) {
            // Subscribe to all children
            this.subscribe();

            // Select assets based on current _selected
            this.updateChildren();

            // Setup grid
            this.updateGrid();

            this.selectablesLength = this.selectables.length;
        }
    }

    /**
     * Stop listen to changes in subcomponents
     */
    protected unsubscribe(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
        }
        this.subscriptions = [];
    }

    /**
     * Listen to changes in all subcomponents
     */
    protected subscribe(): void {
        this.unsubscribe();

        if (this.selectables) {
            for (let index = 0; index < this.selectables.length; index++) {
                const selectable = this.selectables.get(index);
                if (!selectable) {
                    continue;
                }

                // Add class for better styling options
                if (selectable.host) {
                    this.renderer.addClass(selectable.host.nativeElement, 'selectable');
                }

                this.subscriptions.push(
                    selectable.selectedChange.subscribe((value: boolean) => {
                        // Deselect a selected item -> Check if action is allowed
                        if (!value && !this.allowUnselected) {
                            // Multi
                            if (this.multiSelect) {
                                if (this._selected.length < 1) {
                                    selectable.setSelected(true, true);
                                }
                            }
                            // Single selectect
                            else if (selectable.getValue() === this._selected) {
                                selectable.setSelected(true, true);
                            }
                        }
                        if (this.multiSelect === false) {
                            this.deselectAll([selectable.getValue()], true);
                        }
                        const selectUntil =
                            this.multiSelect && selectable.shiftPressed ? index : undefined;
                        this.updateSelected(undefined, selectUntil);
                    })
                );

                this.subscriptions.push(
                    selectable.previewEmitter.subscribe((value: any) => {
                        this.previewChange.emit(value);
                    })
                );

                this.subscriptions.push(
                    selectable.hoverPreviewEmitter.subscribe((value: any) => {
                        this.previewChange.emit(value);
                    })
                );
            }
        }
    }

    updateChildren(): void {
        if (this._selected !== undefined) {
            this.deselectAll([], true);
            const values: any[] = this.multiSelect ? this._selected : [this._selected];
            if (values.length) {
                values.forEach(value => {
                    const component = this.getComponentByValue(value);
                    // Select if any component found
                    if (component) {
                        if (this.tokenField) {
                            component.host.nativeElement.style.display = 'none';
                        }

                        component.setSelected(true, true);
                        if (component.value !== undefined || component.value !== '') {
                            this.selectedViewValue = component.value;
                        } else {
                            this.selectedViewValue = component.host.nativeElement.innerText;
                        }
                        this.selectedViewText = component.host.nativeElement.innerText;
                        if (!(this.changeDetectorRef as any).destroyed) {
                            this.changeDetectorRef.detectChanges();
                        }
                    }
                });
            }
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe();
        this._destroy.next();
        this.changeDetectorRef.detach();
        if (this.selectableComponentsSubscription) {
            this.selectableComponentsSubscription.unsubscribe();
        }
    }

    /**
     * Update grid properties in DOM
     */
    private updateGrid(): void {
        const element: any = this.host.nativeElement;

        if (element) {
            if (this.grid) {
                const min: string = this.grid.min || '0';
                const space: string = this.grid.spacing || '0';

                this.renderer.setStyle(element, 'grid-gap', space);
                this.renderer.setStyle(
                    element,
                    'grid-template-columns',
                    `repeat(auto-fill, minmax(${min}, 1fr))`
                );
                this.renderer.addClass(element, 'ui-selectable-list--grid');
            } else {
                this.renderer.removeClass(element, 'ui-selectable-list--grid');
            }
        }
    }

    /**
     * Get all selected values from children and trigger emit if changed
     */
    private updateSelected(silentEmit?: boolean, selectUntil?: number): void {
        let selected: any[] = [];

        if (this.selectables) {
            const firstSelectedItem = this.selectables
                .map(selectable => selectable.selected)
                .indexOf(true);
            const lastSelectedItem = this.selectables
                .map(selectable => selectable.selected)
                .lastIndexOf(true);
            const startIndex = Math.min(selectUntil ?? -1, firstSelectedItem);
            const endIndex =
                typeof selectUntil === 'undefined' ? -1 : Math.max(selectUntil, lastSelectedItem);
            this.selectables.forEach((item, index) => {
                const validRange = startIndex !== -1 && endIndex !== -1;
                if (!this.multiSelect || typeof selectUntil === 'undefined' || !validRange) {
                    if (item.selected) {
                        selected.push(item.getValue());
                    }
                    return;
                }
                if (startIndex <= index && index <= endIndex) {
                    item.selected = true;
                    selected.push(item.getValue());
                }
            });
        }

        if (!this.multiSelect) {
            selected = selected.length ? selected[0] : undefined;
        }

        // Only trigger update if array values are changed, regardless of order.
        if (selected !== this._selected) {
            this._selected = selected;

            if (!silentEmit) {
                this.selectedChange.emit(this._selected);
            }
            this.changeDetectorRef.detectChanges();
        }
    }

    writeValue(value: string): void {
        this.selected = value;
        this.value = value;
    }
}

export interface IUISelectableListGrid {
    min: string;
    spacing: string;
}
