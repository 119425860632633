/**
 * The data is similar to our backend implementation that uses these libraries
 *  https://github.com/mj1856/TimeZoneNames
 *  https://github.com/mj1856/TimeZoneConverter
 */
export const mockTimeZones = [
    {
        id: 'Dateline Standard Time',
        displayName: '(UTC-12:00) International Date Line West',
        offset: -720.0
    },
    {
        id: 'UTC-11',
        displayName: '(UTC-11:00) Coordinated Universal Time-11',
        offset: -660.0
    },
    {
        id: 'Aleutian Standard Time',
        displayName: '(UTC-10:00) Aleutian Islands',
        offset: -540.0
    },
    {
        id: 'Hawaiian Standard Time',
        displayName: '(UTC-10:00) Hawaii',
        offset: -600.0
    },
    {
        id: 'Marquesas Standard Time',
        displayName: '(UTC-09:30) Marquesas Islands',
        offset: -570.0
    },
    {
        id: 'Alaskan Standard Time',
        displayName: '(UTC-09:00) Alaska',
        offset: -480.0
    },
    {
        id: 'UTC-09',
        displayName: '(UTC-09:00) Coordinated Universal Time-09',
        offset: -540.0
    },
    {
        id: 'Pacific Standard Time (Mexico)',
        displayName: '(UTC-08:00) Baja California',
        offset: -420.0
    },
    {
        id: 'UTC-08',
        displayName: '(UTC-08:00) Coordinated Universal Time-08',
        offset: -480.0
    },
    {
        id: 'Pacific Standard Time',
        displayName: '(UTC-08:00) Pacific Time (US & Canada)',
        offset: -420.0
    },
    {
        id: 'US Mountain Standard Time',
        displayName: '(UTC-07:00) Arizona',
        offset: -420.0
    },
    {
        id: 'Mountain Standard Time (Mexico)',
        displayName: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
        offset: -420.0
    },
    {
        id: 'Mountain Standard Time',
        displayName: '(UTC-07:00) Mountain Time (US & Canada)',
        offset: -360.0
    },
    {
        id: 'Central America Standard Time',
        displayName: '(UTC-06:00) Central America',
        offset: -360.0
    },
    {
        id: 'Central Standard Time',
        displayName: '(UTC-06:00) Central Time (US & Canada)',
        offset: -300.0
    },
    {
        id: 'Easter Island Standard Time',
        displayName: '(UTC-06:00) Easter Island',
        offset: -300.0
    },
    {
        id: 'Central Standard Time (Mexico)',
        displayName: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
        offset: -360.0
    },
    {
        id: 'Canada Central Standard Time',
        displayName: '(UTC-06:00) Saskatchewan',
        offset: -360.0
    },
    {
        id: 'SA Pacific Standard Time',
        displayName: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
        offset: -300.0
    },
    {
        id: 'Eastern Standard Time (Mexico)',
        displayName: '(UTC-05:00) Chetumal',
        offset: -300.0
    },
    {
        id: 'Eastern Standard Time',
        displayName: '(UTC-05:00) Eastern Time (US & Canada)',
        offset: -240.0
    },
    {
        id: 'Haiti Standard Time',
        displayName: '(UTC-05:00) Haiti',
        offset: -240.0
    },
    {
        id: 'Cuba Standard Time',
        displayName: '(UTC-05:00) Havana',
        offset: -240.0
    },
    {
        id: 'US Eastern Standard Time',
        displayName: '(UTC-05:00) Indiana (East)',
        offset: -240.0
    },
    {
        id: 'Turks And Caicos Standard Time',
        displayName: '(UTC-05:00) Turks and Caicos',
        offset: -240.0
    },
    {
        id: 'Paraguay Standard Time',
        displayName: '(UTC-04:00) Asuncion',
        offset: -180.0
    },
    {
        id: 'Atlantic Standard Time',
        displayName: '(UTC-04:00) Atlantic Time (Canada)',
        offset: -180.0
    },
    {
        id: 'Venezuela Standard Time',
        displayName: '(UTC-04:00) Caracas',
        offset: -240.0
    },
    {
        id: 'Central Brazilian Standard Time',
        displayName: '(UTC-04:00) Cuiaba',
        offset: -240.0
    },
    {
        id: 'SA Western Standard Time',
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        offset: -240.0
    },
    {
        id: 'Pacific SA Standard Time',
        displayName: '(UTC-04:00) Santiago',
        offset: -180.0
    },
    {
        id: 'Newfoundland Standard Time',
        displayName: '(UTC-03:30) Newfoundland',
        offset: -150.0
    },
    {
        id: 'Tocantins Standard Time',
        displayName: '(UTC-03:00) Araguaina',
        offset: -180.0
    },
    {
        id: 'E. South America Standard Time',
        displayName: '(UTC-03:00) Brasilia',
        offset: -180.0
    },
    {
        id: 'SA Eastern Standard Time',
        displayName: '(UTC-03:00) Cayenne, Fortaleza',
        offset: -180.0
    },
    {
        id: 'Argentina Standard Time',
        displayName: '(UTC-03:00) City of Buenos Aires',
        offset: -180.0
    },
    {
        id: 'Greenland Standard Time',
        displayName: '(UTC-03:00) Greenland',
        offset: -180.0
    },
    {
        id: 'Montevideo Standard Time',
        displayName: '(UTC-03:00) Montevideo',
        offset: -180.0
    },
    {
        id: 'Magallanes Standard Time',
        displayName: '(UTC-03:00) Punta Arenas',
        offset: -180.0
    },
    {
        id: 'Saint Pierre Standard Time',
        displayName: '(UTC-03:00) Saint Pierre and Miquelon',
        offset: -120.0
    },
    {
        id: 'Bahia Standard Time',
        displayName: '(UTC-03:00) Salvador',
        offset: -180.0
    },
    {
        id: 'UTC-02',
        displayName: '(UTC-02:00) Coordinated Universal Time-02',
        offset: -120.0
    },
    {
        id: 'Mid-Atlantic Standard Time',
        displayName: '(UTC-02:00) Mid-Atlantic - Old',
        offset: -120.0
    },
    {
        id: 'Azores Standard Time',
        displayName: '(UTC-01:00) Azores',
        offset: -60.0
    },
    {
        id: 'Cape Verde Standard Time',
        displayName: '(UTC-01:00) Cabo Verde Is.',
        offset: -60.0
    },
    {
        id: 'UTC',
        displayName: '(UTC) Coordinated Universal Time',
        offset: 0.0
    },
    {
        id: 'GMT Standard Time',
        displayName: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
        offset: 0.0
    },
    {
        id: 'Greenwich Standard Time',
        displayName: '(UTC+00:00) Monrovia, Reykjavik',
        offset: 0.0
    },
    {
        id: 'Sao Tome Standard Time',
        displayName: '(UTC+00:00) Sao Tome',
        offset: 0.0
    },
    {
        id: 'W. Europe Standard Time',
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        offset: 60.0
    },
    {
        id: 'Central Europe Standard Time',
        displayName: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        offset: 60.0
    },
    {
        id: 'Romance Standard Time',
        displayName: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
        offset: 60.0
    },
    {
        id: 'Morocco Standard Time',
        displayName: '(UTC+01:00) Casablanca',
        offset: 60.0
    },
    {
        id: 'Central European Standard Time',
        displayName: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
        offset: 60.0
    },
    {
        id: 'W. Central Africa Standard Time',
        displayName: '(UTC+01:00) West Central Africa',
        offset: 60.0
    },
    {
        id: 'Jordan Standard Time',
        displayName: '(UTC+02:00) Amman',
        offset: 120.0
    },
    {
        id: 'GTB Standard Time',
        displayName: '(UTC+02:00) Athens, Bucharest',
        offset: 120.0
    },
    {
        id: 'Middle East Standard Time',
        displayName: '(UTC+02:00) Beirut',
        offset: 120.0
    },
    {
        id: 'Egypt Standard Time',
        displayName: '(UTC+02:00) Cairo',
        offset: 120.0
    },
    {
        id: 'E. Europe Standard Time',
        displayName: '(UTC+02:00) Chisinau',
        offset: 120.0
    },
    {
        id: 'Syria Standard Time',
        displayName: '(UTC+02:00) Damascus',
        offset: 120.0
    },
    {
        id: 'West Bank Standard Time',
        displayName: '(UTC+02:00) Gaza, Hebron',
        offset: 120.0
    },
    {
        id: 'South Africa Standard Time',
        displayName: '(UTC+02:00) Harare, Pretoria',
        offset: 120.0
    },
    {
        id: 'FLE Standard Time',
        displayName: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        offset: 120.0
    },
    {
        id: 'Israel Standard Time',
        displayName: '(UTC+02:00) Jerusalem',
        offset: 120.0
    },
    {
        id: 'Kaliningrad Standard Time',
        displayName: '(UTC+02:00) Kaliningrad',
        offset: 120.0
    },
    {
        id: 'Sudan Standard Time',
        displayName: '(UTC+02:00) Khartoum',
        offset: 120.0
    },
    {
        id: 'Libya Standard Time',
        displayName: '(UTC+02:00) Tripoli',
        offset: 120.0
    },
    {
        id: 'Namibia Standard Time',
        displayName: '(UTC+02:00) Windhoek',
        offset: 120.0
    },
    {
        id: 'Arabic Standard Time',
        displayName: '(UTC+03:00) Baghdad',
        offset: 180.0
    },
    {
        id: 'Turkey Standard Time',
        displayName: '(UTC+03:00) Istanbul',
        offset: 180.0
    },
    {
        id: 'Arab Standard Time',
        displayName: '(UTC+03:00) Kuwait, Riyadh',
        offset: 180.0
    },
    {
        id: 'Belarus Standard Time',
        displayName: '(UTC+03:00) Minsk',
        offset: 180.0
    },
    {
        id: 'Russian Standard Time',
        displayName: '(UTC+03:00) Moscow, St. Petersburg',
        offset: 180.0
    },
    {
        id: 'E. Africa Standard Time',
        displayName: '(UTC+03:00) Nairobi',
        offset: 180.0
    },
    {
        id: 'Iran Standard Time',
        displayName: '(UTC+03:30) Tehran',
        offset: 210.0
    },
    {
        id: 'Arabian Standard Time',
        displayName: '(UTC+04:00) Abu Dhabi, Muscat',
        offset: 240.0
    },
    {
        id: 'Astrakhan Standard Time',
        displayName: '(UTC+04:00) Astrakhan, Ulyanovsk',
        offset: 240.0
    },
    {
        id: 'Azerbaijan Standard Time',
        displayName: '(UTC+04:00) Baku',
        offset: 240.0
    },
    {
        id: 'Russia Time Zone 3',
        displayName: '(UTC+04:00) Izhevsk, Samara',
        offset: 240.0
    },
    {
        id: 'Mauritius Standard Time',
        displayName: '(UTC+04:00) Port Louis',
        offset: 240.0
    },
    {
        id: 'Saratov Standard Time',
        displayName: '(UTC+04:00) Saratov',
        offset: 240.0
    },
    {
        id: 'Georgian Standard Time',
        displayName: '(UTC+04:00) Tbilisi',
        offset: 240.0
    },
    {
        id: 'Volgograd Standard Time',
        displayName: '(UTC+04:00) Volgograd',
        offset: 240.0
    },
    {
        id: 'Caucasus Standard Time',
        displayName: '(UTC+04:00) Yerevan',
        offset: 240.0
    },
    {
        id: 'Afghanistan Standard Time',
        displayName: '(UTC+04:30) Kabul',
        offset: 270.0
    },
    {
        id: 'West Asia Standard Time',
        displayName: '(UTC+05:00) Ashgabat, Tashkent',
        offset: 300.0
    },
    {
        id: 'Ekaterinburg Standard Time',
        displayName: '(UTC+05:00) Ekaterinburg',
        offset: 300.0
    },
    {
        id: 'Pakistan Standard Time',
        displayName: '(UTC+05:00) Islamabad, Karachi',
        offset: 300.0
    },
    {
        id: 'Qyzylorda Standard Time',
        displayName: '(UTC+05:00) Qyzylorda',
        offset: 300.0
    },
    {
        id: 'India Standard Time',
        displayName: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
        offset: 330.0
    },
    {
        id: 'Sri Lanka Standard Time',
        displayName: '(UTC+05:30) Sri Jayawardenepura',
        offset: 330.0
    },
    {
        id: 'Nepal Standard Time',
        displayName: '(UTC+05:45) Kathmandu',
        offset: 345.0
    },
    {
        id: 'Central Asia Standard Time',
        displayName: '(UTC+06:00) Astana',
        offset: 360.0
    },
    {
        id: 'Bangladesh Standard Time',
        displayName: '(UTC+06:00) Dhaka',
        offset: 360.0
    },
    {
        id: 'Omsk Standard Time',
        displayName: '(UTC+06:00) Omsk',
        offset: 360.0
    },
    {
        id: 'Myanmar Standard Time',
        displayName: '(UTC+06:30) Yangon (Rangoon)',
        offset: 390.0
    },
    {
        id: 'SE Asia Standard Time',
        displayName: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
        offset: 420.0
    },
    {
        id: 'Altai Standard Time',
        displayName: '(UTC+07:00) Barnaul, Gorno-Altaysk',
        offset: 420.0
    },
    {
        id: 'W. Mongolia Standard Time',
        displayName: '(UTC+07:00) Hovd',
        offset: 420.0
    },
    {
        id: 'North Asia Standard Time',
        displayName: '(UTC+07:00) Krasnoyarsk',
        offset: 420.0
    },
    {
        id: 'N. Central Asia Standard Time',
        displayName: '(UTC+07:00) Novosibirsk',
        offset: 420.0
    },
    {
        id: 'Tomsk Standard Time',
        displayName: '(UTC+07:00) Tomsk',
        offset: 420.0
    },
    {
        id: 'China Standard Time',
        displayName: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
        offset: 480.0
    },
    {
        id: 'North Asia East Standard Time',
        displayName: '(UTC+08:00) Irkutsk',
        offset: 480.0
    },
    {
        id: 'Singapore Standard Time',
        displayName: '(UTC+08:00) Kuala Lumpur, Singapore',
        offset: 480.0
    },
    {
        id: 'W. Australia Standard Time',
        displayName: '(UTC+08:00) Perth',
        offset: 480.0
    },
    {
        id: 'Taipei Standard Time',
        displayName: '(UTC+08:00) Taipei',
        offset: 480.0
    },
    {
        id: 'Ulaanbaatar Standard Time',
        displayName: '(UTC+08:00) Ulaanbaatar',
        offset: 480.0
    },
    {
        id: 'Aus Central W. Standard Time',
        displayName: '(UTC+08:45) Eucla',
        offset: 525.0
    },
    {
        id: 'Transbaikal Standard Time',
        displayName: '(UTC+09:00) Chita',
        offset: 540.0
    },
    {
        id: 'Tokyo Standard Time',
        displayName: '(UTC+09:00) Osaka, Sapporo, Tokyo',
        offset: 540.0
    },
    {
        id: 'North Korea Standard Time',
        displayName: '(UTC+09:00) Pyongyang',
        offset: 540.0
    },
    {
        id: 'Korea Standard Time',
        displayName: '(UTC+09:00) Seoul',
        offset: 540.0
    },
    {
        id: 'Yakutsk Standard Time',
        displayName: '(UTC+09:00) Yakutsk',
        offset: 540.0
    },
    {
        id: 'Cen. Australia Standard Time',
        displayName: '(UTC+09:30) Adelaide',
        offset: 630.0
    },
    {
        id: 'AUS Central Standard Time',
        displayName: '(UTC+09:30) Darwin',
        offset: 570.0
    },
    {
        id: 'E. Australia Standard Time',
        displayName: '(UTC+10:00) Brisbane',
        offset: 600.0
    },
    {
        id: 'AUS Eastern Standard Time',
        displayName: '(UTC+10:00) Canberra, Melbourne, Sydney',
        offset: 660.0
    },
    {
        id: 'West Pacific Standard Time',
        displayName: '(UTC+10:00) Guam, Port Moresby',
        offset: 600.0
    },
    {
        id: 'Tasmania Standard Time',
        displayName: '(UTC+10:00) Hobart',
        offset: 660.0
    },
    {
        id: 'Vladivostok Standard Time',
        displayName: '(UTC+10:00) Vladivostok',
        offset: 600.0
    },
    {
        id: 'Lord Howe Standard Time',
        displayName: '(UTC+10:30) Lord Howe Island',
        offset: 660.0
    },
    {
        id: 'Bougainville Standard Time',
        displayName: '(UTC+11:00) Bougainville Island',
        offset: 660.0
    },
    {
        id: 'Russia Time Zone 10',
        displayName: '(UTC+11:00) Chokurdakh',
        offset: 660.0
    },
    {
        id: 'Magadan Standard Time',
        displayName: '(UTC+11:00) Magadan',
        offset: 660.0
    },
    {
        id: 'Norfolk Standard Time',
        displayName: '(UTC+11:00) Norfolk Island',
        offset: 720.0
    },
    {
        id: 'Sakhalin Standard Time',
        displayName: '(UTC+11:00) Sakhalin',
        offset: 660.0
    },
    {
        id: 'Central Pacific Standard Time',
        displayName: '(UTC+11:00) Solomon Is., New Caledonia',
        offset: 660.0
    },
    {
        id: 'Russia Time Zone 11',
        displayName: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',
        offset: 720.0
    },
    {
        id: 'New Zealand Standard Time',
        displayName: '(UTC+12:00) Auckland, Wellington',
        offset: 780.0
    },
    {
        id: 'UTC+12',
        displayName: '(UTC+12:00) Coordinated Universal Time+12',
        offset: 720.0
    },
    {
        id: 'Fiji Standard Time',
        displayName: '(UTC+12:00) Fiji',
        offset: 720.0
    },
    {
        id: 'Kamchatka Standard Time',
        displayName: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
        offset: 720.0
    },
    {
        id: 'Chatham Islands Standard Time',
        displayName: '(UTC+12:45) Chatham Islands',
        offset: 825.0
    },
    {
        id: 'UTC+13',
        displayName: '(UTC+13:00) Coordinated Universal Time+13',
        offset: 780.0
    },
    {
        id: 'Tonga Standard Time',
        displayName: "(UTC+13:00) Nuku'alofa",
        offset: 780.0
    },
    {
        id: 'Samoa Standard Time',
        displayName: '(UTC+13:00) Samoa',
        offset: 840.0
    },
    {
        id: 'Line Islands Standard Time',
        displayName: '(UTC+14:00) Kiritimati Island',
        offset: 840.0
    }
];
