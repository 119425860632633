import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ui-theme',
    templateUrl: './theme.component.html',
    styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}

    public noop(event: Event): void {
        console.log('button', event.target, 'clicked');
    }

    valueChanged(event: Event) {
        console.log(event);
    }
}
