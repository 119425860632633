import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
    selector: '[ui-popover-target]',
    exportAs: 'ui-popover-target'
})
export class UIPopoverTargetDirective {
    constructor(public host: ElementRef) {}

    @HostBinding('class.popover-active')
    public isPopoverActive: boolean;

    onPopoverOpen(): void {
        this.isPopoverActive = true;
    }

    onPopoverClosed(): void {
        this.isPopoverActive = false;
    }
}
