import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
    ViewChild,
    ElementRef,
    Renderer2,
    HostListener,
    AfterViewInit
} from '@angular/core';
import { UIGlobalEvent } from '../../../services/global-event';
import { encodeXml } from '../../../utils/text';

@Component({
    selector: 'ui-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
    host: {
        '[class.input]': 'true',
        '[class.textarea]': 'true',
        '[class.invisible]': 'invisibleBorders'
    }
})
export class UITextareaComponent implements AfterViewInit, OnDestroy {
    /**
     * ID.
     */
    @Input() id?: string;

    /**
     * Placeholder text.
     */
    @Input() placeholder = '';

    /**
     * Blur text field on submit
     */
    @Input() blurOnSubmit: boolean;

    /**
     * Blur text field on submit
     */
    @Input() invisibleBorders: boolean;

    /**
     * Value.
     */
    @Input() value: string | undefined = '';

    /**
     * Maximum amount of rows allowed.
     */
    @Input() maxRows = 0;

    /**
     * Maximum amount of characters allowed.
     */
    @Input() maxCharacters: number;

    /**
     * Autofocus automatically sets focus to the input
     * when the input component is initialized
     */
    @Input() public autofocus = false;

    /**
     * Disable any input.
     */
    @Input() disabled: boolean;

    /**
     * Change event.
     */
    @Output() valueChange = new EventEmitter<string>();

    /**
     * Submit event.
     */
    @Output() submit = new EventEmitter<void>();

    /**
     * Blur event
     */
    @Output('blur') _blur = new EventEmitter<void>();
    /**
     * Focus event
     */
    @Output('focus') _focus = new EventEmitter<void>();

    @ViewChild('sizeHelper') sizeHelper: ElementRef;
    @ViewChild('valueContainer') valueContainer: ElementRef;
    private maxHeight: number;
    @HostListener('click') onHostClick(): void {
        this.focus();
    }

    constructor(
        private host: ElementRef,
        private renderer: Renderer2,
        private globalEvent: UIGlobalEvent
    ) {}

    ngAfterViewInit(): void {
        this.sizeHelper.nativeElement.style.fontFamily = getComputedStyle(
            this.valueContainer.nativeElement
        ).fontFamily;
        this.sizeHelper.nativeElement.style.lineHeight = getComputedStyle(
            this.valueContainer.nativeElement
        ).lineHeight;
        this.sizeHelper.nativeElement.style.letterSpacing = getComputedStyle(
            this.valueContainer.nativeElement
        ).letterSpacing;
        this.sizeHelper.nativeElement.style.wordSpacing = getComputedStyle(
            this.valueContainer.nativeElement
        ).wordSpacing;
        this.sizeHelper.nativeElement.style.fontWeight = getComputedStyle(
            this.valueContainer.nativeElement
        ).fontWeight;

        this.valueContainer.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
            const value = (event.target as HTMLTextAreaElement).value;
            const matchedRows = value.match(/[\r\n]+/g);
            const rows = (matchedRows ? matchedRows.length : 0) + 1;
            if (event.code === 'Enter' && this.maxRows > 0 && rows >= this.maxRows) {
                event.preventDefault();
                return;
            }
        });

        this.valueContainer.nativeElement.addEventListener('keyup', (event: KeyboardEvent) => {
            if (event.code === 'Enter') {
                if (this.blurOnSubmit) {
                    this.blur();
                }
                this.submit.emit();
            }
        });

        this.globalEvent.on('theme-change', this.syncSize);

        setTimeout(() => {
            const hostStyle = getComputedStyle(this.host.nativeElement);
            const maxHeight = hostStyle.maxHeight!;

            if (maxHeight !== 'none') {
                this.sizeHelper.nativeElement.style.maxHeight = maxHeight;
                this.valueContainer.nativeElement.style.maxHeight = maxHeight;
                this.maxHeight = parseInt(maxHeight, 10);
            }

            const minHeight = hostStyle.minHeight;

            if (minHeight !== 'none') {
                this.sizeHelper.nativeElement.style.minHeight = minHeight;
                this.valueContainer.nativeElement.style.minHeight = minHeight;
            }

            this.syncSize(this.value);

            if (this.autofocus) {
                this.focus();
            }
        });
    }

    public focus(): void {
        this.valueContainer.nativeElement.focus();
    }

    public blur(): void {
        this.valueContainer.nativeElement.blur();
    }

    onFocus(): void {
        const focusedClass = 'focused';

        this.renderer.addClass(this.host.nativeElement, focusedClass);
        this._focus.emit();
    }

    onBlur(): void {
        const focusedClass = 'focused';

        this.renderer.removeClass(this.host.nativeElement, focusedClass);
        this._blur.emit();
    }

    syncSize = (value: any) => {
        if (this.maxCharacters && value.length > this.maxCharacters) {
            this.valueContainer.nativeElement.value = this.value;
            return;
        }
        this.value = value;

        this.sizeHelper.nativeElement.innerHTML = this.value
            ? encodeXml(this.value)
                  .replace(/\n/g, '<br>&#8203;')
                  .replace(/\r\n/g, '<br>&#8203;')
                  .replace(/&#8203;(.+?)/g, '$1')
            : '&#8203;';
        const height = this.sizeHelper.nativeElement.getBoundingClientRect().height;
        if (height >= this.maxHeight) {
            this.valueContainer.nativeElement.style.overflowY = 'scroll';
        } else {
            this.valueContainer.nativeElement.style.overflowY = 'hidden';
        }
        this.valueContainer.nativeElement.style.height = `${height}px`;
        this.valueChange.emit(this.value);
    };

    ngOnDestroy(): void {
        this.globalEvent.off('theme-change', this.syncSize);
    }
}
