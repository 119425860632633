import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    // Demo Project components should not be forced to use ui- prefix
    // tslint:disable-next-line:component-selector
    selector: 'docs',
    templateUrl: 'docs.component.html',
    styleUrls: ['./docs.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DocsComponent {
    wait(): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(resolve, 2000);
        });
    }
}
