import { Routes } from '@angular/router';
import { componentRoutes } from './component-docs/component.routes.generated';
import { OverviewComponent } from './overview/overview.component';
import { ComponentDocsComponent } from './component-docs/component-docs.component';
import { VariablesComponent } from './variables/variables.component';
import { EggComponent } from './overview/egg/egg.component';
import { ThemeComponent } from './ui-theme/docs/theme.component';

export const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'overview' },
    { path: 'components', component: ComponentDocsComponent, children: componentRoutes },
    { path: 'overview', component: OverviewComponent },
    { path: 'variables', component: VariablesComponent },
    { path: 'theme', component: ThemeComponent },
    { path: 'egg', component: EggComponent }
];
