<div class="wrapper">
    <div
        class="switch"
        (click)="onClick()"
        [ngClass]="{ checked: selected && !interim, disabled: disabled, interim: interim }"></div>
    <span
        *ngIf="label"
        class="checkbox-label"
        >{{ label }}</span
    >
</div>
