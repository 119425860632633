import {
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    OnDestroy,
    OnInit,
    SecurityContext
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { UINotificationPlacement, UINotificationType } from '../../../types/notification';
import { Icon } from '../../icon/svg-icon/icons';

@Component({
    selector: 'ui-notification',
    templateUrl: 'notification.component.html',
    styleUrls: ['notification.component.scss'],
    host: {
        class: 'ui-notification'
    }
})
export class UINotificationComponent implements OnInit, OnDestroy {
    text = '';
    sanitizedText = '';
    icon: Icon | undefined = 'close';
    placement: UINotificationPlacement;
    type: UINotificationType;
    autoCloseDelay?: number;
    hideCloseIcon?: boolean = false;

    checkBoxText = '';
    showCheckBox = false;
    isChecked = false;

    onClose = new Subject<void>();
    onCloseCheckBox = new Subject<boolean>(); // true if checkbox is checked

    @HostBinding('style.width.rem')
    width: number;

    constructor(
        private host: ElementRef,
        private domSanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this.sanitizedText = this.domSanitizer.sanitize(SecurityContext.HTML, this.text) || '';
        const placement = this.placement.split(' ');
        this.host.nativeElement.classList.add(...[...placement, this.type]);
        if (this.placement === 'top') {
            this.host.nativeElement.style.animationName = 'notificationInTop';
        } else {
            this.host.nativeElement.style.animationName = 'notificationInBottom';
        }
    }

    @HostListener('click')
    onclick = (): void => {
        // error notifications have to be closed via the cross
        if (this.type !== 'error' && !this.showCheckBox) {
            this.close();
        }
    };

    toggleCheckbox(): void {
        this.isChecked = !this.isChecked;
    }

    close(): void {
        if (this.placement === 'top') {
            this.host.nativeElement.style.animationName = 'notificationOutTop ';
        } else {
            this.host.nativeElement.style.animationName = 'notificationOutBottom';
        }

        this.onCloseCheckBox.next(this.isChecked);

        setTimeout(() => this.onClose.next(), 250);
    }

    ngOnDestroy(): void {
        this.onCloseCheckBox.complete();
    }
}
