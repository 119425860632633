import { DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UIListCellType } from '../../../../../types/list-cell';
import { propertyByPath } from '../../../../../utils/property-by-path';
import { UIInputComponent } from '../../../../inputs/input/input.component';
import { IUIEditedCellData, IUIListDataNode } from '../../models';
import { UIListColumnDirective } from '../../templates/list-column.directive';

@Component({
    selector: 'ui-list-cell',
    templateUrl: 'list-cell.component.html',
    styleUrls: ['list-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DatePipe, PercentPipe, DecimalPipe],
    host: {
        '[class.cell]': `true`,
        '[class.multiline]': `column?.multiline`,
        '[class.align-right]': `column?.align === 'right'`,
        '[class.align-center]': `column?.align === 'center'`,
        '[class.hidden]': `column.hidden`,
        '[style.width]': `column.width`
    }
})
export class UIListCellComponent implements OnChanges, OnDestroy {
    @Input() data: any;
    @Input() column: UIListColumnDirective;
    @Input() editColumns: string[];
    @Input() edit: boolean;
    @Input() nodeChange$: Observable<IUIListDataNode>;
    @Output() editDone: EventEmitter<IUIEditedCellData> = new EventEmitter();

    @ViewChild('editInput') private editInput: UIInputComponent;

    value?: any;
    type?: UIListCellType;
    displayValue?: string;
    editable?: boolean;
    editing?: boolean;
    private unsubscribe$ = new Subject<void>();

    constructor(
        protected percentPipe: PercentPipe,
        protected decimalPipe: DecimalPipe,
        protected datePipe: DatePipe,
        protected changeDetectorRef: ChangeDetectorRef
    ) {
        if (!this.nodeChange$) {
            return;
        }

        this.nodeChange$.pipe(takeUntil(this.unsubscribe$)).subscribe((data: IUIListDataNode) => {
            if (data === this.data) {
                this.update();
                this.detectChanges();
            }
        });
    }

    ngOnChanges(): void {
        this.update();
        this.editable = this.column.editable;
        this.editingColumn();
        if (this.edit) {
            this.enableEdit();
        }
    }

    update(): void {
        this.value = this.getValue();
        this.type = this.getType();
        this.displayValue = this.getDisplayValue();
    }

    editingColumn(): void {
        if (this.editColumns && this.editColumns.includes(this.column.property)) {
            this.enableEdit();
        }
    }

    enableEdit(disabled?: boolean): void {
        if (disabled) {
            return;
        }
        this.editing = true;
        if (this.editInput) {
            setTimeout(() => {
                this.editInput.focus();
            });
        }
    }

    inputFocusOut(): void {
        if (this.editing) {
            this.editing = false;
            this.updateCellData();
        }
    }

    keyUpListener(event: KeyboardEvent): void {
        if (event.code === 'Enter') {
            if (this.editing) {
                this.editing = false;
                this.updateCellData();
            }
        }
        if (event.code === 'Escape') {
            this.editing = false;
            this.editInput.value = this.value;
        }
    }

    private updateCellData(): void {
        this.editDone.emit({ newValue: this.editInput.value, oldData: this.data });
    }

    protected getValue(): any {
        return propertyByPath(this.data, this.column.property, '');
    }

    protected getDisplayValue(): string | undefined {
        let value;

        switch (this.type) {
            case 'date': {
                // Make sure date is a real date and not "new Date(null)" or similar (will result in 0ms from 1970)
                if (this.value.getTime() > 0) {
                    value = this.datePipe.transform(this.value, this.column.format);
                }
                break;
            }
            case 'number': {
                value = this.decimalPipe.transform(this.value, this.column.format || '1.0-2');
                break;
            }
            case 'percent': {
                value = this.percentPipe.transform(this.value, this.column.format || '1.0-2');
                break;
            }
            default: {
                value = (this.value || '').toString();
                break;
            }
        }

        return value || this.column.defaultValue || undefined;
    }

    /**
     * Get which type this
     */
    protected getType(): UIListCellType {
        // Type is set
        if (this.column.type && this.column.type !== 'auto') {
            return this.column.type;
        }

        // Date
        else if (this.value instanceof Date) {
            return 'date';
        } else {
            switch (typeof this.value) {
                case typeof 'string': {
                    if (this.isImage(this.value)) {
                        return 'image';
                    }
                    return 'string';
                }
                case typeof 'number': {
                    return 'number';
                }
                case typeof 'boolean': {
                    return 'boolean';
                }
                default: {
                    return 'auto';
                }
            }
        }
    }

    protected isImage(url: string): boolean {
        const regex = /(https?:\/\/.*\.(?:png|jpg|gif|svg))/i;

        return regex.test(url);
    }

    private detectChanges(): void {
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }
}
