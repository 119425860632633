import { Component } from '@angular/core';

@Component({
    selector: 'ui-button-docs-preview',
    templateUrl: './button.docs.preview.html',
    styleUrls: ['./button.docs.preview.scss'],
})
export class UIButtonDocsPreview {
    wait(): Promise<void> {
        return new Promise<void>((resolve) => setTimeout(resolve, 2000));
    }
}
