<div
    *ngIf="!bordered"
    class="image"
    [ngStyle]="{ 'background-image': image ? 'url(' + image + ')' : 'none' }"></div>

<div
    *ngIf="bordered"
    class="bordered-circle">
    <div
        class="image"
        [ngStyle]="{ 'background-image': image ? 'url(' + image + ')' : 'none' }"></div>
</div>
