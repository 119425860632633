import { PercentPipe, DecimalPipe, DatePipe } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { UIListGridDirective } from '../../templates/list-grid.directive';
import { propertyByPath } from '../../../../../utils/property-by-path';
import { UIListCellType } from '../../../../../types/list-cell';

@Component({
    selector: 'ui-list-grid-cell',
    templateUrl: 'list-grid-cell.component.html',
    styleUrls: ['list-grid-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DatePipe, PercentPipe, DecimalPipe],
    host: {
        '[class.grid-cell]': `true`
    }
})
export class UIListGridCellComponent implements OnChanges {
    @Input() data: any;
    @Input() grid: UIListGridDirective;

    value?: any;
    type?: UIListCellType;
    displayValue?: string;

    constructor(
        private percentPipe: PercentPipe,
        private decimalPipe: DecimalPipe,
        private datePipe: DatePipe
    ) {}

    ngOnChanges(): void {
        this.value = this.getValue();
        this.type = this.getType();
        this.displayValue = this.getDisplayValue();
    }

    private getValue(): any {
        return propertyByPath(this.data, this.grid.property, '');
    }

    private getDisplayValue(): string | undefined {
        let value;

        switch (this.type) {
            case 'date': {
                value = this.datePipe.transform(this.value, this.grid.format);
                break;
            }
            case 'number': {
                value = this.decimalPipe.transform(this.value, this.grid.format || '1.0-2');
                break;
            }
            case 'percent': {
                value = this.percentPipe.transform(this.value, this.grid.format || '1.0-2');
                break;
            }
            default: {
                value = (this.value || '').toString();
                break;
            }
        }

        return value || this.grid.defaultValue || undefined;
    }

    /**
     * Get which type this
     */
    private getType(): UIListCellType {
        // Type is set
        if (this.grid.type && this.grid.type !== 'auto') {
            return this.grid.type;
        }

        // Date
        else if (this.value instanceof Date) {
            return 'date';
        } else {
            switch (typeof this.value) {
                case typeof 'string': {
                    if (this.isImage(this.value)) {
                        return 'image';
                    }
                    return 'string';
                }
                case typeof 'number': {
                    return 'number';
                }
                case typeof 'boolean': {
                    return 'boolean';
                }
                default: {
                    return 'auto';
                }
            }
        }
    }

    private isImage(url: string): boolean {
        const regex = /(https?:\/\/.*\.(?:png|jpg|gif|svg))/i;

        return regex.test(url);
    }
}
