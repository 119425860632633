import { Component, HostListener, ElementRef, ChangeDetectorRef, Input } from '@angular/core';
import { UISelectableBaseDirective } from '../selectable-list/selectable.component';

@Component({
    selector: 'ui-checkbox',
    templateUrl: 'checkbox.component.html',
    styleUrls: ['checkbox.component.scss'],
    providers: [
        {
            provide: UISelectableBaseDirective,
            useExisting: UICheckboxComponent,
            multi: true
        }
    ],
    host: {
        class: 'checkbox',
        'class.disabled': 'disabled'
    }
})
export class UICheckboxComponent extends UISelectableBaseDirective {
    /**
     * Label of the checkbox
     */
    @Input() label: string;
    @Input() indeterminate = false;
    @HostListener('click') onClick(): void {
        if (this.disabled) {
            return;
        }
        if (this.indeterminate) {
            this.indeterminate = false;
            this.select();
            return;
        }
        this.toggleSelect();
    }

    constructor(
        public override host: ElementRef,
        public override changeDetectorRef: ChangeDetectorRef
    ) {
        super(host, changeDetectorRef);
    }
}
