import { Directive } from '@angular/core';

@Directive({
    selector: '[ui-prevent-click]',
    host: {
        '(click)': 'preventClick($event)'
    }
})
export class UIPreventClickDirective {
    public preventClick(event: MouseEvent): void {
        event.stopPropagation();
    }
}
