import { UISubmitResponse } from '../../../types/button';
import { UIDiscardCloseDialogCallback, UIConfirmCloseDialogCallback } from '../../../types/dialog';
import { IUIDialogConfig, UIDIALOG_DEFAULT_CONFIG } from '../dialog/dialog-config.interface';

export interface UIConfirmDialogConfig extends IUIDialogConfig {
    text?: string;
    cancelText?: string;
    discardText?: string;
    onDiscard?: () => Promise<UISubmitResponse<any>>;
    onDiscarded?: (closeDialog: UIDiscardCloseDialogCallback, error?: any) => void;
    confirmText?: string;
    onConfirm?: () => Promise<UISubmitResponse<any>>;
    onConfirmed?: (closeDialog: UIConfirmCloseDialogCallback, error?: any) => void;
    showCancelButton?: boolean;
    __discardable?: boolean;
}

export const UICONFIRMDIALOG_DEFAULT_CONFIG: Partial<UIConfirmDialogConfig> = {
    ...UIDIALOG_DEFAULT_CONFIG,
    maxWidth: 520,
    text: 'Put your confirm description here.',
    headerText: 'Confirm Dialog',
    discardText: 'Discard',
    cancelText: 'Cancel',
    confirmText: 'Confirm'
};
