import { Component } from '@angular/core';
import { UIButtonGroupOption } from '../button-group.component';

@Component({
    selector: 'ui-button-group-docs-preview',
    templateUrl: './button-group.docs.preview.html'
})
export class UIButtonGroupDocsPreview {

    selectedButton: any;
    selectedButton2: any = 'With text';
    selectedButton3: any = 'analytics-selected';
    selectedButton4: any = 'icon-with-text';
    selectedButton5: any = 'primary-selection';

    buttonOptions: UIButtonGroupOption[] = [
        { id: 'left-button', svgIcon: 'direction-left', text: 'With text', value: 'icon-with-text' },
        { id: 'center-button', icon: 'analytics',text: 'With text', value: 'analytics-icon' }
    ];

    buttonOptionsDisable: UIButtonGroupOption[] = [
        { id: 'center-button', icon: 'analytics',text: 'With text', value: 'analytics-selected' },
        { id: 'left-button', svgIcon: 'direction-left', text: 'With text', value: 'icon-with-text' },
        { id: 'center-button', icon: 'analytics',text: 'With text-disabled', value: 'analytics-icon', disabled: true }
    ];

    buttonOptionsDisableBoth: UIButtonGroupOption[] = [
        { id: 'left-button', svgIcon: 'direction-left', text: 'With text-disabled', value: 'icon-with-text', disabled: true },
        { id: 'center-button', icon: 'analytics',text: 'With text-disabled', value: 'analytics-icon', disabled: true }
    ];

    updateButtonToggle(event: any): void {
        console.log('Selected', event);
        this.selectedButton = event;
    }

    updateButtonToggle2(event: any): void {
        console.log('Selected', event);
        this.selectedButton2 = event;
    }

    updateButtonToggle3(event: any): void {
        console.log('Selected', event);
        this.selectedButton3 = event;
    }

    updateButtonToggle4(event: any): void {
        console.log('Selected', event);
        this.selectedButton4 = event;
    }

    updateButtonToggle5(event: any): void {
        console.log('Selected', event);
        this.selectedButton5 = event;
    }
}
