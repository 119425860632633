export interface IUIListDataNode extends Object {
    /**
     * Allow ANY data
     */
    [propName: string]: any;

    /**
     *  Passing children will make this node a "folder"
     */
    children?: IUIListDataNode[];

    /**
     * The number of children this folder has.
     * Used to trigger events for lazy loading.
     * "undefined" disables lazy loading.
     * Set to -1 to enable lazy loading without knowing the children count
     */
    totalCount?: number;

    /**
     * To not alter objects we use this to remove node from view
     */
    removedByFilter?: boolean;

    /**
     * To control if the checkbox or radio button is disabled or not.
     */
    disabled?: boolean;

    /**
     * Whether this node is loading more data or not.
     */
    isLoading?: boolean;

    /**
     * Whether this node is currently being edited or not.
     */
    editing?: boolean;
}
