import { Component } from '@angular/core';

@Component({
    selector: 'ui-help-menu-docs-preview',
    templateUrl: './help-menu.docs.preview.html',
    styleUrls: ['./help-menu.docs.preview.scss'],
})
export class UIHelpMenuDocsPreviewComponent {
    public onIntercom(): void {
        alert(
            'If Intercom widget is available in your app, you should initialize a new message by Intercom("showNewMessage")'
        );
    }

    public onIntercomNews(): void {
        alert(
            'If Intercom widget is available in your app, you should initialize it with news space by Intercom("showSpace", "news");'
        );
    }

    public onKeyboardShortcuts(): void {
        alert('To display the keyboard shortcuts option, ensure that the `showKeyboardShortcuts` output is implemented in your component.');
    }
}
