import {
    Component,
    ViewChild,
    ElementRef,
    Input,
    OnDestroy,
    ContentChildren,
    QueryList,
    EventEmitter,
    Output
} from '@angular/core';
import { UIDropdownItemComponent } from './dropdown-item.component';
import { startWith, switchMap } from 'rxjs/operators';
import { Observable, merge, Subscription } from 'rxjs';
import { UIDropdownTargetDirective } from './dropdown.directive';
import { UIPopoverDirective } from '../popover/popover.directive';
import { UITheme } from '../../../types/theme';
import { UIPopoverTargetDirective } from '../popover/popover-target.directive';
import { IPositions, Offset } from '../../../types/popover';

@Component({
    selector: 'ui-dropdown',
    templateUrl: 'dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    host: {
        class: 'ui-dropdown',
        '[class.has-header]': 'header'
    }
})
export class UIDropdownComponent implements OnDestroy {
    @ViewChild('popover') popover: UIPopoverDirective;
    @Output('open') openEmitter = new EventEmitter<void>();
    @Output() close = new EventEmitter();
    @Output() isHovered = new EventEmitter<boolean>();
    @ContentChildren(UIDropdownItemComponent) items: QueryList<UIDropdownItemComponent>;

    /**
     * @summary:
     * Optional header for the dropdown list
     */
    @Input() header?: string;

    /**
     * @summary:
     * Width of the dropdown
     */
    @Input() width = 'auto';

    /**
     * @summary:
     * MaxHeight of the dropdown
     */
    @Input() maxHeight: number;

    /**
     * @summary:
     * Use the target's width
     */
    @Input() useTargetWidth: boolean;

    /**
     * @summary:
     * The type of dropdown
     */
    @Input() type?: string;

    /**
     * @summary:
     * Position offset of the dropdown
     */
    @Input() offset = { x: 0, y: 0 };
    /**
     * @summary:
     * Positions overrides on the popover used by the dropdown
     */
    @Input() positions: IPositions[];

    /**
     * @summary:
     * Overriding theme
     */
    @Input() theme: UITheme;

    /**
     * @summary
     * The dropdowns minimum width
     */
    @Input() minWidth: number;

    /**
     * @summary
     * If the dropdown should have a backdrop. Disabling this requires you to close the dropdown manually
     */
    @Input() hasBackdrop?: boolean;

    /**
     * @summary
     * Disables the possiblity to open the dropdown
     */
    @Input() disabled?: boolean;

    parentDropdown: UIDropdownComponent | undefined;
    target?: UIDropdownTargetDirective;
    closed = false;
    private subscriptions: Subscription[] = [];
    private viewChildrenSubscription?: Subscription;

    open(target: ElementRef, targetDirective?: UIDropdownTargetDirective): void {
        if (this.disabled) {
            return;
        }
        this.closed = false;
        this.configurePopover();
        this.target = targetDirective;
        this.popover.open(new UIPopoverTargetDirective(target));
        this.subscribeViewChildren();
        this.subscribeItems();
        this.openEmitter.emit();
    }

    setOffset(offset: Offset): void {
        this.popover.config.offset = offset;
    }

    closePopover(): void {
        this.popover.close && this.popover.close();
        this.unsubscribeViewChildren();
        this.unsubscribeItems();
    }

    ngOnDestroy(): void {
        this.close.complete();
        this.unsubscribeViewChildren();
        this.unsubscribeItems();
    }

    dropdownHovered(event: MouseEvent): void {
        if (event.type === 'mouseleave') {
            this.isHovered.emit(false);
        } else {
            this.isHovered.emit(true);
        }
    }

    hovered(): Observable<UIDropdownItemComponent> {
        return this.items.changes.pipe(
            startWith(this.items),
            switchMap((items: QueryList<UIDropdownItemComponent>) =>
                merge(...items.map(item => item.hovered).filter(item => !item.closed))
            )
        );
    }

    private subscribeItems(): void {
        this.items.changes
            .pipe(startWith(this.items))
            .subscribe((items: QueryList<UIDropdownItemComponent>) => {
                this.unsubscribeItems();
                items.forEach(item => {
                    const subscription = item.itemClicked.subscribe(
                        (dropdownItem: UIDropdownItemComponent) => {
                            if (!dropdownItem.triggersSubdropdown && !dropdownItem.isToggable) {
                                this.closed = true;
                                this.close.emit('click');
                            }
                            if (!item.preventCloseOnClick && !item.uiDropdownTarget) {
                                this.closePopover();
                            }
                        }
                    );
                    this.subscriptions.push(subscription);
                });
            });
    }

    private unsubscribeItems(): void {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop()!.unsubscribe();
        }
    }

    /**
     * Subsribe to changes on the list of view children.
     * The changes are triggered when a new child is added or removed
     */
    private subscribeViewChildren(): void {
        this.unsubscribeViewChildren();
        this.viewChildrenSubscription = this.items.changes.subscribe(_change => this.subscribeItems);
    }

    private unsubscribeViewChildren(): void {
        if (this.viewChildrenSubscription) {
            this.viewChildrenSubscription.unsubscribe();
            this.viewChildrenSubscription = undefined;
        }
    }

    private configurePopover(): void {
        this.popover.config.width = this.width === 'auto' ? 'auto' : `${this.width}px`;
        this.popover.config.maxHeight = this.maxHeight ? `${this.maxHeight}px` : undefined;
        this.popover.config.theme = this.theme;
        this.popover.config.popoverType = this.type;
        this.popover.config.hasBackdrop =
            typeof this.hasBackdrop !== 'undefined'
                ? this.hasBackdrop
                : this.popover.config.hasBackdrop;
        if (this.minWidth) {
            this.popover.config.minWidth = `${this.minWidth}px`;
        }
        if (this.positions) {
            this.popover.config.positions = this.positions;
        }
    }
}
