import { Component, input, output, signal } from '@angular/core';

@Component({
    selector: 'ui-file-dropzone',
    templateUrl: './file-dropzone.component.html',
    styleUrls: ['./file-dropzone.component.scss']
})
export class UIFileDropzoneComponent {
    accept = input('*');
    hintText = input('Drag and drop a file to upload it or select it from your computer');
    buttonText = input('Select File');
    maxFiles = input<number>(1);
    filesUploaded = output<File[]>();

    isDragOver = signal(false);

    onDragOver(event: DragEvent): void {
        this.stopDefault(event);
        this.isDragOver.set(true);
    }

    onDragLeave(event: DragEvent): void {
        this.stopDefault(event);
        this.isDragOver.set(false);
    }

    onDrop(event: DragEvent): void {
        this.stopDefault(event);
        this.isDragOver.set(false);

        if (event.dataTransfer) {
            const files = Array.from(event.dataTransfer.files).slice(0, this.maxFiles());

            this.filesUploaded.emit(files);
        }
    }

    onFileChange(event: Event): void {
        const inputElement = event.target;

        if (inputElement instanceof HTMLInputElement && inputElement.files?.length) {
            const files = Array.from(inputElement.files).slice(0, this.maxFiles());

            this.filesUploaded.emit(files);
        }
    }

    private stopDefault(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }
}
