@if (popoverRef.templateRef || popoverRef.componentClass) {
    <div
        #contentRef
        class="content"
        data-testid="popover-master"
        [ngClass]="[config.panelClass]"
        cdk-scrollable>
        <ng-template #componentPlaceholder></ng-template>
        @if (popoverRef.templateRef) {
            <ng-container
                *ngTemplateOutlet="popoverRef.templateRef; context: { $implicit: this }"></ng-container>
        }
    </div>
}
