import { Component, QueryList, Input, EventEmitter, Output, HostBinding } from '@angular/core';
import { UIListColumnDirective } from '../../templates/list-column.directive';
import { Observable } from 'rxjs';
import { IUIListDataNode } from '../../models/list-data-node';
import { SelectionType } from '../../../../../types/list';

@Component({
    selector: 'ui-list-header',
    templateUrl: 'list-header.component.html',
    styleUrls: ['list-header.component.scss'],
    host: {
        '[class.row]': 'true'
    }
})
export class UIListHeaderComponent {
    /**
     * List of columns to show
     */
    @Input() columns: QueryList<UIListColumnDirective>;

    @Input()
    @HostBinding('class.initialized')
    initialized = false;

    /**
     * An observable for selectionChange. Should be passed from parent list.component
     */
    @Input() selectionChange?: Observable<IUIListDataNode[]>;

    @Input() allSelected = false;

    @Input() selectionType: SelectionType = 'none';

    @Input() indeterminate = false;
    /**
     * Emitter for sort clicks
     */
    @Output() sortClick: EventEmitter<UIListColumnDirective> = new EventEmitter();

    @Output() selectAll: EventEmitter<void> = new EventEmitter();

    @Output() deselectAll: EventEmitter<void> = new EventEmitter();

    /**
     * Select or deselect all depending on state of selection
     */
    toggleAll(): void {
        if (this.allSelected) {
            this.deselectAll.next();
        } else {
            this.selectAll.next();
        }
    }

    /**
     * Sort is clicked. Emit event to be able to sort accordingly
     * @param column
     */
    onSortClick(column: UIListColumnDirective): void {
        if (column.sortable) {
            this.sortClick.emit(column);
        }
    }
}
