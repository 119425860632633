<form [formGroup]="testForm"
      class="thisForm">
    <div class="column">
        <div class="column column--half">
            <ui-input id="inputOne"
                      value="inputOne"
                      iconPosition="left"
                      icon="banner-set"
                      label="inputOne with label"
                      (submit)="onSubmit($event)"></ui-input>
        </div>

        <div class="column column--half">
            <ui-input id="inputTwo"
                      formControlName="testInput2"
                      label="inputTwo with validation"
                      [validation]="mama"
                      (submit)="onSubmit($event)"></ui-input>
            <div><strong>Dirty: </strong>{{ testForm.controls['testInput2'].dirty }}<span style="float:right"><strong>Valid:
                    </strong>{{ testForm.controls['testInput2'].valid }}</span></div>

        </div>
    </div>

    <div class="column column--half">
        <p>ngModel with no label</p>
        <ui-input id="inputThree"
                  [(ngModel)]="inputThree"
                  [ngModelOptions]="{standalone: true}"
                  placeholder="Write something"></ui-input>
        <span>{{ inputThree }}</span>
    </div>

    <div class="column column--half">
        <ui-input id="inputFour"
                  label="Disabled input"
                  value="myemail&#64;bannerflow.com"
                  [disabled]="true"
                  placeholder="You cant write"></ui-input>
    </div>

    <div class="column column--third">
        <ui-input id="inputFour"
                  type="number"
                  placeholder="You cant write only numbers"></ui-input>
    </div>
</form>

<div class="column column--half">
    <ui-input id="inputFive"
              label="Simple formControl"
              [formControl]="mama"
              [validation]="mama"
              [disableUndo]="true"
              (undo)="handleUndo()"
              placeholder="You cant write"></ui-input>
</div>

<div class="column column--half">
    <ui-input id="inputFive"
              label="Discrete mode"
              placeholder="Descrete input"
              [discrete]="true"></ui-input>
</div>

<div class="column column--half">
    <ui-input id="inputFive"
              label="Super discrete mode"
              placeholder="Super descrete input - No borders at all"
              [superDiscrete]="true"></ui-input>
</div>

<div class="column column--half">
    <label class="input-label">Expanded input</label>
    <ui-input
        id="inputSix"
        iconPosition="left"
        icon="search"
        [expand]="true"
        (submit)="onSubmit($event)">
    </ui-input>
</div>


<label class="input-label">Expanded input rtl</label>
<div class="column column--half column--rtl">
    <ui-input
        id="inputSix"
        iconPosition="right"
        icon="search"
        [expand]="true"
        (submit)="onSubmit($event)">
    </ui-input>
</div>

<label class="input-label">Dynamic width</label>
<div class="column column--half">
    <ui-input
        id="inputSeven"
        [dynamicWidth]="true"
        (submit)="onSubmit($event)">
    </ui-input>
</div>

<label class="input-label">Dynamic width with placeholder</label>
<div class="column column--half">
    <ui-input
        id="inputSeven"
        [dynamicWidth]="true"
        placeholder="Dynamic width with placeholder"
        (submit)="onSubmit($event)">
    </ui-input>
</div>
