<mat-form-field
    appearance="outline"
    color="primary">
    <mat-label *ngIf="label">
        {{ label }}
    </mat-label>

    <div
        matPrefix
        class="prefix"
        *ngIf="leadingIcon">
        <ng-container [ngTemplateOutlet]="leadingIcon"></ng-container>
    </div>

    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'textarea'">
            <textarea
                #input
                matInput
                [attr.maxLength]="maxLength ?? null"
                [type]="type"
                [placeholder]="placeholder ?? ''"
                [formControl]="uiFormControl.control"
                (change)="onInputChange($event)"
                (keyup)="onInputKeyUp($event)"
                (keydown)="onInputKeyDown($event)"
                (focus)="onInputFocus()"
                (blur)="onInputBlur()"
                (click)="onInputClick($event)"></textarea>
        </ng-container>
        <input
            *ngSwitchDefault
            matInput
            [attr.maxLength]="maxLength ?? null"
            [type]="type"
            [placeholder]="placeholder ?? ''"
            [formControl]="uiFormControl.control"
            (change)="onInputChange($event)"
            (keyup)="onInputKeyUp($event)"
            (keydown)="onInputKeyDown($event)"
            (focus)="onInputFocus()"
            (blur)="onInputBlur()"
            (click)="onInputClick($event)"
            #input />
    </ng-container>

    <div
        matSuffix
        *ngIf="trailingIcon"
        class="suffix">
        <ng-container [ngTemplateOutlet]="trailingIcon"></ng-container>
    </div>

    <mat-hint
        *ngIf="hint"
        align="start"
        >{{ hint }}</mat-hint
    >

    <mat-hint
        *ngIf="charCount"
        align="end">
        {{ uiFormControl.control.value?.length ?? '' }}
        <ng-container *ngIf="maxLength"> / {{ maxLength }} </ng-container>
    </mat-hint>

    <mat-error>
        <ng-container *ngFor="let condition of uiFormControl.conditions">
            <ng-container *ngIf="condition.evalFunc(uiFormControl.control)">{{
                condition.hintText
            }}</ng-container>
        </ng-container>
    </mat-error>
</mat-form-field>
