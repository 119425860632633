import { IUIDialogConfig, UIDIALOG_DEFAULT_CONFIG } from '../dialog/dialog-config.interface';

export interface IUIErrorDialogConfig extends IUIDialogConfig {
    text?: string;
    confirmText?: string;
}

export const UIERRORDIALOG_DEFAULT_CONFIG: Partial<IUIErrorDialogConfig> = {
    ...UIDIALOG_DEFAULT_CONFIG,
    headerText: 'Something went wrong',
    width: 590,
    closeButton: true,
    backdropClickClose: true,
    escKeyClose: true
};
