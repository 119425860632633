import { SelectionModel } from '@angular/cdk/collections';
import { CdkColumnDef, CdkTable } from '@angular/cdk/table';
import {
    AfterContentInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UICustomComponent, UIDropEvent } from '../../../directives';
import { UITableDataSource } from './table.datasource';
import { Icon } from '../../icon/svg-icon/icons';

export interface UIColumnDef {
    name: string;
    columnDef: string; // needs to match propertyname of data
    width?: string; // leave empty for auto allocation
    isCustom?: boolean;
    sortable?: boolean;
}

export interface UITableMenuOption<T> {
    displayText: string;
    svgIcon?: Icon;
    disabled?: boolean;
    menuActions: MenuAction | string;
    data?: T;
}

export interface UITableMenuConfig<T> {
    headerTextProp: string; // property that you want to show as header
    options: UITableMenuOption<T>[];
}

export interface RowEvent<T> {
    row: T;
    mouseEvent: MouseEvent;
}

export enum MenuAction {
    DELETE,
    DUPLICATE,
    MOVE,
    RENAME
}

export interface UITableDragConfig<T> {
    customComponent?: UICustomComponent;
    isAllowedToDrop: (dropTarget: string | T, draggedItems: T[]) => boolean;
}
export interface UITableConfig<T> {
    tableHeaderSticky?: boolean;
    dragConfig?: UITableDragConfig<T>;
}
@Component({
    selector: 'ui-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class UITableComponent<T> implements OnDestroy, AfterContentInit, OnChanges {
    // https://stackoverflow.com/questions/46893991/declare-a-component-with-generic-type

    @ViewChild(CdkTable, { static: true }) table: CdkTable<T>;
    @ContentChildren(CdkColumnDef) cdkColumnDef: QueryList<CdkColumnDef>;

    @Input() dataSource: UITableDataSource<T>;
    @Input() selection: SelectionModel<T>;
    @Input() columnNames: UIColumnDef[];
    @Input() menuConfig: UITableMenuConfig<T>;
    @Input() loading = false;
    @Input() noDataText =
        'No results found <br> Try adjusting your search or filter to find what you’re looking for';
    @Input() config: UITableConfig<T> = { tableHeaderSticky: false };
    @Output() menuOptionClicked = new EventEmitter<UITableMenuOption<T>>();
    @Output() rowClicked = new EventEmitter<RowEvent<T>>();
    @Output() itemsDropped = new EventEmitter<UIDropEvent<T>>();
    columnDefs: string[];
    indeterminate: boolean;
    masterSelected: boolean;

    private destroy$ = new Subject<void>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['columnNames'] && !changes['columnNames'].firstChange) {
            this.setupColumns();
        }
    }

    ngAfterContentInit(): void {
        if (this.selection) {
            combineLatest([this.selection.changed, this.dataSource.data$])
                .pipe(takeUntil(this.destroy$))
                .subscribe(([_, data]) => {
                    const everySelected =
                        data.length !== 0 && data.every(row => this.selection.isSelected(row));
                    const someSelected = data.some(row => this.selection.isSelected(row));
                    this.masterSelected = someSelected || everySelected;
                    this.indeterminate = someSelected && !everySelected;
                });
        }

        this.setupColumns();

        if (this.cdkColumnDef.length) {
            this.cdkColumnDef.forEach((columnDef: CdkColumnDef) => {
                this.table.addColumnDef(columnDef);
            });
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /** setup correct columndefs and columnNames depending on if a column is custom or not */
    setupColumns(): void {
        this.columnDefs = this.columnNames.map((column: UIColumnDef) => column.columnDef);
        this.columnNames = this.columnNames.filter((column: UIColumnDef) => !column.isCustom);

        if (this.selection) {
            this.columnDefs.unshift('select');
        }

        if (this.menuConfig) {
            this.columnDefs.push('kebabmenu');
        }
    }

    /** Toggles individual rows and updates master checkbox with its correct state, selected or indeterminate state */
    async toggleSelection(row: T): Promise<void> {
        this.selection.toggle(row);
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    async masterToggle(): Promise<void> {
        this.dataSource.data$.pipe(take(1)).subscribe(rows => {
            if (this.indeterminate || this.masterSelected) {
                this.selection.deselect(...rows);
            } else {
                this.selection.select(...rows);
            }
        });
    }

    emitMenuOption(menuOption: UITableMenuOption<T>, data: T): void {
        menuOption.data = data;
        this.menuOptionClicked.emit(menuOption);
    }

    async emitRow(mouseEvent: MouseEvent, row: T): Promise<void> {
        if (this.selection) {
            await this.toggleSelection(row);
        }
        this.rowClicked.emit({ mouseEvent: mouseEvent, row });
    }
}
