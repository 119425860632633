import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';

export interface IUIMatInputFormControl {
    control: FormControl;
    conditions: Array<{
        evalFunc: (control: FormControl) => boolean;
        hintText: string;
    }>;
}

export interface IUIMatInputTextSelection {
    start: number;
    end: number;
    length: number;
    text: string;
}

@Component({
    selector: 'ui-mat-input',
    templateUrl: './mat-input.component.html',
    styleUrls: ['./mat-input.component.scss']
})
export class UIMatInputComponent implements OnInit, OnChanges {
    @Input() value = '';

    @Input() disabled = false;

    @Input() label: string | undefined;

    /**
     * Define the input type.
     * * 'text' uses a normal text input
     * * 'textarea' uses a textare (having multiline support)
     * */
    @Input() type: 'text' | 'textarea' | 'password' = 'text';

    @Input() placeholder: string | undefined;

    @Input() hint: string | undefined;

    @Input() charCount: boolean | undefined;

    @Input() maxLength: number | undefined;

    @Input() uiFormControl: IUIMatInputFormControl;

    @Input() leadingIcon: TemplateRef<any> | undefined;

    @Input() trailingIcon: TemplateRef<any> | undefined;

    @Input() showStyledText = false;

    @Output() change = new EventEmitter<Event>();

    @Output() keydown = new EventEmitter<KeyboardEvent>();

    @Output() keyup = new EventEmitter<KeyboardEvent>();

    @Output() focus = new EventEmitter<void>();

    @Output() blur = new EventEmitter<void>();

    @Output() click = new EventEmitter<MouseEvent>();

    @Output() selectedText = new EventEmitter<IUIMatInputTextSelection | undefined>();

    @ViewChild('input') private input: ElementRef<HTMLTextAreaElement | HTMLInputElement>;

    // If true, FormControl is controlled by component. If false, it's controlled by parent
    private internalFormControl: boolean;

    ngOnInit(): void {
        // Default FormControl
        this.internalFormControl = !this.uiFormControl;
        this.uiFormControl ??= {
            control: new FormControl({ value: this.value, disabled: this.disabled }),
            conditions: []
        };
        if (this.showStyledText) {
            this.type = 'textarea';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.checkDisabledFlagChange(changes);
        this.checkValueChange(changes);
        this.checkTypeChange(changes);
    }

    onInputChange(event: Event): void {
        this.change.emit(event);
    }

    onInputKeyUp(event: KeyboardEvent): void {
        this.keyup.emit(event);

        if (this.input.nativeElement.clientHeight <= this.input.nativeElement.scrollHeight) {
            this.fixHeight();
        }
    }

    onInputKeyDown(event: KeyboardEvent): void {
        this.keydown.emit(event);
    }

    onInputFocus(): void {
        this.focus.emit();
    }

    onInputBlur(): void {
        this.blur.emit();
    }

    onInputClick(event: MouseEvent): void {
        this.click.emit(event);
    }
    private checkValueChange(changes: SimpleChanges): void {
        if (!this.internalFormControl || !('value' in changes)) {
            return;
        }

        const { currentValue, previousValue } = changes['value'];
        if (currentValue === previousValue) {
            return;
        }

        this.uiFormControl.control.setValue(currentValue);
        this.input.nativeElement.style.height = `fit-content`;
    }

    // Disabling input via attribute doesn't work when a FormControl is attachaed
    private checkDisabledFlagChange(changes: SimpleChanges): void {
        if (!this.internalFormControl || !('disabled' in changes)) {
            return;
        }
        const { currentValue, previousValue } = changes['disabled'];
        if (currentValue === previousValue) {
            return;
        }
        if (currentValue) {
            this.uiFormControl.control.disable();
            return;
        }
        this.uiFormControl.control.enable();
    }

    private checkTypeChange(changes: SimpleChanges): void {
        if (!('type' in changes)) {
            return;
        }
        const { currentValue, previousValue } = changes['type'];
        if (currentValue === previousValue) {
            return;
        }

        // Wait for re-render to occur before attaching listener
        requestAnimationFrame(() => this.attachSelectionListener());
    }

    private attachSelectionListener(): void {
        this.input?.nativeElement?.addEventListener('select', event => {
            if (!event.target) {
                this.selectedText.emit();
                return;
            }
            const eventTarget = event.target as HTMLTextAreaElement;
            const selectedText = eventTarget.value.substring(
                eventTarget.selectionStart,
                eventTarget.selectionEnd
            );
            const textSelection: IUIMatInputTextSelection = {
                start: eventTarget.selectionStart,
                end: eventTarget.selectionEnd,
                length: eventTarget.selectionEnd - eventTarget.selectionStart,
                text: selectedText
            };

            this.selectedText.emit(textSelection);
        });
    }

    private fixHeight(): void {
        if (this.type !== 'textarea') {
            return;
        }
        this.input.nativeElement.style.height = `${this.input.nativeElement.scrollHeight}px`;
    }
}
