/**
 * Check if an element is inside or is the container itself
 * @param element
 * @param container
 */
export function inElement(element: any, container: any): boolean {
    for (element; element; element = element.parentNode) {
        if (element === container) {
            return true;
        }
    }
    return false;
}
