import { OverlayRef } from '@angular/cdk/overlay';
import { Subject, Observable } from 'rxjs';

import { filter, take } from 'rxjs/operators';

import { UIDialogComponent } from './dialog.component';
import { TemplateRef, Type, ComponentRef } from '@angular/core';
import { IUIDialogConfig, UIDIALOG_DEFAULT_CONFIG } from './dialog-config.interface';

export class UIDialogRef {
    componentInstance: UIDialogComponent;
    subComponentRef: ComponentRef<any>;
    subComponentInstancePromise: Promise<ComponentRef<any>>;
    config: IUIDialogConfig;
    afterViewInit: Promise<void>;

    private _beforeClose: Subject<void> = new Subject<void>();
    private _afterClosed: Subject<void> = new Subject<void>();
    private _afterViewInitPromiseResolve: (value?: void | PromiseLike<void>) => void;

    static __openDialogs: UIDialogRef[] = [];

    constructor(
        private overlayRef: OverlayRef,
        config: IUIDialogConfig,
        public templateRef?: TemplateRef<any>,
        public componentClass?: Type<any>
    ) {
        this.config = { ...UIDIALOG_DEFAULT_CONFIG, ...config };
        this.afterViewInit = new Promise<void>(resolve => {
            this._afterViewInitPromiseResolve = resolve;
        });

        UIDialogRef.__openDialogs.unshift(this);
    }

    updateConfig(config: Partial<IUIDialogConfig>): void {
        if (this.componentInstance) {
            if (config.panelClass) {
                // Convert to string of multiple classes that HTMLElement.classList.add supports.
                const panelClass = Array.isArray(config.panelClass)
                    ? config.panelClass
                    : config.panelClass && config.panelClass.split(' ');
                config.panelClass = panelClass;
            }
            if (config.width || config.height || config.maxWidth || config.maxHeight) {
                this.overlayRef.updateSize(config);
            }
            this.config = { ...this.config, ...config };
            this.componentInstance.config = this.config;
        }
    }

    /**
     * Fade out and remove dialog
     */
    close(): void {
        // If dialog exists, fade it out
        if (this.componentInstance) {
            UIDialogRef.__openDialogs.splice(UIDialogRef.__openDialogs.indexOf(this), 1);

            this.componentInstance.animationStateChanged
                .pipe(
                    filter(event => event.phaseName === 'start'),
                    take(1)
                )
                .subscribe(() => {
                    this._beforeClose.next();
                    this._beforeClose.complete();
                });

            this.componentInstance.animationStateChanged
                .pipe(
                    filter(event => event.phaseName === 'done' && event.toState === 'leave'),
                    take(1)
                )
                .subscribe(() => {
                    this.overlayRef.dispose();
                    this._afterClosed.next();
                    this._afterClosed.complete();

                    this.componentInstance = null!;
                });

            this.componentInstance.startExitAnimation();
        }
        // If this is already removed -> clean up
        else {
            this.destroy();
        }
    }

    afterClose(): Observable<void> {
        return this._afterClosed.asObservable();
    }

    beforeClose(): Observable<void> {
        return this._beforeClose.asObservable();
    }

    resolveAfterViewInitPromise(): void {
        this._afterViewInitPromiseResolve();
    }

    /**
     * Destroy and cleanup
     */
    destroy(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
        this._beforeClose.complete();
        this._afterClosed.complete();
        this.componentInstance = null!;
    }
}
