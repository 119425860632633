import { Component } from '@angular/core';

@Component({
    selector: 'ui-icon-docs-preview',
    templateUrl: './icon.docs.preview.html',
    styleUrls: ['./icon.docs.preview.scss'],
})
export class UIIconDocsPreview {

}
