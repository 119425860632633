<h2>Normal Image</h2>

<ui-image [image]="imageUrl"></ui-image>
<ui-image [image]="imageUrl2"></ui-image>
<ui-image [image]="imageUrl3"></ui-image>
<ui-image [image]="imageUrl2"></ui-image>
<ui-image [image]="imageUrl"></ui-image>
<ui-image [image]="imageUrl2"></ui-image>
<ui-image [image]="imageUrl3"></ui-image>
<ui-image [image]="imageUrl3"></ui-image>
<ui-image></ui-image>

<h2>Bordered Image</h2>

<ui-image [image]="imageUrl" [bordered]="true"></ui-image>
<ui-image [image]="imageUrl2" [bordered]="true"></ui-image>
<ui-image [image]="imageUrl3" [bordered]="true"></ui-image>
<ui-image [image]="imageUrl2" [bordered]="true"></ui-image>
<ui-image [image]="imageUrl" [bordered]="true"></ui-image>
<ui-image [image]="imageUrl2" [bordered]="true"></ui-image>
<ui-image [image]="imageUrl3" [bordered]="true"></ui-image>
<ui-image [bordered]="true"></ui-image>

