<table
    cdk-table
    [dataSource]="dataSource"
    aria-label="table"
    fixedLayout>
    <!-- Select START -->
    <ng-container
        cdkColumnDef="select"
        *ngIf="selection">
        <th
            cdk-header-cell
            *cdkHeaderCellDef
            class="cell-header selectable">
            <ui-checkbox
                *ngIf="!loading"
                (click)="masterToggle()"
                data-testid="master-toggle"
                [selected]="masterSelected"
                [indeterminate]="indeterminate"
                [emitOnInputValueChange]="false">
            </ui-checkbox>
        </th>
        <td
            cdk-cell
            *cdkCellDef="let row; let i = index"
            class="cell selectable">
            <ui-checkbox
                *ngIf="!loading"
                (click)="$event.stopPropagation()"
                [attr.data-testid]="'toggle-' + i"
                [selected]="selection.isSelected(row)"
                (selectedChange)="toggleSelection(row)">
            </ui-checkbox>
        </td>
    </ng-container>
    <!-- Select END -->

    <!-- Custom html projection START -->
    <!-- See example of how to use this at the bottom of this file  -->
    <ng-content></ng-content>
    <!-- Custom html projection END -->

    <ng-container
        *ngFor="let column of columnNames"
        [cdkColumnDef]="column.columnDef">
        <!-- Angular does not support adding directives dynamically, so unfortunately we need duplicated code #notSortable-->
        <ng-container *ngIf="column.sortable; else notSortable">
            <th
                cdk-header-cell
                *cdkHeaderCellDef
                [mat-sort-header]="column.columnDef"
                class="cell-header text"
                [ngStyle]="{ width: column.width }">
                <div
                    class="ellipsis"
                    [uiTooltip]="column.name"
                    [uiTooltipOnlyWhenTruncated]="true">
                    {{ column.name }}
                </div>
            </th>
        </ng-container>

        <ng-template #notSortable>
            <th
                cdk-header-cell
                *cdkHeaderCellDef
                class="cell-header text"
                [ngStyle]="{ width: column.width }">
                <div
                    class="ellipsis"
                    [uiTooltip]="column.name"
                    [uiTooltipOnlyWhenTruncated]="true">
                    {{ column.name }}
                </div>
            </th>
        </ng-template>

        <td
            cdk-cell
            *cdkCellDef="let item"
            class="cell text ellipsis"
            [uiTooltip]="column.name"
            [uiTooltipOnlyWhenTruncated]="true">
            <ui-skeleton-cell *ngIf="loading; else text"></ui-skeleton-cell>
            <ng-template #text>
                {{ item[column.columnDef] }}
            </ng-template>
        </td>
    </ng-container>

    <!-- Kebabmenu START -->
    <ng-container
        cdkColumnDef="kebabmenu"
        *ngIf="menuConfig">
        <th
            cdk-header-cell
            *cdkHeaderCellDef
            class="cell-header kebab"></th>
        <td
            cdk-cell
            *cdkCellDef="let row"
            class="cell kebab"
            (click)="$event.stopPropagation()">
            <ui-svg-icon
                icon="kebab"
                [uiDropdownTarget]="optionsDropdown">
            </ui-svg-icon>

            <ui-dropdown
                theme="default"
                [header]="row[menuConfig.headerTextProp]"
                #optionsDropdown>
                <ng-container *ngIf="!loading">
                    <ui-dropdown-item
                        *ngFor="let option of menuConfig.options"
                        [id]="'interaction-kebab-' + option.displayText"
                        [svgIcon]="option.svgIcon ? option.svgIcon : ''"
                        [disabled]="option.disabled"
                        (click)="emitMenuOption(option, row)">
                        {{ option.displayText }}
                    </ui-dropdown-item>
                </ng-container>
            </ui-dropdown>
        </td>
    </ng-container>
    <!-- Kebabmenu END -->

    <tr
        cdk-header-row
        *cdkHeaderRowDef="columnDefs; sticky: config.tableHeaderSticky"
        class="row-header"></tr>

    <!-- Until we can check if an ng-content is empty without wrapping it in a obsolete div
        https://github.com/angular/angular/issues/12530
    or we can send in a customrow as templateoutlet, we unfortunately have to do it this way 😢 -->
    <ng-container *ngIf="config.dragConfig; else nonDragRows">
        <tr
            cdk-row
            *cdkRowDef="let row; columns: columnDefs; let i = index"
            [attr.data-testid]="'ui-table-row-' + i"
            class="row"
            [ngClass]="{
                odd: i % 2 === 0,
                selected: selection && selection.isSelected(row)
            }"
            (click)="!loading && emitRow($event, row)"
            uiDragDrop
            [customComponent]="{
                props: {
                    text: selection.isSelected(row)
                        ? 'Move ' + selection.selected.length + ' elements'
                        : 'Move element'
                }
            }"
            [dragData]="selection.isSelected(row) ? selection.selected : [row]"
            [dropData]="row"
            (itemsDropped)="itemsDropped.emit($event)"
            [isAllowedToDrop]="config.dragConfig.isAllowedToDrop"></tr>
    </ng-container>

    <ng-template #nonDragRows>
        <tr
            cdk-row
            *cdkRowDef="let row; columns: columnDefs; let i = index"
            [attr.data-testid]="'ui-table-row-' + i"
            class="row"
            [ngClass]="{
                odd: i % 2 === 0,
                selected: selection && selection.isSelected(row)
            }"
            (click)="!loading && emitRow($event, row)"></tr>
    </ng-template>

    <ng-container *cdkNoDataRow>
        <tr class="row">
            <ng-container *ngIf="loading">
                <ng-container *ngFor="let column of columnDefs">
                    <ng-container
                        *ngIf="column === 'select' || column === 'kebabmenu'; else skeletonCell">
                        <td class="cell text"></td>
                    </ng-container>
                    <ng-template #skeletonCell>
                        <td class="cell text"><ui-skeleton-cell></ui-skeleton-cell></td>
                    </ng-template>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="!loading">
                <td
                    class="no-data"
                    colspan="100%"
                    [innerHTML]="noDataText"></td>
            </ng-container>
        </tr>
    </ng-container>
</table>

<!-- EXAMPLE CUSTOM HTML USING NG-PROJECTION  -->
<!--  Dont forget to add your cdkColumnDef to columnDef input with isCustom flag ¯\_(ツ)_/¯ -->

<!-- <ui-table style="width: 100%;"[dataSource]="data" [columnNames]="columnNames" [selection]="selectionModel" [menuOptions]="menuOptions">

  <ng-container cdkColumnDef="random">

      <th cdk-header-cell *cdkHeaderCellDef class="cell-header text">
          <div class="cell-header-wrapper">
            <div>Random</div>
          </div>
        </th>
      <td cdk-cell *cdkCellDef="let row;" class="cell text">
          <p style="color: red;">
              {{ row.random }}
          </p>
      </td>
  </ng-container>

  <ng-container cdkColumnDef="anotherone">

      <th cdk-header-cell *cdkHeaderCellDef class="cell-header text">
          <div class="cell-header-wrapper">
            <div>Another one</div>
          </div>
        </th>
      <td cdk-cell *cdkCellDef="let row;" class="cell text">
          <ui-input></ui-input>
      </td>
  </ng-container>

</ui-table> -->
