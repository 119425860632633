<h2>Folder Tree List</h2>

<div class="filter">
    <ui-button text="Show as {{ showGridView ? 'list' : 'grid' }}" (click)="showGridView = !showGridView"></ui-button>
    <ui-button text="Add new item" (click)="addNewItem(false)"></ui-button>
    <ui-button text="Add new item at the top" (click)="addNewItem(true)"></ui-button>
    <ui-button text="Modify" (click)="modifyItem()"></ui-button>
    <ui-button text="Go to parent" (click)="goToParent()"></ui-button>
    <ui-input placeholder="Search list" [(value)]="dataSource.filter"></ui-input>
</div>

<ui-list
    #exampleList
    class="list"
    [layout]="showGridView ? 'grid': 'list'"
    [outerBorder]="!showGridView"
    [dataSource]="dataSource"
    [expandable]="true"
    [dragAndDrop]="true"
    [allowBackDrop]="true"
    [multiSelect]="false"
    (dropOnBackElement)="onBackDrop($event)"
    (drop)="onDrop($event)"
    (load)="onLoadMore($event)"
    [selectionType]="'multi'"
    (selectionChange)="onSelect($event)"
    (editData)="onEdit($event)">

    <ui-list-grid property="title">
        <ng-template let-data="data"
                     let-rowIndex="rowIndex"
                     let-displayValue="displayValue"
                     let-value="value"
                     ui-list-grid-cell-template>
            {{ value }}
        </ng-template>
    </ui-list-grid>

    <ui-list-column
        name="Name"
        property="title"
        [sortable]="true"
        [editable]="true">
        <ng-template let-data="data"
                     let-rowIndex="rowIndex"
                     let-displayValue="displayValue"
                     let-value="value"
                     ui-list-cell-template>
            {{displayValue}} {{ value}}
        </ng-template>
    </ui-list-column>

    <ui-list-column
        width="30%"
        name="ID"
        property="id"
        [sortable]="true"
        align="center">
        <ng-template
            let-data="data"
            let-value="value"
            ui-list-cell-template>
            {{ value }}
        </ng-template>
    </ui-list-column>

    <ui-list-column
        width="30%"
        name="Disabled"
        property="disabled"
        [sortable]="true"
        align="center">
        <ng-template
            let-data="data"
            let-value="value"
            ui-list-cell-template>
            {{ value }}
        </ng-template>
    </ui-list-column>
</ui-list>


<h2>Multiselect List</h2>
<ui-list
    #checkboxList
    class="list"
    [layout]="'list'"
    [dataSource]="multiSelectDataSource"
    [expandable]="false"
    [multiSelect]="true"
    [selectionType]="'checkbox'"
    (selectionChange)="onSelect($event)">

    <ui-list-column
        name="Title"
        property="title">
    </ui-list-column>

    <ui-list-column
        name="Url"
        property="url">
    </ui-list-column>

    <ui-list-column
        width="30%"
        name="ID"
        property="id"
        [sortable]="true"
        align="center">
    </ui-list-column>
</ui-list>

<h2>Radio List</h2>
<ui-list
    #checkboxRadioList
    class="list"
    [layout]="'list'"
    [dataSource]="multiSelectDataSource"
    [expandable]="false"
    [selectionType]="'radio'"
    (selectionChange)="onSelect($event)">

    <ui-list-column
        name="Title"
        property="title">
    </ui-list-column>

    <ui-list-column
        name="Url"
        property="url">
    </ui-list-column>

    <ui-list-column
        width="30%"
        name="ID"
        property="id"
        [sortable]="true"
        align="center">
    </ui-list-column>
</ui-list>

<h2>Drill Down</h2>

<ui-list
    class="list expandable-checkbox-example"
    [layout]="'list'"
    [dataSource]="expandableCheckboxDataSource"
    [expandable]="true"
    [multiSelect]="true"
    (drop)="onDrop($event)"
    (load)="onLoadMore($event)"
    [selectionType]="'checkbox'"
    (selectionChange)="onSelect($event)"
    (editData)="onEdit($event)"
    [enableDrillDown]="true">

    <ui-list-grid property="title">
        <ng-template let-data="data"
                     let-rowIndex="rowIndex"
                     let-displayValue="displayValue"
                     let-value="value"
                     ui-list-grid-cell-template>
            {{ value }}
        </ng-template>
    </ui-list-grid>

    <ui-list-column
        name="Name"
        property="title"
        [sortable]="true">

        <ng-template let-data="data"
                    let-rowIndex="rowIndex"
                    let-displayValue="displayValue"
                    let-value="value"
                    ui-list-folder-template>
            {{ value}}
        </ng-template>
        <ng-template let-data="data"
                     let-rowIndex="rowIndex"
                     let-displayValue="displayValue"
                     let-value="value"
                     ui-list-cell-template>
            {{ value}}
        </ng-template>


    </ui-list-column>

    <ui-list-column
        width="30%"
        name="ID"
        property="id"
        [sortable]="true"
        align="center">
        <ng-template let-data="data"
                     let-rowIndex="rowIndex"
                     let-displayValue="displayValue"
                     let-value="value"
                     ui-list-folder-template>
            {{ value}}
        </ng-template>

        <ng-template
            let-data="data"
            let-value="value"
            ui-list-cell-template>
            {{ value }}
        </ng-template>
    </ui-list-column>

    <ui-list-column
        width="30%"
        name="Disabled"
        property="disabled"
        [sortable]="true"
        align="center">
        <ng-template let-data="data"
                     let-rowIndex="rowIndex"
                     let-displayValue="displayValue"
                     let-value="value"
                     ui-list-folder-template>
            {{ value}}
        </ng-template>
        <ng-template
            let-data="data"
            let-value="value"
            ui-list-cell-template>
            {{ value }}
        </ng-template>
    </ui-list-column>
</ui-list>
