import {
    Component,
    Input,
    EventEmitter,
    OnDestroy,
    ElementRef,
    HostListener,
    Output
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'ui-dropdown-input',
    templateUrl: 'dropdown-input.component.html',
    styleUrls: ['./dropdown-input.component.scss'],
    host: {
        class: 'ui-dropdown-input',
        '[class.disabled]': `disabled`,
        '[class.has-toggle]': 'isToggable'
    }
})
export class UIDropdownInputComponent implements OnDestroy {
    @Input() disabled = false;
    @Input() toggle: boolean;
    @Input() placeholder: string;
    @Input() type: 'number' | 'text';
    @Input() placeholderFocus: string;
    @Input() units: string;
    @Input() icon: string;
    @Output() toggleChange = new EventEmitter<boolean>();
    @Output() valueChange = new EventEmitter<string | number>();

    @HostListener('mouseenter') mouseEnterEvent(): void {
        if (!this.disabled) {
            this.hovered.next(this);
        }
    }

    @HostListener('click') clickEvent(): void {
        if (!this.disabled) {
            if (this.isToggable) {
                this.toggle = !this.toggle;
                this.toggleChange.emit(this.toggle);
            }

            this.itemClicked.emit(this);
        }
    }
    get isToggable(): boolean {
        return typeof this.toggle !== 'undefined';
    }

    triggersSubdropdown = false;
    hovered = new Subject<UIDropdownInputComponent>();
    itemClicked = new EventEmitter<UIDropdownInputComponent>();
    value: string | number;
    focus = false;

    constructor(private _elementRef: ElementRef<HTMLElement>) {}

    ngOnDestroy(): void {
        this.hovered.complete();
    }

    onFocus(input: HTMLInputElement): void {
        this.focus = true;
        if (this.placeholderFocus) {
            input.placeholder = this.placeholderFocus;
        }
        input.value = input.value.replace(this.units, '').replace(' ', '');
    }

    inputChange(input: HTMLInputElement): void {
        if (this.type === 'number') {
            input.value = input.value.replace(/\D/g, '');
        }
        this.valueChange.emit(input.value.replace(this.units, '').replace(' ', ''));
        this.value = input.value;
    }

    focusOut(input: HTMLInputElement): void {
        this.focus = false;
        if (this.units) {
            this.appendUnits(input);
        }

        if (this.placeholder) {
            input.placeholder = this.placeholder;
        }
    }

    _getHostElement(): HTMLElement {
        return this._elementRef.nativeElement;
    }

    private appendUnits(input: HTMLInputElement): void {
        if (input.value.length !== 0 && !input.value.includes(this.units) && this.units) {
            input.value = `${input.value} ${this.units}`;
        }
    }
}
