import { Component, ChangeDetectionStrategy, Input, ElementRef, OnChanges } from '@angular/core';
import { UISvgIconService } from './svg-icon.service';
import { Icon } from '../../icon/svg-icon/icons';

@Component({
    selector: 'ui-svg-icon',
    templateUrl: './svg-icon.component.html',
    styleUrls: ['./svg-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'icon'
    }
})
export class UISVGIconComponent implements OnChanges {
    /**
     * Name of icon to be display. Case insensitive.
     */
    @Input() icon: Icon | 'none';

    /**
     * Fill color of the icon. Pass an array if you want to apply multiple.
     */
    @Input() fill: string | string[];

    /**
     * From which icon set the icon should load.
     */
    @Input() set: 'ui' | 'studio' | 'misc' = 'ui';

    /**
     * Constructor of icon. Needs the SvgIconService to inject the base svg in body to work.
     * @param elementRef
     * @param svgIconService
     */
    constructor(
        private elementRef: ElementRef,
        private _svgIconService: UISvgIconService
    ) {}

    /**
     * An input has been changed
     */
    ngOnChanges(): void {
        this.setFill();
    }

    /**
     * Change fill color.
     */
    private setFill(): void {
        this.clearFill();
        const element = this.elementRef.nativeElement;
        if (this.fill && element) {
            const fill = Array.isArray(this.fill) ? this.fill : [this.fill];

            fill.forEach((color, index) => {
                element.style.setProperty(`--color${index + 1}`, color);
            });
        }
    }

    /**
     * Remove all fill colors
     */
    private clearFill(): void {
        const element = this.elementRef.nativeElement as HTMLDivElement;
        if (element) {
            let index = 1;
            while (element.style.removeProperty(`--color${index}`)) {
                index++;
            }
        }
    }
}
