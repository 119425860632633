<label
    class="label"
    for="{{ id }}-input"
    *ngIf="label"
    >{{ label }}</label
>
<div class="container">
    <input
        class="input"
        #valueContainer
        id="{{ id }}-input"
        type="text"
        autocomplete="off"
        [tabindex]="disabled ? -1 : tabindex ? tabindex : 0"
        [disabled]="disabled"
        [ngClass]="{ invalid: validation?.invalid && (validation?.touched || !validation?.pristine) }"
        [placeholder]="currentPlaceholder"
        [attr.disabled]="disabled ? '' : null"
        [(ngModel)]="inputFieldValue"
        [size]="autoResize ? textSize : 20"
        [ngStyle]="{ 'padding-right': autoResize ? '2px' : '7px' }"
        (blur)="onBlur()"
        (focus)="onFocus()"
        (keydown)="onKeyDown($event)"
        (keyup.escape)="cancel.emit()" />
    <div
        class="unit-label"
        *ngIf="unitLabel">
        {{ unitLabel }}
    </div>
    <div
        *ngIf="arrowButtons"
        class="buttons">
        <div
            id="{{ id }}-increase"
            class="button up"
            [ngClass]="{ disabled: btnUpDisabled }"
            (mousedown)="stepValue(step, true)"
            (mouseup)="onMouseUp()"></div>
        <div
            id="{{ id }}-decrease"
            class="button down"
            [ngClass]="{ disabled: btnDownDisabled }"
            (mousedown)="stepValue(-step, true)"
            (mouseup)="onMouseUp()"></div>
    </div>
</div>
