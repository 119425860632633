import { Injectable } from '@angular/core';
import { UIDialogService } from '../dialog/dialog.service';
import { IUIErrorDialogConfig, UIERRORDIALOG_DEFAULT_CONFIG } from './error-dialog-config.interface';
import { UIErrorDialogComponent } from './error-dialog.component';
import { IBFHttpError } from './error.interface';

@Injectable({ providedIn: 'root' })
export class UIErrorDialogService {
    constructor(public dialogService: UIDialogService) {}

    public async show(config: IUIErrorDialogConfig, error: IBFHttpError): Promise<IBFHttpError> {
        const dialogConfig = { ...UIERRORDIALOG_DEFAULT_CONFIG, ...config };

        const dialogRef = this.dialogService.openComponent(UIErrorDialogComponent, dialogConfig);

        await dialogRef.afterViewInit;

        return await dialogRef.subComponentRef.instance
            .initiate(config, error)
            .then((response: IBFHttpError) => {
                dialogRef.close();
                return response;
            });
    }
}
