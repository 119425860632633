import { Component } from '@angular/core';
import { UINotificationService } from 'components';
import { UINotificationType } from '../../../../types/notification';

@Component({
    selector: 'ui-notification-docs-preview',
    templateUrl: './notification.docs.preview.html',
    styleUrls: ['./notification.docs.preview.scss']
})
export class UINotificationDocsPreview {

    constructor(private uiNotificationService: UINotificationService) {}

    showNotification(type: UINotificationType = 'info', autoClose: boolean = false, hideIcon: boolean = false): void {
        this.uiNotificationService.open('Hello world <a href="https://bannerflow.com">link</a>', {
            type,
            autoCloseDelay: autoClose ? 2000 : undefined,
            hideCloseIcon: hideIcon
        });
    }

    showNotificationWithCheckBox(type: UINotificationType = 'info', autoClose: boolean = false, hideIcon: boolean = false): void {
        this.uiNotificationService.open('Your font does not support all characters used in ‘Text Fr1 Line2’, ‘KeyTermsText’, ‘Sports Button Pulse’. We highly recommend changing font (or copy) to avoid a back up font being used. Read more here.', {
            type,
            autoCloseDelay: autoClose ? 2000 : undefined,
            hideCloseIcon: hideIcon
        }, 'Check box visible', true);
    }

}
