import { AnimationEvent, animate, state, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit,
    Component,
    ComponentRef,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    OnDestroy,
    Type,
    ViewChild,
    ViewContainerRef,
    ViewRef
} from '@angular/core';
import { IUIPopoverConfig } from '../../../types/popover';
import { UIPopoverRef } from './popover-ref';

@Component({
    selector: 'ui-popover-master',
    templateUrl: 'popover.component.html',
    styleUrls: ['popover.component.scss'],
    animations: [
        trigger('slideContent', [
            state('void', style({ transform: 'translateY(-5px)', opacity: 0 })),
            state('enter', style({ transform: 'none', opacity: 1, filter: 'none' })),
            state('leave', style({ opacity: 0 })),
            transition('void => enter', animate('300ms cubic-bezier(0.390, 0.575, 0.565, 1.000)')),
            transition('enter => leave', animate('0ms cubic-bezier(0.390, 0.575, 0.565, 1.000)'))
        ])
    ],
    host: {
        '[class.ui-popover]': 'true',
        '[class.content-loaded]': 'popoverRef.templateRef || popoverRef.componentClass'
    }
})
export class UIPopoverComponent implements AfterViewInit, OnDestroy {
    config: IUIPopoverConfig;
    animationStateChanged: EventEmitter<AnimationEvent> = new EventEmitter<AnimationEvent>();

    @ViewChild('componentPlaceholder', { read: ViewContainerRef })
    viewContainerRef: ViewContainerRef;

    @HostBinding('@slideContent')
    animationState: 'void' | 'enter' | 'leave' = 'void';

    private subComponentViewRef: ViewRef;

    constructor(
        public popoverRef: UIPopoverRef,
        public host: ElementRef<HTMLElement>
    ) {
        this.config = this.popoverRef.config;

        if (this.config.maxWidth && this.config.maxWidth !== 'none') {
            this.host.nativeElement.style.setProperty('--maxWidth', `${this.config.maxWidth}`);
        }
        if (this.config.minWidth && this.config.minWidth !== 'none') {
            this.host.nativeElement.style.setProperty('--minWidth', `${this.config.minWidth}`);
        }
        if (this.config.width && this.config.width !== 0) {
            this.host.nativeElement.style.setProperty('width', `${this.config.width}`);
        }
        if (this.config.popoverType) {
            this.host.nativeElement.classList.add(this.config.popoverType);
        }
        if (this.config.arrowPosition) {
            this.host.nativeElement.classList.add('arrow');
            this.host.nativeElement.classList.add(`arrow-${this.config.arrowPosition}`);
        }
        if (this.config.backgroundColor) {
            this.host.nativeElement.style.setProperty(
                '--popover-backgroundColor',
                this.config.backgroundColor
            );
        }
    }

    ngAfterViewInit(): void {
        // To avoid "ExpressionChangedError"
        setTimeout(() => {
            // Make ref to instance available
            this.popoverRef.subComponentRef = this.injectComponent(this.popoverRef.componentClass)!;

            // For subcomponentes to know when their instances is available
            this.popoverRef.resolveAfterViewInitPromise();

            // Fade in popover
            this.animationState = 'enter';

            if (this.config.theme && this.host.nativeElement.parentElement) {
                this.host.nativeElement.parentElement.setAttribute('ui-theme', this.config.theme);
            }
        });
    }

    /**
     * Start fading out popover
     */
    startExitAnimation(): void {
        this.animationState = 'leave';
    }

    /**
     * Tell the popoverRef to close the popover
     */
    close(): void {
        this.popoverRef.close();
    }

    /**
     * Inject a component to view
     * @param componentClass
     */
    private injectComponent(componentClass?: Type<any>): ComponentRef<any> | undefined {
        if (componentClass) {
            const component = this.viewContainerRef.createComponent(componentClass);
            this.subComponentViewRef = this.viewContainerRef.insert(component.hostView);
            return component;
        }
        return undefined;
    }

    /**
     * @description Listen to when user presses escape.
     * @description If event is from an input, we do not want to close it.
     * @param event
     */
    @HostListener('document:keydown', ['$event'])
    public handleKeydown(event: KeyboardEvent): void {
        if (event.code === 'Escape' && this.popoverRef.config.escKeyClose) {
            const element: Element = event.target as Element;

            if (element && element.tagName.toLowerCase() === 'input') {
                return;
            }

            this.close();
        }
    }

    /**
     * When in/out animation starts
     * @param event
     */
    @HostListener('@slideContent.start', ['$event'])
    onAnimationStart(event: AnimationEvent): void {
        this.animationStateChanged.emit(event);
    }

    /**
     * When in/out animation is done
     * @param event
     */
    @HostListener('@slideContent.done', ['$event'])
    onAnimationDone(event: AnimationEvent): void {
        this.animationStateChanged.emit(event);
    }

    ngOnDestroy(): void {
        if (this.subComponentViewRef) {
            this.subComponentViewRef.detach();
            this.subComponentViewRef.destroy();
        }
    }
}
