import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UIDragAndDropService {
    /**
     * Value currently being dragged
     */
    value?: any;

    /**
     * Which element did the drag start at
     */
    element?: any;

    startDrag(element: any, value?: any): void {
        this.element = element;
        this.value = value;
        document.body.classList.add('ui-dragging');
    }

    /**
     * Reset current dragged values. Should happen
     * when user drops or stops dragginng etc.
     */
    reset(): void {
        this.element = undefined;
        this.value = undefined;

        document.body.classList.remove('ui-dragging');
    }
}
