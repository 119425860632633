import { UIDomAttachService, IUIAttachedDOMComponent } from './../../../services/dom-attach.service';
import { Injectable, OnDestroy } from '@angular/core';
import { UISvgIconsComponent } from './svg-icons.component';

@Injectable({ providedIn: 'root' })
export class UISvgIconService implements OnDestroy {
    private attachedSvg?: IUIAttachedDOMComponent<UISvgIconsComponent>;

    constructor(private domAttach: UIDomAttachService) {
        this.add();
    }

    add(): void {
        this.attachedSvg = this.domAttach.addComponent(UISvgIconsComponent, document.body);
    }

    remove(): void {
        if (this.attachedSvg) {
            this.attachedSvg.domPortalHost.detach();
        }
    }

    ngOnDestroy(): void {
        this.remove();
    }
}
