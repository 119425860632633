<div
    class="text"
    [innerHTML]="config.text || ''"></div>

<div *ngIf="config.__discardable">
    <div
        class="distributed-buttons"
        *ngIf="config.showCancelButton">
        <div class="left-actions">
            <ui-button
                *ngIf="!config.onDiscard"
                (click)="resolve('discard')"
                [text]="config.discardText"></ui-button>
            <ui-button
                *ngIf="config.onDiscard"
                [submit]="discard"
                [done]="onDiscarded"
                [text]="config.discardText"></ui-button>
        </div>
        <div class="right-actions">
            <ui-button
                (click)="resolve('cancel')"
                [text]="config.cancelText"></ui-button>
            <div class="button-space"></div>
            <ui-button
                *ngIf="!config.onConfirm"
                type="primary"
                [text]="config.confirmText"
                (click)="resolve('confirm')"></ui-button>
            <ui-button
                #confirmButton
                *ngIf="config.onConfirm"
                [submit]="confirm"
                [done]="onConfirmed"
                type="primary"
                [text]="config.confirmText"></ui-button>
        </div>
    </div>
    <div
        class="centered-buttons"
        *ngIf="!config.showCancelButton">
        <ui-button
            *ngIf="!config.onDiscard"
            (click)="resolve('discard')"
            [text]="config.discardText"></ui-button>
        <ui-button
            *ngIf="config.onDiscard"
            [submit]="discard"
            [done]="onDiscarded"
            [text]="config.discardText"></ui-button>
        <div class="button-space"></div>
        <ui-button
            *ngIf="!config.onConfirm"
            type="primary"
            [text]="config.confirmText"
            (click)="resolve('confirm')"></ui-button>
        <ui-button
            #confirmButton
            *ngIf="config.onConfirm"
            [submit]="confirm"
            [done]="onConfirmed"
            type="primary"
            [text]="config.confirmText"></ui-button>
    </div>
</div>

<div *ngIf="!config.__discardable">
    <ui-dialog-buttons>
        <ui-button
            (click)="resolve('cancel')"
            *ngIf="config.cancelText"
            [text]="config.cancelText"></ui-button>
        <div class="button-space"></div>
        <ui-button
            *ngIf="!config.onConfirm && config.confirmText"
            type="primary"
            [text]="config.confirmText"
            (click)="resolve('confirm')"></ui-button>
        <ui-button
            #confirmButton
            *ngIf="config.onConfirm && config.confirmText"
            [submit]="confirm"
            [done]="onConfirmed"
            type="primary"
            [text]="config.confirmText"></ui-button>
    </ui-dialog-buttons>
</div>
