<div
    #sizeHelper
    class="input textarea"></div>
<textarea
    #valueContainer
    id="{{ id }}-textarea"
    class="input textarea value-container"
    [ngModel]="value"
    (ngModelChange)="syncSize($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [placeholder]="placeholder"
    [disabled]="!!disabled"></textarea>
