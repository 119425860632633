import { Component, ElementRef, HostBinding, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'ui-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class UIBreadcrumbComponent {
    @ViewChild('template', { static: true }) template: TemplateRef<any>;
    @Input() maxTruncationWidth = 200;
    @HostBinding('class.truncated') get truncated(): boolean {
        return this.elementRef.nativeElement.offsetWidth >= this.maxTruncationWidth;
    }
    collapsed = false;

    constructor(public elementRef: ElementRef<HTMLElement>) {}
}
