<div
    class="dropzone-area ui-scrollbar"
    [ngClass]="{ 'drag-over': isDragOver() }"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)">
    <div class="hint">{{ hintText() }}</div>
    <ui-button
        text="{{ buttonText() }}"
        type="primary"
        svgIcon="upload-file"
        (click)="fileInput.click()" />
</div>

<input
    #fileInput
    type="file"
    [accept]="accept()"
    [multiple]="maxFiles() > 1"
    (change)="onFileChange($event)"
    hidden />
