import { Directive, Input, TemplateRef, ContentChild } from '@angular/core';
import { UIListCellType } from '../../../../types/list-cell';
import { UIListCellTemplateDirective } from './list-cell-template.directive';
import { UIListFolderTemplateDirective } from './list-folder-template.directive';

@Directive({
    // Current lint rules forces directives to be used as attributes
    // TODO: Either change the ruleset or update all the references of this directive
    // tslint:disable-next-line:directive-selector
    selector: 'ui-list-grid'
})
export class UIListGridDirective {
    /**
     * Property to display values from
     */
    @Input() property = '';

    /**
     * Type of data that should be displayed.
     */
    @Input() type: UIListCellType = 'auto';

    /**
     * If user should be able to sort by this column
     */
    @Input() sortable = true;

    /**
     * Werther values
     */
    @Input() filterable = true;

    /**
     * Icon should be displayed in front of values. TO BE IMPLEMENTED.
     */
    @Input() icon: string;

    /**
     * What to show for empty cells.
     */
    @Input() defaultValue: any;

    /**
     * Format to control display value of the cell.
     * Depending on value type use cases may differ.
     * Dates: 'MMM d, y' => 'Jun 15, 2015'
     * Percentages: '1.0-2' => '1.01%'
     * Numbers: '1.0-3' => '20.003'
     */
    @Input() format?: string;

    @ContentChild(UIListCellTemplateDirective, { read: TemplateRef })
    cellTemplate?: TemplateRef<any>;

    @ContentChild(UIListFolderTemplateDirective, { read: TemplateRef })
    folderTemplate?: TemplateRef<any>;
}
