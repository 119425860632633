import {
    Directive,
    TemplateRef,
    Output,
    EventEmitter,
    Input,
    ContentChild,
    OnDestroy
} from '@angular/core';
import { UIPopoverTemplateDirective } from './popover-template.directive';
import { UIPopoverService } from './popover.service';
import { UIPopoverRef } from './popover-ref';
import { IUIPopover } from './popover.interface';
import { UIPopoverTargetDirective } from './popover-target.directive';
import { Subscription } from 'rxjs';
import { IUIPopoverConfig } from '../../../types/popover';

@Directive({
    // Current lint rules forces directives to be used as attributes
    // TODO: Either change the ruleset or update all the references of this directive
    // tslint:disable-next-line:directive-selector
    selector: 'ui-popover',
    exportAs: 'ui-popover'
})
export class UIPopoverDirective implements OnDestroy, IUIPopover {
    _config: IUIPopoverConfig;

    @Input()
    set config(config: IUIPopoverConfig) {
        if (config.openOnHover) {
            config.hasBackdrop = false;
        }
        this._config = config;
    }

    get config(): IUIPopoverConfig {
        return this._config;
    }

    @ContentChild(UIPopoverTemplateDirective, { read: TemplateRef })
    popoverTemplate: TemplateRef<any>;

    @Output('close') onClose = new EventEmitter();

    public isPopoverOpen: boolean;

    private onCloseSubscription = Subscription.EMPTY;

    private popoverRef: UIPopoverRef | undefined;

    constructor(private popoverService: UIPopoverService) {}

    open(target: UIPopoverTargetDirective): UIPopoverRef {
        if (this.popoverRef) {
            this.popoverRef.destroy();
        }
        this.popoverRef = this.popoverService.openTemplate(
            target.host,
            this.popoverTemplate,
            this.config
        );
        this.isPopoverOpen = true;
        target.onPopoverOpen();

        // Listen to close call
        this.onCloseSubscription = this.popoverRef.onClose.subscribe(() => {
            this.onClose.emit();
            this.isPopoverOpen = false;
            target.onPopoverClosed();
            this.onCloseSubscription.unsubscribe();
        });

        return this.popoverRef;
    }

    close(): void {
        if (this.popoverRef) {
            this.popoverRef.close();
            delete this.popoverRef;
        }
        if (this.onCloseSubscription && !this.onCloseSubscription.closed) {
            this.onCloseSubscription.unsubscribe();
        }
    }

    ngOnDestroy(): void {
        if (this.popoverRef) {
            this.popoverRef.destroy();
        }
        if (this.onCloseSubscription && !this.onCloseSubscription.closed) {
            this.onCloseSubscription.unsubscribe();
        }
    }
}
