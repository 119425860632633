<layout>
    <ng-container ngProjectAs="leftRegion">
    </ng-container>

    <ng-container ngProjectAs="centerRegion">
        <ui-panel name="Theme">
            <h1>UI-Theme attribute dictates what theme will be used</h1>

            <p>You can seamlessly override the current theme by decorating an element with attribute <b>ui-theme</b></p>

            <p>Html attribute <b>ui-theme</b> can be of values
                <i>default</i>,
                <i>small</i>,
                <i>minimal</i> and
                <i>tiny</i>.
            </p>
            <p>Yhe attribute denotes what ui-theme will be used. We always have a top-level ui-theme attribute on
                <b>&lt;body&gt;</b> tag
            </p>

            <p>
                You could make another attribute declaration, in the DOM at whatever point you want to denote that that
                wrapper and its children will use another ui-theme.
            </p>

            <div class="button-wrapper">
                <ui-button (click)="noop($event)"
                    text="Reliable Button"
                    type="primary"></ui-button>

                <ui-button (click)="noop($event)"
                    text="Reliable Button"
                    type="primary"
                    ui-theme="default"></ui-button>

                <ui-button (click)="noop($event)"
                    text="Reliable Button"
                    type="primary"
                    ui-theme="minimal"></ui-button>

                <ui-button (click)="noop($event)"
                    text="Reliable Button"
                    type="primary"
                    ui-theme="tiny"></ui-button>
            </div>

            <pre><code class="language-html">
                    <span class="hljs-tag">&lt;<span class="hljs-name">ui-button</span> (<span class="hljs-attr">click</span>)=<span class="hljs-string">"noop($event)"</span>
                        <span class="hljs-attr">text</span>=<span class="hljs-string">"Reliable Button"</span>
                        <span class="hljs-attr">type</span>=<span class="hljs-string">"primary"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ui-button</span>&gt;</span>

                    <span class="hljs-tag">&lt;<span class="hljs-name">ui-button</span> (<span class="hljs-attr">click</span>)=<span class="hljs-string">"noop($event)"</span>
                        <span class="hljs-attr">text</span>=<span class="hljs-string">"Reliable Button"</span>
                        <span class="hljs-attr">type</span>=<span class="hljs-string">"primary"</span>
                        <span class="hljs-attr">ui-theme</span>=<span class="hljs-string">"default"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ui-button</span>&gt;</span>

                    <span class="hljs-tag">&lt;<span class="hljs-name">ui-button</span> (<span class="hljs-attr">click</span>)=<span class="hljs-string">"noop($event)"</span>
                        <span class="hljs-attr">text</span>=<span class="hljs-string">"Reliable Button"</span>
                        <span class="hljs-attr">type</span>=<span class="hljs-string">"primary"</span>
                        <span class="hljs-attr">ui-theme</span>=<span class="hljs-string">"minial"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ui-button</span>&gt;</span>

                    <span class="hljs-tag">&lt;<span class="hljs-name">ui-button</span> (<span class="hljs-attr">click</span>)=<span class="hljs-string">"noop($event)"</span>
                        <span class="hljs-attr">text</span>=<span class="hljs-string">"Reliable Button"</span>
                        <span class="hljs-attr">type</span>=<span class="hljs-string">"primary"</span>
                        <span class="hljs-attr">ui-theme</span>=<span class="hljs-string">"tiny"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ui-button</span>&gt;</span>
            </code></pre>

            <h2>
                Special cases
            </h2>

            <p>
                When using elements that breaks the DOM-hierarchy like selects and its popovers or modals, you will need
                to explicitly tell the ui-component to stick with a specific theme.
                This is done by passing the parameter theme to that component. example:
            </p>

            <div>
                <ui-select theme="minimal"
                    (selectedChange)="valueChanged($event)">
                    <ui-option value="one">Since This Element...</ui-option>
                    <ui-option value="two">Breaks HTML hierarchy,</ui-option>
                    <ui-option value="two">I won't inherit the ui-theme...</ui-option>
                    <ui-option value="two">attribute, so you...</ui-option>
                    <ui-option value="three">need to explicitly pass </ui-option>
                    <ui-option value="four">theme to my &lt;ui-select&gt; </ui-option>
                </ui-select>
            </div>

            <pre><code class="language-html">
                <span class="hljs-tag">&lt;<span class="hljs-name">ui-select</span> <span class="hljs-attr">theme</span>=<span class="hljs-string">"minimal"</span>
                    (<span class="hljs-attr">selectedChange</span>)=<span class="hljs-string">"valueChanged($event)"</span>&gt;</span>
                    <span class="hljs-tag">&lt;<span class="hljs-name">ui-option</span> <span class="hljs-attr">value</span>=<span class="hljs-string">"one"</span>&gt;</span>Since This Element...<span class="hljs-tag">&lt;/<span class="hljs-name">ui-option</span>&gt;</span>
                    <span class="hljs-tag">&lt;<span class="hljs-name">ui-option</span> <span class="hljs-attr">value</span>=<span class="hljs-string">"two"</span>&gt;</span>Breaks HTML hierarchy,<span class="hljs-tag">&lt;/<span class="hljs-name">ui-option</span>&gt;</span>
                    <span class="hljs-tag">&lt;<span class="hljs-name">ui-option</span> <span class="hljs-attr">value</span>=<span class="hljs-string">"two"</span>&gt;</span>I won't inherit the ui-theme...<span class="hljs-tag">&lt;/<span class="hljs-name">ui-option</span>&gt;</span>
                    <span class="hljs-tag">&lt;<span class="hljs-name">ui-option</span> <span class="hljs-attr">value</span>=<span class="hljs-string">"two"</span>&gt;</span>attribute, so you...<span class="hljs-tag">&lt;/<span class="hljs-name">ui-option</span>&gt;</span>
                    <span class="hljs-tag">&lt;<span class="hljs-name">ui-option</span> <span class="hljs-attr">value</span>=<span class="hljs-string">"three"</span>&gt;</span>need to explicitly pass <span class="hljs-tag">&lt;/<span class="hljs-name">ui-option</span>&gt;</span>
                    <span class="hljs-tag">&lt;<span class="hljs-name">ui-option</span> <span class="hljs-attr">value</span>=<span class="hljs-string">"four"</span>&gt;</span>theme to my &amp;lt;ui-select&amp;gt; <span class="hljs-tag">&lt;/<span class="hljs-name">ui-option</span>&gt;</span>
                <span class="hljs-tag">&lt;/<span class="hljs-name">ui-select</span>&gt;</span>
            </code></pre>

        </ui-panel>
    </ng-container>
</layout>
