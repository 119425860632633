/**
 * Get a propert by a path. If obj = { owner: { name: 'human' }}.
 * Passing path 'owner.name' will return 'human'.
 * Should work with array indexes as well.
 * @param obj Object to search
 * @param path Path to the property that should be extracted
 * @param defaultValue If nothing found, what should be returned.
 */
export function propertyByPath(obj: any, path: string, defaultValue?: any): any {
    // No path
    if (path === undefined) {
        return undefined;
    } else if (path === '') {
        return obj;
    }
    const fullPath: string[] = path.replace(/\[/g, '.').replace(/]/g, '').split('.').filter(Boolean);

    function everyFunc(step: any): any {
        return !(step && obj && (obj = obj[step]) === undefined);
    }
    return fullPath.every(everyFunc) ? obj : defaultValue;
}
