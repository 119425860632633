import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export interface InlineEditOnSaveEvent {
    newValue: string;
    oldValue: string;
    inputControl: UntypedFormControl;
}

@Component({
    selector: 'ui-inline-edit',
    templateUrl: './inline-edit.component.html',
    styleUrls: ['./inline-edit.component.scss']
})
export class UIInlineEditComponent implements OnChanges, OnInit {
    @Input() value: string;
    @Input() loading = false;
    @Input() hideEditIcon: boolean;
    @Output() navigationClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    @Output() save: EventEmitter<InlineEditOnSaveEvent> = new EventEmitter<InlineEditOnSaveEvent>();
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
    @Input() isEditing = false;
    @Input() autoClose = true;
    @Input() clickOutside = true;

    showEditIcon = false;
    validationControl: UntypedFormControl = new UntypedFormControl('');
    cancelDisabled = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (this.validationControl.valid && this.isEditing && changes['value'] && this.autoClose) {
            this.cancelDisabled = false;
            this.endEditing();
        }
    }

    ngOnInit(): void {
        this.validationControl.statusChanges.subscribe(() => {
            this.cancelDisabled = false;
        });
    }

    keyUpListener(event: KeyboardEvent, inputValue: string): void {
        if (event.key === 'Enter') {
            this.saveEdit(inputValue);
        }
        if (event.key === 'Escape') {
            this.endEditing();
        }
    }

    mouseEnter(): void {
        if (!this.hideEditIcon) {
            this.showEditIcon = true;
        }
    }

    mouseLeave(): void {
        this.showEditIcon = false;
    }

    enableEditing(mouseEvent: MouseEvent): void {
        mouseEvent.stopPropagation();
        this.isEditing = true;
    }

    saveEdit(inputValue: string, mouseEvent?: MouseEvent): void {
        if (mouseEvent) {
            mouseEvent.stopPropagation();
        }

        if (inputValue !== this.value && inputValue.trim().length > 0) {
            this.cancelDisabled = true;
            this.save.emit({
                newValue: inputValue,
                oldValue: this.value,
                inputControl: this.validationControl
            });
            this.validationControl.markAsTouched();
        }
    }

    navigate(mouseEvent: MouseEvent): void {
        this.navigationClick.emit(mouseEvent);
    }

    endEditing(mouseEvent?: MouseEvent): void {
        if (mouseEvent) {
            mouseEvent.stopPropagation();
        }

        if (this.cancelDisabled) {
            return;
        }

        this.isEditing = false;
        this.validationControl.reset();
        this.cancel.emit();
    }
}
