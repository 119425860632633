<ui-breadcrumbs>
    <ui-breadcrumb (click)="navigate()">First</ui-breadcrumb>
    <ui-breadcrumb (click)="navigate()">Second</ui-breadcrumb>
    <ui-breadcrumb (click)="navigate()">Third</ui-breadcrumb>
    <ui-breadcrumb>Fourth</ui-breadcrumb>
</ui-breadcrumbs>

<h3>Styled Example</h3>
<ui-breadcrumbs class="styled"
    [maxItemWidth]="200">
    <ui-breadcrumb (click)="navigate()">First</ui-breadcrumb>
    <ui-breadcrumb (click)="navigate()">Very Loooooooooooooooooooooooooooooong</ui-breadcrumb>
    <ui-breadcrumb (click)="navigate()">Third</ui-breadcrumb>
</ui-breadcrumbs>

<h3>display:block parent</h3>
<div>
    <ui-breadcrumbs>
        <ui-breadcrumb *ngFor="let crumb of manyCrumbs; let i = index">
            {{crumb}} - {{i}}
        </ui-breadcrumb>
        <ui-breadcrumb>Last Item</ui-breadcrumb>
    </ui-breadcrumbs>
</div>

<h3>display:flex parent</h3>
<div style="display: flex;">
    <ui-breadcrumbs style="flex: 1">
        <ui-breadcrumb *ngFor="let crumb of manyCrumbs; let i = index">
            {{crumb}} - {{i}}
        </ui-breadcrumb>
        <ui-breadcrumb>Last Item</ui-breadcrumb>
    </ui-breadcrumbs>
</div>

<h3>display:grid parent</h3>
<div style="display: grid;">
    <ui-breadcrumbs>
        <ui-breadcrumb *ngFor="let crumb of manyCrumbs; let i = index">
            {{crumb}} - {{i}}
        </ui-breadcrumb>
        <ui-breadcrumb>Last Item</ui-breadcrumb>
    </ui-breadcrumbs>
</div>

<h3>Async Pipe Example</h3>
<ui-breadcrumbs>
    <ui-breadcrumb (click)="navigate()"
        *ngFor="let crumb of manyCrumbsAsync$ | async"> {{crumb}} </ui-breadcrumb>
</ui-breadcrumbs>
