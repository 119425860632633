export interface IBFError {
    title?: string;
    message?: string;
    code?: number;
}

export interface IBFHttpError extends IBFError {
    requestId?: string;
    requestUrl?: string;
    status?: number;
    logglyUrl?: string;
}
