<ng-container *ngIf="column?.cellTemplate && !editable">
    <ng-container
        *ngTemplateOutlet="
            column.cellTemplate ? column.cellTemplate : null;
            context: {
                $implicit: value,
                value: value,
                displayValue: displayValue,
                data: data,
                column: column
            }
        "></ng-container>
</ng-container>
<ng-container *ngIf="!column?.cellTemplate && type !== 'image' && !editable">
    {{ displayValue || '' }}
</ng-container>
<ng-container *ngIf="!column?.cellTemplate && type === 'image' && value && !editable">
    <div
        class="image"
        [style.background-image]="'url(' + value + ')'"></div>
</ng-container>
<ng-container *ngIf="editable">
    <div class="list-input">
        <ui-input
            #editInput
            [disabled]="!editing || data.disabled"
            value="{{ displayValue || value }}"
            (click)="enableEdit(data.disabled)"
            (focusout)="inputFocusOut()"
            (keyup)="keyUpListener($event)">
        </ui-input>
    </div>
</ng-container>
