<h3>Single</h3>
<ui-selectable-list
    #list
    [allowUnselected]="false"
    [disabled]="true"
    [(selected)]="selectedOption"
    [multiSelect]="false">
    <div
        class="option"
        *ngFor="let option of options">
        <ui-radio
            *ngIf="option.type === 'radio'"
            [label]="option.label"
            [value]="option">
        </ui-radio>
        <ui-checkbox
            *ngIf="option.type === 'checkbox'"
            [label]="option.label"
            [value]="option">
        </ui-checkbox>
        <ui-button
            #btn="ui-selectable"
            [type]="btn.selected ? 'primary' : 'default'"
            [ui-selectable]="option"
            *ngIf="option.type === 'directive'"
            [text]="btn.selected ? 'Deselect' : 'Select'">
        </ui-button>
    </div>
</ui-selectable-list>
<p>
    Variable 'selectedOption.label' is: {{ selectedOption?.label }}
</p>

<h3>Multi</h3>
<ui-selectable-list
    #list2
    [allowUnselected]="true"
    [(selected)]="selectedOptions"
    [multiSelect]="true">
    <div
        class="option"
        *ngFor="let option of multiOptions">
        <ui-radio
            *ngIf="option.type === 'radio'"
            [label]="option.label"
            [value]="option">
        </ui-radio>
        <ui-checkbox
            *ngIf="option.type === 'checkbox'"
            [label]="option.label"
            [value]="option">
        </ui-checkbox>
        <ui-button
            #btn="ui-selectable"
            [ui-selectable]="option"
            [type]="btn.selected ? 'primary' : 'default'"
            *ngIf="option.type === 'directive'"
            [text]="btn.selected ? 'Deselect' : 'Select'">
        </ui-button>
    </div>
</ui-selectable-list>
<p>
    Length of 'selectedOptions' is: {{ selectedOptions?.length }}
</p>

<h3>Disabled (Not Working)</h3>
<ui-selectable-list
    #list3
    [disabled]="true"
    [allowUnselected]="false"
    [(selected)]="selectedOption"
    [multiSelect]="false">
    <div
        class="option"
        *ngFor="let option of options">
        <ui-radio
            [disabled]="disabled"
            *ngIf="option.type === 'radio'"
            [label]="option.label"
            [value]="option">
        </ui-radio>
        <ui-checkbox
            *ngIf="option.type === 'checkbox'"
            [label]="option.label"
            [value]="option">
        </ui-checkbox>
        <ui-button
            #btn="ui-selectable"
            [type]="btn.selected ? 'primary' : 'default'"
            [ui-selectable]="option"
            *ngIf="option.type === 'directive'"
            [text]="btn.selected ? 'Deselect' : 'Select'">
        </ui-button>
    </div>
</ui-selectable-list>
<p>
    Variable 'selectedOption.label' is: {{ selectedOption?.label }}
</p>
