import { IUIDialogConfig, UIDIALOG_DEFAULT_CONFIG } from '../dialog/dialog-config.interface';

export interface IUIAlertDialogConfig extends IUIDialogConfig {
    text?: string;
    confirmText?: string;
}

export const UIALERTDIALOG_DEFAULT_CONFIG: Partial<IUIAlertDialogConfig> = {
    ...UIDIALOG_DEFAULT_CONFIG,
    text: '',
    maxWidth: 500,
    closeButton: false,
    backdropClickClose: false,
    escKeyClose: false,
    confirmText: 'Ok'
};
