import { Component, Input } from '@angular/core';

@Component({
    selector: 'ui-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class UIImageComponent {
    @Input() public image: string | undefined;
    @Input() public bordered: boolean;
}
