import {
    Directive,
    TemplateRef,
    Input,
    Output,
    ContentChild,
    OnDestroy,
    EventEmitter,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { UIDialogTemplateDirective } from './dialog-template.directive';
import { UIDialogService } from './dialog.service';
import { IUIDialogConfig } from './dialog-config.interface';
import { UIDialogRef } from './dialog-ref';
import { IUIDialog } from './dialog.interface';

@Directive({
    // Current lint rules forces directives to be used as attributes
    // TODO: Either change the ruleset or update all the references of this directive
    // tslint:disable-next-line:directive-selector
    selector: 'ui-dialog',
    exportAs: 'ui-dialog'
})
export class UIDialogDirective implements OnDestroy, OnChanges, IUIDialog {
    // TODO: Fix so docs can handle double decorators and comments (Adding a JSDoc comment will crash the app)
    // TODO: Fix so docs can handle double decorators and comments (Adding a JSDoc comment will crash the app)
    @ContentChild(UIDialogTemplateDirective, { read: TemplateRef })
    dialogTemplate!: TemplateRef<any>;

    /**
     * The options available
     */
    @Input() config: IUIDialogConfig;

    @Output() afterClose: EventEmitter<void> = new EventEmitter();

    private dialogRef?: UIDialogRef;
    private afterClosedSubscriber: any;

    constructor(private dialogService: UIDialogService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.dialogRef && changes['config'] && !changes['config'].firstChange) {
            this.dialogRef.updateConfig(changes['config'].currentValue);
        }
    }

    /**
     * Open this directive
     */
    open(): void {
        if (this.dialogRef) {
            this.dialogRef.destroy();
        }
        this.dialogRef = this.dialogService.openTemplate(this.dialogTemplate, this.config);

        this.afterClosedSubscriber = this.dialogRef.afterClose().subscribe(() => {
            this.afterClose.emit();
        });
    }

    /**
     * Close this directive
     */
    close(): void {
        this.dialogRef!.close();
        this.dialogRef = undefined;
    }

    ngOnDestroy(): void {
        if (this.dialogRef) {
            this.dialogRef.destroy();
        }
        if (this.afterClosedSubscriber) {
            this.afterClosedSubscriber.unsubscribe();
        }
    }
}
