import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    Input,
    OnDestroy,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { UIPopoverComponent } from '../../popovers/popover/popover.component';
import {
    animationFrames,
    debounceTime,
    delay,
    merge,
    Observable,
    Subject,
    take,
    takeUntil
} from 'rxjs';
import { IUIPopoverConfig } from '../../../types/popover';
import { UIBreadcrumbComponent } from './breadcrumb.component';
/**
 * Auto collapsing breadcrumb container that works with content projection and supports async pipe.
 *
 * Takes @see `UIBreadcrumbComponent` as content children and displays them with ngFor
 * by using `<ng-template>` references and `*ngTemplateOutlet`
 *
 * inspired by {@link https://github.com/angular/components/tree/main/src/material/tabs angular material tabs}
 *
 * @usage
 * ```
 * <ui-breadcrumbs>
 *      <ui-breadcrumb *ngFor="let crumb of manyCrumbs">{{crumb}}
 *      </ui-breadcrumb>
 *  </ui-breadcrumbs>
 * ```
 *
 * @version v1
 *  - auto collapse by parent width
 *  - recalculate collapse on resize
 *  - display collapsed items in popover
 *  - children can truncate and show tooltip if collapsed
 */
@Component({
    selector: 'ui-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIBreadcrumbsComponent implements AfterViewInit, OnDestroy {
    @ContentChildren(UIBreadcrumbComponent)
    contentChildrenBreadcrumbs: QueryList<UIBreadcrumbComponent>;
    @ViewChildren(UIBreadcrumbComponent) viewChildrenBreadcrumbs: QueryList<UIBreadcrumbComponent>;
    @ViewChild('breadcrumbsContainer', { read: ElementRef<HTMLElement> })
    breadcrumbsContainer: ElementRef<HTMLElement>;
    @ViewChild('popover') breadcrumbsPopover: UIPopoverComponent;

    @Input() maxItemWidth = 200;
    @Input() popoverConfig: IUIPopoverConfig = {
        arrowPosition: 'top',
        position: 'bottom',
        panelClass: 'ui-breadcrumbs-popover',
        width: '200px',
        openOnHover: true
    };

    isCollapsed = false;
    ngDestroy$ = new Subject<void>();

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private cdRef: ChangeDetectorRef
    ) {}

    ngAfterViewInit(): void {
        merge(
            // trying to get measured width after layout is drawn
            animationFrames().pipe(take(1)),
            // subscribes to changes in ng-content children to work with async pipe
            this.contentChildrenBreadcrumbs.changes.pipe(delay(1)),
            // observes resize events on host element
            this.observeResize(this.elementRef.nativeElement).pipe(debounceTime(100))
        )
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe(() => {
                this.tryCollapse();
            });
    }

    private observeResize(nativeElement: HTMLElement): Observable<HTMLElement> {
        return new Observable<HTMLElement>(subscriber => {
            const ro = new ResizeObserver(() => {
                subscriber.next(nativeElement);
            });

            ro.observe(nativeElement);

            return () => {
                ro.disconnect();
            };
        });
    }

    ngOnDestroy(): void {
        this.ngDestroy$.next();
        this.ngDestroy$.complete();
    }

    private tryCollapse(): void {
        // resets the collapsed state for expanding on resize
        this.resetCollapsed();
        this.cdRef.detach();

        const containerElement = this.breadcrumbsContainer.nativeElement;
        const hostWidth = containerElement.offsetWidth;
        const parentElement = containerElement.parentElement;

        // if there is no parent element we can't auto collapse
        // if there are no collapsible elements we can't auto collapse
        // if we can't get host width we can't auto collapse
        if (!parentElement || this.contentChildrenBreadcrumbs.length === 0 || hostWidth === 0) {
            return;
        }

        // if host width is less then parent width than everything fits, no need to collapse
        const parentWidth = parentElement.clientWidth;
        if (hostWidth < parentWidth) {
            return;
        }

        // we injected the first breadcrumb twice but we only want to measure it once
        // we injected collapse indicator so we need to adjust the width
        let currentWidth = hostWidth;

        // loops through template references to measure how many items we should collapse until we fit into the parent
        this.contentChildrenBreadcrumbs.forEach((collapsible, index) => {
            // gets the actual view child element because we wrap our template refs with ui-breadcrumb
            const breadcrumbWidth = this.getBreadcrumbWidth(index);

            // if it is first or last breadcrumb we don't collapse
            const isFirstBreadcrumb = collapsible === this.contentChildrenBreadcrumbs.first;
            const isLastBreadcrumb = collapsible === this.contentChildrenBreadcrumbs.last;
            if (isFirstBreadcrumb || isLastBreadcrumb) {
                return;
            }

            if (currentWidth > parentWidth) {
                currentWidth = currentWidth - breadcrumbWidth;
                collapsible.collapsed = true;
            }
        });

        this.isCollapsed = this.contentChildrenBreadcrumbs.some(breadcrumb => breadcrumb.collapsed);

        this.cdRef.reattach();
        this.cdRef.detectChanges();
    }

    private getBreadcrumbWidth(index: number): number {
        return this.viewChildrenBreadcrumbs.get(index)!.elementRef.nativeElement.offsetWidth;
    }

    private resetCollapsed(): void {
        this.isCollapsed = false;
        this.contentChildrenBreadcrumbs.forEach(c => (c.collapsed = false));
        this.cdRef.detectChanges();
    }

    onBreadcrumbClicked(breadcrumb: UIBreadcrumbComponent): void {
        breadcrumb.elementRef.nativeElement.click();
        this.breadcrumbsPopover.close();
    }
}
