import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

export class UITableDataSource<T> implements DataSource<T> {
    private dataSubject = new BehaviorSubject<T[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private dataSourceSub: Subscription;
    private _dataLength: number;

    public readonly loading$ = this.loadingSubject.asObservable();
    public readonly data$ = this.dataSubject.asObservable();

    get dataLength(): number {
        return this._dataLength;
    }

    connect(): Observable<T[]> {
        return this.data$;
    }

    disconnect(): void {
        this.dataSubject.complete();
        this.loadingSubject.complete();
        if (this.dataSourceSub) {
            this.dataSourceSub.unsubscribe();
        }
    }

    setData(dataSource: Observable<T[]> | T[]): void {
        if (dataSource instanceof Observable) {
            this.dataSourceSub = dataSource.subscribe(data => this.onDataChange(data));
        } else {
            this.onDataChange(dataSource);
        }
    }

    addData(items: T[]): void {
        const currentData = this.dataSubject.getValue();
        this.onDataChange([...currentData, ...items]);
    }

    private onDataChange(data: T[]): void {
        this._dataLength = data.length;
        this.dataSubject.next(data);
    }
}
