import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'ui-inline-loader',
    templateUrl: 'inline-loader.component.html',
    styleUrls: ['inline-loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.loader]': 'true'
    }
})
export class UIInlineLoaderComponent {
    @Input() color = 'var(--color)';
}
