import { Component, Input } from '@angular/core';
import { UINotificationType } from '../../../types/notification';

@Component({
    selector: 'ui-sticky-notification',
    templateUrl: './sticky-notification.component.html',
    styleUrls: ['./sticky-notification.component.scss']
})
export class UIStickyNotificationComponent {
    @Input() type: UINotificationType;
}
