<div class="layout">
    <ui-header
        [full]="true"
        (logoClick)="router.navigate(['/egg'])">
        <div id="select-theme">
            <ui-select
                [theme]="theme"
                [(selected)]="layout"
                (selectedChange)="updateLayout($event)">
                <ui-option value="default">Default</ui-option>
                <ui-option value="small">Small</ui-option>
                <ui-option value="minimal">Minimal</ui-option>
                <ui-option value="tiny">Tiny</ui-option>
            </ui-select>
            &nbsp;
            <ui-select
                [theme]="theme"
                [(selected)]="theme"
                (selectedChange)="updateTheme($event)">
                <ui-option value="default">Default</ui-option>
                <ui-option value="dark">Dark</ui-option>
            </ui-select>
        </div>

        <ng-content select="topRegion"></ng-content>
    </ui-header>
    <ui-body [full]="true">
        <div class="layout-body">
            <div class="layout-left">
                <ui-menu-side-panel class="page-navigation">
                    <ui-menu-group title="Bannerflow UI">
                        <ui-menu-item
                            title="Overview"
                            path="/overview"></ui-menu-item>
                        <ui-menu-item
                            title="Colors/Variables"
                            path="/variables"></ui-menu-item>
                        <ui-menu-item
                            title="Theme"
                            path="/theme"></ui-menu-item>
                    </ui-menu-group>
                    <hr class="divider" />
                    <component-menu></component-menu>
                </ui-menu-side-panel>
            </div>
            <div class="layout-center">
                <ng-content select="centerRegion"></ng-content>
            </div>
            <!-- <div class="layout-right-panel">
                <ng-content select="rightRegion"></ng-content>
            </div> -->
        </div>
    </ui-body>
</div>
