<div class="paginator-wrapper">
    <div
        class="show-page"
        data-testid="total-pages">
        Show
        <ui-select
            ui-theme="minimal"
            id="interaction-paginator-select"
            class="select"
            data-testid="select-toggle"
            [placeholder]="activePageSizeOption + ''"
            [listMinWidth]="60"
            (selectedChange)="setActivePageSize($event)"
            [(selected)]="activePageSizeOption"
            [width]="'60px'">
            <ui-option
                ui-theme="minimal"
                [attr.data-testid]="'dropdown-options-' + i"
                *ngFor="let page of pageSizeOptions; let i = index"
                [value]="page"
                >{{ page }}
            </ui-option>
        </ui-select>
        {{ textDescription }}
    </div>
    <div class="page-selection">
        <ui-button
            ui-theme="minimal"
            id="interaction-paginator-prev-page"
            data-testid="prev-page"
            [svgIcon]="'arrow-left'"
            (click)="decreasePageSelection()"
            [disabled]="activePage === 1">
        </ui-button>
        <ui-number-input
            class="page-input"
            data-testid="input-page"
            ui-theme="minimal"
            [min]="1"
            [max]="lastPageLabel"
            [autoResize]="true"
            [allowEmpty]="false"
            [value]="activePage"
            [arrowButtons]="false"
            [disabled]="lastPageLabel === 1 || lastPageLabel === 0"
            (valueChange)="setChangedValue($event)">
        </ui-number-input>
        <span data-testid="last-page"> /&nbsp;{{ lastPageLabel ? lastPageLabel : '-' }}</span>
        <ui-button
            ui-theme="minimal"
            id="interaction-paginator-next-page"
            data-testid="next-page"
            [svgIcon]="'arrow-right'"
            (click)="increasePageSelection()"
            [disabled]="activePage === lastPageLabel || lastPageLabel === 0">
        </ui-button>
    </div>
</div>
