import {
    Component,
    Input,
    ChangeDetectionStrategy,
    HostBinding,
    ElementRef,
    Injectable
} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UIBodyRef {
    host: ElementRef;
}

@Component({
    selector: 'ui-body',
    templateUrl: 'body.component.html',
    styleUrls: ['body.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIBodyComponent {
    @Input()
    @HostBinding('class.full')
    full: boolean;

    @Input()
    @HostBinding('class.show-overflow')
    showOverflow: boolean;

    /**
     * Header is hidden, use that space
     */
    @Input()
    @HostBinding('class.no-header')
    noHeader: boolean;

    constructor(
        host: ElementRef,
        private bodyRef: UIBodyRef
    ) {
        this.bodyRef.host = host;
    }
}
