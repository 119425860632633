<span
    *ngIf="icon"
    class="icon">
    <ui-icon [icon]="icon"></ui-icon>
</span>
<span
    *ngIf="svgIcon"
    class="icon">
    <ui-svg-icon [icon]="svgIcon"></ui-svg-icon>
</span>
<span
    *ngIf="text"
    class="text"
    [ngClass]="{ space: (icon || svgIcon) && text }">
    {{ text }}
</span>
