<span
    class="content"
    uiTooltipTrigger="hover"
    [uiTooltip]="elementRef.nativeElement.innerText"
    [uiTooltipOnlyWhenTruncated]="true"
    [ngStyle]="{ 'max-width': truncated ? maxTruncationWidth + 'px' : undefined }">
    <ng-content select="[inContainerView]"></ng-content>
</span>
<ui-svg-icon
    class="separator"
    icon="arrow-right"></ui-svg-icon>

<!-- Create a template for the content of the <ui-breadcrumb> so that we can grab a reference to this
    TemplateRef and render the content in ngFor loop in the <ui-breadcrumbs> -->
<ng-template #template>
    <ng-content></ng-content>
</ng-template>
