import { DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { UIListCellComponent } from '../cell/list-cell.component';

@Component({
    selector: 'ui-list-folder-cell',
    templateUrl: 'list-folder-cell.component.html',
    styleUrls: ['list-folder-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DatePipe, PercentPipe, DecimalPipe],
    host: {
        '[class.cell]': `true`,
        '[class.folder]': `true`,
        '[class.multiline]': `column?.multiline`,
        '[class.align-right]': `column?.align === 'right'`,
        '[class.align-center]': `column?.align === 'center'`,
        '[class.hidden]': `column.hidden`
    }
})
export class UIListFolderCellComponent extends UIListCellComponent {
    @Input() expandable = true;
    @Input() first?: boolean;
    @Input() enableDrillDown = false;
    @Output() nameClick: EventEmitter<MouseEvent> = new EventEmitter();
    @Output() toggleClick: EventEmitter<MouseEvent> = new EventEmitter();

    constructor(
        percentPipe: PercentPipe,
        decimalPipe: DecimalPipe,
        datePipe: DatePipe,
        changeDetectorRef: ChangeDetectorRef
    ) {
        super(percentPipe, decimalPipe, datePipe, changeDetectorRef);
    }

    onNameClick(e?: MouseEvent): void {
        this.nameClick.emit(e);

        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
    }

    onToggleClick(e?: MouseEvent): void {
        if (this.expandable) {
            this.toggleClick.emit(e);
        }

        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
    }

    public isDrillDown(): boolean {
        return this.enableDrillDown;
    }
}
