import { Component } from '@angular/core';
import { UIAlertDialogService } from 'components/dialogs';

@Component({
    selector: 'ui-accordion-docs-preview',
    templateUrl: './accordion.docs.preview.html',
    styleUrls: ['./accordion.docs.preview.scss'],
})
export class UIAccordionDocsPreview {

    constructor(private alertDialogService: UIAlertDialogService) {

    }
    arrayOfAccordions = [
        {
            id : 1,
            title : 'accordion1',
            content : 'html1'
        },
        {
            id : 2,
            title : 'accordion2',
            content : 'html2'
        },
        {
            id : 3,
            title : 'accordion3',
            content : 'html3'
        }
    ];

    wait(): Promise<void> {
        return new Promise<void>((resolve) => setTimeout(resolve, 2000));
    }

    async actionClicked(elem: any): Promise<void> {
        await this.alertDialogService.show({
            headerText: 'This is an action Callback',
            text: 'Action messsage',
            confirmText: 'I accept'
        });
        console.log('Ok');
    }

}
