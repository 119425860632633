import { Component, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'ui-loader',
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.scss'],
    host: {
        '[class.ui-loader]': 'true',
        '[class.ui-inline-loader]': 'inline',
        '[@fadeIn]': 'true',
        '[@fadeOut]': 'true'
    },
    animations: [
        trigger('fadeIn', [
            transition(':enter', [style({ opacity: 0 }), animate('.3s', style({ opacity: 1 }))])
        ]),
        trigger('fadeOut', [
            transition(':leave', [style({ opacity: 1 }), animate('.3s', style({ opacity: 0 }))])
        ])
    ]
})
export class UILoaderComponent {
    @Input() animationsDisabled = false;
    @Input() inline = false;
}
