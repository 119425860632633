import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ui-help-menu',
    templateUrl: 'help-menu.component.html',
    styleUrls: ['help-menu.component.scss']
})
export class UIHelpMenuComponent {
    /**
     * Is intercom widget available in application. True as default.
     */
    @Input() isIntercomAvailable = true;

    /**
     * Show intercom message void event
     */
    @Output() showIntercom = new EventEmitter<void>();

    /**
     * Show intercom news space void event
     */
    @Output() showIntercomNews = new EventEmitter<void>();

    /**
     * Show keyboard shortcuts void event
     */
    @Output() showKeyboardShortcuts = new EventEmitter<void>();

    public openSupportTab(): void {
        window.open('https://support.bannerflow.com', '_blank');
    }

    public emitIntercomNews(): void {
        this.showIntercomNews.emit();
    }

    public emitIntercomMessage(): void {
        this.showIntercom.emit();
    }

    public emitKeyboardShortcuts(): void {
        this.showKeyboardShortcuts.emit();
    }

    get isKeyboardShortcutsObserved(): boolean {
        return this.showKeyboardShortcuts.observed;
    }
}
