import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Icon } from '../../icon/svg-icon/icons';

@Component({
    selector: 'ui-button-group-item',
    templateUrl: './button-group-item.component.html',
    styleUrls: ['./button-group-item.component.scss']
})
export class UIButtonGroupItemComponent {
    @Input() icon: string | undefined;
    @Input() svgIcon: Icon | undefined;
    @Input() text: string | undefined;
    @Input() active = false;
    @Output() activeChange = new EventEmitter();
}
