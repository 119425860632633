<button
    class="button"
    [ngClass]="{
        disabled: disabled,
        invalid: validation?.invalid && (validation?.touched || !validation?.pristine),
        valid: validation?.valid && (validation?.touched || !validation?.pristine),
        'token-field-selected': tokenField && !isEmpty
    }"
    ui-popover-target
    #selectButton
    #target="ui-popover-target"
    tabindex="0"
    (keydown)="onKeyDown($event)"
    (click)="open()">
    @if ((selected || selectedViewText || value) && selected?.toString()) {
        <div
            class="text"
            #text>
            @if (customLabel) {
                <ng-content select="[ui-select-label]"></ng-content>
            }

            @if (!multiSelect) {
                @if (selectables && getIconByType(selected, 'svgIcon')) {
                    <ui-svg-icon [icon]="$any(getIconByType(selected, 'svgIcon')!)"></ui-svg-icon>
                }
                @if (
                    selectables &&
                    getIconByType(selected, 'icon') &&
                    !getIconByType(selected, 'svgIcon')
                ) {
                    <ui-icon
                        class="icon"
                        [icon]="getIconByType(selected, 'icon')"></ui-icon>
                }
                @if (getIconByType(selected, 'image')) {
                    <ui-image
                        class="selected-image"
                        [image]="getIconByType(selected, 'image')"
                        [bordered]="isItemBordered(selected)"></ui-image>
                }
                @if (getIconByType(selected, 'flag')) {
                    <ui-flag
                        class="flag"
                        size="small"
                        [culture]="getIconByType(selected, 'flag')"></ui-flag>
                }
                @if (!customLabel) {
                    <span class="text-wrapper">
                        {{ selectedViewText }}
                    </span>
                }
            }

            @if (multiSelect && !tokenField) {
                @if (!customLabel && value?.length) {
                    <span
                        class="selected-summary-data"
                        [attr.data-testid]="'selected-item-with-flag'">
                        @for (item of value; track $index; let last = $last; let index = $index) {
                            @if (getIconByType(item, 'icon') && index < displaySelectedLimit) {
                                <ui-icon
                                    class="icon"
                                    [icon]="getIconByType(item, 'icon')"></ui-icon>
                            }
                            @if (getIconByType(item, 'image') && index < displaySelectedLimit) {
                                <ui-image
                                    class="image"
                                    [image]="getIconByType(item, 'image')"
                                    [bordered]="isItemBordered(selected)"></ui-image>
                            }
                            @if (getIconByType(item, 'flag') && index < displaySelectedLimit) {
                                <ui-flag
                                    class="flag"
                                    size="small"
                                    [culture]="getIconByType(item, 'flag')"></ui-flag>
                            }

                            @if (value.length === 1) {
                                <span class="selected-summary-value">
                                    <ng-container>
                                        {{ getItemTextByValue(item) }}{{ last ? '' : ',' }}
                                        <ui-icon
                                            icon="remove"
                                            class="clear"
                                            (click)="deselectAll()"
                                            ui-prevent-click></ui-icon>
                                    </ng-container>
                                </span>
                            }
                        }
                    </span>
                }
                @if (value.length > 1) {
                    <span
                        class="selected-calculations"
                        [attr.data-testid]="'selected-calculated-items'">
                        {{
                            value.length === 1
                                ? getItemTextByValue(value[0])
                                : value.length + ' of ' + selectables.length + ' selected'
                        }}
                        <ui-icon
                            icon="remove"
                            class="clear"
                            (click)="deselectAll()"
                            ui-prevent-click></ui-icon>
                    </span>
                }
            }

            @if (multiSelect && tokenField) {
                @if (!customLabel && value?.length) {
                    <span class="token-field-container">
                        @for (item of value; track $index; let last = $last) {
                            <span class="token-field">
                                @if (getIconByType(item, 'icon'); as icon) {
                                    <ui-icon
                                        class="icon"
                                        [icon]="icon"></ui-icon>
                                }
                                @if (getIconByType(item, 'flag'); as flag) {
                                    <ui-flag
                                        class="flag"
                                        [culture]="flag"></ui-flag>
                                }

                                @if (customTokenField) {
                                    <ng-container
                                        *ngTemplateOutlet="
                                            customTokenField;
                                            context: { $implicit: value, value: item }
                                        ">
                                    </ng-container>
                                } @else {
                                    <ng-container>
                                        {{ getItemTextByValue(item) }}
                                    </ng-container>
                                }

                                <ui-svg-icon
                                    class="remove"
                                    icon="cross-small"
                                    (click)="deselect(item)"
                                    ui-prevent-click>
                                </ui-svg-icon>
                            </span>
                        }
                    </span>
                }
            }
        </div>
    }
    @if (!selected?.toString()) {
        <div class="text placeholder">
            @if (placeholderIcon) {
                <ui-icon [icon]="placeholderIcon"></ui-icon>
            }
            <span class="text-wrapper">{{ placeholder }}</span>
        </div>
    }
    <div class="arrow-wrapper">
        <ui-svg-icon
            [icon]="popoverOpen ? 'arrow-up' : 'arrow-down'"
            class="arrow"
            [attr.data-testid]="'select-dropdown-arrow'"></ui-svg-icon>
    </div>
</button>
<ui-popover
    #popover="ui-popover"
    (close)="onPopoverClose()"
    [config]="{
        panelClass: 'no-padding',
        useTargetWidth: useTargetWidth,
        minWidth: listMinWidth,
        maxHeight: maxHeight,
        offset: { x: 0, y: -1 },
        theme: theme,
        width: width
    }">
    <ng-template ui-popover-template>
        @if (searchable) {
            <input
                id="interaction-search-input"
                class="search"
                #input
                type="text"
                (focus)="clearPreview()"
                autocomplete="off"
                [placeholder]="searchPlaceholder"
                (keydown)="onKeyDownSearch($event)"
                (keyup)="onKeyUp()" />
        }

        @if (showSelectAll && multiSelect) {
            <ui-option
                class="selectable"
                (click)="selectAllList()">
                {{ value && value.length === selectablesLength ? 'Deselect all' : 'Select all' }}
            </ui-option>
        }
        <ng-content></ng-content>
    </ng-template>
</ui-popover>
