import { Component, Input } from '@angular/core';

@Component({
    selector: 'ui-dragpreview',
    templateUrl: './dragpreview.component.html',
    styleUrls: ['./dragpreview.component.scss']
})
export class DragpreviewComponent {
    @Input() text = 'Very nice text';
}
