<div class="content">
    <ui-logo
        *ngIf="showLogo"
        class="logo logo-small"
        [small]="true"
        (click)="onLogoClick($event)">
    </ui-logo>
    <ui-logo
        *ngIf="showLogo"
        class="logo logo-large"
        (click)="onLogoClick($event)">
    </ui-logo>
    <ng-content></ng-content>
</div>
