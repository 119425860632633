import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    ElementRef,
    Renderer2,
    OnDestroy,
    ViewChild,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { UIGlobalEvent } from '../../../services/global-event';
import { CustomValueAccessorDirective, customValueProvider } from '../../../utils/customValueAccessor';
import { UntypedFormControl } from '@angular/forms';
import { UIDebounce } from '../../../decorators/debounce/debounce.decorator';
import { formatNumber } from '@angular/common';

@Component({
    selector: 'ui-number-input',
    templateUrl: './number-input.component.html',
    styleUrls: ['./number-input.component.scss'],
    host: {
        '[class.input]': 'true',
        '[class.discrete]': 'discrete',
        '[class.disabled]': 'disabled'
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [customValueProvider(UINumberInputComponent)]
})
export class UINumberInputComponent
    extends CustomValueAccessorDirective<number>
    implements OnInit, OnDestroy
{
    /**
     * ID.
     */
    @Input() id?: string = Math.random().toString(36).substring(2, 9);

    /**
     * Step amount.
     */
    @Input() step = 1;
    /**
     * Step amount.
     */
    @Input() min?: number;
    /**
     * Step amount.
     */
    @Input() max?: number;

    /**
     * When min value show this string as placeholder
     */
    @Input() minLabel?: string;

    /**
     * When max value show this string as placeholder
     */
    @Input() maxLabel?: string;

    /**
     * Placeholder text.
     */
    @Input() placeholder: string | undefined = '';

    /**
     * Label
     */
    @Input() label?: string;

    /**
     * Unit label, shown when not hovering on the component
     * where the arrow buttons are placed
     */
    @Input() unitLabel?: string;

    /**
     * Disable arrow button up
     */
    @Input() btnUpDisabled = false;

    /**
     * Disable arrow button down
     */
    @Input() btnDownDisabled = false;

    /**
     * Show arrow buttons to the right for
     * incrementing/decrementing number value
     */
    @Input() arrowButtons = true;

    /**
     * Should the number input be allowed to
     * have an empty value
     */
    @Input() allowEmpty = true;

    /**
     * Multiplier, the rendered value in the input box will
     * be multiplied with defined multiplier. Eg. 100 for percentages.
     */
    @Input() multiplier = 1;

    /**
     * Tabindex
     */
    @Input() tabindex = '';

    /**
     * Autofocus automatically sets focus to the input
     * when the input component is initialized
     */
    @Input() autofocus = false;

    /**
     * Validation
     */
    @Input() validation?: UntypedFormControl;

    /**
     * Number format. See https://angular.io/api/common/DecimalPipe
     */
    @Input() format?: string;

    /**
     * Discrete mode
     */
    @Input() discrete: boolean;

    /**
     * Disable native undo
     */
    @Input() disableUndo: boolean;

    /**
     * Emit valueChange after user have stopped typing
     */
    @Input() keyboardEmit = false;

    /**
     * Submit event.
     */
    @Output() submit = new EventEmitter<void>();

    /**
     * Event emitter that gets called when a mouse up event occurs on the arrow buttons.
     */
    @Output() mouseUp = new EventEmitter<void>();

    /**
     * Cancel event.
     */
    @Output() cancel = new EventEmitter<void>();

    /**
     * Focus event.
     */
    @Output('focus') _focus = new EventEmitter<void>();

    /**
     * Blur event.
     */
    @Output() blur = new EventEmitter<void>();

    /**
     * Change event.
     */
    @Output() valueChange = new EventEmitter<number>();

    /**
     * Undo event.
     */
    @Output() undo = new EventEmitter<void>();

    /**
     * Redo event.
     */
    @Output() redo = new EventEmitter<void>();

    /**
     * Select all text when input gets focus
     */
    @Input() selectTextOnFocus: boolean;

    /**
     * Handle step functionaliy. Notice, this won't fire submit event when set.
     */
    @Input() onStep: (step: number) => void;

    /** autoResize if set to true size input attribute will match value length */
    @Input() autoResize = false;

    textSize = 1;

    /**
     * Reference to the input element
     */
    @ViewChild('valueContainer', { static: true }) valueContainer: ElementRef;

    get currentPlaceholder(): string {
        if (this.maxLabel && this.value === this.max) {
            return this.maxLabel;
        }

        if (this.minLabel && this.value === this.min) {
            return this.minLabel;
        }

        if (this.placeholder) {
            return this.placeholder;
        }

        return this.allowEmpty === false ? `${this.min || 0}` : '';
    }

    /**
     * Value to show in input field.
     * When using max min labels show them when reaching limits
     * And only when not having focus
     */
    get inputFieldValue(): string {
        return this.getInputFieldStringFromValue(this.value);
    }

    /**
     * Don't do anything when setting this value.
     */
    set inputFieldValue(val: string) {}

    hasFocus = false;
    lastValidValue: number | undefined;

    private __intervalRef: any;
    private __timeoutRef: any;

    constructor(
        element: ElementRef<HTMLInputElement>,
        renderer: Renderer2,
        private changeDetectorRef: ChangeDetectorRef,
        private globalEvent: UIGlobalEvent
    ) {
        super(element, renderer);

        // In case CustomValueAccessor is used and it will fail
        // having Date or any other kind of Object use this.
        // this.value = '';
    }

    ngOnInit(): void {
        this.globalEvent.on('theme-change', () => {});

        if (this.autofocus) {
            setTimeout(() => {
                this.focus();
            });
        }
        this.writeValue(this.value, false);
        this.lastValidValue = this.value!;
    }

    /**
     * Destroy component
     */
    ngOnDestroy(): void {
        this.globalEvent.off('theme-change', () => {});
    }

    /**
     * Step value up and down
     */
    stepValue(step: number, auto: boolean = false): void {
        const sanitizedValue = this.sanitizeValue(this.value!);
        let newValue = this.truncateValue((sanitizedValue ? sanitizedValue : 0) + step);
        this.writeValue(newValue);
        if (auto) {
            this.__timeoutRef = setTimeout(() => {
                this.__intervalRef = setInterval(() => {
                    newValue = this.truncateValue(this.value! + step);
                    this.writeValue(newValue);
                    if (this.onStep) {
                        this.onStep(step);
                        return;
                    }
                    this.submit.emit();
                }, 65);
            }, 350);
            window.document.addEventListener('mouseup', this.clearStepTimeouts);
        }
        if (this.onStep) {
            this.onStep(step);
            return;
        }
        this.submit.emit();
    }

    /**
     * Write value in input and emit value changes
     * @param value
     */
    writeValue(value: any, emit: boolean = true): void {
        const element = this.valueContainer.nativeElement;

        if (element) {
            const newValue = this.sanitizeValue(value);
            const newInputText = this.getInputFieldStringFromValue(newValue);
            const textChanged = newInputText !== element.value;
            const valueChanged = newValue !== this.value;

            if (valueChanged || textChanged) {
                this.lastValidValue = newValue;
                this.value = newValue;
                element.value = newInputText;
                this.resizeInput(element.value.length);
                this.changeDetectorRef.detectChanges();

                if (emit) {
                    this.onChange(newValue);
                    this.valueChange.emit(newValue);
                }
            }
        }
    }

    /**
     * Clears the current value
     */
    clear(): void {
        this.writeValue('');
    }

    resizeInput(textLength: number): void {
        this.textSize = textLength <= 0 ? 1 : textLength;
    }

    /**
     * When user enter values with keyboard (when input is focused)
     */
    onKeyDown(event: KeyboardEvent): any {
        event.stopPropagation();
        const key = event.key;

        if (key === 'Enter') {
            this.writeValue(this.valueContainer.nativeElement.value);
            this.submit.emit();
            this.valueContainer.nativeElement.blur();
            return;
        }

        if (key === 'ArrowUp') {
            this.stepValue(event.shiftKey ? this.step * 10 : this.step);
            event.preventDefault();
            return;
        }

        if (key === 'ArrowDown') {
            this.stepValue(-(event.shiftKey ? this.step * 10 : this.step));
            event.preventDefault();
            return;
        }

        // Notify the change after delay
        if (this.keyboardEmit) {
            this.keyboardEventChangeEmit();
        }

        if (this.autoResize) {
            const valueLength = this.valueContainer.nativeElement.value.length;
            const textLength = key === 'Backspace' ? valueLength - 1 : valueLength;
            this.resizeInput(textLength);
        }

        const differFromEffectiveText = this.lastValidValue !== this.value;
        const defmodKey = navigator.userAgent.includes('Mac OS X') ? event.metaKey : event.ctrlKey;
        const arrowKey = key === 'ArrowLeft' || key === 'ArrowRight';
        const specialKey = ['Backspace', 'Delete', 'Tab', 'Escape', 'Enter', '.', '-'].find(
            k => k === key
        );

        if (defmodKey || arrowKey || specialKey) {
            if (defmodKey && this.disableUndo) {
                let isUndoRedo = false;
                if (key.toUpperCase() === 'Z') {
                    if (event.shiftKey) {
                        this.redo.emit();
                    } else {
                        if (differFromEffectiveText) {
                            this.value = this.lastValidValue;
                            event.preventDefault();
                            return;
                        }
                        this.undo.emit();
                    }
                    isUndoRedo = true;
                }
                if (isUndoRedo) {
                    event.preventDefault();
                    setTimeout(() => (this.lastValidValue = this.value!));
                }
            }

            return;
        }

        // Ensure that it is a number and stop the keypress
        if (isNaN(Number(key))) {
            event.preventDefault();
            return false;
        }
    }

    /**
     * Called in order to clear the timeouts created by the stepValue function
     */
    private clearStepTimeouts = () => {
        window.document.removeEventListener('mouseup', this.clearStepTimeouts);

        clearTimeout(this.__timeoutRef);
        clearInterval(this.__intervalRef);
    };

    /**
     * When focus leaves input field.
     */
    onBlur(): void {
        this.hasFocus = false;
        let value = this.valueContainer.nativeElement.value;

        // When using max/min labels the field can be empty when leaving, use latest value in those cases.
        if (
            !value &&
            ((this.maxLabel && this.value === this.max) || (this.minLabel && this.value === this.min))
        ) {
            value = this.value;
        }
        this.writeValue(value);
        this.blur.emit();
    }

    /**
     * When input get focused
     */
    onFocus(): void {
        this.hasFocus = true;
        const element = this.valueContainer.nativeElement;

        // When using max/min labels the field can be empty when focusing, swap to real value in those cases.
        if (
            !element.value &&
            ((this.maxLabel && this.value === this.max) || (this.minLabel && this.value === this.min))
        ) {
            element.value = this.value;
        }

        if (this.selectTextOnFocus) {
            setTimeout(() => {
                this.valueContainer.nativeElement.select();
            });
        }
        this._focus.emit();
    }

    /**
     * Emits a mouseUp event when a mouseUp event occurs in the arrow buttons.
     */
    onMouseUp(): void {
        this.mouseUp.emit();
    }

    /**
     * Set focus to the input field
     * @param select Select the text when focusing
     */
    focus(select: boolean = false): void {
        (this.valueContainer.nativeElement as HTMLInputElement).focus();
        if (select) {
            (this.valueContainer.nativeElement as HTMLInputElement).select();
        }
    }

    /**
     * Make sure input is a number, keep value between min and max and round value.
     * @param value
     */
    sanitizeValue(value: any): number | undefined {
        let sanitizedValue;

        const isEmpty = value === '' || value === null || value === undefined;

        if (this.allowEmpty && isEmpty) {
            return undefined;
        } else {
            // Use last valid value for empty values and invalid values such as 'a', NaN etc
            sanitizedValue = isNaN(value) ? this.lastValidValue : Number(value);
            sanitizedValue = this.truncateValue(sanitizedValue ? sanitizedValue : 0);
            sanitizedValue =
                sanitizedValue % 1 === 0 ? sanitizedValue : parseFloat(sanitizedValue.toFixed(2));
        }

        return sanitizedValue;
    }

    private getInputFieldStringFromValue(value: number | undefined): string {
        if (typeof value === 'number' && (this.maxLabel || this.minLabel) && !this.hasFocus) {
            const isGreaterThanMax = this.maxLabel && typeof this.max === 'number' && value >= this.max;
            const isSmallerThanMin = this.minLabel && typeof this.min === 'number' && value <= this.min;

            if (isGreaterThanMax || isSmallerThanMin) {
                return '';
            }
        }

        if (this.allowEmpty && (typeof value !== 'number' || isNaN(value))) {
            return '';
        }

        return this.format ? formatNumber(value || 0, 'en-gb', this.format) : (value || 0).toString();
    }

    /**
     * Make sure value is kept between max and min
     */
    private truncateValue(number: number): number {
        if (typeof this.min !== 'undefined' && number < this.min) {
            return this.min;
        }
        if (typeof this.max !== 'undefined' && number > this.max) {
            return this.max;
        }
        return number;
    }

    /**
     * Trigger change event.
     */
    @UIDebounce(300)
    private keyboardEventChangeEmit(): void {
        const val = this.valueContainer.nativeElement.value;
        if (!val && !this.allowEmpty) {
            return;
        }
        const value = this.sanitizeValue(val);
        if (typeof this.min !== 'undefined' && value === this.min) {
            return;
        }
        if (typeof value === 'number' && value !== this.value) {
            this.writeValue(value, true);
        }
    }
}
