


<h3> Accordion </h3>
<ui-accordion [allowMultipleExpanded]="false">
    <!-- Dayparting -->
    <ui-accordion-item
        *ngFor="let elem of arrayOfAccordions"
        icon="schedule"
        header="{{elem.title}}"
        [expanded]="false">
        {{ elem.content }}
    </ui-accordion-item>
</ui-accordion>

<h3> With Action Icon </h3>
<ui-accordion [allowMultipleExpanded]="false">
    <!-- Dayparting -->
    <ui-accordion-item
        *ngFor="let elem of arrayOfAccordions"
        icon="schedule"
        actionIcon="delete"
        (actionCallback)="actionClicked(elem)"
        header="{{elem.title}}"
        [expanded]="false">
        {{ elem.content }}
    </ui-accordion-item>
</ui-accordion>

<h3> With Custom header content </h3>
<ui-accordion [allowMultipleExpanded]="false">
    <ui-accordion-item
        *ngFor="let elem of arrayOfAccordions"
        [expanded]="false">
        <ng-template ui-accordion-header-template>
            <div class="custom">
                <ui-flag culture="se" size="tiny"></ui-flag> &nbsp;
                <span class="text">Custom header</span>
                <ui-icon icon="loop"></ui-icon>
                <span class="text">template</span>
            </div>
        </ng-template>
        {{ elem.content }}
    </ui-accordion-item>
</ui-accordion>

<h3> With small theme </h3>
<ui-accordion [allowMultipleExpanded]="false" ui-theme="small">
    <!-- Dayparting -->
    <ui-accordion-item
        *ngFor="let elem of arrayOfAccordions"
        icon="schedule"
        actionIcon="delete"
        (actionCallback)="actionClicked(elem)"
        header="{{elem.title}}"
        [expanded]="false">
        <ui-button text="please click me"></ui-button>
    </ui-accordion-item>
</ui-accordion>

<h3> With minimal theme </h3>
<ui-accordion [allowMultipleExpanded]="false" ui-theme="minimal">
    <!-- Dayparting -->
    <ui-accordion-item
        *ngFor="let elem of arrayOfAccordions"
        icon="schedule"
        actionIcon="delete"
        (actionCallback)="actionClicked(elem)"
        header="{{elem.title}}"
        [expanded]="false">
        <ui-button text="please click me"></ui-button>
    </ui-accordion-item>
</ui-accordion>


<h3> With tiny theme with Custom header content </h3>
<ui-accordion [allowMultipleExpanded]="false" ui-theme="tiny">
    <ui-accordion-item
        *ngFor="let elem of arrayOfAccordions"
        [expanded]="false"
        >
        <ng-template ui-accordion-header-template>
            <div class="custom">
                <ui-flag culture="se" size="tiny"></ui-flag> &nbsp;
                <span class="text">Custom header</span>
                <ui-icon icon="loop"></ui-icon>
                <span class="text">template</span>
            </div>
        </ng-template>
        {{ elem.content }}
    </ui-accordion-item>
</ui-accordion>

<h3> With leftSideCaret set to true </h3>
<ui-accordion [allowMultipleExpanded]="false">
    <ui-accordion-item
        *ngFor="let elem of arrayOfAccordions"
        [expanded]="false"
        [leftSideCaret]="true"
        [isContentAnimated]="false"
        >
        <ng-template ui-accordion-header-template>
          <div class="header-container">
              <div class="language left-side-caret-content-example">
                  <ui-flag
                      [culture]="'en-gb'"
                      size="tiny"></ui-flag>
                  <div>some name</div>
              </div>
              <div>some other name</div>
              <div class="ad-status">some status</div>
          </div>
        </ng-template>
        {{ elem.content }}
    </ui-accordion-item>
</ui-accordion>

