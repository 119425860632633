import { Component } from '@angular/core';

@Component({
    selector: 'ui-flag-docs-preview',
    templateUrl: './flag.docs.preview.html',
    styleUrls: ['./flag.docs.preview.scss'],
})
export class UIFlagDocsPreview {

}
