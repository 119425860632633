import { TemplateRef } from '@angular/core';
import { IUIPopoverConfig } from '../../../types/popover';

export interface IUIPopover {
    popoverTemplate: TemplateRef<any>;
}

export const UIPOPOVER_DEFAULT_CONFIG: IUIPopoverConfig = {
    width: 200,
    maxWidth: '900px',
    useTargetWidth: false,
    backdropClickClose: true,
    escKeyClose: true,
    hasBackdrop: true,
    closeButton: true,
    backdropClass: 'ui-popover-backdrop',
    panelClass: 'ui-popover-panel',
    openOnHover: false,
    offset: { x: 0, y: 0 },
    positions: [
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
        },
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
        },
        {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom'
        }
    ]
};
