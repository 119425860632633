import {
    EventEmitter,
    ElementRef,
    OnInit,
    Directive,
    Input,
    Output,
    OnChanges,
    OnDestroy
} from '@angular/core';

/**
 * Use this to get event when a directive is scrolled into view and leave view.
 * Based on IntersectionObserver.
 * TODO: Add polyfill
 */
@Directive({ selector: '[ui-in-view]' })
export class UIInViewDirective implements OnInit, OnChanges, OnDestroy {
    /**
     * Threshold when to trigger enter/leave events.
     * 0.3 results in event triggering when over 30% of the element is/was visible to trigger event.
     * Pass false to turn listeners off
     */
    @Input('ui-in-view') inView: string | number | boolean;

    /**
     * Will emitted when something is drageged over this zone
     */
    @Output('ui-in-view-enter') inViewEnter: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Will be emitted when something is dragged out from this zone
     */
    @Output('ui-in-view-leave') inViewLeave: EventEmitter<void> = new EventEmitter<void>();

    private element: Element;
    private observer?: IntersectionObserver;

    constructor(private elementRef: ElementRef) {}

    /**
     * Init directive
     */
    ngOnInit(): void {
        this.init();
    }

    /**
     * When input is changed
     */
    ngOnChanges(): void {
        this.init();
    }

    /**
     * Initialize listening
     */
    private init(): void {
        // Make sure this is reset
        this.destroy();

        // Turn observer off from outside
        if (this.inView === false) {
            return;
        }

        // Browser does not supprt IntersectionObserver
        if (!IntersectionObserver) {
            throw new Error(
                'IntersectionObserver is not available. Make sure polyfill is available to support this browser.'
            );
        }

        this.element = this.elementRef.nativeElement;

        const threshold: number = parseFloat(this.inView as string) || 0;

        // TODO: Add polyfill
        this.observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                // Enter
                if (entries[0] && entries[0].intersectionRatio > threshold) {
                    this.inViewEnter.emit();
                }
                // Leave
                else {
                    this.inViewLeave.emit();
                }
            },
            {
                threshold,
                root: this.getRoot()
            }
        );
        this.observer.observe(this.element);
    }

    /**
     * Get root where scroll is triggered. App using UIBody should use that tag instead.
     */
    private getRoot(): Element | null {
        return document.querySelector('ui-body .scroll-wrapper');
    }

    private destroy(): void {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = undefined;
        }
    }

    ngOnDestroy(): void {
        this.destroy();
    }
}
