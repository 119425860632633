import { Component } from '@angular/core';
import { Icon } from '../../../icon/svg-icon/icons';

@Component({
    selector: 'ui-select-docs-preview',
    templateUrl: './select.docs.preview.html',
    styleUrls: ['./select.docs.preview.scss'],
})
export class UISelectDocsPreviewComponent {

    multiSelectToken = [
        { value: 'Bannerflow', id: 0 },
    { value: 'Html', id: 1 },
    { value: 'Image', id: 2},
    { value: 'B2 Banners', id: 3},
    { value: 'Studio Banners', id: 4},
    { value: 'Item5', id: 5},
    { value: 'Item6', id: 6},
    { value: 'Item7', id: 7},
    { value: 'Item8', id: 8},
    { value: 'Item9', id: 9},
    { value: 'Item10', id: 10},
    { value: 'Item11', id: 11},
    { value: 'Item12', id: 12},
    { value: 'Item13', id: 13},
    { value: 'Item14', id: 14, disabled: true },
    { value: 'Item15', id: 15},
    { value: 'Item16', id: 16},
    { value: 'Item17', id: 17},
    { value: 'Item18', id: 18},
    { value: 'Item19', id: 19},
    { value: 'Item20', id: 20},
    { value: 'Item21', id: 21},
    { value: 'Item22', id: 22},
    { value: 'Item23', id: 23},
    { value: 'Item24', id: 24},
    { value: 'Item25', id: 25},
    { value: 'Item26', id: 26}];

    selectedMutliSelectToken: any[] = [this.multiSelectToken[0], this.multiSelectToken[1]];

    multiSelectValueWithFlags = [{ language: 'Arabic', id: 0, culuture: 'ar' },
    { language: 'English', id: 1, culuture: 'se' },
    { language: 'French', id: 2, culuture: 'gr' },
    { language: 'German', id: 3, culuture: 'eg' },
    { language: 'German', id: 4, culuture: 'eg' },
    { language: 'German', id: 5, culuture: 'eg' },
    { language: 'German', id: 6, culuture: 'eg' },
    { language: 'German', id: 7, culuture: 'eg' },
    { language: 'German', id: 8, culuture: 'eg' },
    { language: 'German', id: 9, culuture: 'eg' },
    { language: 'German', id: 10, culuture: 'eg' }];

    selectedMutliSelectWithFlags: any[] = [this.multiSelectValueWithFlags[0], this.multiSelectValueWithFlags[1]];

    multiSelectValueWithImages = [{ name: 'Hello', id: 0, image: 'https://bannerflowsandbox.blob.core.windows.net:443/bf-content/logo/brand/bannerflow-enterprise/5a6f35c2952dd74cecfe35d8/5a02f7a98866581878e53695.png' },
    { name: 'Bannerflow', id: 1, image: 'https://bannerflowsandbox.blob.core.windows.net:443/bf-content/logo/brand/bannerflow-enterprise/5508363c3b6ee30810ca29d6/05-2016---giles-coren.jpg' },
    { name: 'Haj', id: 2, image: 'https://bannerflowsandbox.blob.core.windows.net:443/bf-content/logo/brand/bannerflow-enterprise/5a9d13f7402a9c1c74eca37d/siam-lilacpoint.jpg' },
    { name: 'Bannerflow World', id: 3, image: '' },
    { name: 'Haj', id: 4, image: 'https://bannerflowsandbox.blob.core.windows.net:443/bf-content/logo/brand/bannerflow-enterprise/5a6f35c2952dd74cecfe35d8/5a02f7a98866581878e53695.png' },
    { name: 'Bannerflow2', id: 5, image: 'https://bannerflowsandbox.blob.core.windows.net:443/bf-content/logo/brand/bannerflow-enterprise/5a6f35c2952dd74cecfe35d8/5a02f7a98866581878e53695.png' },
    { name: 'Studio', id: 6, image: 'https://bannerflowsandbox.blob.core.windows.net:443/bf-content/logo/brand/bannerflow-enterprise/5a6f35c2952dd74cecfe35d8/5a02f7a98866581878e53695.png' },
    { name: 'Cache', id: 7, image: 'https://bannerflowsandbox.blob.core.windows.net:443/bf-content/logo/brand/bannerflow-enterprise/5a6f35c2952dd74cecfe35d8/5a02f7a98866581878e53695.png' },
    { name: 'DataDog', id: 8, image: 'https://bannerflowsandbox.blob.core.windows.net:443/bf-content/logo/brand/bannerflow-enterprise/5a6f35c2952dd74cecfe35d8/5a02f7a98866581878e53695.png' },
    { name: 'Haj3', id: 9, image: 'https://bannerflowsandbox.blob.core.windows.net:443/bf-content/logo/brand/bannerflow-enterprise/5a6f35c2952dd74cecfe35d8/5a02f7a98866581878e53695.png' },
    { name: 'Haj4', id: 10, image: 'https://bannerflowsandbox.blob.core.windows.net:443/bf-content/logo/brand/bannerflow-enterprise/5a6f35c2952dd74cecfe35d8/5a02f7a98866581878e53695.png' },
    { name: 'Haj5', id: 11, image: 'https://bannerflowsandbox.blob.core.windows.net:443/bf-content/logo/brand/bannerflow-enterprise/5a6f35c2952dd74cecfe35d8/5a02f7a98866581878e53695.png' },
    { name: 'Haj6', id: 12, image: 'https://bannerflowsandbox.blob.core.windows.net:443/bf-content/logo/brand/bannerflow-enterprise/5a6f35c2952dd74cecfe35d8/5a02f7a98866581878e53695.png' },
    { name: 'Haj7', id: 13, image: 'https://bannerflowsandbox.blob.core.windows.net:443/bf-content/logo/brand/bannerflow-enterprise/5a6f35c2952dd74cecfe35d8/5a02f7a98866581878e53695.png' },
    { name: 'Haj22', id: 14, image: 'https://bannerflowsandbox.blob.core.windows.net:443/bf-content/logo/brand/bannerflow-enterprise/5a6f35c2952dd74cecfe35d8/5a02f7a98866581878e53695.png' },
    { name: 'Haj33', id: 15, image: 'https://bannerflowsandbox.blob.core.windows.net:443/bf-content/logo/brand/bannerflow-enterprise/5a6f35c2952dd74cecfe35d8/5a02f7a98866581878e53695.png' },
    { name: 'Haj44', id: 16, image: 'https://bannerflowsandbox.blob.core.windows.net:443/bf-content/logo/brand/bannerflow-enterprise/5a6f35c2952dd74cecfe35d8/5a02f7a98866581878e53695.png' }];

    singleSelectedMutliSelectWithImages: any = [this.multiSelectValueWithImages[0]];
    singleSelectedImage: any = this.multiSelectValueWithImages[0];

    multiSelectValueWithIcons = [{ name: 'Custom', id: 0, icon: '' },
    { name: 'Hello', id: 0, icon: 'banner-set' },
    { name: 'Bannerflow', id: 1, icon: 'landingpage' },
    { name: 'Haj', id: 2, icon: 'schedule' },
    { name: 'Bannerflow World', id: 3, icon: 'analytics' },
    { name: 'Haj', id: 4, icon: '' }];

    multiSelectValueWithSvgIcons: {name: string, id: number, svgIcon: Icon | ''}[] = [{ name: 'Custom', id: 0, svgIcon: '' },
    { name: 'Campaigns', id: 0, svgIcon: 'campaigns-s' },
    { name: 'Download', id: 1, svgIcon: 'download' },
    { name: 'Align', id: 2, svgIcon: 'align-top' },
    { name: 'Uppercase', id: 3, svgIcon: 'uppercase' },
    { name: 'Gif frame', id: 4, svgIcon: 'gif-frame' }];


    singleSelectedMultiSelectWithIcons: any[];
    singleSelectedSelectWithIcons: any;
    singleSelectedSelectWithSvgIcons: any;
    selectedMultiSelectWithFlagsAndToken: any;
    selectedMultiSelectWithFlags: any;
    customLabelNotSelected: any;

    multiSelectValue = ['Hello World', 'Haj'];
    searchvalue = 'Hello World';
    value = 'World';
    preview: string;

    constructor() { }

    imageValueChanged(_: any) {
        // Do something
    }
    flagsValueChanged(_: any) {
        // Do something
    }

    valueChanged(event: Event) {
        // eslint-disable-next-line no-console
        console.log(event);
    }

    previewChanged(preview: string) {
        this.preview = preview;
    }
}
