import { Input, Component, Output, EventEmitter } from '@angular/core';
import { Icon } from '../../icon/svg-icon/icons';
export * from './button-group-item.component';

export interface UIButtonGroupOption {
    id: string;
    icon?: string;
    svgIcon?: Icon;
    text?: string;
    disabled?: boolean;
    value: string;
}

@Component({
    selector: 'ui-button-group',
    templateUrl: './button-group.component.html',
    styleUrls: ['./button-group.component.scss'],
    host: {
        '[class.input]': 'true'
    }
})
export class UIButtonGroupComponent {
    /**
     * Options array, needs to be the type UIButtonToggleOption
     */
    @Input('options') options: UIButtonGroupOption[];

    /**
     * The default selected value, that matches the value in the options. If null, nothing will be selected.
     */
    @Input('value') value: string;

    /**
     * Use primary selection style of the button.
     */
    @Input() primarySelectionStyle?: boolean;

    /**
     * Value of whatever is selected.
     */
    @Output('valueChange') valueChange = new EventEmitter();

    updateValue(value: string): void {
        this.value = value;
        this.valueChange.emit(value);
    }
}
