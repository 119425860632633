<h3>Plain radios (without groupId)</h3>
<ui-radio
    label="On"
    (select)="setOnState(true)"
    [disabled]="disabled"
    [selected]="on">
</ui-radio>
<ui-radio
    label="Off"
    (select)="setOnState(false)"
    [disabled]="disabled"
    [selected]="!on">
</ui-radio>
<p>Variable 'on' is: {{ on }}</p>
<ui-button text="{{ disabled ? 'Enable' : 'Disable'}}" (click)="toggleDisabledState()"></ui-button>

<h3>Plain radios (with groupId)</h3>
<ui-radio
    label="On"
    groupId="id1"
    [(selected)]="on2">
</ui-radio>
<ui-radio
    label="Off"
    groupId="id1">
</ui-radio>
<p>Variable 'on2' is: {{ on2 }}</p>


<h3>Radios in selectable list</h3>
<ui-selectable-list
    #list
    [(selected)]="selectedOption"
    [multiSelect]="false">
    <ui-radio
        *ngFor="let option of radioOptions"
        [label]="option.label"
        [value]="option">
    </ui-radio>
</ui-selectable-list>
<p>
    Variable 'selectedOption.label' is: {{ selectedOption?.label }}
</p>
