<ng-container *ngIf="column.headerTemplate">
    <ng-container
        *ngTemplateOutlet="column.headerTemplate; context: { $implicit: column }"></ng-container>
</ng-container>
<ng-container *ngIf="!column.headerTemplate && column.name">
    {{ column.name }}
    <ui-icon
        *ngIf="column.description"
        class="description-icon"
        icon="help-tooltip">
    </ui-icon>
    <ui-svg-icon
        class="sort-icon"
        icon="arrow-down">
    </ui-svg-icon>
</ng-container>
