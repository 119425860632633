<ui-button-group-item
    *ngFor="let option of options"
    [id]="option.id"
    [icon]="option.icon"
    [svgIcon]="option.svgIcon"
    [text]="option.text"
    (click)="updateValue(option.value)"
    [ngClass]="primarySelectionStyle ? 'primary-selection' : ''"
    [class.disabled]="option.disabled"
    [class.active]="option.value === value">
</ui-button-group-item>
