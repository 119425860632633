<h3>Primary</h3>
<ui-button type="primary" text="Primary" [submit]="wait"></ui-button>
<ui-button type="primary" text="Primary" icon="plus-skinny-minimal" [submit]="wait"></ui-button>
<ui-button type="primary" icon="plus-skinny-minimal" [submit]="wait"></ui-button>
<ui-button type="primary" icon="close" [submit]="wait"></ui-button>
<ui-button type="primary" icon="analytics" [submit]="wait"></ui-button>
<br/><br/>
<ui-button type="primary" [disabled]="true" text="Primary"></ui-button>
<ui-button type="primary" [disabled]="true" icon="plus-skinny-minimal" text="Primary"></ui-button>
<ui-button type="primary" [disabled]="true" icon="plus-skinny-minimal" [submit]="wait"></ui-button>
<ui-button type="primary" [disabled]="true" icon="close" [submit]="wait"></ui-button>
<ui-button type="primary" [disabled]="true" icon="analytics" [submit]="wait"></ui-button>
<br/><br/>

<h3>Default</h3>
<ui-button type="default" text="Default" [submit]="wait"></ui-button>
<ui-button type="default" text="Default" icon="plus-skinny-minimal" [submit]="wait"></ui-button>
<ui-button type="default" icon="plus-skinny-minimal" [submit]="wait"></ui-button>
<ui-button type="default" icon="close" [submit]="wait"></ui-button>
<ui-button type="default" icon="analytics" [submit]="wait"></ui-button>
<br/><br/>
<ui-button type="default" [disabled]="true" text="Default" [submit]="wait"></ui-button>
<ui-button type="default" [disabled]="true" text="Default" icon="plus-skinny-minimal" [submit]="wait"></ui-button>
<ui-button type="default" [disabled]="true" icon="plus-skinny-minimal" [submit]="wait"></ui-button>
<ui-button type="default" [disabled]="true" icon="close" [submit]="wait"></ui-button>
<ui-button type="default" [disabled]="true" icon="analytics" [submit]="wait"></ui-button>
<br/><br/>

<h3>Discrete</h3>
<ui-button type="discrete" text="Discrete" [submit]="wait"></ui-button>
<ui-button type="discrete" icon="plus-skinny-minimal" text="Discrete" [submit]="wait"></ui-button>
<ui-button type="discrete" icon="plus-skinny-minimal" [submit]="wait"></ui-button>
<ui-button type="discrete" icon="close" [submit]="wait"></ui-button>
<ui-button type="discrete" icon="analytics" [submit]="wait"></ui-button>
<br/><br/>
<ui-button type="discrete" [disabled]="true" text="Discrete"></ui-button>
<ui-button type="discrete" [disabled]="true" icon="plus-skinny-minimal" text="Discrete"></ui-button>
<ui-button type="discrete" [disabled]="true" icon="plus-skinny-minimal"></ui-button>
<ui-button type="discrete" [disabled]="true" icon="close" [submit]="wait"></ui-button>
<ui-button type="discrete" [disabled]="true" icon="analytics" [submit]="wait"></ui-button>

<h3>SvgIcon</h3>
<ui-button type="default" svgIcon="campaigns-l-create-new" text="SvgIcon" [submit]="wait"></ui-button>
<ui-button type="default" svgIcon="smiley" [submit]="wait"></ui-button>
<ui-button type="default" svgIcon="cloud-l-negative" [submit]="wait"></ui-button>
<ui-button type="default" svgIcon="widget" [submit]="wait"></ui-button>
<br/><br/>
<ui-button type="default" [disabled]="true" svgIcon="campaigns-l-create-new" text="SvgIcon"></ui-button>
<ui-button type="default" [disabled]="true" svgIcon="smiley"></ui-button>
<ui-button type="default" [disabled]="true" svgIcon="cloud-l-negative" [submit]="wait"></ui-button>
<ui-button type="default" [disabled]="true" svgIcon="widget" [submit]="wait"></ui-button>

<h3>Borderless</h3>
<ui-button type="discrete" icon="plus-skinny-minimal" text="Add" [border]="false"></ui-button>
<ui-button type="default" icon="plus-skinny-minimal" text="Add" [border]="false"></ui-button>
<ui-button type="primary" icon="plus-skinny-minimal" text="Add" [border]="false"></ui-button>
