import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'ui-number-input-docs-preview',
    templateUrl: './number-input.docs.preview.html',
    styleUrls: ['./number-input.docs.preview.scss']
})
export class UINumberInputDocsPreview {
    testForm: UntypedFormGroup;

    mama: UntypedFormControl;
    textModel: string;

    inputOne = 10000;
    maxValue = 10000;

    constructor(private fb: UntypedFormBuilder) {
        this.testForm = this.fb.group({
            testInput2: ['', [Validators.minLength(3), Validators.required]],
            testInput3: ['', [Validators.maxLength(5)]],
            testInput4: ['', [Validators.maxLength(5)]]
        });

        this.mama = new UntypedFormControl('', [Validators.minLength(3), Validators.required]);

        this.testForm.valueChanges.subscribe((arg: any) => {
            console.log(arg);
        });
    }

    handleUndo() {
        console.log('hej');
    }

    log() {
        console.log(this.inputOne);
    }

    onSubmit(event: any): void {
        console.log('onSubmit', this.inputOne);
    }

    widthChange(width: number) {
        console.log({ widthChange: width });
    }

    heightChange(height: number) {
        console.log({ heightChange: height });
    }

    sizeSubmit() {
        console.log('sizeSubmit');
    }
}
