import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'ui-svg-icons',
    templateUrl: 'svg-icons.component.html',
    styleUrls: ['./svg-icons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * Only used to inject the svg into body when the UISvgIconService initializes (once)
 */
export class UISvgIconsComponent {}
