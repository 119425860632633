import { Component, ElementRef, HostListener, ChangeDetectorRef, OnInit, Input } from '@angular/core';
import { UISelectableBaseDirective } from '../../inputs/selectable-list/selectable.component';
import { UISelectService } from './select.service';

@Component({
    selector: 'ui-option',
    templateUrl: 'option.component.html',
    styleUrls: ['option.component.scss'],
    providers: [
        {
            provide: UISelectableBaseDirective,
            useExisting: UIOptionComponent,
            multi: true
        }
    ],
    host: {
        '[class.ui-option]': 'true'
    }
})
export class UIOptionComponent extends UISelectableBaseDirective implements OnInit {
    @Input() alwaysVisible = false;

    public multiSelect = false;
    public tokenField = false;

    constructor(
        host: ElementRef,
        changeDetectorRef: ChangeDetectorRef,
        private uiSelectService: UISelectService
    ) {
        super(host, changeDetectorRef);
    }

    ngOnInit(): void {
        this.multiSelect = this.uiSelectService.isMultiSelect;
        this.tokenField = this.uiSelectService.isTokenField;
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent): void {
        this.shiftPressed = this.multiSelect && !this.selected && event.shiftKey;
        if (this.disabled) {
            return;
        }
        this.toggleSelect();
        if (!this.multiSelect) {
            this.changeDetectorRef.detectChanges();
            this.uiSelectService.close();
        }
    }

    @HostListener('mouseover', ['$event'])
    onMouseOver(): void {
        if (this.disabled) {
            return;
        }

        this.emitHoverPreview();
    }
}
