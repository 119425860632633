<div class="header">
    <div
        [id]="tab?.headerId"
        class="tab"
        *ngFor="let tab of tabs"
        #tabHeader
        [ngClass]="{ selected: tab.selected }"
        (mouseenter)="hoverTab($event)"
        (mouseleave)="resetSelectionIndicator()"
        (click)="selectTab(tab)">
        {{ tab.name }}
        <div
            class="dot"
            *ngIf="tab.dot"></div>
    </div>
    <div
        class="indicator"
        #indicator></div>
</div>
<div class="content">
    <ng-content></ng-content>
</div>
