import { UITheme } from '../../../types/theme';
import { Injector } from '@angular/core';

export interface IUIDialogConfig {
    /**
     * Supports single and multiple classes based on the default convention.
     * E.g: "classname" or "classname myclass"
     */
    panelClass?: string | string[];
    hasBackdrop?: boolean;
    backdropClass?: string;
    headerText?: string;
    width?: number | string;
    height?: number | string;
    maxWidth?: number | string;
    maxHeight?: number | string;
    backdropClickClose?: boolean;
    closeButton?: boolean;
    escKeyClose?: boolean;
    padding?: number;
    data?: any;
    theme?: UITheme;
    injector?: Injector;
    closeFunc?: () => void;
    id?: string;
    disableFocusInitialElement?: boolean;
}

export const UIDIALOG_DEFAULT_CONFIG: Partial<IUIDialogConfig> = {
    width: '90%', // NOTE: Setting this to 100% will fuck up centering...
    maxWidth: '900px',
    backdropClickClose: true,
    escKeyClose: true,
    hasBackdrop: true,
    closeButton: true,
    backdropClass: 'ui-dialog-backdrop',
    panelClass: 'ui-dialog-panel',
    headerText: 'Are you sure you want to do this?',
    padding: 4
};
