import { Component, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'ui-iframe',
    templateUrl: 'iframe.component.html'
})
export class UIIframeComponent {
    @Input() url: SafeUrl;
    constructor() {}
}
