import { Component } from '@angular/core';

@Component({
    selector: 'ui-radio-docs-preview',
    templateUrl: './radio.docs.preview.html',
    styleUrls: ['./radio.docs.preview.scss'],
})
export class UIRadioDocsPreview {

    disabled = false;
    on = true;
    on2 = true;

    radioOptions: IOption[] = [
        { label: 'Option 1', id: 0 },
        { label: 'Option 2', id: 1 },
        { label: 'Option 3', id: 2 }
    ];

    selectedOption?: IOption;

    setOnState(on: boolean) {
        this.on = on;
    }

    toggleDisabledState() {
        this.disabled = !this.disabled;
    }
}

interface IOption {
    label: string, id: number
}
