import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { UIModule } from '../ui.module';
import { routes } from './app.routes';
import { ComponentDocsComponent } from './component-docs/component-docs.component';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { ComponentMenuComponent } from './component-docs/component-menu.component';
import { DocsComponent } from './docs/docs.component';
import { UIDocsModule } from './docs/docs.module.generated';
import { OverviewComponent } from './overview/overview.component';
import { VariablesComponent } from './variables/variables.component';
import { VariableFilterPipe } from './variables/variable-filter.pipe';
import { EggComponent } from './overview/egg/egg.component';
import { ThemeComponent } from './ui-theme/docs/theme.component';

@NgModule({
    declarations: [
        AppComponent,
        ComponentDocsComponent,
        LayoutComponent,
        ComponentMenuComponent,
        DocsComponent,
        OverviewComponent,
        VariablesComponent,
        EggComponent,
        VariableFilterPipe,
        ThemeComponent
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        UIDocsModule,
        UIModule,
        RouterModule.forRoot(routes, {})
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
