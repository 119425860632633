<div [class]="focus ? 'container focus' : 'container'">
    <div class="left">
        <ui-icon
            class="checkmark"
            *ngIf="isToggable"
            [class.hidden]="!toggle"
            icon="checkmark"></ui-icon>
    </div>
    <div class="center">
        <input
            #input
            type="text"
            (keydown.escape)="input.blur()"
            (input)="inputChange(input)"
            (focus)="onFocus(input)"
            (focusout)="focusOut(input)"
            [placeholder]="placeholder ? placeholder : ''" />
    </div>
    <div class="right">
        <ui-icon
            class="edit-icon"
            [class.hidden]="!icon"
            *ngIf="!focus; else edit"
            icon="edit"></ui-icon>
    </div>
    <ng-template #edit>
        {{ units }}
    </ng-template>
</div>
