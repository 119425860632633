<div
    class="help-wrapper"
    [uiDropdownTarget]="helpMenuDropDownMenu">
    <span
        class="help-icon"
        id="interaction-help-center">
        <ui-icon icon="help" />
    </span>
    <ui-dropdown #helpMenuDropDownMenu>
        <ui-dropdown-item
            id="interaction-knowledge-base"
            svgIcon="question-mark-s"
            (click)="openSupportTab()">
            Knowledge base
        </ui-dropdown-item>

        @if (isIntercomAvailable) {
            <ui-dropdown-item
                id="interaction-chat-with-us"
                svgIcon="comments"
                (click)="emitIntercomMessage()">
                Chat with us
            </ui-dropdown-item>

            <ui-dropdown-item
                id="interaction-launched-features"
                svgIcon="announcement"
                (click)="emitIntercomNews()">
                Launched features
            </ui-dropdown-item>
        }

        @if (isKeyboardShortcutsObserved) {
            <ui-dropdown-item
                id="interaction-keyboard-shortcuts"
                svgIcon="keyboard"
                (click)="emitKeyboardShortcuts()">
                Keyboard shortcuts
            </ui-dropdown-item>
        }
    </ui-dropdown>
</div>
