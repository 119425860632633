import { Component, OnChanges, Input } from '@angular/core';

@Component({
    selector: 'ui-flag',
    template: '',
    styleUrls: ['./flag.component.scss'],
    host: {
        '[class.ui-flag]': 'true',
        '[class.small]': `size === 'small'`,
        '[class.tiny]': `size === 'tiny'`,
        '[style.backgroundImage]': `'url(https://cdn.bannerflow.com/shared/ui/flags/1x1/' + icon + '.svg' + ')'`
    }
})
export class UIFlagComponent implements OnChanges {
    /**
     * Culture receives ISO 3166-1 alpha-2 code [Two-letters country codes]
     * Culture code can also be in the format "en-us" and it will be treated as "us"
     *
     * @summary:
     * Icon of the flag.
     */
    @Input() culture: string | undefined;

    /**
     * Size can be either 'tiny' or 'small' or empty
     *
     * @summary:
     * Size of the icon.
     */
    @Input() size?: 'small' | 'tiny';

    public icon: string;

    ngOnChanges(): void {
        this.setIcon();
    }

    // Support input of different formats (use the last numbers) ex. "en-us" => "us"
    private setIcon(): void {
        const cultureStr = this.culture ? this.culture : '';
        const match: RegExpMatchArray | null = cultureStr.match(/[a-zA-Z]{2,2}$/g);

        if (match) {
            this.icon = match[0];
        } else {
            this.icon = cultureStr;
        }
    }
}
