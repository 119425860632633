import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class UISelectService {
    private _close$ = new Subject<void>();
    close$ = this._close$.asObservable();
    private _open$ = new Subject<void>();
    open$ = this._open$.asObservable();
    isMultiSelect = false;
    isTokenField = false;

    open(): void {
        this._open$.next();
    }

    close(): void {
        this._close$.next();
    }
}
