<ui-button
    text="Click me to see a tooltip!"
    uiTooltipTrigger="click"
    uiTooltipType="discrete"
    uiTooltip="This is a lovely tooltip"></ui-button>
<ui-button
    text="Hover me to see a tooltip!"
    uiTooltip="This is a lovely tooltip"
    uiTooltipPosition="right"
    #tooltip="uiTooltip"></ui-button>
