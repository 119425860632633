<layout>
    <ng-container ngProjectAs="leftRegion"></ng-container>

    <ng-container ngProjectAs="centerRegion">
        <ui-panel name="Easter Egg">
            <div
                #board
                class="board x">
                <div
                    *ngFor="let cell of grid"
                    class="cell {{ cell.selection }} free-cell"
                    (click)="handleClick(cell)"></div>
            </div>
            <div class="stats">
                Player: {{ stats.user }} | Computer: {{ stats.ai }} | Draw: {{ stats.draw }}
            </div>

            <div
                class="winning-message"
                *ngIf="showEndgame">
                <div>{{ winningMessageText }}</div>
                <ui-button
                    (click)="startGame()"
                    text="Restart"
                    type="primary"></ui-button>
            </div>
        </ui-panel>
    </ng-container>
</layout>
