import {
    Component,
    Input,
    ContentChildren,
    QueryList,
    ChangeDetectionStrategy,
    AfterContentInit,
    OnDestroy
} from '@angular/core';

import { UIAccordionItemComponent } from './accordion-item/accordion-item.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ui-accordion',
    templateUrl: 'accordion.component.html',
    styleUrls: ['accordion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.panel]': 'true'
    }
})
export class UIAccordionComponent implements AfterContentInit, OnDestroy {
    /**
     * When false only one accordion is expanded per time, if true multiple accordions can be expanded.
     *
     */
    @Input() allowMultipleExpanded = true;

    @ContentChildren(UIAccordionItemComponent) accordionItems: QueryList<UIAccordionItemComponent>;
    subscriptions: Subscription[] = [];

    // tslint:disable-next-line:use-life-cycle-interface
    ngAfterContentInit(): void {
        this.subscribe();
    }

    subscribe(): void {
        if (this.accordionItems) {
            this.accordionItems.forEach(item => {
                this.subscriptions.push(
                    item.toggleChange.subscribe((expanded: boolean) => {
                        // If you only want one expanded at a time, set all other accordions to expanded false.
                        if (!this.allowMultipleExpanded && expanded) {
                            this.accordionItems.forEach(accordion => {
                                if (accordion !== item) {
                                    accordion.expanded = false;
                                }
                            });
                        }
                    })
                );
            });
        }
    }

    ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
}
