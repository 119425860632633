import { Directive, ElementRef, ChangeDetectorRef, Input, HostListener } from '@angular/core';
import { UISelectableBaseDirective } from './selectable.component';

@Directive({
    selector: '[ui-selectable]',
    exportAs: 'ui-selectable',
    providers: [
        {
            provide: UISelectableBaseDirective,
            useExisting: UISelectableDirective,
            multi: true
        }
    ]
})
export class UISelectableDirective extends UISelectableBaseDirective {
    /**
     * Pass value to directive attribute to set value
     */
    @Input('ui-selectable') set argument(val: any) {
        if (val !== undefined) {
            this.value = val;
        }
    }

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
        super(elementRef, changeDetectorRef);
    }

    /**
     * Toggle on click
     * @param event
     */
    @HostListener('click')
    onClick(): void {
        this.toggleSelect();
    }
}
