<div
    #breadcrumbsContainer
    class="breadcrumbs-container"
    data-testid="breadcrumbs-container">
    <ui-breadcrumb
        *ngIf="contentChildrenBreadcrumbs.first as first"
        [maxTruncationWidth]="maxItemWidth"
        data-testid="breadcrumb-0"
        (click)="onBreadcrumbClicked(first)">
        <ng-container
            inContainerView
            *ngTemplateOutlet="first.template"></ng-container>
    </ui-breadcrumb>

    <ui-breadcrumb
        *ngIf="contentChildrenBreadcrumbs.length > 1"
        [class.hidden]="!isCollapsed"
        data-testid="breadcrumbs-collapsed-indicator">
        <ng-container inContainerView>
            <div
                ui-popover-target
                #target="ui-popover-target"
                (mouseover)="popover.open(target)">
                ...
            </div>
        </ng-container>
    </ui-breadcrumb>

    <ng-container *ngFor="let breadcrumb of contentChildrenBreadcrumbs; let i = index">
        <ui-breadcrumb
            *ngIf="!breadcrumb.collapsed && breadcrumb !== contentChildrenBreadcrumbs.first"
            [maxTruncationWidth]="maxItemWidth"
            [attr.data-testid]="'breadcrumb-' + i"
            (click)="onBreadcrumbClicked(breadcrumb)">
            <ng-container
                inContainerView
                *ngTemplateOutlet="breadcrumb.template"></ng-container>
        </ui-breadcrumb>
    </ng-container>
</div>

<ui-popover
    #popover="ui-popover"
    [config]="popoverConfig">
    <ng-template ui-popover-template>
        <ng-container *ngFor="let breadcrumb of contentChildrenBreadcrumbs; let i = index">
            <div
                *ngIf="breadcrumb.collapsed"
                class="popover-breadcrumb"
                data-testid="breadcrumbs-collapsed-container"
                (click)="onBreadcrumbClicked(breadcrumb)">
                <ng-container *ngTemplateOutlet="breadcrumb.template"></ng-container>
            </div>
        </ng-container>
    </ng-template>
</ui-popover>
