import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'ui-input-docs-preview',
    templateUrl: './input.docs.preview.html',
    styleUrls: ['./input.docs.preview.scss']
})
export class UIInputDocsPreview {
    testForm: UntypedFormGroup;

    mama: UntypedFormControl;
    textModel?: string;
    inputThree = 'Hej';

    // mama: string;

    constructor(private fb: UntypedFormBuilder) {
        this.testForm = this.fb.group({
            testInput2: ['', [Validators.minLength(3), Validators.required]],
            testInput3: ['', [Validators.maxLength(5)]],
            testInput4: ['', [Validators.maxLength(5)]]
        });

        this.mama = new UntypedFormControl('', [Validators.minLength(3), Validators.required]);

        this.testForm.valueChanges.subscribe((arg: any) => {
            console.log(arg);
        });
    }

    onSubmit(event: any): void {
        console.log(this.mama.value);
    }

    handleUndo() {
        console.log('undo handled');
    }
}
