<div class="header">
    {{ config.headerText }}
    <div
        *ngIf="config.closeButton"
        class="header__close"
        id="interaction-close-dialog"
        (click)="close()">
        <ui-icon icon="close-big"></ui-icon>
    </div>
</div>
<div
    #bodyRef
    class="body"
    [style.padding.rem]="config.padding"
    cdk-scrollable>
    <div class="content">
        <ng-template #componentPlaceholder></ng-template>
        <ng-container *ngIf="dialogRef.templateRef">
            <ng-container
                *ngTemplateOutlet="dialogRef.templateRef; context: { $implicit: this }"></ng-container>
        </ng-container>
    </div>
</div>
