import { Component, Input } from '@angular/core';

@Component({
    selector: 'ui-menu-item',
    templateUrl: './ui-menu-item.component.html',
    styleUrls: ['./ui-menu-item.component.scss']
})
export class UIMenuItemComponent {
    @Input() title: string;
    @Input() path: string;
}
