<div
    *ngIf="selectionType === 'checkbox'"
    class="header-cell checkbox-cell align-center"
    (click)="toggleAll()">
    <ui-checkbox
        [selected]="allSelected"
        [indeterminate]="indeterminate">
    </ui-checkbox>
</div>
<div
    *ngIf="selectionType === 'radio'"
    class="header-cell radio-cell align-center"></div>

<ui-list-header-cell
    class="header-cell"
    [column]="column"
    *ngFor="let column of columns"
    [ngClass]="{ 'no-border': !column.border }"
    [uiTooltip]="column.description"
    [uiTooltipDisabled]="!column.description"
    [uiTooltipWidth]="200"
    (click)="onSortClick(column)">
</ui-list-header-cell>

<!-- <col width=100><col width=100><col width=100> -->
