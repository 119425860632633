
<p>Popover is {{ popover.isPopoverOpen ? 'open' : 'closed' }}</p>

<ui-button
    (click)="popover.open(target)"
    ui-popover-target
    #target="ui-popover-target"
    text="Open popover">
</ui-button>

<ui-button
    (mouseover)="hoverPopover.open(hoverTarget)"
    ui-popover-target
    #hoverTarget="ui-popover-target"
    text="Hover to open popover">
</ui-button>

<ui-popover
    #popover="ui-popover"
    [config]="{ }">

    <ng-template ui-popover-template>
        Fill this with any HTML
    </ng-template>
</ui-popover>

<ui-popover
    #hoverPopover="ui-popover"
    [config]="{ arrowPosition: 'top', position: 'bottom', openOnHover: true }">

    <ng-template ui-popover-template>
        Fill this with any HTML
    </ng-template>
</ui-popover>

