<ui-button text="Show Info" (click)="showNotification()"></ui-button>
<ui-button text="Show Success" (click)="showNotification('success')"></ui-button>
<ui-button text="Show Warning" (click)="showNotification('warning')"></ui-button>
<ui-button text="Show Error" (click)="showNotification('error')"></ui-button>
<ui-button text="Show Warning with checkbox" (click)="showNotificationWithCheckBox('warning')"></ui-button>
<br>
<br>

<ui-button text="Show Auto closing Notification" (click)="showNotification('success', true)"></ui-button>

<br>
<br>

<ui-button text="No close icon" (click)="showNotification('success', true, true)"></ui-button>
