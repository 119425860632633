import { Component, Input } from '@angular/core';

@Component({
    selector: 'ui-menu-group',
    templateUrl: './ui-menu-group.component.html',
    styleUrls: ['./ui-menu-group.component.scss'],
    host: {
        '[class.menu-group]': 'true'
    }
})
export class UIMenuGroupComponent {
    @Input() title: string;
    @Input() path: string;
}
