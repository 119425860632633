import { IUIListDataNode } from './list-data-node';
import { TemplateRef } from '@angular/core';

export class UIListControlNode implements IUIListDataNode {
    id: string;

    /**
     *  Owner of this control node
     */
    parent?: IUIListDataNode;

    /**
     *  Which type of control node it is
     */
    type: 'loader' | 'back' | 'empty' | 'noMatch';

    /**
     * Template to use
     */
    template: TemplateRef<any>;

    /**
     * Just to share the interface of IUIListDataNode and make it esier to work with
     */
    children?: IUIListDataNode[];

    /**
     * Just to share the interface of IUIListDataNode and make it esier to work with
     */
    totalCount?: number;

    /**
     * Werther this node is loading or not
     */
    isLoading?: boolean;

    constructor(
        type: 'loader' | 'back' | 'empty' | 'noMatch',
        template: TemplateRef<any>,
        parent?: IUIListDataNode
    ) {
        this.type = type;
        this.template = template;
        this.parent = parent;
    }
}
