import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';

import moment from 'moment';

@Component({
    selector: 'ui-date-picker',
    templateUrl: 'date-picker.component.html',
    styleUrls: ['date-picker.component.scss'],
    host: {
        '[class.date-picker]': 'true'
    }
})
export class UIDatePickerComponent implements OnChanges, OnInit {
    /**
     * Datepicker date if not period
     */
    @Input() date?: Date;

    /**
     * Start date if period datepicker
     */
    @Input() startDate?: Date;

    /**
     * End date if period datepicker
     */
    @Input() endDate?: Date;

    /**
     * Is datepicker with hours picker
     */
    @Input() hourPicker?: boolean;

    /**
     * Is datepicker with hours picker
     */
    @Input() yearPicker?: boolean = true;

    /**
     * Is period datepicker
     */
    @Input() dateSpanPicker?: boolean;

    /**
     * Maximum date limitation of datepiker
     */
    @Input() maxDate?: Date;

    /**
     * minimum date limitation of datepiker
     */
    @Input() minDate?: Date;

    /**
     * Week starts with Sunday
     */
    @Input() firstWeekdaySunday?: boolean;

    /**
     * Show outer borders
     */
    @Input() outerBorders = true;

    /**
     * Compact view
     */
    @Input() isCompact = false;

    /**
     * Output event that single date is changed, return type is Date
     */
    @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();

    /**
     * Output event that start date of period is changed, return type is Date
     */
    @Output() startDateChange: EventEmitter<Date> = new EventEmitter<Date>();

    /**
     * Output event that end date of period is changed, return type is Date
     */
    @Output() endDateChange: EventEmitter<Date> = new EventEmitter<Date>();

    currentDate: moment.Moment = moment();
    years: number[] = [];
    days: UICalendarDate[] = [];
    hours: number[] = new Array(24);
    showYearPicker: boolean;
    showHourPicker: boolean;

    private hoverDateSpan?: Date[];
    private tempDate?: moment.Moment;
    private firstSelectedDate?: moment.Moment;
    private initialized: boolean;

    /**
     * If a selection is currently being made or not
     */
    get isSelecting(): moment.Moment | false | undefined {
        return this.dateSpanPicker && this.firstSelectedDate;
    }

    ngOnInit(): void {
        this.initialized = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            // Date is a semantic alias for startDate to make more sense when picking single date.
            if (changes['date'] && !changes['startDate']) {
                this.startDate = this.date;
            }

            // Toggling between picking datespans and single dates
            if (changes['dateSpanPicker']) {
                // To not get ExpressionChangedAfterItHasBeenCheckedError
                setTimeout(() => {
                    // Make sure endDate is undefined when picking single dates
                    if (!this.dateSpanPicker) {
                        this.endDate = undefined;
                        this.endDateChange.emit(this.endDate);
                    }
                    // Make sure we have a preslect span when swapping to date span
                    else if (!this.endDate && this.startDate) {
                        this.endDate = moment(this.startDate).endOf('day').toDate();
                        this.endDateChange.emit(this.endDate);
                    }
                });
            }

            if (!this.initialized && this.startDate) {
                this.currentDate = moment(this.startDate);
            }

            // Reset selection process
            this.firstSelectedDate = undefined;
            this.hoverDateSpan = undefined;
            this.generateYears();
            this.generateCalendar();
        }
    }

    /**
     * When hovering a date
     * @param date Date hovered
     * @param event
     */
    hoverDate(date: moment.Moment | undefined, event?: MouseEvent): void {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }

        if (!date) {
            return;
        }

        if (this.isSelecting) {
            if (date.isAfter(this.firstSelectedDate)) {
                this.hoverDateSpan = [this.firstSelectedDate!.toDate(), date.toDate()];
            } else {
                this.hoverDateSpan = [date.toDate(), this.firstSelectedDate!.toDate()];
            }
            this.generateCalendar(true);
        }
    }

    onDateClick(date: moment.Moment | undefined): void {
        if (!date) {
            return;
        }
        // Show time picker
        if (this.hourPicker && !this.dateSpanPicker) {
            this.tempDate = date;
            this.showHourPicker = true;
        }
        // Select the date
        else {
            this.selectDate(date);
        }
    }

    /**
     * Select date
     * @param date
     * @param event
     */
    selectDate(date: moment.Moment, event?: MouseEvent): void {
        if (event) {
            event.stopPropagation();
        }

        // Abort if outside allowed min or max.
        if (!this.isBetweenMinMax(date)) {
            return;
        }

        setTimeout(() => {
            this.hoverDateSpan = undefined;
            let hasChanged = false;

            // When a dateSpan should be selected (start and end date)
            if (this.dateSpanPicker) {
                // When second date in span is selected
                if (this.firstSelectedDate) {
                    if (moment(this.firstSelectedDate).isBefore(moment(date))) {
                        this.startDate = this.firstSelectedDate.startOf('day').toDate();
                        this.endDate = date.endOf('day').toDate();
                    } else {
                        this.endDate = this.firstSelectedDate.endOf('day').toDate();
                        this.startDate = date.startOf('day').toDate();
                    }
                    this.firstSelectedDate = undefined;

                    hasChanged = true;
                }
                // if both dates were defined, you should restart the selection process.
                else {
                    this.firstSelectedDate = date;
                }
            }
            // Single date
            else {
                this.startDate = date.utc().toDate();
                this.firstSelectedDate = undefined; // Should always be undefined for single select
                hasChanged = true;
            }

            this.date = this.startDate;

            // Only emit changes if something has changed
            if (hasChanged) {
                this.dateChange.emit(this.date);
                this.startDateChange.emit(this.startDate);
                this.endDateChange.emit(this.endDate);
            }

            this.generateCalendar(!!this.firstSelectedDate);
        });
    }

    /**
     * Select a specific hour
     * @param hour Hour to select (0-23)
     * @param event
     */
    selectHour(hour: number, event: MouseEvent): void {
        this.showHourPicker = false;
        this.selectDate(this.tempDate!.hour(hour));

        if (event) {
            event.preventDefault();
        }
    }

    /**
     * Select year
     * @param year
     * @param event
     */
    selectYear(year: number, event?: MouseEvent): void {
        setTimeout(() => {
            this.currentDate.year(year);
            this.showYearPicker = false;
            this.generateCalendar();
        });

        if (event) {
            event.preventDefault();
        }
    }

    /**
     * Goto next month
     */
    prevMonth(): void {
        this.currentDate = this.currentDate.subtract(1, 'month');
        this.generateCalendar();
    }

    /**
     * Goto previous month
     */
    nextMonth(): void {
        this.currentDate = this.currentDate.add(1, 'month');
        this.generateCalendar();
    }

    /**
     * Set current date to today
     */
    today(): void {
        this.currentDate = moment();
        this.selectDate(this.currentDate);
    }

    /**
     * Show year picker
     */
    openYearPicker(): void {
        if (!this.yearPicker) {
            return;
        }

        setTimeout(() => (this.showYearPicker = true)); // why timeout?
    }

    /**
     * Clear date picker
     */
    clear(): void {
        this.firstSelectedDate = undefined;
        this.hoverDateSpan = undefined;
        this.startDate = undefined;
        this.endDate = undefined;
        this.date = undefined;
    }

    /**
     * To optimize *ngFor, use this as an indexer
     * @param index
     * @param date
     */
    trackByDate(_: number, date: UICalendarDate): string | undefined {
        if (date.selected) {
            const first = date.selected.first ? 1 : 0;
            const last = date.selected.last ? 1 : 0;
            const between = date.selected.between ? 1 : 0;
            return `${Math.ceil(
                date.year! * 365 + date.month! * 31 + date.day!
            )}_${first}${last}${between}`;
        }
        return undefined;
    }

    /**
     * Generate an array of years
     */
    private generateYears(): void {
        const date =
            (!this.minDate ? undefined : moment(this.minDate)) || moment().year(moment().year() - 15);
        const toDate =
            (!this.maxDate ? undefined : moment(this.maxDate)) || moment().year(moment().year() + 27);
        const years = toDate.year() - date.year() + 1;

        this.years = [];

        for (let i = 0; i < years; i++) {
            this.years.push(date.year());
            date.add(1, 'year');
        }
    }

    /**
     * Generate the calendar of the current month
     * @param hoverPreview Werther this is a preview of the current hover state or not
     */
    private generateCalendar(hoverPreview?: boolean): void {
        const currentDate = moment(this.currentDate); // Date currently in view
        const month = currentDate.month();
        const year = currentDate.year();
        const firstWeekDay = this.firstWeekdaySunday
            ? currentDate.date(2).day()
            : currentDate.date(1).day();
        const dateSpan = hoverPreview
            ? this.hoverDateSpan || [this.firstSelectedDate, this.firstSelectedDate]
            : [this.startDate, this.endDate];
        const selectedStartDate = dateSpan[0] ? moment(dateSpan[0]) : undefined;
        const selectedEndDate = dateSpan[1] ? moment(dateSpan[1]) : undefined;
        const dateOffset = 1 - (firstWeekDay !== 1 ? (firstWeekDay + 6) % 7 : 0);
        // const firstDate = moment(`${dateOffset}.${month + 1}.${year}`, 'DD.MM.YYYY');

        // End dates of months (numbers)
        const monthEndDate = moment(currentDate).endOf('month').date();

        // First date shown in calendar (moment instance)
        const firstDate = moment(currentDate)
            .startOf('month')
            .subtract(1, 'days')
            .add(dateOffset, 'days');

        // reset days
        this.days = [];

        // Make sure to fill up end row with dates
        const numberOfDaysDisplayed = monthEndDate - dateOffset;
        const iterateTo =
            monthEndDate + (numberOfDaysDisplayed % 7 !== 0 ? 6 - (numberOfDaysDisplayed % 7) : 0);

        for (let i = dateOffset; i <= iterateTo; i++) {
            const date = moment(firstDate).add(i - dateOffset, 'days');
            const today = moment().isSame(date, 'day') && moment().isSame(date, 'month');
            let outOfMonth = false;
            let between = false;
            let first = false;
            let last = false;

            if (this.dateSpanPicker) {
                if (selectedStartDate && selectedEndDate) {
                    between = date.isBetween(selectedStartDate, selectedEndDate, 'day');
                    first = date.isSame(selectedStartDate, 'day');
                    last = date.isSame(selectedEndDate, 'day');
                } else if (this.firstSelectedDate) {
                    first = this.firstSelectedDate.isSame(date, 'day');
                }
            } else {
                first = last = (selectedStartDate && selectedStartDate.isSame(date, 'day')) || false;
            }

            outOfMonth =
                !((i > 0 || this.dateSpanPicker) && (first || between || last)) &&
                date.month() !== currentDate.month();

            const day: UICalendarDate = {
                day: date.date(),
                month: i > 0 ? month : undefined,
                year: i > 0 ? year : undefined,
                enabled:
                    (date.month() === currentDate.month() || this.dateSpanPicker) &&
                    this.isBetweenMinMax(date),
                today: i > 0 && today,
                selected: {
                    first: (i > 0 || this.dateSpanPicker) && first,
                    last: (i > 0 || this.dateSpanPicker) && last,
                    between: (i > 0 || this.dateSpanPicker) && between
                },
                outOfMonth: outOfMonth,
                momentObj: date
            };

            this.days.push(day);
        }
    }

    /**
     * Check if a date is between the min/max limits or not.
     * @param date
     */
    private isBetweenMinMax(date: moment.Moment | moment.MomentInput): boolean {
        date = this.toMoment(date);

        if (this.minDate !== undefined) {
            if (this.maxDate !== undefined) {
                return date.isBetween(this.minDate, this.maxDate, 'day', '[]');
            }
            return !date.isBefore(this.minDate, 'day');
        } else if (this.maxDate !== undefined) {
            return !date.isAfter(this.maxDate, 'day');
        }
        return true;
    }

    /**
     * Make sure object is a moment object
     * @param date
     */
    private toMoment(date: moment.Moment | moment.MomentInput): moment.Moment {
        if (moment.isMoment(date)) {
            return date;
        }
        return moment(date);
    }
}

export interface UICalendarDate {
    day: number;
    month: number | undefined;
    year: number | undefined;
    enabled: boolean | undefined;
    today: boolean;
    selected: {
        first: boolean | undefined;
        last: boolean | undefined;
        between: boolean | undefined;
    };
    momentObj: moment.Moment;
    outOfMonth: boolean;
}
