import { Component } from '@angular/core';
import { UIAlertDialogService } from '../alert-dialog.service';

@Component({
    selector: 'ui-alert-dialog-docs-preview',
    templateUrl: './alert-dialog.docs.preview.html',
    styleUrls: ['./alert-dialog.docs.preview.scss'],
})
export class UIAlertDialogDocsPreview {

    constructor(public alertDialogService: UIAlertDialogService) {}

    async showAlert() {
        await this.alertDialogService.show({
            headerText: 'This is a warning',
            text: 'Important messsage',
            confirmText: 'I accept'
        });
        console.log('Ok');
    }
}
