import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UIThemeService } from 'services';
import { UITheme } from '../../types/theme';

@Component({
    // Demo Project components should not be forced to use ui- prefix
    // tslint:disable-next-line:component-selector
    selector: 'layout',
    styleUrls: ['layout.component.scss'],
    templateUrl: 'layout.component.html'
})
export class LayoutComponent {
    public theme: UITheme;
    public layout: UITheme;

    constructor(
        private themeService: UIThemeService,
        public router: Router
    ) {
        this.theme = this.themeService.getActiveTheme();
        this.layout = this.themeService.getActiveLayout();
    }

    public updateTheme(theme: UITheme): void {
        this.themeService.setStyle(theme, this.layout, true);
        this.theme = theme;
    }

    public updateLayout(layout: UITheme): void {
        this.themeService.setStyle(this.theme, layout, true);
        this.layout = layout;
    }
}
