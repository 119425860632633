<div class="container">
    <ng-container *ngIf="headerTemplate">
        <div
            [ngClass]="{ expanded: expanded, 'left-side-caret': leftSideCaret }"
            class="header custom"
            (click)="toggleAccordion()">
            <ng-container
                *ngTemplateOutlet="
                    headerTemplate;
                    context: { $implicit: headerTemplate }
                "></ng-container>
            <div class="caret">
                <ui-icon icon="arrow-down"></ui-icon>
            </div>
        </div>
    </ng-container>

    <div
        *ngIf="!headerTemplate"
        [ngClass]="{
            expanded: expanded,
            highlight: highlightHeader,
            error: highlightErrorHeader
        }"
        class="header"
        (click)="toggleAccordion()">
        <ui-icon
            [icon]="icon"
            class="icon"></ui-icon>
        <ui-svg-icon
            [icon]="svgIcon"
            class="icon"></ui-svg-icon>

        <span class="text">
            <ui-icon
                icon="alert"
                class="warningIcon"
                *ngIf="highlightErrorHeader"></ui-icon>
            {{ header }}
        </span>

        <div class="caret">
            <ui-icon icon="arrow-down"></ui-icon>
        </div>

        <div
            *ngIf="actionIcon"
            class="action">
            <ui-icon
                (click)="actionClicked()"
                (mouseover)="isActionHovered = true"
                (mouseleave)="isActionHovered = false"
                [icon]="actionIcon"></ui-icon>
        </div>
    </div>
    <div
        #accordionItemContent
        [style.max-height]="contentHeight"
        [style.padding]="!expanded ? padding : 0"
        class="content"
        [ngClass]="{ expanded: expanded, 'content-is-not-animated': !isContentAnimated }">
        <ng-content style="padding: 5%"></ng-content>
    </div>
</div>
