<h3>Default</h3>
<ui-button-group
    [options]="buttonOptions"
    [value]="selectedButton"
    (change)="updateButtonToggle($event)">
</ui-button-group>

<div style="margin-top: 10px;">
    Selected: {{ selectedButton ? selectedButton : 'nothing selected' }}
</div>

<h3>Pre selected</h3>
<ui-button-group
    [options]="buttonOptions"
    [value]="selectedButton2"
    (change)="updateButtonToggle2($event)">
</ui-button-group>

<div style="margin-top: 10px;">
    Selected: {{ selectedButton2 }}
</div>

<h3>With One Disable With First Active </h3>
<ui-button-group
    [options]="buttonOptionsDisable"
    [value]="selectedButton3"
    (change)="updateButtonToggle3($event)">
</ui-button-group>

<div style="margin-top: 10px;">
    Selected: {{ selectedButton3 }}
</div>

<h3>With Both Disable With First Active </h3>
<ui-button-group
    [options]="buttonOptionsDisableBoth"
    [value]="selectedButton4"
    (change)="updateButtonToggle4($event)">
</ui-button-group>

<div style="margin-top: 10px;">
    Selected: {{ selectedButton4 }}
</div>

<h3>With primary selection style </h3>
<ui-button-group
    [options]="buttonOptions"
    [value]="selectedButton5"
    [primarySelectionStyle]="true"
    (change)="updateButtonToggle5($event)">
</ui-button-group>

<div style="margin-top: 10px;">
    Selected: {{ selectedButton5 }}
</div>