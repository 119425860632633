import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'ui-logo',
    templateUrl: 'logo.component.html',
    styleUrls: ['logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UILogoComponent {
    @Input() small: boolean;
}
