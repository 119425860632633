import { Component } from '@angular/core';

@Component({
    // Demo Project components should not be forced to use ui- prefix
    // tslint:disable-next-line:component-selector
    selector: 'component-menu',
    templateUrl: './component-menu.component.generated.html',
    styleUrls: ['./component-menu.component.scss']
})
export class ComponentMenuComponent {}
