import {
    Component,
    ElementRef,
    ChangeDetectorRef,
    Input,
    OnInit,
    ChangeDetectionStrategy,
    OnDestroy
} from '@angular/core';
import { UISelectableBaseDirective } from '../selectable-list/selectable.component';
import { Subscription } from 'rxjs';
import { UIRadioButtonService } from './radio.service';

@Component({
    selector: 'ui-radio',
    templateUrl: 'radio.component.html',
    styleUrls: ['radio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: UISelectableBaseDirective,
            useExisting: UIRadioComponent,
            multi: true
        }
    ],
    host: {
        '[class.radio]': 'true',
        '[class.ui-radio]': 'true'
    }
})
export class UIRadioComponent extends UISelectableBaseDirective implements OnInit, OnDestroy {
    /**
     * When true, the input is checked.
     * Useful for setting via e.g. an event to programmatically check the input.
     */
    @Input() public override selected: boolean;

    /**
     * Group id. TODO: Better to call this "name" as in the native implementation
     */
    @Input() public groupId: string;

    /**
     * Unique ID of the component
     * This will be randomised if it is empty
     */
    @Input() public id: string;

    /**
     * Label of the radio
     */
    @Input() public label: string;

    /**
     * Allways generate a unique id to separate this from any other elements.
     * Value may not be a string or serializable.
     */
    public readonly uniqueId = this.getRandomId();

    /**
     * The unique id selected
     */
    public selectedId = '';

    private selectedChangeSubscription: Subscription;
    private radioButtonServiceSubscription: Subscription;

    constructor(
        public override host: ElementRef,
        public override changeDetectorRef: ChangeDetectorRef,
        private radioButtonService: UIRadioButtonService
    ) {
        super(host, changeDetectorRef);
    }

    ngOnInit(): void {
        if (!this.id) {
            this.id = this.getRandomId();
        }

        if (!this.groupId) {
            this.groupId = this.getRandomId();
        }

        this.updateSelectedId();

        this.selectedChangeSubscription = this._selectedChangeEmitter.subscribe(this.onSelectedChange);
        this.radioButtonServiceSubscription = this.radioButtonService.select.subscribe(
            this.onExternalSelection
        );
    }

    /**
     * On user input ()
     * @param event
     */
    public onInputChange(): void {
        const isSelected = this.uniqueId === this.selectedId;
        this.setSelected(isSelected);
    }

    /**
     * When an external radiobutton with the same groupId is selected this one should be delsected
     */
    private onExternalSelection = (selected: UIRadioComponent) => {
        if (this.groupId && this.groupId && selected !== this && selected.groupId === this.groupId) {
            this.deselect();
        }
    };

    private onSelectedChange = () => {
        this.updateSelectedId();

        if (this.selected) {
            this.radioButtonService.select.emit(this);
        }
    };

    /**
     * The native implementation is based on id and doesn't have a state.
     * So when selectedId is set equal to uniqueId the input element field will get the ":checked" psudo class
     */
    private updateSelectedId(): void {
        this.selectedId = this.selected ? this.uniqueId : '';
    }

    /**
     * Get a unique id, need to start with a letter to work.
     */
    private getRandomId(): string {
        return `id_${Date.now()}_${Math.ceil(Math.random() * 10000)}`;
    }

    /**
     * Cleanup when component is removed
     */
    override ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.selectedChangeSubscription) {
            this.selectedChangeSubscription.unsubscribe();
        }
        if (this.radioButtonServiceSubscription) {
            this.radioButtonServiceSubscription.unsubscribe();
        }
    }
}
