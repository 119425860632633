import { Component } from '@angular/core';

@Component({
    selector: 'ui-toggle-switch-docs-preview',
    templateUrl: './toggle-switch.docs.preview.html',
    styleUrls: ['./toggle-switch.docs.preview.scss'],
})
export class UIToggleSwitchDocsPreview {

    public disabled = false;
    public on = true;
    public cow = true;
    public chicken = true;

    public checkboxOptions: { label: string, id: number }[] = [
        { label: 'Option 1', id: 0 },
        { label: 'Option 2', id: 1 },
        { label: 'Option 3', id: 2 }
    ];

    public selectedOption?: IOption;
    public multiSelectedOptions?: IOption[];

    public setOnState(on: boolean) {
        this.on = on;
    }

    public toggleDisabledState() {
        this.disabled = !this.disabled;
    }

}

interface IOption {
    label: string, id: number
}
