<div class="left">
    <ui-icon
        class="checkmark"
        *ngIf="isToggable"
        [class.hidden]="!toggle"
        [icon]="'checkmark'"></ui-icon>
    <ui-icon
        class="icon"
        *ngIf="icon"
        [icon]="icon"></ui-icon>
    <ui-svg-icon
        class="icon"
        *ngIf="svgIcon"
        [icon]="svgIcon"></ui-svg-icon>
</div>
<div class="content">
    <ng-content></ng-content>
</div>
<div class="right">
    <ui-loader
        *ngIf="loading"
        class="loader">
    </ui-loader>
    <ui-icon
        *ngIf="!loading && uiDropdownTarget"
        class="arrow"
        icon="arrow-right">
    </ui-icon>
    <div
        *ngIf="label"
        class="tiny-label">
        {{ label }}
    </div>
</div>
