import { Component } from '@angular/core';
import { delay, of } from 'rxjs';

@Component({
    selector: 'ui-breadcrumbs-docs-preview',
    templateUrl: './breadcrumbs.docs.preview.html',
    styleUrls: ['./breadcrumbs.docs.preview.scss'],
})
export class UIBreadcrumbsDocsPreview {

    manyCrumbs = Array(10).fill('Trololo');
    manyCrumbsAsync$ = of(Array(100).fill('Async Trololo')).pipe(delay(1000));

    navigate(): void {
        alert('navigate clicked');
    }
}
