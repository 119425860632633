import { Component } from '@angular/core';

@Component({
    selector: 'ui-selectable-list-docs-preview',
    templateUrl: './selectable-list.docs.preview.html',
    styleUrls: ['./selectable-list.docs.preview.scss'],
})
export class UISelectableListDocsPreview {

    disabled = false;
    on = true;
    on2 = true;

    options: IOption[] = [
        { label: 'Option 1', id: 0, type: 'radio' },
        { label: 'Option 2', id: 1, type: 'directive' },
        { label: 'Option 3', id: 2, type: 'checkbox' }
    ];
    multiOptions: IOption[] = [
        { label: 'Option 1', id: 0, type: 'radio' },
        { label: 'Option 2', id: 1, type: 'directive' },
        { label: 'Option 3', id: 2, type: 'checkbox' }
    ];

    selectedOption?: IOption;
    selectedOptions?: IOption[];
}

interface IOption {
    label: string,
    id: number,
    type: 'directive' | 'radio' | 'checkbox';
}
