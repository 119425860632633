/**
 * Decorator for debouncing calls to a method
 * @param milliseconds
 */
export function UIDebounce(milliseconds: number = 100): any {
    return function (
        _target: any,
        _propertyKey: string,
        descriptor: PropertyDescriptor
    ): PropertyDescriptor {
        const originalMethod = descriptor.value;
        descriptor.value = debounce(originalMethod, milliseconds);
        return descriptor;
    };
}

/**
 * Debounce calls to a method
 * @param func
 * @param limit
 */
export const debounce = (func: any, milliseconds: number = 100): (() => void) => {
    let inDebounce: any;
    return function (this: any): void {
        const context = this;
        const args = arguments;
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => func.apply(context, args), milliseconds);
    };
};
