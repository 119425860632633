import { Directive, ElementRef, HostListener, Input, Renderer2, ViewContainerRef } from '@angular/core';
import { combineLatest, finalize, fromEvent, Observable, startWith, takeUntil } from 'rxjs';
import { UIDragdropService } from './dragdrop.service';
import { DragpreviewComponent } from './dragpreview/dragpreview.component';
import { UIDropDirective } from './drop.directive';

export interface UICustomComponent {
    component?: any;
    props: any;
}

export interface UIDragDropConfig {
    offsetX: number;
    offsetY: number;
    scollContainerSelector?: string;
}

// tslint:disable-next-line:directive-selector old settings
@Directive({
    selector: '[uiDragDrop]',
    hostDirectives: [
        {
            directive: UIDropDirective,
            inputs: ['dropData', 'isAllowedToDrop'],
            outputs: ['itemsDropped']
        }
    ]
})
export class UIDragDropDirective<T> {
    /** Directive for drag and drop, supports custom dragelement, set it with custom component
     * Consumer needs to set theese classes 'ui-dropzone-allowed' 'ui-dropzone-not-allowed', due to viewencapsulation
     */

    @Input() dragData: T[];
    @Input() config: UIDragDropConfig = {
        offsetX: 0,
        offsetY: -40,
        scollContainerSelector: '.scroll-wrapper'
    };
    @Input() customComponent: UICustomComponent; // Will default to DragpreviewComponent check createComponent

    constructor(
        private eleRef: ElementRef,
        private renderer: Renderer2,
        private service: UIDragdropService<T>,
        private viewContainer: ViewContainerRef
    ) {}

    private dragElement?: any;

    private createComponent(): any {
        const defaultCustomComp: UICustomComponent = {
            component: DragpreviewComponent,
            props: { text: 'Placeholder text' }
        };

        this.customComponent = {
            component: this.customComponent?.component ?? defaultCustomComp.component,
            props: this.customComponent?.props ?? defaultCustomComp.props
        };

        // Create component that is being dragged and populate its inputs
        const componentRef = this.viewContainer.createComponent(this.customComponent.component);

        Object.keys(this.customComponent.props).forEach(property => {
            componentRef.setInput(property, this.customComponent.props[property]);
        });

        return componentRef.location.nativeElement;
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(): void {
        // Initial dragging starting
        this.service.isDragging = true;
        // Save the data of the one who initiated the move so the other directives will know about it
        this.service.dragData = this.dragData;

        const mouseEvents = [fromEvent<MouseEvent>(document.body, 'mousemove')];

        // If we are dragging inside a scrollable container we need to get its scrollTop,
        // and add a listener for scroll events to get correct scrollTop during mousemove
        let scrollTop = 0;
        if (this.config.scollContainerSelector) {
            const scrollContainer = document.querySelector(this.config.scollContainerSelector);
            scrollTop = scrollContainer?.scrollTop || 0;

            if (scrollContainer) {
                mouseEvents.push(
                    fromEvent<MouseEvent>(scrollContainer, 'scroll').pipe(startWith({} as MouseEvent))
                );
            }
        }

        this.startObservingMouseMove(mouseEvents, scrollTop);
    }

    private startObservingMouseMove(mouseEvents: Observable<MouseEvent>[], scrollTop: number): void {
        combineLatest(mouseEvents)
            .pipe(
                takeUntil(fromEvent<MouseEvent>(document.body, 'mouseup')),
                finalize(() => {
                    this.viewContainer.clear();
                    this.service.isDragging = false;
                    this.dragElement = undefined;
                    this.renderer.removeClass(document.body, 'ui-body-grabbing');
                })
            )
            .subscribe(val => {
                if (!this.dragElement) {
                    // Set correct cursor aka class to show that we are in dragging state
                    this.renderer.addClass(document.body, 'ui-body-grabbing');
                    this.renderer.addClass(this.eleRef.nativeElement, 'ui-dropzone-allowed');

                    this.dragElement = this.createComponent();
                    // Make it draggable everywhere
                    this.renderer.setStyle(this.dragElement, 'position', 'absolute');
                    this.renderer.setStyle(this.dragElement, 'z-index', '9999');
                }

                const mousemove = val[0];

                if (val[1]?.target) {
                    scrollTop = (val[1].target as HTMLElement).scrollTop;
                }

                this.renderer.setStyle(
                    this.dragElement,
                    'left',
                    `${mousemove.pageX + this.config.offsetX}px`
                );

                this.renderer.setStyle(
                    this.dragElement,
                    'top',
                    `${mousemove.pageY + this.config.offsetY + scrollTop}px`
                );
            });
    }
}
