<layout>
    <ng-container ngProjectAs="leftRegion"> </ng-container>

    <ng-container ngProjectAs="centerRegion">
        <ui-panel name="Overview">
            <h2>Purpose of UI</h2>
            To create a reliable and highly reusable UI library to use in every app we create. The
            library should be fast to modify and easy to use. UX department should have the
            responsibility over the look of it.
            <br />
            <br />
            <h2>When to add a component to UI?</h2>
            <h3>Add it if it passes these requirements quite well.</h3>
            <ol>
                <li>
                    Your component will be used in multiple apps
                    <strong>according to sketches or UX team.</strong>
                </li>
                <li>
                    The component is <strong>unique</strong> and doesn’t exist already as something
                    similar. In those cases it may be better to add more options to already existing
                    components or create new component in the app you need it for.
                </li>
                <li>
                    The component is well thought through and will be able to work in several different
                    use cases.
                </li>
                <li>The component works with our theming.</li>
                <li>The component have passed code review and is well performant.</li>
            </ol>
            <h3>Other reasons</h3>
            <ol>
                <li>A component in UI really need this component to work.</li>
                <li>Other “not that good” reasons that still may qualify you component?</li>
            </ol>

            <h3>What about directives, pipes, help classes, and functions etc?</h3>
            Same as components!

            <h2>How to add a component to Bannerflow UI?</h2>
            <ul>
                <li>
                    A good thing could be to start adding it to the app you need it to live in first.
                </li>
                <li>
                    Have a look in Mathias new
                    <a
                        href="https://bannerflow.atlassian.net/secure/RapidBoard.jspa?rapidView=50&projectKey=UI"
                        target="_blank"
                        >UI board</a
                    >
                    for some ticket for this.
                </li>
                <li>
                    Write in
                    <a
                        href="https://bannerflow.slack.com/messages/C6NN76GLS"
                        target="_blank"
                        >#frontend-devs</a
                    >
                    slack channel and ask!
                </li>
                <li>
                    Scout the
                    <a
                        href="https://github.com/nordicfactory"
                        target="_blank"
                        >repo</a
                    >
                    for something similar.
                </li>
                <li>
                    Have a chat about you idea with a colleague (good to grab one that have done a few
                    components here before)
                </li>
                <li>
                    Do a pull request. For more info check the
                    <a
                        href="https://wikiflow.atlassian.net/wiki/spaces/DEV/pages/894599169/Bannerflow+UI"
                        >wiki</a
                    >
                    for Bannerflow UI pull requests.
                </li>
            </ul>

            <h2>Setting up the project</h2>
            See the
            <a
                href="https://github.com/nordicfactory/bannerflow-ui"
                target="_blank"
                >banneflow-ui</a
            >
            repository for instructions on setting up and building the project.
        </ui-panel>
    </ng-container>
</layout>
