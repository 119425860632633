<h3>Multiselect With Token Field</h3>
    <ui-select  [tokenField]="true"
                [multiSelect]="true"
                class="tokenFields"
                [(selected)]="selectedMutliSelectToken">

    <ui-option *ngFor="let item of multiSelectToken" [disabled]="item.disabled!" [value]="item">{{item.value}}</ui-option>
</ui-select>

<h3>Multiselect With Token Field and Flags</h3>
    <ui-select
        [tokenField]="true"
        [multiSelect]="true"
        class="tokenFields"
        [(selected)]="selectedMultiSelectWithFlagsAndToken">

        <ng-template
            let-value="value"
            ui-select-token-field-template>
            {{value.language}} <span class="dialect">{{value.language}} </span>
        </ng-template>

        <ui-option *ngFor="let item of multiSelectValueWithFlags" [flag]="item.culuture" [value]="item">
                {{item.language}} <span class="dialect">{{item.language}}</span>
        </ui-option>
</ui-select>

<h3>Multiselect With Token Field and Icons</h3>
    <ui-select
                [tokenField]="true"
                [multiSelect]="true"
                class="tokenFields"
                [(selected)]="singleSelectedMultiSelectWithIcons">
    <ui-option *ngFor="let item of multiSelectValueWithIcons" [icon]="item.icon" [value]="item">{{item.name}}</ui-option>
</ui-select>

<h3>Multiselect With flags</h3>
    <ui-select [searchable]="true"
                [multiSelect]="true"
                class="multiSelect"
                [(selected)]="selectedMultiSelectWithFlags"
                (selectedChange)="flagsValueChanged($event)">
    <ui-option *ngFor="let item of multiSelectValueWithFlags" [flag]="item.culuture" [value]="item">{{item.language}}</ui-option>
</ui-select>

<h3>Select With svg icons</h3>
<ui-select
            class="multiSelect"
            [(selected)]="singleSelectedSelectWithSvgIcons">
    <ui-option *ngFor="let item of multiSelectValueWithSvgIcons" [svgIcon]="item.svgIcon" [value]="item">{{item.name}}</ui-option>
</ui-select>

<h3>Select With icons</h3>
<ui-select
            class="multiSelect"
            [(selected)]="singleSelectedSelectWithIcons">
    <ui-option *ngFor="let item of multiSelectValueWithIcons" [icon]="item.icon" [value]="item">{{item.name}}</ui-option>
</ui-select>

<h3>Select With images</h3>
<ui-select
            class="multiSelect"
            [multiSelect]="true"
            [(selected)]="singleSelectedMutliSelectWithImages"
            (selectedChange)="imageValueChanged($event)">
    <ui-option *ngFor="let item of multiSelectValueWithImages" [image]="item.image"  [value]="item" >{{item.name}}</ui-option>
</ui-select>

<h3>Select With images Single Select with border item</h3>
<ui-select
            class="multiSelect"
            [(selected)]="singleSelectedImage"
            (selectedChange)="imageValueChanged($event)">
    <ui-option *ngFor="let item of multiSelectValueWithImages" [image]="item.image" [value]="item" [withBorder] = "true">{{item.name}}</ui-option>
</ui-select>

<h3>Select With images Single Select without border item</h3>
<ui-select
            class="multiSelect"
            [(selected)]="singleSelectedImage"
            (selectedChange)="imageValueChanged($event)">
    <ui-option *ngFor="let item of multiSelectValueWithImages" [image]="item.image" [value]="item" >{{item.name}}</ui-option>
</ui-select>
<h3>Searchable</h3>
<ui-select
    [searchable]="true"
    class="multiSelect"
    (previewChange)="previewChanged($event)"
    [(selected)]="searchvalue">
    <ui-option value="Hello World">Hello World</ui-option>
    <ui-option value="Haj">Haj</ui-option>
    <ui-option value="Bannerflow">Bannerflow</ui-option>
    <ui-option value="One">One</ui-option>
    <ui-option value="Two">Two</ui-option>
    <ui-option value="Three">Three</ui-option>
    <ui-option value="Four">Four</ui-option>
    <ui-option value="Five">Five</ui-option>
    <ui-option value="Six">Six</ui-option>
    <ui-option value="searchable">searchable</ui-option>
</ui-select>
<br>
<br>
<br>

<h3>Select with preview & selection emit</h3>
<ui-select
    [(selected)]="value"
    (previewChange)="previewChanged($event)"
    (selectedChange)="valueChanged($event)">
    <ui-option value="Hello">Hello</ui-option>
    <ui-option value="World">World</ui-option>
    <ui-option value="Bannerflow">Bannerflow</ui-option>
</ui-select>
<br>
Currently selected: {{ value }} <br/>
Currently previewed: {{ preview }}
<br>
<br>
<h3>Custom label</h3>
<ui-select
    [(selected)]="value"
    (selectedChange)="valueChanged($event)">
    <ng-container ui-select-label>
        <ui-flag culture="se"></ui-flag>&nbsp;&nbsp;{{ value }}
    </ng-container>
    <ui-option value="Hello">Hello</ui-option>
    <ui-option value="World">World</ui-option>
    <ui-option value="Bannerflow">Bannerflow</ui-option>
</ui-select>
<br>
<br>
<br>
<h3>Discrete</h3>
<ui-select
    [discrete]="true"
    [(selected)]="value"
    (selectedChange)="valueChanged($event)">
    <ui-option value="Hello">Hello</ui-option>
    <ui-option value="World">World</ui-option>
    <ui-option value="Bannerflow">Bannerflow</ui-option>
</ui-select>
<br>
<br>
<br>
<h3>Use target width</h3>
<ui-select
    width="100"
    theme="tiny"
    [useTargetWidth]="true"
    [(selected)]="value"
    (selectedChange)="valueChanged($event)">
    <ui-option value="Hello">Hello</ui-option>
    <ui-option value="World">World</ui-option>
    <ui-option value="Bannerflow">Bannerflow</ui-option>
</ui-select>
<br>
