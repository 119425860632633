<div
    *ngIf="multiSelect && !tokenField"
    class="checkbox-container">
    <ui-svg-icon
        *ngIf="multiSelect && !tokenField && selected"
        icon="checkmark-large"
        class="checkbox">
    </ui-svg-icon>
</div>

<ui-icon
    *ngIf="icon && !svgIcon"
    class="icon"
    [icon]="icon"></ui-icon>
<ui-svg-icon
    *ngIf="svgIcon"
    class="icon"
    [icon]="svgIcon"></ui-svg-icon>

<ui-flag
    *ngIf="flag"
    class="flag"
    [size]="tokenField ? 'tiny' : 'small'"
    [culture]="flag"></ui-flag>
<ui-image
    *ngIf="image !== undefined"
    class="image"
    [image]="image"
    [bordered]="withBorder"></ui-image>

<div class="container">
    <ng-content></ng-content>
</div>
