import { Injectable } from '@angular/core';
import { UIAlertDialogComponent } from './alert-dialog.component';
import { IUIAlertDialogConfig, UIALERTDIALOG_DEFAULT_CONFIG } from './alert-dialog-config.interface';
import { UIDialogService } from '../dialog/dialog.service';

@Injectable({ providedIn: 'root' })
export class UIAlertDialogService {
    constructor(public dialogService: UIDialogService) {}

    /**
     * Open confirm dialog and wait for user to accept or close.
     * @param config
     */
    public async show(config: IUIAlertDialogConfig): Promise<boolean> {
        const dialogConfig = { ...UIALERTDIALOG_DEFAULT_CONFIG, ...config };

        // Create & open a new dialog
        const dialogRef = this.dialogService.openComponent(UIAlertDialogComponent, dialogConfig);

        // Wait for view to initialize
        await dialogRef.afterViewInit;

        // Wait for user to confirm or not
        const confirm = await dialogRef.subComponentRef.instance.initiate(config);

        // Close after confirmation
        dialogRef.close();

        return confirm;
    }
}
