<input
    type="radio"
    [id]="id"
    [disabled]="disabled"
    [name]="groupId"
    [value]="uniqueId"
    [(ngModel)]="selectedId"
    (change)="onInputChange()"
    [checked]="selected" />

<div class="check"></div>

<label
    *ngIf="label"
    class="label"
    [for]="id">
    {{ label }}
</label>
