import { Injectable, Injector, ComponentRef, TemplateRef, Type } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { UIDialogComponent } from './dialog.component';
import { UIDialogRef } from './dialog-ref';
import { IUIDialogConfig, UIDIALOG_DEFAULT_CONFIG } from './dialog-config.interface';

@Injectable({ providedIn: 'root' })
export class UIDialogService {
    constructor(
        private injector: Injector,
        private overlay: Overlay
    ) {}

    /**
     * Open a dialog containg the template passed to it
     * @param config
     * @param templateRef
     */
    openTemplate(templateRef: TemplateRef<any>, config: IUIDialogConfig): UIDialogRef {
        return this.open(config, templateRef);
    }

    /**
     * Open a dialog containg an instance of a the component passed to it.
     * @param config
     * @param componentClass
     */
    openComponent(componentClass: Type<any>, config: IUIDialogConfig): UIDialogRef {
        return this.open(config, undefined, componentClass);
    }

    /**
     * Open dialog with template / dialog
     */
    private open(
        config: IUIDialogConfig,
        templateRef?: TemplateRef<any>,
        componentClass?: Type<any>
    ): UIDialogRef {
        // Override default configuration
        const dialogConfig = { ...UIDIALOG_DEFAULT_CONFIG, ...config };

        // Returns an OverlayRef which is a PortalHost
        const overlayRef = this.createOverlay(dialogConfig);

        // Instantiate remote control
        const dialogRef = new UIDialogRef(overlayRef, dialogConfig, templateRef, componentClass);
        const dialogComponent = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);

        dialogRef.componentInstance = dialogComponent;

        // Apply settings for backdrop
        if (dialogConfig.hasBackdrop && dialogConfig.backdropClickClose) {
            overlayRef.backdropClick().subscribe(_ => dialogComponent.close());
        }

        if (dialogConfig.theme) {
            dialogComponent.host.nativeElement.setAttribute('ui-theme', dialogConfig.theme);
        }

        return dialogRef;
    }

    private createOverlay(config: IUIDialogConfig): OverlayRef {
        const overlayConfig = this.getOverlayConfig(config);
        return this.overlay.create(overlayConfig);
    }

    private attachDialogContainer(
        overlayRef: OverlayRef,
        config: IUIDialogConfig,
        dialogRef: UIDialogRef
    ): UIDialogComponent {
        const injector = this.createInjector(config, dialogRef);

        const containerPortal = new ComponentPortal(UIDialogComponent, null, injector);
        const containerRef: ComponentRef<UIDialogComponent> = overlayRef.attach(containerPortal);

        return containerRef.instance;
    }

    /**
     * Inject properties to component contructor
     * @param config
     * @param dialogRef
     */
    private createInjector(config: IUIDialogConfig, dialogRef: UIDialogRef): Injector {
        return Injector.create({
            providers: [{ provide: UIDialogRef, useValue: dialogRef }],
            parent: config.injector || this.injector
        });
    }

    private getOverlayConfig(config: IUIDialogConfig): OverlayConfig {
        const positionStrategy = this.overlay
            .position()
            .global()
            .centerHorizontally()
            .centerVertically();

        // Convert to string of multiple classes that HTMLElement.classList.add supports.
        const panelClass = Array.isArray(config.panelClass)
            ? config.panelClass
            : config.panelClass && config.panelClass.split(' ');

        const overlayConfig = new OverlayConfig({
            width: config.width,
            height: config.height,
            maxWidth: config.maxWidth,
            maxHeight: config.maxHeight,
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass: panelClass,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });

        return overlayConfig;
    }

    hasDialog(id: string | undefined): boolean {
        if (!id) {
            return false;
        }
        return UIDialogRef.__openDialogs.some(dialog => dialog.config.id === id);
    }
}
