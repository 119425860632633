import { Component, OnDestroy, TemplateRef, HostListener } from '@angular/core';
import { IUIDialog } from '../dialog/dialog.interface';
import { IUIErrorDialogConfig, UIERRORDIALOG_DEFAULT_CONFIG } from './error-dialog-config.interface';
import { IBFHttpError } from './error.interface';

@Component({
    selector: 'ui-error-dialog',
    templateUrl: 'error-dialog.component.html',
    styleUrls: ['./error-dialog.component.scss']
})
export class UIErrorDialogComponent implements IUIDialog, OnDestroy {
    public config: IUIErrorDialogConfig;
    public dialogTemplate: TemplateRef<any>;
    public error: any;

    private resolve: (value: IBFHttpError) => void;
    private confirmPromise?: Promise<IBFHttpError>;

    /**
     * Initiate the error dialog component.
     * Will return a promise which will resolve to true if confirmed by user.
     * If cancelled it will resolve to false.
     * @param error
     */
    public initiate(config: IUIErrorDialogConfig, error: IBFHttpError): Promise<IBFHttpError> {
        this.config = { ...UIERRORDIALOG_DEFAULT_CONFIG, ...config };
        this.error = error;

        this.confirmPromise = new Promise<IBFHttpError>(resolve => {
            this.resolve = resolve;
        });

        return this.confirmPromise;
    }

    /**
     * The user pressed reload
     */
    public reload(): void {
        window.location.reload();
    }

    public close(): void {
        this.resolve({});
    }

    /**
     * Clean up component when removed
     */
    public ngOnDestroy(): void {
        if (this.resolve) {
            this.resolve(this.error);
        }
    }

    @HostListener('window:keyup', ['$event'])
    public keyEvent(event: KeyboardEvent): void {
        if (event.code === 'Enter') {
            this.reload();
        } else if (event.code === 'Escape') {
            this.resolve(this.error);
        }
    }
}
