export class DownloadUtilities {
    public static downloadToText(textInMultiLine: string, fileName: string): void {
        const anchorElement: HTMLAnchorElement = document.createElement('a');

        anchorElement.setAttribute(
            'href',
            `data:text/plain;charset=utf-8,${encodeURIComponent(textInMultiLine)}`
        );
        anchorElement.setAttribute('download', fileName);
        anchorElement.style.display = 'none';
        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
    }
}
