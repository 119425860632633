<!-- HEADER -->
<ui-list-header
    *ngIf="showHeader && layout !== 'grid'"
    (sortClick)="onSortClick($event)"
    [columns]="columns"
    [allSelected]="allSelected"
    [indeterminate]="indeterminate"
    [selectionType]="selectionType"
    [initialized]="layoutInitialized"
    [ngClass]="{ sticky: stickyHeader }"
    (selectAll)="selectAll()"
    (deselectAll)="deselectAll()">
</ui-list-header>

<!-- BODY -->
<cdk-tree
    [ui-in-view]="true"
    (ui-in-view-enter)="resizeTable()"
    class="list-body"
    [dataSource]="nodeSource"
    [treeControl]="treeControl"
    [trackBy]="trackBy"
    [ngClass]="{ grid: layout === 'grid', visible: layoutInitialized }"
    #listBody>
    <!-- NORMAL NODE -->
    <cdk-tree-node
        [ngClass]="{
            selected: selection.isSelected(node),
            row: layout !== 'grid',
            box: layout === 'grid',
            disabled: node.data.disabled,
            'non-folder': enableDrillDown
        }"
        (click)="selectFlatNode(node, $event)"
        *cdkTreeNodeDef="let node"
        class="row"
        (uiDraggableDragStart)="updateSelection(node)"
        [uiDraggable]="dragAndDrop ? (selection.selected.length ? selection.selected : node) : false">
        <!-- List view -->
        <ng-container *ngIf="layout !== 'grid'">
            <div
                *ngIf="selectionType === 'checkbox' || selectionType === 'radio'"
                class="cell align-center"
                cdkTreeNodePadding
                [cdkTreeNodePaddingIndent]="40">
                <ng-container *ngIf="selectionType === 'checkbox'">
                    <ng-container
                        *ngTemplateOutlet="
                            checkboxTemplate;
                            context: { $implicit: node }
                        "></ng-container>
                </ng-container>
                <ng-container *ngIf="selectionType === 'radio'">
                    <ng-container
                        *ngTemplateOutlet="radioTemplate; context: { $implicit: node }"></ng-container>
                </ng-container>
            </div>
            <ui-list-cell
                *ngFor="let column of columns; let last = last; let first = first"
                cdkTreeNodePadding
                (click)="
                    selectionType === 'radio' &&
                        !node.edit &&
                        !node.data.disabled &&
                        toggle(node.data, !!$event)
                "
                [cdkTreeNodePaddingIndent]="
                    first && selectionType !== 'checkbox' && selectionType !== 'radio' ? 40 : 5
                "
                [column]="column"
                [ngClass]="{
                    'list-cell': selectionType === 'radio' && !node.edit,
                    first: first,
                    last: last,
                    indent: first && selectionType !== 'checkbox' && selectionType !== 'radio',
                    disabled: node.data.disabled
                }"
                [data]="node.data"
                [edit]="node.edit"
                [nodeChange$]="nodeChange$"
                (editDone)="editedData($event)">
            </ui-list-cell>
        </ng-container>

        <!-- Grid view -->
        <ng-container *ngIf="layout === 'grid'">
            <ui-list-grid-cell
                *ngIf="grid"
                [grid]="grid"
                [data]="node.data"
                [ngClass]="{ disabled: node.data.disabled }">
            </ui-list-grid-cell>
        </ng-container>
    </cdk-tree-node>

    <!-- Control nodes (Loader, empty etc) -->
    <cdk-tree-node
        (click)="selectFlatNode(node, $event)"
        *cdkTreeNodeDef="let node; when: isControlNode"
        class="row">
        <ng-container
            *ngTemplateOutlet="
                node.data.template;
                context: { $implicit: node, columns: columns, node: node }
            "></ng-container>
    </cdk-tree-node>

    <!-- Expandable nodes -->
    <cdk-tree-node
        *cdkTreeNodeDef="let node; when: isExpandable"
        (click)="selectFlatNode(node, $event)"
        [ngClass]="{ selected: selection.isSelected(node), expanded: isExpanded(node) }"
        class="row folder"
        (uiDraggableDragStart)="updateSelection(node)"
        [uiDraggable]="dragAndDrop ? (selection.selected.length ? selection.selected : node) : false"
        [uiDropZone]="dragAndDrop ? node : false"
        (uiDropZoneDrop)="onDrop($event)">
        <div
            *ngIf="selectionType === 'checkbox'"
            class="cell align-center"
            cdkTreeNodePadding
            [cdkTreeNodePaddingIndent]="40">
            <ng-container *ngIf="selectionType === 'checkbox'">
                <ng-container
                    *ngTemplateOutlet="checkboxTemplate; context: { $implicit: node }"></ng-container>
            </ng-container>
        </div>

        <ui-list-folder-cell
            *ngFor="let column of columns; let last = last; let first = first"
            cdkTreeNodePadding
            [cdkTreeNodePaddingIndent]="
                first && selectionType !== 'checkbox' && selectionType !== 'radio' ? 40 : 5
            "
            [ngClass]="{
                first: first,
                last: last,
                indent: first && selectionType !== 'checkbox' && selectionType !== 'radio'
            }"
            (toggleClick)="toggleNodeCollapse(node)"
            (nameClick)="browseNode(node)"
            [expandable]="expandable"
            [first]="first"
            [column]="column"
            [data]="node.data"
            [enableDrillDown]="enableDrillDown">
        </ui-list-folder-cell>
    </cdk-tree-node>
</cdk-tree>

<!-- ROW TEMPLATE -->
<ng-template
    #rowTemplate
    let-node="node"
    let-columns="columns">
    <ng-container *ngIf="!node.type">
        <ui-list-cell
            cdkTreeNodePadding
            *ngFor="let column of columns"
            [column]="column"
            [data]="node.data">
        </ui-list-cell>
    </ng-container>
    <ng-container *ngIf="node.type && node.template">
        <ng-container
            *ngTemplateOutlet="
                node.template;
                context: { $implicit: node, columns: columns }
            "></ng-container>
    </ng-container>
</ng-template>

<!-- BACK NAVIGATION -->
<ng-template
    #backNavigationTemplate
    let-node>
    <div
        [uiDropZone]="dragAndDrop && allowBackDrop && node"
        (uiDropZoneDrop)="onDrop($event, true)"
        class="cell full-width control-cell back"
        (click)="browseNode(node)">
        <ui-svg-icon
            class="icon"
            icon="arrow-left">
        </ui-svg-icon>
        <!-- TODO: Make this generic -->
        <span class="text">{{ node.data?.parent?.title || node.data?.parent?.name || 'Go back' }}</span>
    </div>
</ng-template>

<ng-template #headerCheckboxemplate></ng-template>

<!-- CHECKBOX TEMPLATE -->
<ng-template
    #checkboxTemplate
    let-node>
    <ui-checkbox
        *ngIf="node.level === 0"
        (click)="!node.data.disabled && toggle(node.data)"
        [selected]="selection.isSelected(node)"
        [disabled]="node.data.disabled">
    </ui-checkbox>
</ng-template>

<!-- CHECKBOX TEMPLATE -->
<ng-template
    #radioTemplate
    let-node>
    <ui-radio
        [groupId]="radioGroupId"
        (selectedChange)="!selection.isSelected(node) && toggle(node.data, $event)"
        [selected]="selection.isSelected(node)"
        [disabled]="node.data.disabled">
    </ui-radio>
</ng-template>

<!-- LOADER TEMPLATE -->
<ng-template
    #loaderTemplate
    let-node>
    <div
        class="cell full-width align-center control-cell loader"
        [ui-in-view]="true"
        (ui-in-view-enter)="loadMore(node)">
        <ui-loader [inline]="true"></ui-loader>
    </div>
</ng-template>

<!-- EMPTY TEMPLATE -->
<ng-template #emptyTemplate>
    <div class="cell full-width align-center control-cell empty">
        <span class="text">No items to show</span>
    </div>
</ng-template>

<!-- NO MATCHES TEMPLATE -->
<ng-template #noMatchTemplate>
    <div class="cell full-width align-center control-cell empty">
        <span class="text">No matches for your search</span>
    </div>
</ng-template>
