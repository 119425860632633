import { EventCallbackStore, Callback } from '../types/event-emitter';
export class EventEmitter {
    public eventCallbacks: EventCallbackStore = {};
    public thisArg = null;

    public on(event: string, callback: Callback): this;
    public on(event: string, prop: string, callback: Callback): this;
    public on(event: string, propOrCallback: string | Callback, callback?: Callback): this;
    public on(event: string, propOrCallback: string | Callback, callback?: Callback): this {
        if (typeof propOrCallback === 'string') {
            event = `${event}:${propOrCallback}`;
        } else {
            callback = propOrCallback;
        }
        if (!this.eventCallbacks[event]) {
            this.eventCallbacks[event] = [];
        }
        if (this.eventCallbacks[event].indexOf(callback!) === -1) {
            this.eventCallbacks[event].push(callback!);
        }
        return this;
    }

    public once(event: string, callback: Callback): this;
    public once(event: string, prop: string, callback: Callback): this;
    public once(event: string, propOrCallback: string | Callback, callback?: Callback): this {
        const self = this;
        if (typeof propOrCallback === 'string') {
            event = `${event}:${propOrCallback}`;
        } else {
            callback = propOrCallback;
        }
        this.on(event, function temporary(): void {
            self.off(event, temporary);
            callback!();
        });
        return this;
    }

    public off(event: string, callback: Callback): this;
    public off(event: string, prop: string, callback: Callback): this;
    public off(event: string): this;
    public off(event: string, propOrCallback?: string | Callback, callback?: Callback): this {
        if (typeof propOrCallback !== 'undefined') {
            if (typeof propOrCallback === 'string') {
                event = `${event}:${propOrCallback}`;
            } else {
                callback = propOrCallback;
            }
        }
        if (this.eventCallbacks[event]) {
            const callbacks = this.eventCallbacks[event];
            for (let i = 0; i < callbacks.length; i++) {
                if (this.eventCallbacks[event][i] === callback) {
                    this.eventCallbacks[event].splice(i, 1);
                }
            }
        }

        return this;
    }

    public emit(event: string, args?: any[]): this;
    public emit(event: string, prop: string, args?: any[]): this;
    public emit(event: string, propOrArgs?: string | any[], args?: any[]): this {
        if (typeof propOrArgs === 'string') {
            event = `${event}:${propOrArgs}`;
        } else {
            args = propOrArgs;
        }
        if (this.eventCallbacks[event]) {
            for (const callback of this.eventCallbacks[event]) {
                callback.apply(this.thisArg, args!);
            }
        }

        return this;
    }

    public clearEvents(): void {
        this.eventCallbacks = {};
    }
}
