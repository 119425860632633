import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    computed,
    effect,
    ElementRef,
    inject,
    input,
    model,
    untracked,
    viewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, firstValueFrom, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { CREATIVE_PREVIEW_HOST, PreviewType } from '../../types/creative-preview';

class HttpError extends Error {
    constructor(
        message: string,
        public originalError: unknown
    ) {
        super(message);
        this.name = 'HttpError';
    }
}

type Command = { action: 'stop' | 'play' | 'pause' };
type SeekCommand = { action: 'seek'; time: number };
type MuteCommand = { action: 'mute'; isMuted: boolean };
type AdApiCommand = Command | SeekCommand | MuteCommand;

/**
 * Provides a creative preview.
 *
 * This component calls the CreativePreviewService.
 * Therefore, a global http interceptor needs to be configured to attach the bearer token automatically to requests.
 *
 * Configure the preview host in the containing module `CREATIVE_PREVIEW_HOST`:
 *   ```ts
 *   import { CREATIVE_PREVIEW_HOST } from '@bannerflow/ui';
 *
 *   {
 *       provide: CREATIVE_PREVIEW_HOST,
 *       useValue: 'http://api.bannerflow.com/preview'
 *   }
 *   ```
 */
@Component({
    selector: 'ui-creative-preview',
    standalone: true,
    templateUrl: './creative-preview.component.html',
    styleUrl: './creative-preview.component.scss'
})
export class UICreativePreviewComponent {
    private previewFrame = viewChild<ElementRef<HTMLIFrameElement>>('previewFrame');
    creativeset = input.required<string>();
    creative = input.required<string>();
    type = input<PreviewType>('animated');
    responsive = input<boolean>(true);
    token = model<string | undefined>();

    previewUrl = computed(() => {
        const creativeset = untracked(() => this.creativeset());
        const creative = untracked(() => this.creative());
        const type = this.type();
        const responsive = this.responsive();
        const token = this.token();

        if (!creativeset || !creative) {
            throw new Error('Creativeset and creative id must be set');
        }

        if (!token) {
            return;
        }

        return this.sanitizer.bypassSecurityTrustResourceUrl(
            this.getCreativePreviewUrl(creativeset, creative, type, responsive, token)
        );
    });

    private readonly previewOrigin = inject(CREATIVE_PREVIEW_HOST);
    private readonly http = inject(HttpClient);
    private readonly sanitizer = inject(DomSanitizer);

    constructor() {
        effect(
            async () => {
                const creativeset = this.creativeset();
                const creative = this.creative();
                const accessToken = await firstValueFrom(this.getAccessToken(creativeset, creative));
                if (accessToken) {
                    this.token.set(accessToken);
                }
            },
            { allowSignalWrites: true }
        );
    }

    play(): void {
        this.sendCommandToIframe({ action: 'play' });
    }

    pause(): void {
        this.sendCommandToIframe({ action: 'pause' });
    }

    stop(): void {
        this.sendCommandToIframe({ action: 'stop' });
    }

    seek(seconds: number): void {
        this.sendCommandToIframe({ action: 'seek', time: seconds });
    }

    mute(isMuted: boolean): void {
        this.sendCommandToIframe({ action: 'mute', isMuted });
    }

    private getCreativePreviewUrl(
        creativeset: string,
        creative: string,
        type: string,
        responsive: boolean,
        accessToken: string
    ) {
        const url = new URL(`${this.previewOrigin}/${creativeset}/${creative}/ui`);
        url.searchParams.set('preview-type', type);
        url.searchParams.set('responsive', String(+responsive));
        url.searchParams.set('responsive-mode', 'contain');
        url.searchParams.set('access-token', accessToken);
        return url.toString();
    }

    private getAccessToken(creativeset: string, creative: string): Observable<string | null> {
        const url = new URL(`${this.previewOrigin}/preview-url`);
        url.searchParams.set('creativeset', creativeset);
        url.searchParams.set('creative', creative);

        return this.http.get<{ previewUrl: string }>(url.toString()).pipe(
            map(({ previewUrl }) => {
                const tmpUrl = new URL(previewUrl);
                return tmpUrl.searchParams.get('access-token');
            }),
            catchError((e: unknown) => {
                const status = (e as HttpErrorResponse)?.status;
                const reason = (e as HttpErrorResponse)?.statusText;
                return throwError(
                    () => new HttpError(`Failed to get access token - ${status}: ${reason}`, e)
                );
            })
        );
    }

    private sendCommandToIframe(cmd: AdApiCommand): void {
        const iframeWindow = this.previewFrame()?.nativeElement.contentWindow;
        if (!iframeWindow) {
            return;
        }

        const message = { type: 'cmd', cmd };

        iframeWindow.postMessage(message, this.previewOrigin);
    }
}
