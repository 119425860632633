import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'ui-dialog-buttons',
    templateUrl: 'dialog-buttons.component.html',
    styleUrls: ['dialog-buttons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.align-left]': `align === 'left'`,
        '[class.align-right]': `align === 'right'`
    }
})
export class UIDialogButtonsComponent {
    @Input() align: 'left' | 'center' | 'right' = 'center';
}
