<div class="text-content">
    <div>
        We’re sorry, it seems like an error has occurred on our server. If you want to get in touch with
        us please send an email to
        <a href="mailto:support@bannerflow.com">support&#64;bannerflow.com</a>.
    </div>

    <div *ngIf="error.message">{{ error.message }}</div>

    <div *ngIf="!error.message">
        For fast help please refer to this Error ID when contacting our support.
    </div>
</div>

<div
    class="error-box"
    *ngIf="!error.message">
    {{ error.requestId }}
</div>

<ui-dialog-buttons>
    <ui-button
        (click)="close()"
        type="default"
        text="Close">
    </ui-button>

    <ui-button
        (click)="reload()"
        type="primary"
        text="Reload">
    </ui-button>
</ui-dialog-buttons>
