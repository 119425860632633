import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'variableFilter'
})
export class VariableFilterPipe implements PipeTransform {
    transform(items: string[], searchText: string, staticColors: string[], remove?: boolean): any[] {
        if (!items) {
            return [];
        }

        if (!searchText) {
            return items;
        }

        searchText = searchText.toLowerCase();

        return items.filter(item => {
            if (remove) {
                return !item.toLowerCase().includes(searchText);
            }

            const foundItem: boolean = item.toLowerCase().includes(searchText);

            // If we didn't find the item itself, we want to check if there are any references to it somewhere
            if (!foundItem) {
                // Check the actual value of the variable
                const variableValue = this.getVariableValue(item);
                const foundValue = variableValue.toLowerCase().includes(searchText);
                if (foundValue) {
                    return foundValue;
                }

                // Check the variable reference name
                const referenceVariable = this.getReferenceVariableName(item, staticColors);
                const foundReference = referenceVariable.toLowerCase().includes(searchText);

                if (foundReference) {
                    return foundReference;
                }
            }

            return foundItem;
        });
    }

    private getVariableValue(variableName: string): string {
        const value: string = window
            .getComputedStyle(document.body)
            .getPropertyValue(variableName)
            .trim();

        return value.includes('url(') ? 'Svg Image' : value;
    }

    private isColor(variableName: string): boolean {
        const property: string = window
            .getComputedStyle(document.body)
            .getPropertyValue(variableName)
            .trim();

        return !!(property[0] === '#' || (property[0] === 'r' && property.includes('rgb')));
    }

    private getReferenceVariableName(variable: string, staticColors: string[]): string {
        const variableHex: string = this.getVariableValue(variable);
        let staticReference = '';

        if (this.isColor(variable)) {
            staticColors.forEach((staticColor: string) => {
                const staticHex = this.getVariableValue(staticColor);
                if (variableHex === staticHex) {
                    staticReference = staticColor;
                }
            });
        }

        return staticReference.length ? staticReference : variableHex;
    }
}
