<div class="header">
    <div class="header__name">{{ name }}</div>
    <ui-button
        ui-theme="small"
        class="header__button"
        *ngIf="buttonIcon"
        [icon]="buttonIcon"
        (click)="buttonClick.emit($event)">
    </ui-button>
    <ng-content select="[panel-header]"></ng-content>
</div>
<div class="content">
    <ng-content></ng-content>
</div>
