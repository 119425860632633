<ng-container *ngIf="grid?.cellTemplate">
    <ng-container
        *ngTemplateOutlet="
            grid.cellTemplate ? grid.cellTemplate : null;
            context: {
                $implicit: value,
                value: value,
                displayValue: displayValue,
                data: data,
                grid: grid
            }
        "></ng-container>
</ng-container>
<ng-container *ngIf="!grid?.cellTemplate && type !== 'image'">
    {{ displayValue !== undefined ? displayValue : '' }}
    Type: {{ type }}
</ng-container>
<ng-container *ngIf="!grid?.cellTemplate && type === 'image' && value">
    <div
        class="image"
        [style.background-image]="'url(' + value + ')'"></div>
</ng-container>
