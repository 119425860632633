import { Component, OnDestroy, TemplateRef, HostListener } from '@angular/core';
import { IUIDialog } from '../dialog/dialog.interface';
import { IUIAlertDialogConfig, UIALERTDIALOG_DEFAULT_CONFIG } from './alert-dialog-config.interface';

@Component({
    selector: 'ui-alert-dialog',
    styleUrls: ['./alert-dialog.component.scss'],
    templateUrl: './alert-dialog.component.html'
})
export class UIAlertDialogComponent implements IUIDialog, OnDestroy {
    config: IUIAlertDialogConfig;
    dialogTemplate: TemplateRef<any>;

    private resolve: (value: boolean | PromiseLike<boolean>) => void;
    private confirmPromise: Promise<boolean>;

    /**
     * Initiate the alert dialog component.
     * Will return a promise which will resolve to true if confirmed by user.
     * If cancelled it will resolve to false.
     * @param config
     */
    initiate(config: IUIAlertDialogConfig): Promise<boolean> {
        this.config = { ...UIALERTDIALOG_DEFAULT_CONFIG, ...config };

        // console.log('alert.init', this.config, UIALERTDIALOG_DEFAULT_CONFIG);

        this.confirmPromise = new Promise<boolean>(resolve => {
            this.resolve = resolve!;
        });

        return this.confirmPromise;
    }

    /**
     * The user pressed confirm
     */
    confirm(): void {
        this.resolve(true);
    }

    /**
     * Clean up component when removed
     */
    ngOnDestroy(): void {
        if (this.resolve) {
            this.resolve(false);
        }
    }

    @HostListener('window:keyup', ['$event'])
    public keyEvent(event: KeyboardEvent): void {
        if (event.code === 'Enter') {
            this.confirm();
        } else if (event.code === 'Escape') {
            this.resolve(false);
        }
    }
}
