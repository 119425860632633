<ui-button
    text="Confirm"
    (click)="confirm()">
</ui-button>

<ui-button
    text="Confirm Discard"
    (click)="confirmDiscard()">
</ui-button>

<ui-button
    text="Confirm & Discard (Cancel)"
    (click)="confirmDiscardWithCancel()">
</ui-button>
