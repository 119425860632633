import { Component } from '@angular/core';

@Component({
    selector: 'ui-checkbox-docs-preview',
    templateUrl: './checkbox.docs.preview.html',
    styleUrls: ['./checkbox.docs.preview.scss'],
})
export class UICheckboxDocsPreview {

    disabled = false;
    on = true;
    cow = true;
    chicken = true;

    checkboxOptions: { label: string, id: number }[] = [
        { label: 'Option 1', id: 0 },
        { label: 'Option 2', id: 1 },
        { label: 'Option 3', id: 2 }
    ];

    selectedOption?: IOption;
    multiSelectedOptions?: IOption[];

    setOnState(on: boolean) {
        this.on = on;
    }

    toggleDisabledState() {
        this.disabled = !this.disabled;
    }

}

interface IOption {
    label: string, id: number
}
