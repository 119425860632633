import { Component, TemplateRef, OnDestroy, ViewChild } from '@angular/core';
import { UISubmitResponse } from '../../../types/button';
import { UIConfirmDialogResult } from '../../../types/dialog';
import { IUIDialog } from '../dialog/dialog.interface';
import {
    UIConfirmDialogConfig,
    UICONFIRMDIALOG_DEFAULT_CONFIG
} from './confirm-dialog-config.interface';
import { UIButtonComponent } from '../../buttons/button/button.component';

@Component({
    selector: 'ui-confirm-dialog',
    styleUrls: ['./confirm-dialog.component.scss'],
    templateUrl: './confirm-dialog.component.html'
})
export class UIConfirmDialogComponent implements IUIDialog, OnDestroy {
    @ViewChild('confirmButton') confirmButton: UIButtonComponent;
    config: UIConfirmDialogConfig = {};
    dialogTemplate: TemplateRef<any>;
    resolve: (value: UIConfirmDialogResult) => void;
    private confirmPromise: Promise<UIConfirmDialogResult>;

    initiate(config: UIConfirmDialogConfig): Promise<UIConfirmDialogResult> {
        this.config = { ...UICONFIRMDIALOG_DEFAULT_CONFIG, ...config };

        document.addEventListener('keyup', this.onPressEnter);

        this.confirmPromise = new Promise<UIConfirmDialogResult>(resolve => {
            this.resolve = resolve;
        });

        return this.confirmPromise;
    }

    cancel(): void {
        this.resolve('cancel');
    }

    discard = async (): Promise<void> => {
        if (!this.config.onDiscard) {
            throw new Error(`The configuration 'onDiscard' is not set.`);
        }
        await this.config.onDiscard();
    };

    onDiscarded = (error?: any): void => {
        if (this.config.onDiscarded) {
            this.config.onDiscarded((result: 'discard' | 'cancel') => {
                this.resolve(result);
            }, error);
        } else {
            if (!error) {
                this.resolve('discard');
                return;
            }
            throw error;
        }
    };

    confirm = async (): Promise<UISubmitResponse<any>> => {
        if (!this.config.onConfirm) {
            throw new Error(`The configuration 'onConfirm' is not set.`);
        }
        return await this.config.onConfirm();
    };

    onConfirmed = (error?: any): void => {
        if (this.config.onConfirmed) {
            this.config.onConfirmed((result: 'confirm' | 'cancel') => {
                this.resolve(result);
            }, error);
        } else {
            if (!error) {
                this.resolve('confirm');
                return;
            }
            throw error;
        }
    };

    onPressEnter = (event: KeyboardEvent): void => {
        if (event.code === 'Enter') {
            if (this.config.onConfirm) {
                this.confirm();
            } else {
                this.resolve('confirm');
            }
        }
    };

    ngOnDestroy(): void {
        this.resolve('cancel');
        document.removeEventListener('keyup', this.onPressEnter);
    }
}
