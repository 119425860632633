import { Component } from '@angular/core';

@Component({
    selector: 'ui-loader-docs-preview',
    templateUrl: './loader.docs.preview.html',
    styleUrls: ['./loader.docs.preview.scss'],
})
export class UILoaderDocsPreview {

    public loading = true;

    public ngOnInit(): void {
        setTimeout(() => {
            this.loading = false;
        }, 2000);
    }

}
