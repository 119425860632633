<div
    class="text"
    [innerHTML]="config.text || ''"></div>
<ui-dialog-buttons>
    <ui-button
        (click)="confirm()"
        type="primary"
        [text]="config.confirmText">
    </ui-button>
</ui-dialog-buttons>
