import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
@Component({
    selector: 'ui-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss']
})
export class UIPaginatorComponent implements OnChanges {
    @Input() totalItems: number;
    @Input() pageSizeOptions: number[] = [10, 50, 100];
    @Input() textDescription = 'items out of';
    @Input() activePage = 1;
    @Input() activePageSizeOption: number;
    @Output() pageChange = new EventEmitter<number>();
    @Output() numOfItemsToDisplay = new EventEmitter<number>();

    lastPageLabel = 0;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['totalItems']) {
            this.updateLastPageLabel();
        }
    }

    setActivePageSize(pageSize: number): void {
        this.activePageSizeOption = pageSize;
        this.updateLastPageLabel();

        if (this.lastPageLabel === 1) {
            this.activePage = 1;
            this.pageChange.emit(this.activePage);
        }

        this.numOfItemsToDisplay.emit(this.activePageSizeOption);
    }

    updateLastPageLabel(): void {
        this.lastPageLabel = Math.ceil(this.totalItems / this.activePageSizeOption);
    }

    setChangedValue(value: number): void {
        this.activePage = value;
        this.pageChange.emit(this.activePage);
    }

    decreasePageSelection(): void {
        this.activePage--;
        this.pageChange.emit(this.activePage);
    }

    increasePageSelection(): void {
        this.activePage++;
        this.pageChange.emit(this.activePage);
    }
}
