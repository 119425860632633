<form [formGroup]="testForm"
    class="thisForm">
    <div class="column">
        <div class="column column--half">
            <ui-number-input
                id="inputOne"
                unitLabel="%"
                [allowEmpty]="false"
                [step]="1"
                [min]="-100"
                [max]="maxValue"
                maxLabel="Max"
                minLabel="Min"
                [(value)]="inputOne"
                label="inputOne with label"
                [disableUndo]="true"
                (undo)="handleUndo()"
                (submit)="onSubmit($event)"></ui-number-input>
        </div>
    </div>
</form>

<div class="column ">
    <ui-number-input id="disabledInput"
        [disabled]="true"
        [allowEmpty]="false"
        [step]="1"
        [min]="-100"
        [max]="maxValue"
        maxLabel="Max"
        minLabel="Min"
        [value]="100"
        label="Disabled Input"></ui-number-input>
</div>


<div class="columns">
    <div class="column column--fourth">
        <ui-number-input #widthInput
                         placeholder="Width"
                         [arrowButtons]="false"
                         [keyboardEmit]="true"
                         [min]="0"
                         (valueChange)="widthChange($event)"
                         (submit)="sizeSubmit()"></ui-number-input>
    </div>
    <div class="column column--fourth">
        <ui-number-input #heightInput
                         placeholder="Height"
                         [arrowButtons]="false"
                         [keyboardEmit]="true"
                         [min]="0"
                         (valueChange)="heightChange($event)"
                         (submit)="sizeSubmit()"></ui-number-input>
    </div>
</div>
