<ui-popover
    #popover="ui-popover"
    [config]="{
        panelClass: 'ui-dropdown',
        hasBackdrop: false,
        width: width,
        useTargetWidth: useTargetWidth,
        offset: offset
    }">
    <ng-template
        ui-popover-template
        #viewContent>
        <ui-dropdown-item
            *ngIf="header"
            class="ui-dropdown-header">
            {{ header }}
        </ui-dropdown-item>
        <div
            class="ui-dropdown-list ui-scrollbar"
            (mouseenter)="dropdownHovered($event)"
            (mouseleave)="dropdownHovered($event)"
            [style.maxHeight]="maxHeight + 'px'">
            <ng-content></ng-content>
        </div>
    </ng-template>
</ui-popover>
