import { Component } from '@angular/core';

@Component({
    selector: 'ui-image-docs-preview',
    templateUrl: './image.docs.preview.html',
    styleUrls: ['./image.docs.preview.scss'],
})
export class UIImageDocsPreview {
    imageUrl: any = 'https://bannerflowsandbox.blob.core.windows.net:443/bf-content/logo/brand/bannerflow-enterprise/5a6f35c2952dd74cecfe35d8/5a02f7a98866581878e53695.png';
    imageUrl2: any = 'https://netrefer.wpengine.com/wp-content/uploads/2017/11/netrefer-favicon-293x300.png';
    imageUrl3: any = 'https://blog.adform.com/img/favicon.ico';
}
